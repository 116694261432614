import { NotificationService } from './../../services/notification.service';
import { JobService } from './../../services/felixApi/job.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { GridService } from '../../services/grid.service';
import { Subscription } from 'rxjs';
import { ClaimsService } from '../../services/felixApi/claims.service';
import { Job } from '../../dtos/job';
import { UserService } from '../../services/felixApi/user.service';
import { User } from '../../dtos/user';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'js-receipts',
  templateUrl: './receipts.component.html',
  styleUrls: ['./receipts.component.scss']
})
export class ReceiptsComponent implements OnInit, OnDestroy {

  dataSource: DataSource;
  subscriptions: Subscription[] = [];
  gridHeight: number;
  jobs: Job[];
  loading = true;
  users: User[];

  constructor(
    private claimsService: ClaimsService,
    private jobService: JobService,
    private globalService: GlobalService,
    protected gridService: GridService,
    private userService: UserService,
    private notiService: NotificationService
  ) {
    this.calculateClientName = this.calculateClientName.bind(this);
    this.calculateSiteAddress = this.calculateSiteAddress.bind(this);
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          this.setHeightWidths();
        }
      )
    );

    this.setHeightWidths();
    this.loadData(true);
  }

  setHeightWidths() {
    this.gridHeight = window.innerHeight - 107;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  loadData(useCache: boolean) {
    this.subscriptions.push(
      this.claimsService.getClaimsData(useCache)
        .subscribe({
          next: () => {
            this.jobs = this.jobService.jobs;
            this.users = this.userService.users;
            this.loading = false;
            this.setUpDataSource();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  setUpDataSource() {
    this.dataSource = new DataSource({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.claimsService.getReceipts(false).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.claimsService.addReceipt(values).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.claimsService.updateReceipt(encodeURIComponent(key), values).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.claimsService.deleteReceipt(encodeURIComponent(key)).subscribe({
              next: () => {
                return resolve();
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      }
    });
  }

  calculateClientName(data) {
    return this.jobs.find(i => i.id === data.jobId)?.contractName;
  }

  calculateSiteAddress(data) {
    return this.jobs.find(i => i.id === data.jobId)?.jobAddressString;
  }
}
