<div class="modal-header">
  <h3 class="modal-title">Global Item Search</h3>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="dx-field">
    <div class="dx-field-label">Vendor (blank for all):</div>
    <div class="dx-field-value">
      <dx-select-box [(ngModel)]="vendorId" [dataSource]="vendors" valueExpr="id" displayExpr="vendorName"
        [showClearButton]="true" [searchEnabled]="true">
      </dx-select-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label">Job (blank for all):</div>
    <div class="dx-field-value">
      <dx-select-box [(ngModel)]="jobId" [dataSource]="jobs" valueExpr="id" displayExpr="jobAddressString"
        [showClearButton]="true" [searchEnabled]="true">
      </dx-select-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label">Price File Item (blank for all):</div>
    <div class="dx-field-value">
      <dx-drop-down-box [dropDownOptions]="dropDownOptions" [dataSource]="priceFileItems" [(value)]="priceFileItemId"
        displayExpr="priceFileCode" valueExpr="id" contentTemplate="contentTemplate" [showClearButton]="true">

        <div *dxTemplate="let e of 'contentTemplate'" class="dropdownClass">
          <dx-data-grid [dataSource]="priceFileItems" keyExpr="id" [remoteOperations]="false" height="500px"
            [showBorders]="true" [selection]="{ mode: 'single' }" [focusedRowEnabled]="true"
            [focusedRowKey]="priceFileItemId" [autoNavigateToFocusedRow]="true" [selectedRowKeys]="[priceFileItemId]"
            [hoverStateEnabled]="true" (onSelectionChanged)="onSelectionChanged(e, $event)">

            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
            <dxo-search-panel [visible]="true" [width]="200"></dxo-search-panel>
            <dxo-group-panel [visible]="false"></dxo-group-panel>
            <dxo-grouping #expand [autoExpandAll]="true" [contextMenuEnabled]="true" expandMode="rowClick">
            </dxo-grouping>

            <dxi-column dataField="costCentreDescription" dataType="string" caption="" [groupIndex]="0" [width]="220"
              groupCellTemplate="groupCellTemplate">
            </dxi-column>

            <dxi-column dataField="costCentreOrderNumber" dataType="number" caption="" sortIndex="1" sortOrder="asc"
              [visible]="false">
            </dxi-column>

            <dxi-column dataField="itemGroupDescription" dataType="string" caption="" [groupIndex]="1" [width]="220"
              groupCellTemplate="groupCellTemplate">
            </dxi-column>

            <dxi-column dataField="itemGroupOrderNumber" dataType="number" caption="" sortIndex="2" sortOrder="asc"
              [visible]="false">
            </dxi-column>

            <dxi-column dataField="priceFileCode" caption="Code" [width]="110" sortOrder="asc"
              sortIndex="3"></dxi-column>

            <dxi-column dataField="description"></dxi-column>

            <dxi-column dataField="unitOfMeasure" dataType="number" caption="Units" [width]="90">
            </dxi-column>

            <dxi-column dataField="rate" dataType="number" [width]="80" [format]="{ type: 'fixedPoint', precision: 2 }">
            </dxi-column>

            <div *dxTemplate="let data of 'groupCellTemplate'">
              {{ data.data.key }}
            </div>
          </dx-data-grid>
        </div>
      </dx-drop-down-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label">Description:</div>
    <div class="dx-field-value">
      <dx-text-box [(ngModel)]="searchDescription" [showClearButton]="true">
      </dx-text-box>
    </div>
  </div>

  <div class="dx-field mb-2">
    <div class="dx-field-label">Product Code:</div>
    <div class="dx-field-value">
      <dx-text-box [(ngModel)]="productCode" [showClearButton]="true">
      </dx-text-box>
    </div>
  </div>

  <dx-data-grid *ngIf="!loading" #copyGrid id="copyGrid" [height]="gridHeight" [dataSource]="orderLines"
    [allowColumnReordering]="false" [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true"
    [rowAlternationEnabled]="true" [wordWrapEnabled]="true" (onToolbarPreparing)="onToolbarPreparing($event)"
    (onExporting)="gridService.onExporting($event, 'find-item')">

    <dxo-search-panel [visible]="true" location="before" [width]="400"></dxo-search-panel>
    <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-sorting mode='multiple'></dxo-sorting>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>

    <dxi-column dataField="jobNumber" dataType="string" caption="Job" [width]="110" sortOrder="asc"> </dxi-column>

    <dxi-column dataField="currentActivity" dataType="string" caption="Activity" [width]="180"
      [calculateCellValue]="calculateActivityDesc"> </dxi-column>

    <dxi-column dataField="priceFileCode" dataType="string" caption="Code" [width]="110" sortOrder="asc"> </dxi-column>

    <dxi-column dataField="description" dataType="string" sortOrder="asc"> </dxi-column>

    <dxi-column dataField="unitOfMeasure" dataType="string" caption="Units" [width]="110"> </dxi-column>

    <dxi-column dataField="productCode" dataType="string" [width]="130"> </dxi-column>

    <dxi-column dataField="supplierQuantity" caption="Quantity" dataType="number" [width]="80"> </dxi-column>

    <dxi-column dataField="rate" dataType="number" [width]="100" format="#,###.00"> </dxi-column>

    <dxi-column dataField="lineTotal" caption="Total" dataType="number" [width]="100" format="#,###.00"> </dxi-column>

    <dxi-column dataField="purchaseOrderNumber" dataType="string" caption="Order#" [width]="90"> </dxi-column>
  </dx-data-grid>
</div>

<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loading" [showIndicator]="true" [showPane]="true"
  [shading]="false" [hideOnOutsideClick]="false">
</dx-load-panel>
