import { Injectable } from '@angular/core';

export class HelpDictionary {
  dataField: string;
  title: string;
  helpText: string;
}

const helpDictionary: HelpDictionary[] = [{
  dataField: 'extraIncomeIncGST',
  title: 'Extra Income Inc.GST',
  helpText: 'User definable field to allow entry of extra income, e.g. rebates, to correctly display the margin for the job. This is only used in this margin calculation screen.',
},
{
  dataField: 'contractPriceIncGST',
  title: 'Forecast Price Inc.GST',
  helpText: 'If the contract is locked this is equal to the contract price.\n' +
    'If the contract is NOT locked this is the sales price however this can be overridden via the WIP or the Tracking -> Forecast.',
},
{
  dataField: 'jobCostExcGST',
  title: 'Job Cost Ex.GST',
  helpText: 'If Orders are NOT locked this is the greater of the estimated total or the manual job cost entered into the Forecast.\n'
    + 'If the orders ARE locked then this is the total of the Orders however this will be affected by orders with accrual stopped'
    + ' where the total for that PO is then the total of the invoices entered against that purchase order.',
}];




@Injectable({
  providedIn: 'root'
})
export class HelpService {
  getHelpDictionary() {
    return helpDictionary;
  }
}
