<div class="modal-header">
  <h4 class="modal-title">Order logo</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="page">
    NOTE: Overwrites the default logo for the division.
    <br><br>
    <js-loading-spinner *ngIf="loading"></js-loading-spinner>

    <div *ngIf="logo">
      <img [src]="'data:image/jpeg;base64,'+logo.attachment" />
    </div>

    <div *ngIf="!loading && !logo && isAdmin" class="blueBorder">
      <input type="file" (change)="fileChangeEvent($event)"
        style="width: 100%; height: 100px; border: 2px; border-color: blue; border-style: dashed;" />
      <br>
      <image-cropper style="width: 100%; height: 400px;" [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="false" [aspectRatio]="3 / 2" format="png" (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"
        output="base64">
      </image-cropper>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button *ngIf="logo" class="btn btn-sm btn-secondary" (click)="deleteLogo()">Delete Logo</button>
  <button *ngIf="!logo && fileToUpload" class="btn btn-sm btn-secondary" (click)="fileSelected()">Upload Logo</button>
</div>
