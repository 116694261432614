import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceHelperService {

  orderSections = ['Extras Codes', 'Extras Reasons', 'Control'];
  accountInvoiceSections = ['Job Divisions', 'Activities', 'Vendor Types', 'Division Accounts', 'Vendor Groups', 'Job-Accounting Customers'];
  itemInvoiceSections = ['Job Divisions', 'Vendor Types', 'Division Products', 'Vendor Groups', 'Job-Accounting Customers'];
  
  selectedSection = 'Extras Codes';

  // houseTypeModMode = new BehaviorSubject(false);

  constructor() { }

}
