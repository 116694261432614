import { InvoiceError } from './../../dtos/invoice-error';
import { CompanyService } from './company.service';
import { SplitOrder } from './../../dtos/purchase-order';
import { DivisionService } from './division.service';
import { EstimatingService } from './estimating.service';
import { PoService } from './po.service';
import { Vendor } from './../../dtos/vendor';
import { InvoiceBatch } from './../../dtos/invoice-batch';
import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable, forkJoin, of } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { MaintenanceService } from './maintenance.service';
import { JobService } from './job.service';
import { Invoice, InvoiceStatusTypeEnum } from '../../dtos/invoice';
import { FileAttachment } from '../../dtos/file-attachment';
import { HttpService } from '../http.service';
import { PurchaseOrder } from '../../dtos/purchase-order';
import { SupplierPayTermsTypesEnum } from '../../dtos/vendor-group';
import { Holiday } from '../../dtos/holiday';
import { TaskControl } from '../../dtos/task-control';
import { SubbieInvoiceDateCheckComponent } from '../../invoice/subbie-invoice-date-check/subbie-invoice-date-check.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilsService } from '../utils.service';
import { UserService } from './user.service';
import { CompanyActivityService } from './company-activity.service';
import { InvoiceLog } from '../../dtos/invoice-log';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  globalGSTRate: number;

  MS_PER_DAY = 1000 * 60 * 60 * 24;
  holidays: Holiday[] = [];
  holidaysCompany: number;
  taskControl: TaskControl;
  invoiceErrors: InvoiceError[] = [];
  invoiceBatches: InvoiceBatch[] = [];

  constructor(
    private _http: HttpClient,
    private divisionService: DivisionService,
    private maintenanceService: MaintenanceService,
    private estimatingService: EstimatingService,
    private companyService: CompanyService,
    private jobService: JobService,
    private poService: PoService,
    private globalService: GlobalService,
    private modalService: NgbModal,
    private utilsService: UtilsService,
    private userService: UserService,
    private companyActivityService: CompanyActivityService,
    private httpService: HttpService) { }


  getInvoicesData(useCache: boolean): Observable<InvoiceBatch[]> {
    return forkJoin(
      [
        this.getInvoiceBatches(),
        this.getVendorData(useCache),
        this.jobService.getJobsByAddressWithExtras(useCache),
        this.getCurrentGST(),
        this.maintenanceService.getInvoiceControlData(useCache),
        this.estimatingService.getPriceFileItemGroups(useCache),
        this.companyService.getCompanyConfigurations()
      ]
    )
      .pipe(map(
        ([invoiceBatches]) => {
          return invoiceBatches;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getInvoicesDataForOnHold(useCache: boolean): Observable<Vendor[]> {
    return forkJoin(
      [
        this.getVendorData(useCache),
        this.jobService.getJobsByAddressWithExtras(useCache),
        this.getCurrentGST(),
        this.maintenanceService.getInvoiceControlData(useCache),
        this.estimatingService.getPriceFileItemGroups(useCache),
        this.getInvoiceErrors(),
        this.companyActivityService.getCompanyActivities(),
        this.maintenanceService.getExtraCodes(true),
        this.maintenanceService.getExtraReasons(true),
        this.userService.getInvoiceUsersForAllocation(useCache)
      ]
    )
      .pipe(map(
        ([result]) => {
          return result;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getInvoicesDataForBackCharges(useCache: boolean): Observable<Vendor[]> {
    return forkJoin(
      [
        this.getVendorData(useCache),
        this.jobService.getJobsByAddress(useCache),
        this.maintenanceService.getInvoiceControlData(useCache),
        this.estimatingService.getPriceFileItemGroups(useCache)
      ]
    )
      .pipe(map(
        ([result]) => {
          return result;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getPurchaseOrdersWithLines(jobId: number): Observable<PurchaseOrder[]> {
    return forkJoin(
      [
        this.poService.getPurchaseOrdersForJob(jobId),
        this.poService.getOrderLineExtras(jobId),
        this.poService.getOrderLines(jobId, null),
        this.poService.getInvoicesForJob(jobId)
      ]
    )
      .pipe(map(
        ([dataRecords]) => {
          return dataRecords;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getWIPData(useCache: boolean): Observable<any[]> {
    return forkJoin(
      [
        this.divisionService.getDivisions(useCache),
        this.maintenanceService.getAllVendors(useCache),
        this.maintenanceService.getVendorPayables(useCache),
        this.jobService.getJobsByAddressWithExtras(useCache),
        this.getCurrentGST(),
        this.estimatingService.getPriceFileItemGroups(useCache),
        this.userService.getCurrCompUsers(useCache),
        this.getTaskControl()
      ]
    )
      .pipe(map(
        ([dataRecords]) => {
          return dataRecords;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getVendorData(useCache: boolean): Observable<Vendor[]> {
    return forkJoin(
      [
        this.maintenanceService.getAllVendors(useCache),
        this.maintenanceService.getVendorPayables(useCache),
        this.maintenanceService.getVendorInvoiceApprovers(useCache),
        this.maintenanceService.getVendorAccounts(useCache),
        this.getHolidays(),
        this.getTaskControl(),
        this.maintenanceService.getVendorGroups(useCache)
      ]
    )
      .pipe(map(
        ([result]) => {
          return result;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getCurrentGST(): Observable<number> {
    if (this.globalGSTRate) {
      return of(this.globalGSTRate);
    } else {
      return this._http.get<number>(this.globalService.getApiUrl()
        + '/gst/current', this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.globalGSTRate = res;
          }),
          catchError(this.handleError));
    }
  }

  getInvoices(batchId: number, invoiceStatusId: number, vendorId: number,
    jobNumber: string, externalInvoiceId: string, purchaseOrderId: number,
    costCentreId: number, includeDeleted: boolean): Observable<Invoice[]> {
    if (batchId === 0) {
      return of([]);
    } else {
      let url = this.globalService.getApiUrl() + '/invoices';
      let hasParam = false;

      if (batchId) {
        url += '?invoiceBatchId=' + batchId;
        hasParam = true;
      }

      if (invoiceStatusId) {
        url += hasParam ? '&' : '?';
        url += 'invoiceStatusId=' + invoiceStatusId;
        hasParam = true;
      }

      if (vendorId) {
        url += hasParam ? '&' : '?';
        url += 'vendorId=' + vendorId;
        hasParam = true;
      }

      if (jobNumber && jobNumber !== '') {
        url += hasParam ? '&' : '?';
        url += 'jobNumber=' + jobNumber;
        hasParam = true;
      }

      if (externalInvoiceId && externalInvoiceId !== '') {
        url += hasParam ? '&' : '?';
        url += 'externalInvoiceId=' + externalInvoiceId;
        hasParam = true;
      }

      if (purchaseOrderId) {
        url += hasParam ? '&' : '?';
        url += 'purchaseOrderId=' + purchaseOrderId;
        hasParam = true;
      }

      if (costCentreId) {
        url += hasParam ? '&' : '?';
        url += 'costCentreId=' + costCentreId;
        hasParam = true;
      }

      if (includeDeleted) {
        url += hasParam ? '&' : '?';
        url += 'includeDeleted=true';
      }

      return this._http.get<Invoice[]>(url, this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
    }
  }

  getInvoicesApproved(useCache: boolean): Observable<Invoice[]> {
    return forkJoin(
      [
        this.getInvoices(null, InvoiceStatusTypeEnum.Approved, null, null, null, null, null, false),
        this.poService.getAllPurchaseOrders(useCache)
      ]
    )
      .pipe(map(
        ([result]) => {
          return result;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getAllInvoices(vendorId: number, jobNumber: string, externalInvoiceId: string,
    purchaseOrderId: number, useCache: boolean, includeDeleted: boolean): Observable<Invoice[]> {
    return forkJoin(
      [
        this.getInvoices(null, null, vendorId, jobNumber, externalInvoiceId, purchaseOrderId, null, includeDeleted),
        this.poService.getAllPurchaseOrders(useCache)
      ]
    )
      .pipe(map(
        ([result]) => {
          return result;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getInvoicesAndPOs(batchId: number, invoiceStatusId: number, useCache: boolean): Observable<Invoice[]> {
    return forkJoin(
      [
        this.getInvoices(batchId, invoiceStatusId, null, null, null, null, null, false),
        this.poService.getAllPurchaseOrders(useCache)
      ]
    )
      .pipe(map(
        ([result]) => {
          return result;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getInvoicesOnHold(useCache: boolean): Observable<Invoice[]> {
    return forkJoin(
      [
        this.getInvoices(null, InvoiceStatusTypeEnum.OnHold, null, null, null, null, null, false),
        this.poService.getAllPurchaseOrders(useCache)
      ]
    )
      .pipe(map(
        ([result]) => {
          return result;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getInvoiceLogs(invoiceId: number): Observable<InvoiceLog[]> {
    const url = this.globalService.getApiUrl() + '/invoice-logs?invoiceId=' + invoiceId;
    return this._http.get<InvoiceLog[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.globalService.handleError));
  }

  getInvoiceErrors(): Observable<InvoiceError[]> {
    const url = this.globalService.getApiUrl() + '/invoice-errors';
    return this._http.get<InvoiceError[]>(url, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.invoiceErrors = res;
      }),
      catchError(this.globalService.handleError));
  }

  getVendorInvoice(vendorId: number, jobId: number, invoiceNumber: string): Observable<Invoice[]> {
    const url = this.globalService.getApiUrl() + '/invoices/existing?vendorId=' + vendorId + '&jobId=' + jobId + '&invoiceNumber=' + invoiceNumber;
    return this._http.get<Invoice[]>(url, this.httpService.getHttpOptions());
  }

  addInvoice(dataRecord: any): Observable<Invoice> {
    const url = this.globalService.getApiUrl() + '/invoices';
    return this._http.post<Invoice>(url, JSON.stringify(dataRecord, this.jsonReplacer), this.httpService.getHttpOptions());
  }

  updateInvoice(id: string, itm: any): Observable<Invoice> {
    const url = this.globalService.getApiUrl() + '/invoices/' + id;
    return this._http.patch<Invoice>(url, JSON.stringify(itm, this.jsonReplacer), this.httpService.getHttpOptions());
  }

  rejectInvoice(id: string, dataRecord: object) {
    const url = this.globalService.getApiUrl() + '/invoices/' + id + '/reject';
    return this._http.patch(url, JSON.stringify(dataRecord, this.jsonReplacer), this.httpService.getHttpOptions());
  }

  backChargeInvoice(id: number, dataRecord: object): Observable<Invoice> {
    const url = this.globalService.getApiUrl() + '/invoices/' + id + '/back-charge';
    return this._http.post<Invoice>(url, JSON.stringify(dataRecord, this.jsonReplacer), this.httpService.getHttpOptions());
  }

  createBackChargeCredit(dataRecord: object): Observable<Invoice> {
    const url = this.globalService.getApiUrl() + '/invoices/create-credit';
    return this._http.post<Invoice>(url, JSON.stringify(dataRecord, this.jsonReplacer), this.httpService.getHttpOptions());
  }

  deleteInvoice(id: string) {
    const url = this.globalService.getApiUrl() + '/invoices/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  checkIfInvoicePaid(id: string): Observable<Invoice> {
    const url = this.globalService.getApiUrl() + '/invoices/' + id + '/payment-status';
    return this._http.post<Invoice>(url, '', this.httpService.getHttpOptions());
  }

  getInvoiceBatches(): Observable<InvoiceBatch[]> {
    return this._http.get<InvoiceBatch[]>(this.globalService.getApiUrl() +
      '/invoice-batches', this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.invoiceBatches = res;
        }),
        catchError(this.handleError));
  }

  addInvoiceBatch(dataRecord: any): Observable<InvoiceBatch> {
    const url = this.globalService.getApiUrl() + '/invoice-batches';
    return this._http.post<InvoiceBatch>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateInvoiceBatch(id: string, itm: any): Observable<InvoiceBatch> {
    const url = this.globalService.getApiUrl() + '/invoice-batches/' + id;
    return this._http.patch<InvoiceBatch>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteInvoiceBatch(id: string) {
    const url = this.globalService.getApiUrl() + '/invoice-batches/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  uploadInvoice(invoiceId: number, xlFile) {
    const options = this.httpService.getHttpFileOptions();
    return this._http.post(this.globalService.getApiUrl()
      + '/invoices/' + invoiceId + '/attachments/upload', xlFile, options)
      .pipe(
        catchError(this.handleError.bind(this)));
  }

  scanInvoice(invoiceBatchId: number, xlFile) {
    const options = this.httpService.getHttpFileOptions();
    return this._http.post(this.globalService.getApiUrl()
      + '/invoicescan?invoiceBatchId=' + invoiceBatchId, xlFile, options)
      .pipe(
        catchError(this.handleError.bind(this)));
  }

  replaceInvoiceAttachment(invoiceId: number, xlFile): Observable<FileAttachment> {
    const options = this.httpService.getHttpFileOptions();
    return this._http.post(this.globalService.getApiUrl()
      + '/invoices/' + invoiceId + '/attachments/replace', xlFile, options)
      .pipe(
        catchError(this.handleError.bind(this)));
  }

  deleteInvoiceAttachment(id: string) {
    const url = this.globalService.getApiUrl() + '/invoice-attachments/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  getInvoiceAttachment(id: number): Observable<FileAttachment> {
    return this._http.get<FileAttachment>(this.globalService.getApiUrl() +
      '/invoice-attachments/' + id, this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  processInvoiceBatch(invoiceBatchId: number): Observable<InvoiceBatch> {
    const url = this.globalService.getApiUrl() + '/invoice-batches/' + invoiceBatchId + '/process';
    return this._http.patch<InvoiceBatch>(url, JSON.stringify({}), this.httpService.getHttpOptions());
  }

  processOnHoldInvoices(): Observable<InvoiceBatch> {
    const url = this.globalService.getApiUrl() + '/invoices/process-onhold';
    return this._http.patch<InvoiceBatch>(url, JSON.stringify({}), this.httpService.getHttpOptions());
  }

  splitExistingInvoice(invoiceId: number, splits: SplitOrder[]) {
    const url = this.globalService.getApiUrl() + '/invoices/' + invoiceId + '/split';
    return this._http.patch(url, JSON.stringify({ splitOrders: splits }), this.httpService.getHttpOptions());
  }

  getTaskControl(): Observable<TaskControl> {
    const url = this.globalService.getApiUrl() + '/task-controls/';
    return this._http.get<TaskControl>(url, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.taskControl = res;

        // ensure we have a date
        this.taskControl.subContractorPayStartDateAsDate = this.utilsService.convertDateStringToDate(res.subContractorPayStartDate);
      }),
      catchError(this.handleError));
  }

  getHolidays(): Observable<Holiday[]> {
    if (this.holidaysCompany === this.globalService.getCurrentCompany().id
      && this.holidays && this.holidays.length) {
      return of(this.holidays);
    } else {
      const url = this.globalService.getApiUrl() + '/holidays/';
      return this._http.get<Holiday[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.holidays = res;

          // ensure we have dates
          this.holidays.forEach(holiday => {
            holiday.holidayDate = this.utilsService.convertDateStringToDate(holiday.date);
          });
          this.holidaysCompany = this.globalService.getCurrentCompany().id;
        }),
        catchError(this.handleError));
    }
  }

  async calcDueDate(vendorId: number, invoiceDateString: Date | string, enteredDateString: Date | string): Promise<Date> {
    if (!invoiceDateString) {
      return null;
    }

    let invoiceDate = this.utilsService.convertDateStringToDate(invoiceDateString);
    let dueDate = this.utilsService.convertDateStringToDate(invoiceDateString);
    let enteredDate = this.utilsService.convertDateStringToDate(enteredDateString);

    const vendor = this.maintenanceService.allVendors.find(i => i.id === vendorId);

    if (!vendor || !vendor.vendorGroupId) {
      return dueDate;
    }

    const vendorGroup = this.maintenanceService.vendorGroups.find(i => i.id === vendor.vendorGroupId);

    if (vendorGroup) {
      if (vendorGroup.supplierPayTermsTypeId === SupplierPayTermsTypesEnum.Invoice) {
        if (vendorGroup.supplierPayTermsDays) {
          dueDate = this.dateAddDays(dueDate, vendorGroup.supplierPayTermsDays);
        }
      } else if (vendorGroup.supplierPayTermsTypeId === SupplierPayTermsTypesEnum.Statement) {
        // statement
        // use the followingMonths to find the starting point
        const followingMonths = vendorGroup.followingMonths ? vendorGroup.followingMonths : 0;
        dueDate = new Date(dueDate.getFullYear(), dueDate.getMonth() + 1 + followingMonths, 0);

        // if February we use the last day
        if (vendorGroup.supplierPayTermsDays) {
          if (dueDate.getMonth() === 0 && vendorGroup.supplierPayTermsDays > 28 && vendorGroup.supplierPayTermsDays < 32) {
            dueDate = new Date(dueDate.getFullYear(), dueDate.getMonth() + 2, 0);
          } else {
            dueDate = this.dateAddDays(dueDate, vendorGroup.supplierPayTermsDays);
          }
        }
      } else {
        // subbie
        // calc due date for invoice date
        let diffDays = this.dateDiffInDays(dueDate, this.taskControl.subContractorPayStartDateAsDate);
        let numberOfPeriods = Math.ceil(diffDays / vendorGroup.supplierPayTermsDays);

        dueDate = this.dateAddDays(this.taskControl.subContractorPayStartDateAsDate,
          (numberOfPeriods * vendorGroup.supplierPayTermsDays) + this.taskControl.daysToPaySubContractorsFromCutOff);

        dueDate = this.calcSubbieDueDate(dueDate, vendorGroup.supplierPayTermsDays);

        if (this.maintenanceService.orderControl.isSubcontractorsCutOffStrict) {
          // calc due date for the actual date entered
          diffDays = this.dateDiffInDays(enteredDate, this.taskControl.subContractorPayStartDateAsDate);
          numberOfPeriods = Math.ceil(diffDays / vendorGroup.supplierPayTermsDays);

          let dueDateFromEntry = this.dateAddDays(this.taskControl.subContractorPayStartDateAsDate,
            (numberOfPeriods * vendorGroup.supplierPayTermsDays) + this.taskControl.daysToPaySubContractorsFromCutOff);

          dueDateFromEntry = this.calcSubbieDueDate(dueDateFromEntry, vendorGroup.supplierPayTermsDays);

          if (this.dateDiffInDays(dueDateFromEntry, dueDate) !== 0) {
            const modalRef = this.modalService.open(SubbieInvoiceDateCheckComponent, { backdrop: 'static', keyboard: false });
            modalRef.componentInstance.invoiceDate = invoiceDate;
            modalRef.componentInstance.dueDate = dueDate;
            modalRef.componentInstance.dueDateFromEntry = dueDateFromEntry;

            await modalRef.result.then(() => {
              dueDate = dueDateFromEntry;
            }, () => { });
          }
        }
      }
    } else {
      return null;
    }

    const newDate = this.getPrevWorkdayIfWeekendOrHoliday(dueDate);
    return this.dateDiffInDays(newDate, dueDate) <= 0 ? dueDate : newDate;
  }

  calcSubbieDueDate(dueDate: Date, supplierPayTermsDays: number): Date {
    if (this.dateDiffInDays(dueDate, new Date()) >= 0) {
      return dueDate;
    }

    const diffDays = this.dateDiffInDays(dueDate, this.taskControl.subContractorPayStartDateAsDate);
    const numberOfPeriods = Math.ceil(diffDays / supplierPayTermsDays);

    dueDate = this.dateAddDays(this.taskControl.subContractorPayStartDateAsDate,
      (numberOfPeriods * supplierPayTermsDays) + this.taskControl.daysToPaySubContractorsFromCutOff);

    return this.calcSubbieDueDate(dueDate, supplierPayTermsDays);
  }

  // a and b are javascript Date objects
  dateDiffInDays(a: Date, b: Date): number {
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc1 - utc2) / this.MS_PER_DAY);
  }

  dateAddDays(startingDate: Date, days: number): Date {
    const date = new Date(startingDate.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  }

  getPrevWorkdayIfWeekendOrHoliday(originalDate: Date): Date {
    let tmpDate = new Date(originalDate.toDateString());

    while (tmpDate.getDay() === 0 || tmpDate.getDay() === 6 || this.isHoliday(tmpDate, true)) {
      tmpDate = this.dateAddDays(tmpDate, -1);
    }
    return tmpDate;
  }

  isHoliday(originalDate: Date, skipIfNotUsed: boolean): boolean {
    const holiday = this.holidays.find(w => this.dateDiffInDays(w.holidayDate, originalDate) === 0
      && (!skipIfNotUsed || w.isUsedInCashflow));

    if (holiday) {
      return true;
    }

    return false;
  }

  jsonReplacer(key, value) {
    if (this[key] instanceof Date) {
      return this[key].toDateString();
    }
    return value;
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
