import { RecipeRate } from './recipeRate';

export class Recipe {
  public id: number;
  public recipeParentId: number;
  public recipeTypeId: number;
  public orderNumber: number;
  public recipeCode: string;
  public description: string;
  public unitOfMeasure: string;
  public rate: number;
  public scale: number;
  public rateLastUpdatedDate: Date;
  public isActive: boolean;
  public children: RecipeRate[];
  public companyMarginId: number;
  public isGSTFree: boolean;
  public sellingPrice: number;
  public sellingPriceLastUpdated: Date;
  public districtId: number;
  public costingDate: Date;
  public recipeCostingVendorTypeId: number;
  public recipeRateId: number;

  public priceFileItemId: number;
  public masterGroupCostCentre: string;
  public costCentreId: number;
  public subGroupItemDesc: string;
  public vendorItemDescription: string;
  public vendorId: number;
  public expiryDate: Date; // for a price file item
  public quantity: number;
  public hasSizes: boolean;
  public productCode: string;
  public qtySizeControlId: number;
  public comment: string; // for a price file item
}
