<dx-select-box id="varianceCode" [(ngModel)]="varianceCodeId" [dataSource]="varianceCodes" valueExpr="id"
  [showClearButton]="true" [searchEnabled]="true" displayExpr="description" placeholder="Select code"
  (onValueChanged)="onCodeValueChanged($event)">
</dx-select-box>

<dx-select-box id="varianceReason" [(ngModel)]="varianceReason" [dataSource]="varianceReasons" valueExpr="reason"
  [acceptCustomValue]="true" [showClearButton]="true" [searchEnabled]="true"
  (onValueChanged)="onReasonValueChanged($event)" (onCustomItemCreating)="addCustomItem($event)" displayExpr="reason"
  placeholder="Select or Enter reason">
</dx-select-box>
