<div class="modal-header">
  <h4 class="modal-title" placement="bottom">Summary for {{jobNumber}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <dx-data-grid class="mt-3" #treeList id="tree-list" [dataSource]="dataSource" [height]="gridHeight"
    [remoteOperations]="false" [showRowLines]="false" [showColumnLines]="true" [showBorders]="true"
    [columnAutoWidth]="false" [wordWrapEnabled]="true" [allowColumnResizing]="true"
    (onCellPrepared)="onCellPrepared($event)" (onExporting)="gridService.onExporting($event, 'cc-summary')">

    <dxo-search-panel [visible]="true" [width]="130"></dxo-search-panel>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-paging [enabled]="false"> </dxo-paging>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-sorting mode="multiple"></dxo-sorting>

    <dxi-column dataField="costCentreOrderNumber" dataType="number" [visible]="false" sortOrder="asc">
    </dxi-column>

    <dxi-column dataField="costCode" caption="Code" dataType="string" [width]="120">
    </dxi-column>

    <dxi-column dataField="description" dataType="string">
    </dxi-column>

    <dxi-column dataField="lineCost" caption="Cost" dataType="number" [width]="130" format="#,##0.00">
    </dxi-column>

    <dxi-column dataField="estimatedCost" caption="Estimated" dataType="number" [width]="130" format="#,##0.00">
    </dxi-column>

    <dxi-column dataField="difference" caption="Difference" dataType="number" [width]="130" format="#,##0.00"
      [calculateCellValue]="calcDifference">
    </dxi-column>

    <dxo-summary>
      <dxi-total-item column="lineCost" summaryType="sum" [valueFormat]="{ type: 'fixedPoint', precision: 2 }"
        displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="estimatedCost" summaryType="sum" [valueFormat]="{ type: 'fixedPoint', precision: 2 }"
        displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="difference" summaryType="sum" [valueFormat]="{ type: 'fixedPoint', precision: 2 }"
        displayFormat="{0}">
      </dxi-total-item>
    </dxo-summary>
  </dx-data-grid>
</div>
