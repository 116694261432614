<div *ngIf="!loadingData" class="page">

  <dx-data-grid class="m-auto" [height]="gridHeight" [dataSource]="dataSource" [allowColumnReordering]="true"
    [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true" [rowAlternationEnabled]="true"
    [wordWrapEnabled]="true" [hoverStateEnabled]="true" (onToolbarPreparing)="onToolbarPreparing($event)"
    (onExporting)="gridService.onExporting($event, 'margins')">

    <dxo-search-panel [visible]="true" location="before" [width]="200"></dxo-search-panel>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-column-chooser [enabled]="true" mode="dragAndDrop"> </dxo-column-chooser>
    <dxo-state-storing [enabled]="true" type="custom" [savingTimeout]="500" [customSave]="saveState"
      [customLoad]="loadState">
    </dxo-state-storing>

    <dxo-editing mode="row" [allowUpdating]="true" [useIcons]="true" startEditAction="click"
      [selectTextOnEditStart]="true" refreshMode="full">
    </dxo-editing>

    <dxi-column dataField="jobNumber" caption="Job" width="85" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="masterJob" width="85" [allowEditing]="false" [calculateCellValue]="calculateMasterJob">
    </dxi-column>

    <dxi-column dataField="division" width="100" [calculateCellValue]="calculateDivision" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="contractName" minWidth="100" [allowEditing]="false"> </dxi-column>

    <dxi-column dataField="houseTypeId" caption="House Type" dataType="number" width="130" [allowEditing]="false">
      <dxo-lookup [dataSource]="houseTypes" displayExpr="description" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="salesDate" dataType="date" width="100" format="d/M/yy" alignment="center" [visible]="false"
      [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="lockedDate" caption="Orders Locked Date" dataType="date" width="95" format="d/M/yy"
      alignment="center" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="completionDate" caption="Practical Completion" dataType="date" width="100" format="d/M/yy"
      alignment="center" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="currentActivity" [visible]="false" minWidth="100" [allowEditing]="false"> </dxi-column>

    <dxi-column dataField="contractPrice" caption="Contract Price Inc.GST" dataType="number" width="110" format="#,###"
      [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="postContractVariations" dataType="number" width="95" format="#,###" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="extraIncomeIncGST" caption="Extra Income Inc.GST" dataType="number" width="95" format="#,###"
      headerCellTemplate="headerTemplate"> </dxi-column>

    <dxi-column dataField="contractTotal" caption="Contract Total Inc.GST" dataType="number" width="110"
      [calculateCellValue]="calcTotalContract" format="#,###" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="allOrdersTotal" dataType="number" width="110" format="#,###" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="jobCostLessExtras" caption="Original Job Cost Ex.GST" dataType="number" width="110"
      [calculateCellValue]="calculateOriginalCost" format="#,###" [allowEditing]="false"> </dxi-column>

    <dxi-column dataField="orderExtraTotal" caption="Total Extras Ex.GST" dataType="number" width="110" format="#,###"
      [allowEditing]="false"> </dxi-column>

    <dxi-column dataField="jobCostExcGST" caption="Job Cost Ex.GST" dataType="number" width="110" format="#,###"
      [allowEditing]="false" headerCellTemplate="headerTemplate">
    </dxi-column>

    <dxi-column dataField="profit" caption="Gross Profit" dataType="number" width="100"
      [calculateCellValue]="calcProfit" format="#,###" [visible]="false" [allowEditing]="false"> </dxi-column>

    <dxi-column *ngIf="!showProfitAsMargin" dataField="originalMarkUp" caption="Original Mark Up%" dataType="number"
      width="85" [calculateCellValue]="calcScheduledMarkUpPercent" format="#,###.00" alignment='center'
      [allowEditing]="false">
    </dxi-column>

    <dxi-column *ngIf="showProfitAsMargin" dataField="originalMargin" caption="Original Margin %" dataType="number"
      width="85" [calculateCellValue]="calcScheduledMarginPercent" format="#,###.00" alignment='center'
      [allowEditing]="false">
    </dxi-column>

    <dxi-column *ngIf="!showProfitAsMargin" dataField="markUp" caption="Current Mark Up%" dataType="number" width="85"
      [calculateCellValue]="calcMarkUpPercent" format="#,###.00" alignment='center' [allowEditing]="false">
    </dxi-column>

    <dxi-column *ngIf="showProfitAsMargin" dataField="margin" caption="Current Margin %" dataType="number" width="85"
      [calculateCellValue]="calcMarginPercent" format="#,###.00" alignment='center' [allowEditing]="false">
    </dxi-column>

    <dxi-column *ngIf="!showProfitAsMargin" dataField="markUpDrift" caption="Mark Up% Drift" dataType="number"
      width="85" [calculateCellValue]="calcMarkUpPercentDrift" format="#,###.00" alignment='center'
      [allowEditing]="false">
    </dxi-column>

    <dxi-column *ngIf="showProfitAsMargin" dataField="marginDrift" caption="Margin% Drift" dataType="number" width="85"
      [calculateCellValue]="calcMarginPercentDrift" format="#,###.00" alignment='center' [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="siteAddress" minWidth="200" [calculateCellValue]="calculateSiteAddress" [visible]="false"
      [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="contractSigned" caption="Contract Signed" dataType="date" width="100" format="d/M/yy"
      alignment="center" [visible]="false" [allowEditing]="false" [calculateCellValue]="calculateContractSignedDate">
    </dxi-column>

    <dxi-column dataField="salesRepId" dataType="number" [caption]="salesRepWording" [width]="100" alignment="left"
      [allowEditing]="false" [visible]='false' [calculateCellValue]="calculateSalesRep">
    </dxi-column>

    <dxi-column dataField="estimatedCostExcGST" dataType="number" caption="Estimated Cost Ex.GST" [width]="100"
      format="#,###" [allowEditing]="false" [visible]='false'>
    </dxi-column>


    <dxi-column type="buttons" [width]="70">
      <dxi-button name="edit"></dxi-button>
    </dxi-column>


    <dxo-summary>
      <dxi-total-item column="contractPrice" summaryType="sum" valueFormat="#,###" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="postContractVariations" summaryType="sum" valueFormat="#,###" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="extraIncomeIncGST" summaryType="sum" valueFormat="#,###" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="contractTotal" summaryType="sum" valueFormat="#,###" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="allOrdersTotal" summaryType="sum" valueFormat="#,###" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="jobCostLessExtras" summaryType="sum" valueFormat="#,###" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="orderExtraTotal" summaryType="sum" valueFormat="#,###" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="jobCostExcGST" summaryType="sum" valueFormat="#,###" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="profit" summaryType="sum" valueFormat="#,###" displayFormat="{0}">
      </dxi-total-item>
    </dxo-summary>

    <div *dxTemplate="let info of 'headerTemplate'">
      <p>{{info.column.caption}}</p>
      <i (click)="$event.preventDefault(); $event.stopPropagation();help(info.column.dataField)"
        class="material-icons md-18 help">help_outline</i>
    </div>
  </dx-data-grid>

</div>

<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [visible]="loadingData" [showIndicator]="true"
  [showPane]="true" [shading]="false" [hideOnOutsideClick]="false">
</dx-load-panel>
