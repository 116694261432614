<div class="page">

  <h4>
    Load History Orders - use with caution!
  </h4>

  <div>(fields: Job Number, Cost Centre, BLoad, PO Number, Vendor Code, PO Total, Date)</div>

  <js-loading-spinner *ngIf="loadingFile"></js-loading-spinner>

  <div *ngIf="!loadingFile" id="fileuploader">
    <dx-file-uploader #fileUploader selectButtonText="Select file" labelText="or drop file here" [multiple]="false"
      [(value)]="fileValue" uploadMode="useForm">
    </dx-file-uploader>
  </div>

  <dx-check-box *ngIf="!loadingFile" text="Skip missing jobs" [(value)]='skipMissingJobs' class="ms-2">
  </dx-check-box>
  <br>

  <dx-check-box *ngIf="!loadingFile" text="Skip cost centres with existing orders?" [(value)]='skipWhereOrdersExist'
    class="ms-2">
  </dx-check-box>
  <br>

  Include completions from:
  <dx-date-box *ngIf="!loadingFile" [(value)]='includeCompletionsFrom' class="ms-2" [width]="150" class="inlineBlock ms-2">
  </dx-date-box>

  <dx-button *ngIf="fileValue.length && !loadingFile" text="Load" type="default" class="ms-4" (onClick)="loadFileGo()"
    [disabled]="!includeCompletionsFrom">
  </dx-button>

  <dx-data-grid *ngIf="fileLoaded" id="dataGrid" class="m-auto" [height]="gridHeight" [dataSource]="dataSource"
    [allowColumnReordering]="true" [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true"
    [rowAlternationEnabled]="true" [wordWrapEnabled]="true" (onExporting)="gridService.onExporting($event, 'export')">

    <dxo-scrolling mode="virtual"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-search-panel [visible]="true" location="before" [width]="250"></dxo-search-panel>
    <dxo-paging [enabled]="false"></dxo-paging>

    <dxi-column dataField="rowNumber" caption="Row" dataType="number" width="85" [format]="0"> </dxi-column>
    <dxi-column dataField="column" dataType="string" width="85"> </dxi-column>

    <dxi-column dataField="jobNumber" dataType="string" [width]="110"> </dxi-column>
    <dxi-column dataField="costCentre" dataType="string" [width]="110"> </dxi-column>
    <dxi-column dataField="orderNumber" dataType="string" [width]="150"> </dxi-column>
    <dxi-column dataField="vendorName" caption="Vendor" dataType="string"> </dxi-column>

    <dxi-column dataField="orderTotalExGst" dataType="number" width="140"
      [format]="{ type: 'fixedPoint', precision: 2 }">
    </dxi-column>

    <dxi-column dataField="errorString" dataType="string" minWidth="500"> </dxi-column>

  </dx-data-grid>

  <div *ngIf="fileLoaded">Note: File will not load at all if there are any errors.</div>

</div>
