<div class="page">

  <dx-data-grid id="dataGrid" class="max-w-800 m-auto grid-height" [dataSource]="dataSource"
    [allowColumnReordering]="true" [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true"
    [rowAlternationEnabled]="true" [wordWrapEnabled]="true"
    (onExporting)="gridService.onExporting($event, 'extra-codes')">

    <dxo-scrolling mode="virtual"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <!-- <dxo-row-dragging [allowReordering]="true" [onReorder]="onReorder"></dxo-row-dragging> -->
    <dxo-search-panel [visible]="true" location="before" [width]="250"></dxo-search-panel>
    <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"
      refreshMode="full">
    </dxo-editing>
    <dxo-paging [enabled]="false"></dxo-paging>

    <dxi-column dataField="code" dataType="string" sortOrder="asc">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="description" dataType="string">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="isActive" dataType="boolean">
    </dxi-column>

  </dx-data-grid>

</div>
