import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalChangeOrderVendorComponent } from '../global-change-order-vendor/global-change-order-vendor.component';
import { GlobalRevalueOrdersComponent } from '../global-revalue-orders/global-revalue-orders.component';
import { GlobalFindItemComponent } from '../global-find-item/global-find-item.component';
import { GlobalAddItemComponent } from '../global-add-item/global-add-item.component';

@Component({
  selector: 'js-order-tools-menu',
  templateUrl: './order-tools-menu.component.html',
  styleUrls: ['./order-tools-menu.component.scss']
})
export class OrderToolsMenuComponent implements OnInit {
  @Input() jobNumber: string;

  gridHeight: number;

  constructor(
    private _activeModal: NgbActiveModal,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.gridHeight = window.innerHeight - 200;
    this.gridHeight = this.gridHeight > 800 ? 800 : this.gridHeight;
  }

  close() {
    this._activeModal.close();
  }

  globalChangeOrderVendor() {
    const modalRef = this.modalService.open(GlobalChangeOrderVendorComponent, { windowClass: 'modal-1000' });

    modalRef.result.then(() => { });
  }

  globalRevalueOrdersForVendor() {
    const modalRef = this.modalService.open(GlobalRevalueOrdersComponent, { windowClass: 'modal-1000' });

    modalRef.result.then(() => { });
  }

  globalItemSearch() {
    const modalRef = this.modalService.open(GlobalFindItemComponent, { windowClass: 'modal-1500' });

    modalRef.result.then(() => { });
  }

  globalAddItem() {
    const modalRef = this.modalService.open(GlobalAddItemComponent, { windowClass: 'modal-1200' });

    modalRef.result.then(() => { });
  }
}
