import { Division } from './../../dtos/division';
import { UserService } from './../../services/felixApi/user.service';
import { JobService } from './../../services/felixApi/job.service';
import { MaintenanceService } from './../../services/felixApi/maintenance.service';
import { Component, OnInit, ViewChild, OnDestroy, OnChanges, Input } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { Subscription } from 'rxjs';
import { GlobalService } from '../../services/global.service';
import { GridService } from '../../services/grid.service';
import { DivisionService } from '../../services/felixApi/division.service';
import { NotificationService } from '../../services/notification.service';
import { AuthService } from '../../services/auth.service';
import { AccountingSystemCategory } from '../../dtos/accounting-system-category';
import { User } from '../../dtos/user';
import { ConfigurationEnum } from '../../dtos/configuration-enum';
import CustomStore from 'devextreme/data/custom_store';
import { AccountingSystemContact } from '../../dtos/accounting-system-contact';

@Component({
  selector: 'js-job-accounting-customer',
  templateUrl: './job-accounting-customer.component.html',
  styleUrls: ['./job-accounting-customer.component.scss']
})
export class JobAccountingCustomerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() jobAccountingCustomerCounter: number;

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  dataSource: CustomStore;
  loading = true;
  gridHeight: number;
  invoiceAdmin: boolean;
  accountingSystemCategories: AccountingSystemCategory[];
  accountsLoaded: boolean;
  accountingSystemContacts: AccountingSystemContact[];
  loadingAccounts: boolean;
  accountCategoriesLoaded: boolean;
  users: User[];
  accountingSystemType: number;
  accountingSystemTenantId: string;
  divisions: Division[];
  divisionId: number;
  dropDownOptions: { width: number; minHeight: number; };
  autoConnectPopupVisible: boolean;

  constructor(
    private divisionService: DivisionService,
    private globalService: GlobalService,
    private notiService: NotificationService,
    public gridService: GridService,
    private authService: AuthService,
    private maintenanceService: MaintenanceService,
    private jobService: JobService,
    private userService: UserService
  ) {
    this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.calculateSiteAddress = this.calculateSiteAddress.bind(this);
    this.calculateContactName = this.calculateContactName.bind(this);
    this.dropDownOptions = { width: 300, minHeight: 400 };
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.gridHeight = window.innerHeight - 130;
    this.getData();
  }

  getData() {
    this.subscriptions.push(
      this.divisionService.getDataForJobClientLink().subscribe({
        next: () => {
          this.users = this.userService.users;
          this.divisions = this.divisionService.divisions;
          this.accountingSystemType = this.globalService.getCompanyConfigValue(ConfigurationEnum.AccountingSystem);

          if (this.authService.isAdminOrSuper()
            || this.authService.areaPermissions.find(i => i.applicationArea === 'Invoices')?.permissionType === 'Admin') {
            this.invoiceAdmin = true;
          }

          this.loading = false;
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }

  refresh() {
    this.grid.instance.refresh();
  }

  setUpDataSource() {
    this.dataSource = new CustomStore({
      key: 'jobId',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.jobService.getClientJobs(this.divisionId).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.jobService.updateClientJob(encodeURIComponent(key), values).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onToolbarPreparing(e, templateName: string) {
    const toolbarItems = e.toolbarOptions.items;

    var updateButtonText = '';
    if (this.accountingSystemType === 1) {
      updateButtonText = 'Update Xero Contacts';
    }
    if (this.accountingSystemType === 2) {
      updateButtonText = 'Update QuickBooks Contacts';
    }
    if (this.accountingSystemType === 3) {
      updateButtonText = 'Update MYOB Contacts';
    }

    // if (this.accountsLoaded) {
    toolbarItems.push({
      location: 'before',
      locateInMenu: 'auto',
      template: templateName
    });

    toolbarItems.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          type: 'default',
          text: 'Auto-Connect',
          onClick: this.autoConnect.bind(this)
        }
      });

    if (updateButtonText !== '') {
      toolbarItems.unshift(
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxButton',
          options: {
            type: 'default',
            text: updateButtonText,
            onClick: this.updateContactList.bind(this)
          }
        });
    }
  }

  getAccountingContacts(tenantId: string): Promise<AccountingSystemContact[]> {
    return new Promise((resolve, reject) =>
      this.subscriptions.push(
        this.maintenanceService.getAccountingSystemContacts(tenantId).subscribe({
          next: (res) => {
            return resolve(res);
          }, error: (err) => {
            return reject(this.globalService.returnError(err));
          }
        })
      ));
  }

  async onSelectionChanged(event) {
    this.accountingSystemContacts = [];
    this.divisionId = event.value;

    // if (this.accountsLoaded) {
    const division = this.divisionService.divisions.find(i => i.id === this.divisionId);
    if (division?.accountingSystemTenantId) {
      this.accountingSystemTenantId = division.accountingSystemTenantId;
      this.loadingAccounts = true;

      await this.getAccountingContacts(division.accountingSystemTenantId)
        .then(
          res => {
            if (res) {
              res.forEach(accountingSystemContact => {
                accountingSystemContact.tenantId = division.accountingSystemTenantId;
              });
              this.accountingSystemContacts = res;
            }
            this.loadingAccounts = false;
          },
          err => {
            this.loadingAccounts = false;
            this.notiService.notify(err);
          });
    }
    this.setUpDataSource();
  }

  onLookupValueChanged(ea, e) {
    e.setValue(ea.value);
  }

  onAccountSelectionChanged(cellInfo, e, event) {
    if (event.selectedRowKeys.length > 0) {
      cellInfo.setValue(event.selectedRowsData[0].contactID);
      e.component.close();
    }
  }

  onDropDownChanged(cellInfo, e) {
    if (!e.value) {
      cellInfo.setValue(null);
    }
  }

  autoConnect() {
    // auto connect any that it can match the names
    if (this.accountingSystemTenantId && this.accountingSystemTenantId !== '') {
      this.autoConnectPopupVisible = true;
    } else {
      this.notiService.showInfo('Select a division');
    }
  }

  autoConnectGo() {
    // auto connect any that it can match the names
    this.autoConnectPopupVisible = false;
    this.loadingAccounts = true;

    this.subscriptions.push(
      this.maintenanceService.autoConnectContacts(this.divisionId).subscribe({
        next: () => {
          this.loadingAccounts = false;
          this.refresh();
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loadingAccounts = false;
        }
      })
    );
  }

  calculateSiteAddress(data) {
    return this.jobService.jobs?.find(i => i.id === data.jobId)?.jobAddressString;
  }

  calculateContactName(data): string {
    if (data === null || data === undefined) return '';
    const contact = this.accountingSystemContacts?.find(i => i.contactID === data.contactID);
    var contactName = contact?.name ?? "";
    if (contact?.siteAddress) {
      if (contactName.length > 0)
        contactName = ' - ' + contactName;
      contactName = contact?.siteAddress + contactName;
    }
    return contactName;
  }

  updateContactList() {
    if (this.accountingSystemTenantId && this.accountingSystemTenantId !== '') {
      this.loadingAccounts = true;
      this.subscriptions.push(
        this.maintenanceService.updateAccountingSystemContacts(this.accountingSystemTenantId).subscribe({
          next: (res) => {
            this.accountingSystemContacts = res
            this.loadingAccounts = false;
          }, error: (err) => {
            this.notiService.notify(err);
            this.loadingAccounts = false;
          }
        })
      );
    } else {
      this.notiService.showInfo('Select a division');
    }
  }
}