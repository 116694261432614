import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { HttpClient } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorPopupComponent } from '../shared/error-popup/error-popup.component';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class LogService {

constructor(
  private globalService: GlobalService,
  private modalService: NgbModal,
  private httpService: HttpService,
  private http: HttpClient
  ) { }

  /* Pass null as error for msg only error-log */
  log(component: string, method: String, error, errorMsg = '', print = false, alertPopUp = false) {
      const errType = component + '::' + method;
      if (error) {
          if (!error.error || !error.error.message) {
              errorMsg = errorMsg + '. STATUS: ' + error.status + '. NO MSG FOUND PRINTING ERROR OBJ: ' + JSON.stringify(error);
          } else {
              errorMsg = errorMsg + '. STATUS: ' + error.status + '. MSG: ' + error.error.message;
          }
      }

      this.writelog(errType, errorMsg);

      if (print) {
          console.log(errType + ' - ' + errorMsg);
      }

      if (alertPopUp) {
          let errorMessage = errType + ' - ' + errorMsg;
          if (error && error.error && error.error.message) {
              errorMessage = error.error.message;
          }
          const modalRef = this.modalService.open(ErrorPopupComponent, { windowClass: 'modal-error' });
          modalRef.componentInstance.errorMessage = errorMessage;

          modalRef.result.then((updatedForm) => {
          }, (reason) => {
          });
      }
  }

  writelog(errType, errString) {
      const url = this.globalService.getApiUrl() + '/errorlog';
      const log = {
          'errorType': errType,
          'errorString': errString
      };
      this.http.post(url, log, this.httpService.getHttpOptions()).subscribe(res => {
      }, err => {
          console.error('writing to error log failed!');
      });
  }

  loginLog(email: string, success: boolean, msg: string): Observable<number> {
    const url = environment.apiUrl + '/login-log';
    const log = {
      'emailAddress': email,
      'isSuccessful': success,
      'errorMessage': msg,
      'applicationId': environment.appId,
      'applicationVersion': environment.fullVersion
    };

    return this.http.post<number>(url, JSON.stringify(log), this.httpService.getHttpOptions());
  }
}
