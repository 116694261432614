import { Component, OnInit, ViewChild, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { locale } from 'devextreme/localization';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { AuthService } from './services/auth.service';
import { GlobalService } from './services/global.service';
import { MENU_HEIGHT } from '../config/variables';
import { NotificationService } from './services/notification.service';
import dxDataGrid from 'devextreme/ui/data_grid';


@Component({
  selector: 'js-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  menuHeight = MENU_HEIGHT;
  isIframe = false;
  subscriptions: Subscription[] = [];

  @ViewChild('ParentDiv') parentDiv: ElementRef;
  containerHeight: number;

  @HostListener('window:resize') onResize() {
    setTimeout(() => {
      this.setWidths();
    }, 600); // wait for iPhone
  }

  constructor(
    private globalService: GlobalService,
    private route: ActivatedRoute,
    public authService: AuthService,
    private notiService: NotificationService
  ) {
    locale('en-AU'); // can use navigator.language (browser language) but is mostly left on US default

    // console.log(navigator.language);
    const browser = window.navigator.userAgent.toLowerCase();
    if (browser.indexOf('firefox') > 0) {
      this.notiService.showWarning(
        'While this app functions on Firefox, Chrome is the officially supported browser. <br><br> If you encounter any issues please test on Chrome before raising it with support.',
        'Warning',
        { timeOut: 0, enableHtml: true, positionClass: 'toast-bottom-full-width' }
      );
    }

    // get any passed variables
    this.subscriptions.push(
      this.route.queryParamMap.subscribe(params => {
        if (params['params']['coy']) {
          this.globalService.setSessionAtt('passedCompanyId', params['params']['coy']);
        }
        if (params['params']['inv']) {
          this.globalService.setSessionAtt('externalInvoiceId', params['params']['inv']);
        }
        if (params['params']['code']) {
          if (params['params']['state'].startsWith("q")) {
            this.globalService.setSessionAtt('quickBooksCode', params['params']['code']);
            this.globalService.setSessionAtt('quickBooksState', params['params']['state']);
            this.globalService.setSessionAtt('quickBooksRealmId', params['params']['realmId']);
          }
          else if (params['params']['state'].startsWith("x")) {
            this.globalService.setSessionAtt('xeroCode', params['params']['code']);
            this.globalService.setSessionAtt('xeroState', params['params']['state']);
          }
          else {
            this.globalService.setSessionAtt('myobCode', params['params']['code']);
            if (params['params']['state']) {
              this.globalService.setSessionAtt('myobState', params['params']['state']);
            }
          }
        }
      })
    );

    // data grid default
    dxDataGrid.defaultOptions({
      options: {
        filterRow: { showAllText: '' }
      }
    });
  }

  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;
    this.setWidths();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setWidths() {
    this.globalService.setInnerHeightWidth();
    this.containerHeight = window.innerHeight - this.menuHeight;
  }

  refresh(): void {
    window.location.reload();
  }
}
