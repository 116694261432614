<div class="modal-header">
  <h3 class="modal-title">Copy items to unordered</h3>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <dx-data-grid *ngIf="!loading" #copyGrid id="copyGrid" [height]="gridHeight" [dataSource]="orderLines"
    [allowColumnReordering]="false" [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true"
    [rowAlternationEnabled]="true" [wordWrapEnabled]="true" [(selectedRowKeys)]="selectedOrderLines"
    [hoverStateEnabled]="true" (onExporting)="gridService.onExporting($event, 'copy-po-items')">

    <dxo-search-panel [visible]="true" location="before" [width]="400"></dxo-search-panel>
    <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-sorting mode='multiple'></dxo-sorting>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>

    <dxo-selection [allowSelectAll]="true" showCheckBoxesMode='always' mode="multiple">
    </dxo-selection>

    <dxo-editing mode="batch" [allowUpdating]="true"></dxo-editing>

    <dxo-keyboard-navigation [editOnKeyPress]="true" enterKeyAction="moveFocus" enterKeyDirection="column">
    </dxo-keyboard-navigation>

    <dxi-column dataField="priceFileCode" caption="Code" [width]="110" sortOrder="asc" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="description" dataType="string" sortOrder="asc" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="unitOfMeasure" dataType="number" caption="Units" [width]="80" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="hasSizes" dataType="boolean" [visible]="false"> </dxi-column>

    <dxi-column dataField="measuredQuantity" caption="Actual Qty" dataType="number" [width]="70"
      [setCellValue]="setQtyCellValue" [allowEditing]="true">
    </dxi-column>

    <dxi-column dataField="rate" dataType="number" [width]="100" [format]="{ type: 'fixedPoint', precision: 2 }"
      [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="lineTotal" caption="Total" dataType="number" [width]="100"
      [format]="{ type: 'fixedPoint', precision: 2 }" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="phaseId" caption="Phase" dataType="number" [width]="70" [hidingPriority]="4"
      [allowEditing]="false">
      <dxo-lookup [dataSource]="phases" displayExpr="phaseCode" valueExpr="id"> </dxo-lookup>
    </dxi-column>
  </dx-data-grid>

  <!-- if orders locked -->
  <js-extra-variance-select *ngIf="isLocked && !loading" (varianceCodeIdSelected)="varianceCodeId = $event"
    (varianceReasonSelected)="varianceReason = $event">
  </js-extra-variance-select>
</div>

<div class="modal-footer">
  <div>Note: items may be repriced and zero quantity items will be copied with quantity 1</div>
  <dx-button type="success" text="Run Copy" (onClick)="runCopy()"></dx-button>
</div>
