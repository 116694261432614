import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {

  constructor(private toastr: ToastrService) { }

  showSuccess(message, title = null, options = { timeOut: 3000 }) {
    this.toastr.success(message, title, options);
  }

  showError(message, title = null, options = { disableTimeOut: true, closeButton: true }) {
    this.toastr.error(message, title, options);
  }

  showInfo(message, title = null, options = null) {
    this.toastr.info(message, title, options);
  }

  showWarning(message, title = null, options = null) {
    this.toastr.warning(message, title, options);
  }

  notify(error: HttpErrorResponse) {
    let msg;
    if (error.statusText !== 'handled') {
      if (error.status === 422) {
        if (error.error && error.error.message) {
          msg = error.error.message;
        } else {
          msg = 'There was an unexpected error. Please try again in a moment.';
        }
        this.showError(msg);
      } else if (error.status === 403) {
        msg = 'Permission denied. Please see your administrator.';
        this.toastr.warning(msg);
      } else {
        msg = 'There was an unexpected error. Please try again in a moment.';
        this.toastr.error(msg);
      }
    }
  }
}
