import { CompanyService } from './company.service';
import { DivisionService } from './division.service';
import { UserService } from './user.service';
import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable, of, forkJoin } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { Phase } from '../../dtos/phase';
import { UnitOfMeasure } from '../../dtos/unitOfMeasure';
import { QtySizeControl } from '../../dtos/qty-size-control';
import { District } from '../../dtos/district';
import { Catalogue } from '../../dtos/catalogue';
import { Vendor } from '../../dtos/vendor';
import { VarianceCode } from '../../dtos/variance-code';
import { VarianceReason } from '../../dtos/variance-reason';
import { OrderControl } from '../../dtos/order-control';
import { OrderAttachment } from '../../dtos/order-attachment';
import { CostCentreDocsType } from '../../dtos/cost-centre-docs-type';
import { CallUpDocsType } from '../../dtos/call-up-docs-type';
import { VendorPayable } from '../../dtos/vendor-payable';
import { UtilsService } from '../utils.service';
import { AccountingSystemContact } from '../../dtos/accounting-system-contact';
import { AccountingSystemVendor } from '../../dtos/accounting-system-vendor';
import { AccountingSystemAccount } from '../../dtos/accounting-system-account';
import { AccountingSystemCategory } from '../../dtos/accounting-system-category';
import { AccountingSystemTerm } from '../../dtos/accounting-system-term';
import { AccountingSystemTenant } from '../../dtos/accounting-system-tenant';
import { AccountingSystemProductService } from '../../dtos/accounting-system-product-service';
import { GlAccountType } from '../../dtos/gl-account-type';
import { CompanyConfiguration } from '../../dtos/company-configuration';
import { VendorGroup } from '../../dtos/vendor-group';
import { HttpService } from '../http.service';
import { VendorAccount } from '../../dtos/vendor-account';
import { VendorInvoiceApprover } from '../../dtos/vendor-invoice-approver';
import { FileAttachment } from '../../dtos/file-attachment';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {
  phases: Phase[];
  cachCompanyPhases: string;

  unitOfMeasures: UnitOfMeasure[];
  cachCompanyUnitOfMeasures: string;

  qtySizeControls: QtySizeControl[];
  cachCompanyQtySizeControls: string;

  catalogues: Catalogue[];

  districts: District[];
  cachCompanyDistricts: string;

  vendorsForTenant: Vendor[];
  vendorsForTenantCompany: string;
  orderAttachments: OrderAttachment[];
  varianceCodes: VarianceCode[];
  cachCompanyVarianceCodes: string;
  varianceReasons: VarianceReason[];
  cachCompanyVarianceReasons: string;
  costCentreCallUpDocs: CostCentreDocsType[];
  cachCompanyCostCentreCallUpDocs: string;
  callUpDocsTypes: CallUpDocsType[];
  callUpDocsTypesCompany: number;
  vendorPayables: VendorPayable[];
  vendorPayablesCompany: string;
  accountingSystemCategories: AccountingSystemCategory[];
  accountingSystemCategoriesCompany: number;
  accountingSystemCategoriesTenantId: string;
  orderControl: OrderControl;
  orderControlCompany: string;
  private accountingSystemTenants: AccountingSystemTenant[];
  accountingSystemTenantsCompany: number;
  accountingSystemAccounts: AccountingSystemAccount[];
  accountingSystemAccountsCompany: number;
  accountingSystemAccountsTenantId: string;
  accountingSystemProductsAndServices: AccountingSystemProductService[];
  accountingSystemProductsAndServicesCompany: number;
  accountingSystemProductsAndServicesTenantId: string;
  accountingSystemProductsAndServicesType: string;
  accountingSystemTerms: AccountingSystemTerm[];
  accountingSystemTermsCompany: number;
  accountingSystemTermsTenantId: string;
  glAccountTypesCompany: string;
  glAccountTypes: GlAccountType[];
  vendorGroups: VendorGroup[];
  vendorGroupsCompany: string;
  vendorAccountCacheCompany: string;
  vendorAccountCache: VendorAccount[];
  vendorTenantId: string;
  allVendorsCompany: string;
  allVendors: Vendor[];
  allVarianceCodes: VarianceCode[];
  vendorInvoiceApprovers: VendorInvoiceApprover[];
  vendorInvoiceApproversCompany: string;

  constructor(
    private _http: HttpClient,
    private globalService: GlobalService,
    private httpService: HttpService,
    private userService: UserService,
    private utilsService: UtilsService,
    private divisionService: DivisionService,
    private compService: CompanyService
  ) { }


  getCatalogues(): Observable<Catalogue[]> {
    const url = this.globalService.getApiUrl() + '/catalogues';

    return this._http.get<Catalogue[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  getDistricts(useCache: boolean = true): Observable<District[]> {
    const url = this.globalService.getApiUrl() + '/districts';

    if (useCache && this.districts && this.districts.length && this.cachCompanyDistricts === this.globalService.getCurrentCompanyId()) {
      return of(this.districts);
    } else {
      return this._http.get<District[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.districts = res; this.cachCompanyDistricts = this.globalService.getCurrentCompanyId();
        }),
        catchError(this.handleError));
    }
  }

  getVendorsForTenant(useCache: boolean = true, tenantId: string): Observable<Vendor[]> {
    if (useCache && this.vendorsForTenant && this.vendorsForTenant.length
      && this.vendorsForTenantCompany === this.globalService.getCurrentCompanyId()
      && this.vendorTenantId === tenantId) {
      return of(this.vendorsForTenant);
    } else {
      let url = this.globalService.getApiUrl() + '/vendors';

      if (tenantId && tenantId !== '') {
        url += '?tenantId=' + tenantId;
      }
      return this._http.get<Vendor[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.vendorsForTenant = res;
          this.vendorsForTenantCompany = this.globalService.getCurrentCompanyId();
          this.vendorTenantId = tenantId;
        }),
        catchError(this.handleError));
    }
  }

  getAllVendors(useCache: boolean = true): Observable<Vendor[]> {
    if (useCache && this.allVendors && this.allVendors.length
      && this.allVendorsCompany === this.globalService.getCurrentCompanyId()) {
      return of(this.allVendors);
    } else {
      const url = this.globalService.getApiUrl() + '/vendors';
      return this._http.get<Vendor[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.allVendors = res;
          this.allVendorsCompany = this.globalService.getCurrentCompanyId();
        }),
        catchError(this.handleError));
    }
  }

  getVendorPayables(useCache: boolean = true): Observable<VendorPayable[]> {
    if (useCache && this.vendorPayables && this.vendorPayables.length
      && this.vendorPayablesCompany === this.globalService.getCurrentCompanyId()) {
      return of(this.vendorPayables);
    } else {
      return this._http.get<VendorPayable[]>(this.globalService.getApiUrl() +
        '/vendor-payables', this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.vendorPayables = res; this.vendorPayablesCompany = this.globalService.getCurrentCompanyId();
          }),
          catchError(this.handleError));
    }
  }

  getVendorInvoiceApprovers(useCache: boolean = true): Observable<VendorInvoiceApprover[]> {
    if (useCache && this.vendorInvoiceApprovers && this.vendorInvoiceApprovers.length
      && this.vendorInvoiceApproversCompany === this.globalService.getCurrentCompanyId()) {
      return of(this.vendorInvoiceApprovers);
    } else {
      return this._http.get<VendorInvoiceApprover[]>(this.globalService.getApiUrl() +
        '/vendor-invoice-approvers', this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.vendorInvoiceApprovers = res; this.vendorInvoiceApproversCompany = this.globalService.getCurrentCompanyId();
          }),
          catchError(this.handleError));
    }
  }

  getVendorAccounts(useCache: boolean = true): Observable<VendorAccount[]> {
    if (useCache && this.vendorAccountCache && this.vendorAccountCache.length
      && this.vendorAccountCacheCompany === this.globalService.getCurrentCompanyId()) {
      return of(this.vendorAccountCache);
    } else {
      const url = this.globalService.getApiUrl() + '/vendor-accounts';
      return this._http.get<VendorAccount[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.vendorAccountCache = res; this.vendorAccountCacheCompany = this.globalService.getCurrentCompanyId();
        }),
        catchError(this.handleError));
    }
  }

  getVendorsWithPayables(useCache: boolean = true, tenantId: string): Observable<Vendor[]> {
    return forkJoin(
      [this.getVendorsForTenant(useCache, tenantId),
      this.getVendorPayables(useCache),
      this.getVendorInvoiceApprovers(useCache),
      this.getGlAccountTypes(useCache),
      this.getVendorGroups(useCache)]
    )
      .pipe(map(
        ([vendors, vendorPayables, vendorInvoiceApprovers]) => {
          vendors.forEach(vendor => {
            vendor.vendorPayableIds = [];
            vendorPayables.filter(i => i.orderVendorId === vendor.id).forEach(vendorPayable => {
              console.log('vendorPayable: ' + vendorPayable.payVendorId);
              vendor.vendorPayableIds.push(vendorPayable.payVendorId);
            });

            vendor.vendorInvoiceApproverUserIds = [];
            vendorInvoiceApprovers.filter(i => i.vendorId === vendor.id).forEach(vendorInvoiceApprover => {
              console.log('vendorInvoiceApprovers: ' + vendorInvoiceApprover.userId);
              vendor.vendorInvoiceApproverUserIds.push(vendorInvoiceApprover.userId);
            });
          });
          return vendors;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  addVendor(dataRecord: any): Observable<Vendor> {
    const url = this.globalService.getApiUrl() + '/vendors';
    return this._http.post<Vendor>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions())
      .pipe(
        tap(res => {
          this.vendorsForTenant.push(res);
        })
      );
  }

  updateVendorForTenant(id: string, itm: any, tenantId: string): Observable<Vendor> {
    const url = this.globalService.getApiUrl() + '/vendors/' + id + '/tenantId/' + tenantId;
    return this._http.patch<Vendor>(url, JSON.stringify(itm), this.httpService.getHttpOptions())
      .pipe(
        tap(res => {
          this.utilsService.editCache(res, this.vendorsForTenant);
        })
      );
  }

  deleteVendor(id: string) {
    const url = this.globalService.getApiUrl() + '/vendors/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions())
      .pipe(
        tap(res => {
          this.utilsService.deleteFromCache(id, this.vendorsForTenant);
        })
      );
  }

  getPhases(useCache: boolean = true): Observable<Phase[]> {
    const url = this.globalService.getApiUrl() + '/phases';

    if (useCache && this.phases && this.phases.length && this.cachCompanyPhases === this.globalService.getCurrentCompanyId()) {
      return of(this.phases);
    } else {
      return this._http.get<Phase[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.phases = res; this.cachCompanyPhases = this.globalService.getCurrentCompanyId();
        }),
        catchError(this.handleError));
    }
  }

  getUnitOfMeasures(useCache: boolean = true): Observable<UnitOfMeasure[]> {
    const url = this.globalService.getApiUrl() + '/company-units';

    if (useCache && this.unitOfMeasures && this.unitOfMeasures.length
      && this.cachCompanyUnitOfMeasures === this.globalService.getCurrentCompanyId()) {
      return of(this.unitOfMeasures);
    } else {
      return this._http.get<UnitOfMeasure[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.unitOfMeasures = res; this.cachCompanyUnitOfMeasures = this.globalService.getCurrentCompanyId();
        }),
        catchError(this.handleError));
    }
  }

  // Qty Size Control
  getQtySizeControls(useCache: boolean = true): Observable<QtySizeControl[]> {
    const url = this.globalService.getApiUrl() + '/qty-size-control';

    if (useCache && this.qtySizeControls && this.qtySizeControls.length
      && this.cachCompanyQtySizeControls === this.globalService.getCurrentCompanyId()) {
      return of(this.qtySizeControls);
    } else {
      return this._http.get<QtySizeControl[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.qtySizeControls = res; this.cachCompanyQtySizeControls = this.globalService.getCurrentCompanyId();
        }),
        catchError(this.handleError));
    }
  }


  getExtraCodes(useCache: boolean): Observable<VarianceCode[]> {
    if (useCache && this.varianceCodes && this.varianceCodes.length
      && this.cachCompanyVarianceCodes === this.globalService.getCurrentCompanyId()) {
      return of(this.varianceCodes);
    } else {
      return this._http.get<VarianceCode[]>(this.globalService.getApiUrl() +
        '/variance-codes?isActiveOnly=false', this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.allVarianceCodes = res;
            this.varianceCodes = res.filter(i => i.isActive);
            this.cachCompanyVarianceCodes = this.globalService.getCurrentCompanyId();
          }),
          catchError(this.handleError));
    }
  }

  addExtraCode(dataRecord: any): Observable<VarianceCode> {
    const url = this.globalService.getApiUrl() + '/variance-codes';
    return this._http.post<VarianceCode>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateExtraCode(id: string, itm: any): Observable<VarianceCode> {
    const url = this.globalService.getApiUrl() + '/variance-codes/' + id;
    return this._http.patch<VarianceCode>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteExtraCode(id: string): Observable<VarianceCode> {
    const url = this.globalService.getApiUrl() + '/variance-codes/' + id;
    return this._http.delete<VarianceCode>(url, this.httpService.getHttpOptions());
  }


  getExtraReasons(useCache: boolean): Observable<VarianceReason[]> {
    if (useCache && this.varianceReasons && this.varianceReasons.length
      && this.cachCompanyVarianceReasons === this.globalService.getCurrentCompanyId()) {
      return of(this.varianceReasons);
    } else {
      return this._http.get<VarianceReason[]>(this.globalService.getApiUrl() +
        '/variance-reasons', this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.varianceReasons = res; this.cachCompanyVarianceReasons = this.globalService.getCurrentCompanyId();
          }),
          catchError(this.handleError));
    }
  }

  addExtraReason(dataRecord: any): Observable<VarianceReason> {
    const url = this.globalService.getApiUrl() + '/variance-reasons';
    return this._http.post<VarianceReason>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateExtraReason(id: string, itm: any): Observable<VarianceReason> {
    const url = this.globalService.getApiUrl() + '/variance-reasons/' + id;
    return this._http.patch<VarianceReason>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteExtraReason(id: string): Observable<VarianceReason> {
    const url = this.globalService.getApiUrl() + '/variance-reasons/' + id;
    return this._http.delete<VarianceReason>(url, this.httpService.getHttpOptions());
  }


  getOrderControl(useCache: boolean = true): Observable<OrderControl> {
    if (useCache && this.orderControl
      && this.orderControlCompany === this.globalService.getCurrentCompanyId()) {
      return of(this.orderControl);
    } else {
      return this._http.get<OrderControl>(this.globalService.getApiUrl() +
        '/order-control', this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.orderControl = res;
            this.orderControlCompany = this.globalService.getCurrentCompanyId();
          }),
          catchError(this.handleError));
    }
  }

  getOrderControlData(useCache: boolean = true): Observable<OrderControl> {
    return forkJoin(
      [this.getOrderControl(useCache),
      this.userService.getCurrCompUsers(useCache)]
    )
      .pipe(map(
        ([taskControl]) => {
          return taskControl;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getInvoiceControlData(useCache: boolean = true): Observable<OrderControl> {
    return forkJoin(
      [this.getOrderControl(false),
      this.userService.getCurrCompUsers(useCache),
      this.divisionService.getDivisions(useCache),
      this.divisionService.getDivisionAccounts(useCache),
      this.getGlAccountTypes(useCache),
      this.getVendorGroups(useCache)]
    )
      .pipe(map(
        ([taskControl]) => {
          return taskControl;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  addOrderControl(dataRecord: any): Observable<OrderControl> {
    const url = this.globalService.getApiUrl() + '/order-control';
    return this._http.post<OrderControl>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateOrderControl(itm: any): Observable<OrderControl> {
    const url = this.globalService.getApiUrl() + '/order-control';
    return this._http.patch<OrderControl>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }



  getGlAccountTypes(useCache: boolean): Observable<GlAccountType[]> {
    if (useCache && this.glAccountTypes && this.glAccountTypes.length
      && this.glAccountTypesCompany === this.globalService.getCurrentCompanyId()) {
      return of(this.glAccountTypes);
    } else {
      return this._http.get<GlAccountType[]>(this.globalService.getApiUrl() +
        '/gl-account-types', this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.glAccountTypes = res; this.glAccountTypesCompany = this.globalService.getCurrentCompanyId();
          }),
          catchError(this.handleError));
    }
  }

  addGlAccountType(dataRecord: any): Observable<GlAccountType> {
    const url = this.globalService.getApiUrl() + '/gl-account-types';
    return this._http.post<GlAccountType>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateGlAccountType(id: string, itm: any): Observable<GlAccountType> {
    const url = this.globalService.getApiUrl() + '/gl-account-types/' + id;
    return this._http.patch<GlAccountType>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteGlAccountType(id: string): Observable<GlAccountType> {
    const url = this.globalService.getApiUrl() + '/gl-account-types/' + id;
    return this._http.delete<GlAccountType>(url, this.httpService.getHttpOptions());
  }



  getVendorGroups(useCache: boolean): Observable<VendorGroup[]> {
    if (useCache && this.vendorGroups && this.vendorGroups.length
      && this.vendorGroupsCompany === this.globalService.getCurrentCompanyId()) {
      return of(this.vendorGroups);
    } else {
      return this._http.get<VendorGroup[]>(this.globalService.getApiUrl() +
        '/vendor-groups', this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.vendorGroups = res; this.vendorGroupsCompany = this.globalService.getCurrentCompanyId();
          }),
          catchError(this.handleError));
    }
  }

  addVendorGroup(dataRecord: any): Observable<VendorGroup> {
    const url = this.globalService.getApiUrl() + '/vendor-groups';
    return this._http.post<VendorGroup>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateVendorGroup(id: string, itm: any): Observable<VendorGroup> {
    const url = this.globalService.getApiUrl() + '/vendor-groups/' + id;
    return this._http.patch<VendorGroup>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteVendorGroup(id: string): Observable<VendorGroup> {
    const url = this.globalService.getApiUrl() + '/vendor-groups/' + id;
    return this._http.delete<VendorGroup>(url, this.httpService.getHttpOptions());
  }


  getOrderLogo(): Observable<FileAttachment> {
    const url = this.globalService.getApiUrl() + '/order-attachments/1';
    const options = this.httpService.getHttpFileOptions();
    return this._http.get<FileAttachment>(url, options);
  }

  uploadOrderLogo(loadFile, orderAttachmentTypeId: number) {
    const options = this.httpService.getHttpFileOptions();
    const url = this.globalService.getApiUrl() + '/order-attachments/upload?orderAttachmentTypeId=' + orderAttachmentTypeId;

    return this._http.post(url, loadFile, options)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  deleteOrderLogo() {
    const url = this.globalService.getApiUrl() + '/order-attachments/1';
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  getCostCentreCallUpDocs(useCache: boolean): Observable<CostCentreDocsType[]> {
    if (useCache && this.costCentreCallUpDocs && this.costCentreCallUpDocs.length
      && this.cachCompanyCostCentreCallUpDocs === this.globalService.getCurrentCompanyId()) {
      return of(this.costCentreCallUpDocs);
    } else {
      const url = this.globalService.getApiUrl() + '/cost-centre-docs-types/';
      return this._http.get<CostCentreDocsType[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.costCentreCallUpDocs = res;
          this.cachCompanyCostCentreCallUpDocs = this.globalService.getCurrentCompanyId();
        }),
        catchError(this.handleError));
    }
  }

  getCallUpDocsTypes(useCache: boolean): Observable<CallUpDocsType[]> {
    if (useCache && this.callUpDocsTypesCompany === this.globalService.getCurrentCompany().id
      && this.callUpDocsTypes && this.callUpDocsTypes.length) {
      return of(this.callUpDocsTypes);
    } else {
      const url = this.globalService.getApiUrl() + '/call-up-docs-types/';
      return this._http.get<CallUpDocsType[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.callUpDocsTypes = res;
          this.callUpDocsTypesCompany = this.globalService.getCurrentCompany().id;
        }),
        catchError(this.handleError));
    }
  }


  // AccountingSystem Tenants
  getAccountingSystemTenants(): Observable<AccountingSystemTenant[]> {
    if (this.accountingSystemTenants && this.accountingSystemTenants.length
      && this.accountingSystemTenantsCompany === this.globalService.getCurrentCompany().id) {
      return of(this.accountingSystemTenants);
    }
    else {
      return this._http.get<AccountingSystemTenant[]>(this.globalService.getApiUrl() +
        '/accounting-system/tenants', this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.accountingSystemTenants = res;
            this.accountingSystemTenantsCompany = this.globalService.getCurrentCompany().id;
          }),
          catchError(this.handleError));
    }
  }

  accountingSystemTenantsList(includeHidden: Boolean = false): AccountingSystemTenant[] {
    if (includeHidden) {
      return this.accountingSystemTenants;
    }
    else {
      return this.accountingSystemTenants.filter(i => !i.hidden);
    }
  }

  updateAccountingSystemTenant(tenantId: string, itm: any): Observable<AccountingSystemTenant> {
    const url = this.globalService.getApiUrl() + '/accounting-system/tenant/' + tenantId;
    return this._http.patch<AccountingSystemTenant>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteAccountingSystemTenant(tenantId: string): Observable<boolean> {
    return this._http.delete<boolean>(this.globalService.getApiUrl() +
      '/accounting-system/tenant/' + tenantId, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.accountingSystemTenants = null;
        }),
        catchError(this.handleError));
  }

  // AccountingSystem accounts
  getAccountingSystemAccounts(tenantId: string): Observable<AccountingSystemAccount[]> {
    if (this.accountingSystemAccounts
      && this.accountingSystemAccountsTenantId === tenantId
      && this.accountingSystemAccountsCompany === this.globalService.getCurrentCompany().id) {
      return of(this.accountingSystemAccounts);
    } else {
      return this._http.post<AccountingSystemAccount[]>(this.globalService.getApiUrl() +
        '/accounting-system/accounts?tenantId=' + tenantId, null, this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.accountingSystemAccounts = res;
            this.accountingSystemAccountsCompany = this.globalService.getCurrentCompany().id;
            this.accountingSystemAccountsTenantId = tenantId;
          }),
          catchError(this.handleError));
    }
  }

  // AccountingSystem tracking categories
  getAccountingSystemCategories(tenantId: string): Observable<AccountingSystemCategory[]> {
    if (this.accountingSystemCategories
      && this.accountingSystemCategoriesTenantId === tenantId
      && this.accountingSystemCategoriesCompany === this.globalService.getCurrentCompany().id) {
      return of(this.accountingSystemCategories);
    } else {
      return this._http.get<AccountingSystemCategory[]>(this.globalService.getApiUrl() +
        '/accounting-system/tracking-categories?tenantId=' + tenantId, this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.accountingSystemCategories = res;
            this.accountingSystemCategoriesCompany = this.globalService.getCurrentCompany().id;
            this.accountingSystemCategoriesTenantId = tenantId;
          }),
          catchError(this.handleError));
    }
  }

  getAccountingSystemData(tenantId: string): Observable<AccountingSystemAccount[]> {
    return forkJoin(
      [
        this.getAccountingSystemAccounts(tenantId),
        this.getAccountingSystemCategories(tenantId)
      ]
    )
      .pipe(
        map(([result]) => { return result; }),
        catchError((err) => { return this.handleError(err); })
      );
  }

  getAccountingSystemDataServices(tenantId: string, getTerms: boolean): Observable<AccountingSystemProductService[]> {
    return forkJoin(
      [
        this.getAccountingSystemProductsAndServices(tenantId, "Service"),
        getTerms ? this.getAccountingSystemTerms(tenantId) : of([])
      ]
    )
      .pipe(
        map(([result]) => { return result; }),
        catchError((err) => { return this.handleError(err); })
      );
  }

  getAccountingSystemProductsAndServices(tenantId: string, type: string): Observable<AccountingSystemProductService[]> {
    if (this.accountingSystemProductsAndServices
      && this.accountingSystemProductsAndServicesTenantId === tenantId
      && this.accountingSystemProductsAndServicesCompany === this.globalService.getCurrentCompany().id
      && this.accountingSystemProductsAndServicesType === type) {
      return of(this.accountingSystemProductsAndServices);
    } else {
      var typeSearch = "";
      if (type.length > 0) {
        typeSearch = '&type=' + type;
      }
      return this._http.get<AccountingSystemProductService[]>(this.globalService.getApiUrl() +
        '/accounting-system/product-and-services?tenantId=' + tenantId + typeSearch, this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.accountingSystemProductsAndServices = res;
            this.accountingSystemProductsAndServicesCompany = this.globalService.getCurrentCompany().id;
            this.accountingSystemProductsAndServicesTenantId = tenantId;
            this.accountingSystemProductsAndServicesType = type;
          }),
          catchError(this.handleError));
    }
  }

  // AccountingSystem terms
  getAccountingSystemTerms(tenantId: string): Observable<AccountingSystemTerm[]> {
    if (this.accountingSystemTerms
      && this.accountingSystemTermsTenantId === tenantId
      && this.accountingSystemTermsCompany === this.globalService.getCurrentCompany().id) {
      return of(this.accountingSystemTerms);
    } else {
      return this._http.get<AccountingSystemTerm[]>(this.globalService.getApiUrl() +
        '/accounting-system/terms?tenantId=' + tenantId, this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.accountingSystemTerms = res;
            this.accountingSystemTermsCompany = this.globalService.getCurrentCompany().id;
            this.accountingSystemTermsTenantId = tenantId;
          }),
          catchError(this.handleError));
    }
  }

  getDivisionAccountsData(): Observable<CompanyConfiguration[]> {
    return forkJoin(
      [this.compService.getCompanyConfigurations(),
      this.divisionService.getDivisions(true),
      this.getGlAccountTypes(true)]
    )
      .pipe(
        map(([result]) => { return result; }),
        catchError((err) => { return this.handleError(err); })
      );
  }

  // AccountingSystem vendors
  getAccountingSystemVendors(tenantId): Observable<AccountingSystemVendor[]> {
    return this._http.get<AccountingSystemVendor[]>(this.globalService.getApiUrl() +
      '/accounting-system/vendors?tenantId=' + tenantId, this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  updateAccountingSystemVendors(tenantId): Observable<AccountingSystemVendor[]> {
    const url = this.globalService.getApiUrl() + '/accounting-system/vendors?tenantId=' + tenantId;
    return this._http.post<AccountingSystemVendor[]>(url, JSON.stringify({}), this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  autoConnectVendors(tenantId: string) {
    const url = this.globalService.getApiUrl() + '/vendors/auto-connect/tenantId/' + tenantId;
    return this._http.post(url, JSON.stringify({}), this.httpService.getHttpOptions());
  }

  // AccountingSystem contacts
  getAccountingSystemContacts(tenantId): Observable<AccountingSystemContact[]> {
    return this._http.get<AccountingSystemContact[]>(this.globalService.getApiUrl() +
      '/accounting-system/contacts?tenantId=' + tenantId, this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  updateAccountingSystemContacts(tenantId): Observable<AccountingSystemContact[]> {
    const url = this.globalService.getApiUrl() + '/accounting-system/contacts?tenantId=' + tenantId;
    return this._http.post<AccountingSystemContact[]>(url, JSON.stringify({}), this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  autoConnectContacts(divisionId: number) {
    const url = this.globalService.getApiUrl() + '/client-jobs/auto-connect/' + divisionId;
    return this._http.post(url, JSON.stringify({}), this.httpService.getHttpOptions());
  }


  calcRoundedQty(measuredQty: number, qtySizeControlId: number, hasLengths: boolean): number {
    if (!qtySizeControlId || !measuredQty || hasLengths) {
      return measuredQty;
    } else {
      const qtySizeControl = this.qtySizeControls.find(i => i.id === qtySizeControlId);
      if (qtySizeControl) {
        // const newQty = measuredQty; // * (qtySizeControl.conversionRatio ? qtySizeControl.conversionRatio : 1);

        if (qtySizeControl.min && measuredQty <= qtySizeControl.min) {
          return qtySizeControl.min;
        } else if (qtySizeControl.max && measuredQty > qtySizeControl.max) {
          return null;
        } else if (qtySizeControl.increment) {
          const minQty = qtySizeControl.min ? qtySizeControl.min : 0;
          const overflow = (measuredQty - minQty) % qtySizeControl.increment;
          if (overflow < 0.00001) {
            return measuredQty;
          }
          const result = overflow ? measuredQty - overflow + qtySizeControl.increment : measuredQty;
          return result;
        } else {
          return measuredQty;
        }
      } else {
        return measuredQty;
      }
    }
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
