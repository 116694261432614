<dx-drop-down-box [dropDownOptions]="dropDownOptions" [dataSource]="accountingSystemAccounts"
[(value)]="oldAccountID" displayExpr="name" valueExpr="accountID"
contentTemplate="contentTemplate">
<!-- Note we have to use standard scrolling so the list will "autoNavigateToFocusedRow" if the item is not near the top of the list -->
<div *dxTemplate="let e of 'contentTemplate'" class="dropdownClass">
  <dx-data-grid [dataSource]="accountingSystemAccounts" keyExpr="accountID" [remoteOperations]="false"
    height="500px" [showBorders]="true" [selection]="{ mode: 'single' }" [focusedRowEnabled]="true"
    [focusedRowKey]="oldAccountID" [autoNavigateToFocusedRow]="true"
    [selectedRowKeys]="[oldAccountID]" [hoverStateEnabled]="true"
    [scrolling]="{ mode: 'virtual', preloadEnabled: true }"
    (onSelectionChanged)="onSelectionChanged(e, $event)">

    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-search-panel [visible]="true" [width]="200"></dxo-search-panel>

    <dxi-column dataField="code" [width]="110"></dxi-column>
    <dxi-column dataField="name"></dxi-column>
  </dx-data-grid>
</div>
</dx-drop-down-box>
