import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { VarianceCode } from '../../dtos/variance-code';
import { VarianceReason } from '../../dtos/variance-reason';
import { MaintenanceService } from '../../services/felixApi/maintenance.service';

@Component({
  selector: 'js-extra-variance-select',
  templateUrl: './extra-variance-select.component.html',
  styleUrls: ['./extra-variance-select.component.scss']
})
export class ExtraVarianceSelectComponent implements OnInit {
  @Input() varianceCodeId: number;
  @Input() varianceReason: string;

  @Output() varianceCodeIdSelected: EventEmitter<number> =
    new EventEmitter<number>();

  @Output() varianceReasonSelected: EventEmitter<string> =
    new EventEmitter<string>();

  varianceCodes: VarianceCode[];
  varianceReasons: VarianceReason[];
  adHocReason: VarianceReason;

  constructor(
    private maintenanceService: MaintenanceService
  ) { }

  ngOnInit() {
    this.varianceCodes = this.maintenanceService.varianceCodes;
    this.varianceReasons = this.maintenanceService.varianceReasons;
  }

  onCodeValueChanged(ea) {
    this.varianceCodeIdSelected.emit(this.varianceCodeId);
  }

  onReasonValueChanged(ea) {
    if (!this.adHocReason) {
      this.varianceReason = ea.value;
    } else {
      this.varianceReason = this.adHocReason.reason;
      this.adHocReason = null;
    }

    this.varianceReasonSelected.emit(this.varianceReason);
  }

  addCustomItem(data) {
    if (!data.text) {
      data.customItem = null;
      return;
    }

    this.addToList(data.text);

    this.varianceReason = data.text;
    data.customItem = this.adHocReason;
  }

  addToList(adhocText: string) {
    const productIds = this.varianceReasons.map(function (item) {
      return item.id;
    });
    const incrementedId = Math.max.apply(null, productIds) + 1;

    this.adHocReason = new VarianceReason(incrementedId, adhocText);
    this.varianceReasons.push(this.adHocReason);
  }
}
