export enum RoleTypeEnum {
    SiteManager = 1,
    PreStartOfficer = 2,
    LiaisonOfficer = 3,
    SalesRep = 4,
    SalesEstimator = 5,
    ConstructionCoordinator = 6,
    AreaConstructionManager = 7,
    Designer = 8,
    ConstructionEstimator = 9
}
