import { Component, OnDestroy, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { GridService } from '../../services/grid.service';
import { MaintenanceService } from '../../services/felixApi/maintenance.service';
import { Subscription, of, firstValueFrom } from 'rxjs';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'js-vendor-group',
  templateUrl: './vendor-group.component.html',
  styleUrls: ['./vendor-group.component.scss']
})
export class VendorGroupComponent implements OnInit, OnDestroy {

  dataSource: CustomStore;
  subscriptions: Subscription[] = [];
  supplierPayTermsTypes = [
    { id: 1, description: 'End of Month' },
    { id: 2, description: 'Invoice' },
    { id: 3, description: 'Subcontractor' }
  ];

  constructor(
    private maintenanceService: MaintenanceService,
    private globalService: GlobalService,
    protected gridService: GridService
  ) { this.asyncValidation = this.asyncValidation.bind(this); }

  ngOnInit(): void {
    this.setUpDataSource();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setUpDataSource() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.maintenanceService.getVendorGroups(false).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      },
      insert: async (values) => {
        if (values.isActive === undefined || values.isActive === null) {
          values.isActive = true;
        }

        // followingMonths is only for End-Of-Month calcs
        // if (values.supplierPayTermsTypeId !== 1) {
        //   values['followingMonths'] = 0;
        // }
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.maintenanceService.addVendorGroup(values).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      },
      update: async (key, values) => {
        // if (values['supplierPayTermsTypeId'] !== undefined && values['supplierPayTermsTypeId'] !== 1) {
        //   values['followingMonths'] = 0;
        // }
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.maintenanceService.updateVendorGroup(encodeURIComponent(key), values).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.maintenanceService.deleteVendorGroup(encodeURIComponent(key)).subscribe({
              next: () => {
                return resolve();
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      }
    });
  }

  asyncValidation(params) {
    if (params.data.supplierPayTermsTypeId !== 3) {
      return firstValueFrom(of(true));
    }

    if (params.value === 7 || params.value === 14) {
      return firstValueFrom(of(true));
    }
    return firstValueFrom(of(false));
  }

  followingMonthValidation(params) {
    if (params.data.supplierPayTermsTypeId === 1) {
      return firstValueFrom(of(true));
    }

    if (!params.value || params.value === 0) {
      return firstValueFrom(of(true));
    }
    return firstValueFrom(of(false));
  }
}
