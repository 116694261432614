import { GridService } from './../../services/grid.service';
import { OrderHeader } from './../../dtos/order-header';
import { EstimatingService } from './../../services/felixApi/estimating.service';
import { MaintenanceService } from '../../services/felixApi/maintenance.service';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PoService } from '../../services/felixApi/po.service';
import { NotificationService } from '../../services/notification.service';
import { Subscription } from 'rxjs';
import { OrderLine } from '../../dtos/order-line';
import { Phase } from '../../dtos/phase';
import { Vendor } from '../../dtos/vendor';
import { SelectedItem } from '../../dtos/selected-item';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
  selector: 'js-copy-po-items',
  templateUrl: './copy-po-items.component.html',
  styleUrls: ['./copy-po-items.component.scss']
})
export class CopyPoItemsComponent implements OnInit, OnDestroy {
  @Input() orderHeader: OrderHeader;
  @Input() isLocked: boolean;
  @Input() purchaseOrderId: number;

  @ViewChild('copyGrid', { static: false }) copyGrid: DxDataGridComponent;

  loading = false;
  subscriptions: Subscription[] = [];
  orderLines: OrderLine[] = [];
  gridHeight: number;
  phases: Phase[] = [{ id: 0, orderNo: 0, phaseCode: 'Default', description: 'Default' }];
  vendors: Vendor[];
  selectedOrderLines: OrderLine[];
  itemsToPost: SelectedItem[] = [];
  selectedOrderLine: SelectedItem;
  varianceCodeId: number;
  varianceReason: string;

  constructor(
    private poService: PoService,
    private maintenanceService: MaintenanceService,
    private _activeModal: NgbActiveModal,
    private estimatingService: EstimatingService,
    public gridService: GridService,
    private notiService: NotificationService) {
    this.setQtyCellValue = this.setQtyCellValue.bind(this);
  }

  ngOnInit() {
    this.poService.orderLines.filter(i => i.purchaseOrderId === this.purchaseOrderId).forEach(orderLine => {
      this.orderLines.push({ ...orderLine });
    });

    this.gridHeight = window.innerHeight - 330;
    this.phases = this.phases.concat(this.maintenanceService.phases);
    this.vendors = this.maintenanceService.allVendors;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  cancel() {
    this._activeModal.dismiss();
  }

  runCopy() {
    this.copyGrid.instance.saveEditData();

    if (this.selectedOrderLines && this.selectedOrderLines.length) {
      this.itemsToPost = [];
      this.selectedOrderLines.forEach(element => {
        const priceFileItemWithRate = this.estimatingService.recipesAndItems.find(i => i.recipeCode === element.priceFileCode && i.vendorId === element.vendorId);

        this.selectedOrderLine = {
          costCentreId: element.costCentreId,
          recipeItemId: element.recipeItemId,
          priceFileItemId: element.priceFileItemId,
          description: element.description,
          unitOfMeasure: element.unitOfMeasure,
          hasSizes: element.hasSizes,
          quantityString: element.measuredQuantity ? element.measuredQuantity.toString() : '1',
          measuredQuantity: element.measuredQuantity ?? 1,
          supplierQuantity: element.supplierQuantity ?? 1,
          vendorId: element.vendorId,
          rate: priceFileItemWithRate ? priceFileItemWithRate.rate : element.rate,
          productCode: element.productCode,
          phaseId: element.phaseId,
          orderLineId: element.originalOrderLineId,
          orderLineLengths: []
        };

        this.itemsToPost.push(this.selectedOrderLine);
      });

      this.loading = true;

      this.subscriptions.push(
        this.poService.addOrderLinesFromSelectedRecipes(this.orderHeader.id, this.itemsToPost,
          this.varianceCodeId, this.varianceReason, null).subscribe({
            next: () => {
              this._activeModal.close();
            },
            error: (err) => {
              this.notiService.notify(err);
              this.loading = false;
            }
          })
      );
    }
  }

  setQtyCellValue(rowData, value, originalData) {
    if (!originalData.hasSizes) {
      if (value) {
        rowData.measuredQuantity = value;
        rowData.quantityString = value.toString();

        const priceFileItem = this.estimatingService.getPriceFileItemForVendor(originalData.priceFileItemId, originalData.vendorId, this.orderHeader.baseDate, this.orderHeader.districtId);

        const supplierQty = this.maintenanceService
          .calcRoundedQty(rowData.measuredQuantity, priceFileItem?.qtySizeControlId, priceFileItem?.hasSizes);
        rowData.supplierQuantity = supplierQty;

        rowData.lineTotal = supplierQty * this.estimatingService.calculateRate(originalData.rate, originalData.unitOfMeasure);
      } else {
        rowData.measuredQuantity = 0;
        rowData.quantityString = '';
        rowData.supplierQuantity = 0;
        rowData.lineTotal = 0;
      }
    } else {
      this.notiService.showInfo('Cannot change the quantity for an item with a cutting list');
    }
  }
}
