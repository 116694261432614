import { GridService } from './../../services/grid.service';
import { CompanyService } from './../../services/felixApi/company.service';
import { UserService } from './../../services/felixApi/user.service';
import { CompanyActivityService } from './../../services/felixApi/company-activity.service';
import { AuthService } from './../../services/auth.service';
import { InvoiceService } from './../../services/felixApi/invoice.service';
import { EstimatingService } from './../../services/felixApi/estimating.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MaintenanceService } from '../../services/felixApi/maintenance.service';
import { Vendor } from '../../dtos/vendor';
import { PoService } from '../../services/felixApi/po.service';
import { Phase } from '../../dtos/phase';
import CustomStore from 'devextreme/data/custom_store';
import { JobService } from '../../services/felixApi/job.service';
import { GlobalService } from '../../services/global.service';
import { PriceFileItem } from '../../dtos/price-file-item';
import { NotificationService } from '../../services/notification.service';
import { Subscription } from 'rxjs';
import { Job } from '../../dtos/job';
import { User } from '../../dtos/user';
import { RoleTypeEnum } from '../../dtos/role-type.enum';

@Component({
  selector: 'js-all-orders',
  templateUrl: './all-orders.component.html',
  styleUrls: ['./all-orders.component.scss']
})
export class AllOrdersComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  loadingData = true;
  loading = false;
  vendors: Vendor[];
  phases: Phase[] = [{ id: 0, orderNo: 0, phaseCode: '', description: 'Default' }];
  purchaseOrderData: CustomStore;
  jobs: Job[];
  costCentres: PriceFileItem[];
  gridHeight: number;
  isAdmin: boolean;
  users: User[];
  salesRepWording: string;

  constructor(
    private globalService: GlobalService,
    private maintenanceService: MaintenanceService,
    private estimatingService: EstimatingService,
    private jobService: JobService,
    private notiService: NotificationService,
    private invoiceService: InvoiceService,
    private authService: AuthService,
    private companyActivityService: CompanyActivityService,
    private poService: PoService,
    private companyService: CompanyService,
    public gridService: GridService,
    private userService: UserService) {
    this.calculateJobNumber = this.calculateJobNumber.bind(this);
    this.calculateCostCentreSortValue = this.calculateCostCentreSortValue.bind(this);
    this.calculateCostCentreDesc = this.calculateCostCentreDesc.bind(this);
    this.openPO = this.openPO.bind(this);
    this.calculateSelectedRow = this.calculateSelectedRow.bind(this);
    this.calculateOrderIncGSTValue = this.calculateOrderIncGSTValue.bind(this);
    this.calculateVendorSortValue = this.calculateVendorSortValue.bind(this);
    this.calculatePONumber = this.calculatePONumber.bind(this);
    this.calculateActivityDesc = this.calculateActivityDesc.bind(this);
    this.calculateSalesRep = this.calculateSalesRep.bind(this);
  }

  ngOnInit() {
    this.isAdmin = this.authService.isAdminOrSuper();

    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          this.setHeightWidths();
        }
      )
    );

    this.setHeightWidths();
    this.loadData(true);
  }

  setHeightWidths() {
    this.gridHeight = window.innerHeight - 107;
  }

  loadData(useCache: boolean) {
    this.subscriptions.push(
      this.invoiceService.getInvoicesDataForOnHold(useCache)
        .subscribe({
          next: () => {
            this.vendors = this.maintenanceService.allVendors;
            this.jobs = this.jobService.jobs;
            this.costCentres = this.estimatingService.costCentres;
            this.users = this.userService.users;
            this.salesRepWording = this.companyService.companyRoles?.find(i => i.roleId === RoleTypeEnum.SalesRep)?.companyRoleDescription;
            this.loadingData = false;
            this.setupDataSource(useCache);
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loadingData = false;
          }
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setupDataSource(useCache: boolean) {
    this.purchaseOrderData = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.poService.getOrdersDataForInvoices(useCache).subscribe({
              next: (res) => {
                this.vendors = this.maintenanceService.allVendors;
                this.phases = this.phases.concat(this.maintenanceService.phases);
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.poService.updatePurchaseOrder(encodeURIComponent(key), values).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      },
    });
  }

  calculateJobNumber(data) {
    return this.jobs.find(i => i.id === data.jobId)?.jobNumber;
  }

  calculateCostCentreSortValue(data) {
    return this.costCentres.find(i => i.id === data.costCentreId)?.orderNumber;
  }

  calculateCostCentreDesc(data) {
    return this.costCentres.find(i => i.id === data.costCentreId)?.description;
  }

  calculatePONumber(data) {
    return this.jobs.find(i => i.id === data.jobId)?.jobNumber + '.' + data.poNumber;
  }

  calculateActivityDesc(data) {
    const jobExtra = this.jobService.jobExtras?.find(i => i.jobId === data.jobId);
    if (jobExtra && jobExtra.maintenanceCompleteDate) {
      return 'Maintenance Complete';
    } else if (jobExtra && jobExtra.currentActivityId) {
      const activity = this.companyActivityService.activities.find(i => i.id === jobExtra.currentActivityId);
      if (activity) {
        return activity.activityCode + ' - ' + activity.description;
      }
    }
    return '';
  }

  openPO(e) {
    if (e.row.data.jobId && e.row.data.id) {
      const jobNumber = this.jobs.find(i => i.id === e.row.data.jobId)?.jobNumber;
      const dataRecord = {
        purchaseOrderIds: [e.row.data.id],
        emailAddresses: [],
        ccToSelf: false,
        download: true,
        printPrices: true
      };

      this.notiService.showInfo('downloading order');

      this.subscriptions.push(
        this.poService.sendPurchaseOrders(e.row.data.jobId, dataRecord)
          .subscribe({
            next: (orderResponse) => {
              this.poService.convertAndSave(orderResponse.pdfOrders, jobNumber, e.row.data.poNumber, false);
            },
            error: (err) => {
              this.notiService.notify(err);
            }
          })
      );
    }
  }

  calculateRemainingBudget(data) {
    return data.orderTotal - data.approvedInvoicesTotal;
  }

  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: this.refresh.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          type: 'default',
          stylingMode: 'outlined',
          text: 'Reset Layout',
          onClick: this.clearStatePersistance.bind(this)
        }
      });
  }

  refresh() {
    this.poService.allPurchaseOrdersForCompany = [];
    this.loadData(false);
  }

  clearStatePersistance() {
    this.loading = true;
    localStorage.removeItem('all-orders');
    setTimeout(() => {
      this.loading = false;
    }, 300); // wait
  }

  calculateSelectedRow(options) {
    if (options.name === 'SelectedOrderTotal') {
      if (options.summaryProcess === 'start') {
        options.totalValue = 0;
      } else if (options.summaryProcess === 'calculate') {
        if (options.component.isRowSelected(options.value.id)) {
          options.totalValue += options.value.orderTotal;
        }
      }
    } else if (options.name === 'SelectedApprovedInvoices') {
      if (options.summaryProcess === 'start') {
        options.totalValue = 0;
      } else if (options.summaryProcess === 'calculate') {
        if (options.component.isRowSelected(options.value.id)) {
          options.totalValue += options.value.approvedInvoicesTotal;
        }
      }
    } else if (options.name === 'SelectedRemainingBudget') {
      if (options.summaryProcess === 'start') {
        options.totalValue = 0;
      } else if (options.summaryProcess === 'calculate') {
        if (options.component.isRowSelected(options.value.id)) {
          options.totalValue += (options.value.orderTotal - options.value.approvedInvoicesTotal);
        }
      }
    } else if (options.name === 'SelectedOrderTotalIncGST') {
      if (options.summaryProcess === 'start') {
        options.totalValue = 0;
      } else if (options.summaryProcess === 'calculate') {
        if (options.component.isRowSelected(options.value.id)) {
          options.totalValue += (options.value.orderTotal * (1 + this.invoiceService.globalGSTRate / 100));
        }
      }
    }
  }

  onSelectionChanged(e) {
    e.component.refresh(true);
  }

  calculateVendorSortValue(data) {
    return this.vendors.find(i => i.id === data.vendorId)?.vendorName;
  }

  calculateOrderIncGSTValue(data) {
    return data.orderTotal * (1 + this.invoiceService.globalGSTRate / 100);
  }

  calculateSalesRep(rowData): string {
    return this.jobService.jobRoles.find(i => i.jobId === rowData.jobId && i.roleId === RoleTypeEnum.SalesRep)?.user.fullName;
  }
}
