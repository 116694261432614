<div class="page">

  <js-job-number *ngIf="!loadingData" (refreshJobNumber)='onJobNumberChanged($event)'></js-job-number>

  <js-loading-spinner *ngIf="loadingData || loading"></js-loading-spinner>

  <dx-data-grid *ngIf="!loadingData && !loading" class="max-w-1500 m-auto mt-1" [height]="gridHeight"
    [dataSource]="dataSource" [allowColumnReordering]="true" [allowColumnResizing]="true" [repaintChangesOnly]="true"
    [showBorders]="true" [rowAlternationEnabled]="true" [wordWrapEnabled]="true" [hoverStateEnabled]="true"
    [focusedRowEnabled]="true" (onToolbarPreparing)="onToolbarPreparing($event)"
    (onExporting)="gridService.onExporting($event, 'accruals')">

    <dxo-search-panel [visible]="true" location="before" [width]="400"></dxo-search-panel>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-filter-row [visible]="true"></dxo-filter-row>

    <dxo-editing mode="batch" [allowUpdating]="true" [useIcons]="true" startEditAction="click" refreshMode="repaint">
    </dxo-editing>

    <dxi-column dataField="id" dataType="number" [visible]="false">
    </dxi-column>

    <dxi-column dataField="costCentreId" caption="Cost Code" dataType="number" width="120" alignment="center"
      sortOrder="asc" [allowEditing]="false" [calculateSortValue]="calculateCostCentreSortValue">
      <dxo-lookup [dataSource]="costCentres" displayExpr="priceFileCode" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="costCentreDescription" caption="Cost Centre" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="poNumber" caption="PO Number" width="150" sortOrder="asc" [allowEditing]="false"
      [calculateCellValue]="calculatePONumber">
    </dxi-column>

    <dxi-column dataField="vendorId" caption="Vendor" dataType="number" [allowEditing]="false"
      [calculateSortValue]="calculateVendorSortValue">
      <dxo-lookup [dataSource]="vendors" displayExpr="vendorName" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="orderTotal" width="130" [allowEditing]="false" [calculateCellValue]='calculateOrderTotal'
      [format]="{ type: 'fixedPoint', precision: 2 }">
    </dxi-column>

    <dxi-column dataField="approvedInvoicesTotal" caption="Invoices Processed Ex GST" width="130" [allowEditing]="false"
      [calculateCellValue]="calculateInvoiceTotal" [format]="{ type: 'fixedPoint', precision: 2 }">
    </dxi-column>

    <dxi-column dataField="remainingBudget" caption="Remaining Budget Ex GST" dataType="number" width="130"
      [calculateCellValue]='calculateRemainingBudget' [format]="{ type: 'fixedPoint', precision: 2 }"
      [allowEditing]="false" [allowFiltering]="false">
    </dxi-column>

    <dxi-column dataField="isNoAccrual" caption="Stop Accrual" width="110" [setCellValue]="setIsNoAccrualCellValue">
    </dxi-column>

    <dxi-column type="buttons" width="50">
      <dxi-button icon="download" [onClick]="openPO"></dxi-button>
    </dxi-column>

    <dxo-summary>
      <dxi-total-item column="orderTotal" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="approvedInvoicesTotal" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="remainingBudget" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}">
      </dxi-total-item>
    </dxo-summary>
  </dx-data-grid>

</div>


<dx-popup class="popup" [width]="225" [height]="130" [showTitle]="true" title="Set Accrual" [dragEnabled]="true"
  [hideOnOutsideClick]="true" [(visible)]="markAllPopupVisible" [showCloseButton]="true">
  <div *dxTemplate="let data of 'content'">
    <dx-button text="Mark All as No Accrual" type="success" (onClick)="markAllStopped()">
    </dx-button>
  </div>
</dx-popup>
