import { LogService } from './../services/log.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { environment } from '../../environments/environment';

import { UserTypeEnum } from '../dtos/user-type.enum';
import { Company } from '../dtos/company';
import { User } from '../dtos/user';
import { AuthService } from '../services/auth.service';
import { GlobalService } from '../services/global.service';
import { UserService } from '../services/felixApi/user.service';
import { NotificationService } from '../services/notification.service';
import { CompanyService } from '../services/felixApi/company.service';
import { AuthApiService } from '../services/felixApi/auth-api.service';
import { ConfigurationEnum } from '../dtos/configuration-enum';


@Component({
  selector: 'js-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit, OnDestroy {

  COMPONENT_NAME = 'welcome';
  userTypeEnum = UserTypeEnum;
  userCompanies: Company[];
  currComp: Company;
  currUser: User;
  currUserEmail: string;
  multipleCompanies = false;
  admin = false;
  loading = true;
  innerWidth: number;
  compForm: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  officeUser = false;
  currentVersion: string;
  trackingSystemActive: boolean;


  constructor(
    private router: Router,
    private userService: UserService,
    private globalService: GlobalService,
    private notiService: NotificationService,
    private authService: AuthService,
    private authApi: AuthApiService,
    private compService: CompanyService,
    private logService: LogService) {

    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.subscribe(() => {
        this.innerWidth = window.innerWidth;
      })
    );
  }

  ngOnInit() {
    console.log('welcome');
    this.innerWidth = window.innerWidth;
    this.globalService.setAreaSelected('');

    this.currentVersion = this.authService.currentVersion;

    this.currComp = this.globalService.getCurrentCompany();
    this.currUserEmail = this.authService.getUserEmail();

    if (this.globalService.getCompanyConfigValue(ConfigurationEnum.TrackingSystemActive) === 1) {
      this.trackingSystemActive = true;
    }

    if (!this.currUserEmail) {
      this.authService.signOut();
    } else {
      this.currUser = this.authService.getCurrentUser();
      if ((this.currComp && this.currComp.userTypeId === this.userTypeEnum.Admin) || (this.currUser && this.currUser.isSuperUser)) {
        this.admin = true;
        this.officeUser = true;
      } else if (this.currComp && this.currComp.userTypeId === this.userTypeEnum.Office) {
        this.officeUser = true;
      }

      this.getCompanies();
    }
  }

  getCompanies() {
    // console.log('getCompanies');
    this.userCompanies = this.compService.getCurrentCompanies();
    if (this.userCompanies) {
      this.populateCompanies();
    } else {
      if (!this.authService.getUserEmail()) {
        this.signOut();
      } else {
        this.subscriptions.push(
          this.authApi.getAuthorisedCompanies().subscribe(companies => {
            this.userCompanies = companies;
            this.populateCompanies();
          },
            err => {
              this.notiService.notify(err);
              this.currUser = null;
              this.loading = false;
            })
        );
      }
    }
  }

  populateCompanies() {
    // console.log('populateCompanies');
    this.compService.setCurrentCompanies(this.userCompanies);
    this.loading = false;
    if (this.userCompanies.length === 1) {
      this.multipleCompanies = false;
      this.currComp = this.userCompanies[0];
      if (!this.authService.signedIn) {
        this.compSelected();
      }
    } else {
      // check for a passed company
      const session = this.globalService.getSessionObject();

      if (session.passedCompanyId) {
        const thisCompany = this.userCompanies.filter(i => i.id === +session.passedCompanyId);
        if (thisCompany.length) {
          this.currComp = thisCompany[0];
          this.multipleCompanies = false;
          this.globalService.setSessionAtt('passedCompanyId', ''); // reset so we can go back to welcome page
          this.compSelected();
        } else {
          this.multipleCompanies = true;
        }
      } else {
        this.multipleCompanies = true;
      }
    }
  }

  compSelected() {
    // console.log('comp selected');
    this.loading = true;
    this.globalService.menuDisable.emit(true);
    // this.authService.setCurrentUserId(this.currComp.userId);
    this.globalService.setCurrentCompany(this.currComp);

    // get the user master - only have all user info once company is selected
    // only then do we fully sign in
    this.subscriptions.push(
      this.userService.getUser(this.currComp.userId).subscribe(
        currUser => {
          this.currUser = currUser;
          this.authService.setCurrentUser(this.currUser);
          this.authService.signIn();
          if (this.currComp.userTypeId === this.userTypeEnum.Admin) {
            this.admin = true;
          }

          this.globalService.menuDisable.emit(false);
          this.globalService.setCurrentCompany(this.currComp); // do twice so we have the user set
          this.writeLoginLog();
        },
        err => {
          this.notiService.notify(err);
          this.currUser = null;
          this.globalService.menuDisable.emit(false);
          this.loading = false;
        })
    );
  }

  writeLoginLog() {
    // console.log('writeLoginLog');
    this.subscriptions.push(
      this.logService.loginLog(this.authService.getUserEmail(), true, 'Company ' + this.currComp.id.toString()).subscribe(
        () => {
          const session = this.globalService.getSessionObject();

          // console.log(session);

          if (session && session.externalInvoiceId && session.externalInvoiceId !== '') {
            this.globalService.setAreaSelected('invoice');
            this.router.navigateByUrl('/invoice');
          } else if (session && session.xeroCode && session.xeroCode !== '') {
            this.setupXeroConnection(session.xeroCode);
          } else if (session && session.quickBooksCode && session.quickBooksCode !== '') {
            this.setupQuickBooksConnection(session.quickBooksCode, session.quickBooksRealmId);
          } else if (session && session.myobCode && session.myobCode !== '') {
            this.setupMyobConnection(session.myobCode);
          } else {
            this.setStartingArea();
          }
        },
        err => {
          this.notiService.notify(err);
          this.setStartingArea();
        })
    );
  }

  setStartingArea() {
    const area = localStorage.getItem('truthEngineCostingArea');
    const costCentreId = localStorage.getItem('jobANNX-costcentreId');
    if (costCentreId && costCentreId !== '') {
      this.globalService.selectedCostCentreId = +costCentreId;
    }

    localStorage.setItem('jobANNX-costcentreId', '');

    if (area && area !== '' && !costCentreId) {
      this.globalService.setAreaSelected(area);
      this.router.navigateByUrl('/' + area);
    } else {
      this.globalService.setAreaSelected('orders');
      this.router.navigateByUrl('/orders');
    }
  }

  setupXeroConnection(xeroCode: string) {
    this.subscriptions.push(
      this.compService.getXeroConnect(xeroCode, environment.applicationUrl).subscribe(
        () => {
          this.notiService.showSuccess('Xero connection successful');
          this.globalService.setAreaSelected('maintenance');
          this.router.navigateByUrl('/maintenance');
        },
        err => {
          this.globalService.setSessionAtt('xeroCode', ''); // clear
          this.globalService.setSessionAtt('xeroState', ''); // clear
          this.notiService.notify(err);
        })
    );
  }

  setupQuickBooksConnection(quickBooksCode: string, quickBooksRealmId: string) {
    this.subscriptions.push(
      this.compService.getQuickBooksConnect(quickBooksCode, environment.applicationUrl, quickBooksRealmId).subscribe(
        () => {
          this.notiService.showSuccess('QuickBooks connection successful');
          this.globalService.setAreaSelected('maintenance');
          this.router.navigateByUrl('/maintenance');
        },
        err => {
          this.globalService.setSessionAtt('quickBooksCode', ''); // clear
          this.globalService.setSessionAtt('quickBooksState', ''); // clear
          this.globalService.setSessionAtt('quickBooksRealmId', ''); // clear
          this.notiService.notify(err);
        })
    );
  }

  setupMyobConnection(myobCode: string) {
    this.subscriptions.push(
      this.compService.getMyobConnect(myobCode, environment.applicationUrl).subscribe(
        () => {
          this.notiService.showSuccess('MYOB connection successful');
          this.globalService.setAreaSelected('maintenance');
          this.router.navigateByUrl('/maintenance');
        },
        err => {
          this.globalService.setSessionAtt('myobCode', ''); // clear
          this.globalService.setSessionAtt('myobState', ''); // clear
          this.notiService.notify(err);
        })
    );
  }

  compareComps(comp1: Company, comp2: Company) {
    if (!comp1 || !comp2) {
      return false;
    } else {
      return comp1.id === comp2.id;
    }
  }

  signOut() {
    this.loading = true;
    this.authService.signOut();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  launchAdminPortal() {
    window.open(environment.adminAppUrl);
  }

  launchTruthEngine() {
    window.open(environment.addendaAppUrl);
  }

  launchEstConfig() {
    window.open(environment.estConfigAppUrl);
  }

  launchTracking() {
    window.open(environment.trackingAppUrl);
  }

  launchConfig() {
    window.open(environment.configAppUrl);
  }

  clearCache() {
    localStorage.clear();
    this.notiService.showInfo('Cache cleared.');
    setTimeout(() => {
      this.signOut();
    }, 5000);
  }
}
