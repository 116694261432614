import { NotificationService } from './../../services/notification.service';
import { InvoiceService } from './../../services/felixApi/invoice.service';
import { AuthService } from './../../services/auth.service';
import { GlobalService } from './../../services/global.service';
import { AttachmentTypeEnum } from './../../dtos/attachment-type.enum';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';

@Component({
  selector: 'js-show-pdf',
  templateUrl: './show-pdf.component.html',
  styleUrls: ['./show-pdf.component.scss']
})

/* Shows pdf attachment of either a job or variation, depending on what @Input is passed */
export class ShowPdfComponent implements OnInit, OnDestroy {
  @Input() blob: Blob;
  @Input() fileName: string;
  @Input() attachmentTypeId: number;
  @Input() allowReplace: boolean;
  @Input() invoiceId: number;

  subscriptions: Subscription[] = [];
  loading: boolean;
  mobileFriendlyZoomSetting = '150%';
  isMobile = false;
  attachmentTypeEnum = AttachmentTypeEnum;
  isInvoiceAdmin: boolean;
  attachmentsPopupVisible = false;
  files: any[] = [];
  loadingFile: boolean;
  popupWidth: number;
  pdfHeight: string;

  constructor(
    private deviceService: DeviceDetectorService,
    private authService: AuthService,
    private globalService: GlobalService,
    private activeModal: NgbActiveModal,
    private invoiceService: InvoiceService,
    private notiService: NotificationService
  ) {
    this.save = this.save.bind(this);
    this.replaceInvoice = this.replaceInvoice.bind(this);
  }

  ngOnInit() {
    this.isMobile = (this.deviceService.isMobile() || this.deviceService.isTablet()) && this.deviceService.browser !== 'Firefox';

    const perm = this.authService.areaPermissions.find(i => i.applicationArea === 'Invoices');
    if (this.authService.isAdminOrSuper() || perm?.permissionType === 'Admin') {
      this.isInvoiceAdmin = true;
    }

    this.popupWidth = window.innerWidth > 1200 ? 1200 : window.innerWidth;
    this.pdfHeight = (window.innerHeight - 100) + 'px';;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  save() {
    if (this.attachmentTypeId === this.attachmentTypeEnum.PDF) {
      saveAs(this.blob, this.fileName);
    } else if (this.attachmentTypeId === this.attachmentTypeEnum.JPEG) {
      const file = new File([this.globalService.base64toBlob(this.blob, this.attachmentTypeId)], this.fileName);
      saveAs(file);
    } else {
      const file = new File([this.globalService.base64toBlob(this.blob, this.attachmentTypeId)], this.fileName);
      saveAs(file);
    }
  }

  openInNewWindow() {
    const url = window.URL.createObjectURL(this.blob);
    window.open(url);
    this.close();
  }

  close() {
    this.activeModal.close(true);
  }

  replaceInvoice() {
    this.attachmentsPopupVisible = true;
  }

  uploadClick() {
    // load the file
    if (this.files && this.files.length) {
      this.loadingFile = true;
      this.attachmentsPopupVisible = false;
      const formData: FormData = new FormData();
      formData.append('image', this.files[0], this.files[0].name);

      this.subscriptions.push(
        this.invoiceService.replaceInvoiceAttachment(this.invoiceId, formData).subscribe(res => {
          this.blob = this.globalService.getBlobFromBase64(res.attachment, res.attachmentTypeId);
          this.files = [];
          this.loadingFile = false;
        }, err => {
          this.notiService.notify(err);
          this.files = [];
          this.loadingFile = false;
        })
      );
    }
  }
}
