export class VendorGroup {
  id: number;
  description: string;
  orderNumber: number;
  supplierPayTermsTypeId: number;
  followingMonths: number;
  supplierPayTermsDays: number;

  constructor(id: number, desc: string) {
    this.id = id;
    this.description = desc;
  }
}


export enum SupplierPayTermsTypesEnum {
  Statement = 1,
  Invoice = 2,
  Subcontractor = 3
}
