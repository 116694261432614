import { GridService } from './../../services/grid.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import DataSource from 'devextreme/data/data_source';
import { Subscription } from 'rxjs';
import { CostCentreSummary } from '../../dtos/cost-centre-summary';
import { OrderLine } from '../../dtos/order-line';
import { EstimatingService } from '../../services/felixApi/estimating.service';
import { JobService } from '../../services/felixApi/job.service';
import { JobEstimateSummary } from '../../dtos/Job-estimate-summary';

@Component({
  selector: 'js-cost-centre-summary',
  templateUrl: './cost-centre-summary.component.html',
  styleUrls: ['./cost-centre-summary.component.scss']
})
export class CostCentreSummaryComponent implements OnInit, OnDestroy {
  @Input() orderExport: OrderLine[];

  subscriptions: Subscription[] = [];
  gridHeight: number;
  dataSource: DataSource;
  costCentres: CostCentreSummary[] = [];
  jobEstimateSummaries: JobEstimateSummary[] = [];
  jobNumber: string;

  constructor(
    private _activeModal: NgbActiveModal,
    private _estimatingService: EstimatingService,
    public gridService: GridService,
    private _jobService: JobService
  ) {
  }

  ngOnInit() {
    this.jobNumber = this._jobService.currentJob.jobNumber;
    this.setHeight();
    this.getEstimatedCosts();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  setHeight() {
    this.gridHeight = window.innerHeight - 300;
  }

  getEstimatedCosts() {
    this.subscriptions.push(
      this._estimatingService.getJobEstimateSummaries(this._jobService.currentJob.id).subscribe({
        next:
          (jobEstimateSummaries) => {
            this.jobEstimateSummaries = jobEstimateSummaries;
            this.setUpDataSet();
          },
        error: () => {
          this.setUpDataSet();
        }
      })
    );
  }

  setUpDataSet() {
    this._estimatingService.costCentres.forEach(costCentre => {
      this.costCentres.push({
        costCentreOrderNumber: costCentre.costCentreOrderNumber ?? 0,
        costCode: costCentre.priceFileCode ?? '',
        description: costCentre.description,
        lineCost: 0,
        estimatedCost: this.jobEstimateSummaries?.find(i => i.costCentreId === costCentre.id)?.total
      });
    });

    // add up the order lines
    this.orderExport.forEach(costLine => {
      const existingEntry = this.costCentres.find(i => i.costCode === costLine.costCentreCode);

      if (existingEntry) {
        existingEntry.lineCost += (costLine.lineTotal ?? 0);
      }
    });

    this.dataSource = new DataSource({
      key: 'costCode',
      load: () => this.costCentres.filter(i => i.lineCost || i.estimatedCost),
    });
  }

  close() {
    this._activeModal.close(null);
  }

  getRate(data: any) {
    if (data.rate === null) {
      return 'Invalid';
    }
    return data.rate;
  }

  onCellPrepared(e) {
    if (e.rowType === 'data' && e.column.dataField === 'rate' && e.data.rate === null) {
      e.cellElement.style.color = 'red';
    }
  }

  calcDifference(data) {
    if (data.lineCost || data.estimatedCost) {
      return (data.lineCost ?? 0) - (data.estimatedCost ?? 0);
    }
    return null;
  }
}
