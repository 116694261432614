import { DivisionService } from './../../services/felixApi/division.service';
import { JobService } from '../../services/felixApi/job.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { GlobalService } from '../../services/global.service';
import { CompanyService } from './../../services/felixApi/company.service';
import { ConfigurationEnum } from '../../dtos/configuration-enum';
import { ClaimsService } from '../../services/felixApi/claims.service';
import { NotificationService } from '../../services/notification.service';
import { Job } from '../../dtos/job';
import { IncomeInvoiceTypeEnum } from '../../dtos/income-invoice';
import CustomStore from 'devextreme/data/custom_store';
import { GridService } from '../../services/grid.service';

@Component({
  selector: 'js-progress-claims',
  templateUrl: './progress-claims.component.html',
  styleUrls: ['./progress-claims.component.scss']
})
export class ProgressClaimsComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  dataSource: CustomStore;
  loading = false;
  jobs: Job[];
  gridHeight: number;
  submitFutureInvoices = false;
  currentJobId: number;
  selectedRecords: number[] = [];
  accountingSystemType: number;
  loadingData = true;
  minuteCountdown = 0;
  inEditMode: boolean;

  constructor(
    private globalService: GlobalService,
    private compService: CompanyService,
    private claimsService: ClaimsService,
    private notiService: NotificationService,
    private divisionService: DivisionService,
    public gridService: GridService,
    private jobService: JobService) {
    this.calculateClientName = this.calculateClientName.bind(this);
    this.calculateSiteAddress = this.calculateSiteAddress.bind(this);
    this.calculateConnectionStatus = this.calculateConnectionStatus.bind(this);
    this.calculateClientEmail = this.calculateClientEmail.bind(this);
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          this.setHeightWidths();
        }
      )
    );

    this.setHeightWidths();
    this.getCompanyConfigurations();
    this.setupDataRefresh();
  }

  setHeightWidths() {
    this.gridHeight = window.innerHeight - 107;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getCompanyConfigurations() {
    this.subscriptions.push(
      this.compService.getCompanyConfigurations().subscribe({
        next: () => {
          this.accountingSystemType = this.globalService.getCompanyConfigValue(ConfigurationEnum.AccountingSystem);
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }

  loadData(useCache: boolean) {
    this.loadingData = true;
    this.resetCountdown();
    this.subscriptions.push(
      this.claimsService.getClaimsData(useCache)
        .subscribe({
          next: () => {
            this.jobs = this.jobService.jobs;
            this.loadingData = false;
            this.setUpDataSource();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loadingData = false;
          }
        })
    );
  }
  

  setUpDataSource() {
    this.dataSource = new CustomStore({
      key: 'claimJobLineId',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.claimsService.getClaimsDue()
              .subscribe({
                next: (res) => {
                  res.forEach(async claim => {
                    claim.connectionStatus = await this.calculateConnectionStatus(claim);
                  });
                  return resolve(res);
                }, error: (err) => {
                  return reject(this.globalService.returnError(err));
                }
              })
          ));
      }
    });
  }

  setupDataRefresh(): void {
    // Refresh data every 10 minutes by counting down the minutes
    this.subscriptions.push(
      timer(0, 60000).subscribe(() => {
        if (!this.inEditMode) {
          if (this.minuteCountdown > 0 && !this.loading) {
            this.minuteCountdown--; // Decrement the countdown
          }

          if (this.minuteCountdown <= 0) {
            this.loadData(false);
          }
        }
      })
    );
  }

  onToolbarPreparing(e, templateName2: string) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        template: templateName2
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          type: 'default',
          text: 'Submit',
          onClick: this.processInvoices.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          type: 'outline',
          icon: 'refresh',
          onClick: this.refresh.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          type: 'default',
          stylingMode: 'outlined',
          text: 'Reset Layout',
          onClick: this.clearStatePersistance.bind(this)
        }
      });
  }

  calculateClientName(data) {
    return this.jobs.find(i => i.id === data.jobId)?.contractName;
  }

  calculateSiteAddress(data) {
    return this.jobs.find(i => i.id === data.jobId)?.jobAddressString;
  }

  calculateClientEmail(data) {
    return this.jobs.find(i => i.id === data.jobId)?.clientEmail;
  }

  async calculateConnectionStatus(data) {
    if (!data.orderNumber) {
      return 'Claims do not match contract';
    }

    if (!data.invoiceDate || data.invoiceDate === '') {
      if (data.companyActivityId) {
        // check that the activity is connected via the template to the job
        const checkResult = await this.checkActivityLinkedToJob(data.jobId, data.claimJobLineId)
          .then(
            res => {
              if (res) {
                return true;
              }
              return false;
            })
          .catch(() => {
            return false;
          });

        if (checkResult) {
          return 'Stage Skipped - mark appropriate task complete.';
        }
      }
      return 'Stage Skipped - Claim activity NOT linked to a task related to this job.';
    }

    const job = this.jobs.find(i => i.id === data.jobId);
    if (job) {
      const division = this.divisionService.divisions.find(i => i.id === job.divisionId);

      if (!division) {
        return 'Division not specified';
      }

      if (!division.accountingSystemTenantId || division.accountingSystemTenantId.trim() === '') {
        return 'Division not linked to accounts';
      }

      // Xero/MYOB requires an Income Account
      if (this.accountingSystemType == 1 || this.accountingSystemType == 3) {
        if (!division.incomeAccountId || division.incomeAccountId.trim() === '') {
          return 'Division not linked to an Income Account';
        }
      }

      // Xero also requires a Job Tracking Category
      if (this.accountingSystemType == 1) {
        if (!division.jobTrackingCategoryId || division.jobTrackingCategoryId.trim() === '') {
          return 'Division not linked to a Job Tracking Category';
        }
      }

      // QuickBooks and Business Central require a Service Code for Claims
      if (this.accountingSystemType == 2 || this.accountingSystemType == 4) {
        if (!division.claimIncomeAccountId || division.claimIncomeAccountId.trim() === '') {
          return 'Division not linked to a Service Code for Claims';
        }
      }
    } else {
      return 'Job Invalid';
    }

    const incomeError = this.claimsService.incomeInvoiceErrors
      .find(i => i.jobId === data.jobId
        && i.incomeInvoiceTypeId === IncomeInvoiceTypeEnum.Claim
        && i.claimJobLineId === data.claimJobLineId);

    if (incomeError) {
        if (incomeError.errorString.toLowerCase().indexOf("pending processing in business central") != -1) {
          return incomeError.errorString;
        } 
      return 'Integration Error: ' + incomeError.errorString + ' - Process again to add .2 to the end';
    }
    return 'OK to Submit';
  }

  async checkActivityLinkedToJob(jobId: number, claimJobLineId: number): Promise<boolean> {
    return new Promise((resolve, reject) =>
      this.subscriptions.push(
        this.claimsService.checkClaimActivityInJob(jobId, claimJobLineId).subscribe({
          next: () => {
            return resolve(true);
          }, error: () => {
            return resolve(false);
          }
        })
      ));
  }

  processInvoices() {
    // authorise
    if (!this.loading) {
      this.loading = true;
      this.subscriptions.push(
        this.claimsService.submitClaimsDue(this.submitFutureInvoices, { ids: this.selectedRecords }).subscribe({
          next: () => {
            this.loading = false;
            this.selectedRecords = [];
            this.setUpDataSource();
          }, error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
      );
    }
  }

  refresh() {
    this.selectedRecords = [];
    this.loadData(false);
  }

  clearStatePersistance() {
    this.loadingData = true;
    localStorage.removeItem('progress-claim-invoices');
    setTimeout(() => {
      this.loadingData = false;
    }, 300); // wait
  }
  resetCountdown() {
    this.minuteCountdown = 10; // Reset the countdown
  }
}



