<js-loading-spinner *ngIf="loading"></js-loading-spinner>

<div *ngIf="selectedCostCentreId && !loading" [class.inlineFlex]="isPriceListRight">
  <dx-resizable [handles]="resizeableHandles" [height]="ordersGridHeight" [minHeight]='300' [minWidth]="300"
    [width]="ordersGridWidth" [maxHeight]='gridHeight' (onResizeEnd)="onResizeEnd($event)" class="ms-2"
    (onInitialized)="onResizableInitialized($event)">
    <dx-data-grid *ngIf="orderLineGridVisible" #orderLineGrid id="orderLineGrid" [dataSource]="dataSource" height="100%"
      width="100%" [allowColumnReordering]="true" [allowColumnResizing]="true" [repaintChangesOnly]="true"
      [showBorders]="true" [rowAlternationEnabled]="true" [wordWrapEnabled]="false" [hoverStateEnabled]="true"
      [remoteOperations]="false" (onRowInserted)="$event.component.navigateToRow($event.key)"
      (onEditorPreparing)="onEditorPreparing($event)" (onCellPrepared)="onCellPrepared($event)"
      (onToolbarPreparing)="onToolbarPreparing($event)" (onEditingStart)='onEditingStart($event)'
      (onInitNewRow)='onInitNewRow($event)' (onSaving)="onSaving($event)" (onRowPrepared)="onRowPrepared($event)"
      (onExporting)="gridService.onExporting($event, 'order-lines')">

      <dxo-scrolling mode="standard"></dxo-scrolling>
      <dxo-paging [enabled]="false"></dxo-paging>
      <dxo-group-panel [visible]="false"></dxo-group-panel>
      <dxo-grouping #expand [autoExpandAll]="true" [contextMenuEnabled]="false"></dxo-grouping>
      <dxo-column-chooser [enabled]="true" mode="dragAndDrop"> </dxo-column-chooser>
      <dxo-state-storing [enabled]="true" type="custom" [customSave]="saveState" [customLoad]="loadState"
        [savingTimeout]="500">
      </dxo-state-storing>


      <dxo-search-panel [visible]="true" [width]="130"></dxo-search-panel>
      <dxo-export [enabled]="true"></dxo-export>
      <dxo-editing [mode]="editMode" refreshMode="full" [useIcons]="true" [allowDeleting]="true" [allowAdding]="true"
        [allowUpdating]="true">
      </dxo-editing>

      <dxo-keyboard-navigation [editOnKeyPress]="true" enterKeyAction="moveFocus" enterKeyDirection="column">
      </dxo-keyboard-navigation>

      <dxi-column dataField="orderPhaseDescription" caption="" dataType="string" [groupIndex]="0"
        groupCellTemplate="groupCellTemplate1">
      </dxi-column>

      <dxi-column dataField="priceFileCode" caption="Code" [width]="110" editCellTemplate="codeDropDown"
        [setCellValue]="setRecipeCellValue" [calculateSortValue]='calculateItemSortOrder' sortOrder="asc">
      </dxi-column>

      <dxi-column dataField="description" dataType="string" cellTemplate="wrapTemplate" [width]="descriptionWidth"
        sortOrder="asc">
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-column>

      <dxi-column dataField="unitOfMeasure" dataType="number" caption="Units"
        [editorOptions]="{ showClearButton: true }" [width]="80">
        <dxo-lookup [dataSource]="unitsOfMeasure" displayExpr="description" valueExpr="description"></dxo-lookup>
      </dxi-column>

      <dxi-column dataField="quantityString" caption="Measured Qty" dataType="string" [width]="100"
        editCellTemplate="editQuantityCellTemplate" [setCellValue]="setQtyCellValue">
      </dxi-column>

      <dxi-column dataField="measuredQuantity" caption="Actual Qty" dataType="number" [width]="70" allowEditing="false">
      </dxi-column>

      <dxi-column dataField="vendorId" caption="Vendor" dataType="number" [width]="150"
        [setCellValue]="setVendorCellValue" editCellTemplate="editVendorTemplate">
        <dxi-validation-rule type="required"></dxi-validation-rule>
        <dxo-lookup [dataSource]="vendors" displayExpr="vendorName" valueExpr="id">
        </dxo-lookup>
      </dxi-column>

      <dxi-column dataField="productCode" dataType="string" [width]="85" [hidingPriority]="1"> </dxi-column>

      <dxi-column dataField="supplierQuantity" caption="Vendor Qty" dataType="number" [width]="70"
        [setCellValue]="setSupplierQtyCellValue">
      </dxi-column>

      <dxi-column dataField="rate" dataType="number" [width]="100" [format]="{ type: 'fixedPoint', precision: 2 }"
        [setCellValue]="setRateCellValue">
      </dxi-column>

      <dxi-column dataField="lineTotal" caption="Total" dataType="number" [width]="100"
        [format]="{ type: 'fixedPoint', precision: 2 }" [allowEditing]="false">
      </dxi-column>

      <dxi-column dataField="phaseId" caption="Phase" dataType="number" [width]="70" [hidingPriority]="4">
        <dxo-lookup [dataSource]="phases" displayExpr="phaseCode" valueExpr="id">
        </dxo-lookup>
      </dxi-column>

      <dxi-column dataField="isToBeChecked" caption="To Be Checked" dataType="boolean" [width]="70"
        [hidingPriority]="3">
      </dxi-column>

      <dxi-column dataField="isLocked" dataType="boolean" [visible]="false"> </dxi-column>
      <dxi-column dataField="purchaseOrderId" dataType="boolean" [visible]="false"> </dxi-column>
      <dxi-column dataField="hasSizes" dataType="boolean" [visible]="false"> </dxi-column>

      <dxi-column type="buttons" [width]="85">
        <dxi-button name="delete" [visible]="isDeleteIconVisible"></dxi-button>
        <dxi-button name="delete" [visible]="isDeleteWithVOVisible" [onClick]="deletePopup"></dxi-button>
        <dxi-button name="edit" [visible]="isEditIconVisible" [onClick]="editPopup"></dxi-button>
        <dxi-button icon="close" [visible]="isEditIconVisible" [onClick]="deletePopup"></dxi-button>
        <dxi-button icon="tags" hint="Show Extras" [visible]="isExtrasIconVisible" [onClick]="extrasPopup"></dxi-button>
        <dxi-button [visible]="isLockIconVisible" template="lockCommand">
          <div class="lockButtonClass" *dxTemplate="let data of 'lockCommand'">
            <i *ngIf="!data.data.isLocked" class="material-icons" (click)="lockLine(data)">lock_open</i>
            <i *ngIf="data.data.isLocked" class="material-icons red" (click)="lockLine(data)">lock</i>
          </div>
        </dxi-button>
      </dxi-column>

      <div *dxTemplate="let cellInfo of 'wrapTemplate'">
        <div id="wrapComment" class='mx-0 px-0'>{{cellInfo.value}}</div>
      </div>

      <!-- select a vendor - but may need to filter by trade -->
      <div *dxTemplate="let cellInfo of 'editVendorTemplate'">
        <!-- need to check purchaseOrderIdToEdit here as we should not allow change of vendor for ordered item -->
        <dx-drop-down-box [dropDownOptions]="dropDownVendor" [dataSource]="activeVendors" [(value)]="cellInfo.value"
          displayExpr="vendorName" valueExpr="id" contentTemplate="vendorContentTemplate"
          (onValueChanged)="onVendorDropDownChanged(cellInfo, $event)" (onKeyDown)="onKeyDownVendor($event)"
          [disabled]="disableEntryField(cellInfo)">
          <div *dxTemplate="let e of 'vendorContentTemplate'">
            <dx-data-grid [dataSource]="activeVendors" keyExpr="id" [remoteOperations]="false" height="358px"
              [showBorders]="true" [wordWrapEnabled]="true" [selectedRowKeys]="[cellInfo.value]"
              [hoverStateEnabled]="true" [selection]="{ mode: 'single' }"
              [scrolling]="{ mode: 'standard', preloadEnabled: true }" [autoNavigateToFocusedRow]="true"
              (onSelectionChanged)="onVendorSelectionChanged(cellInfo, e.component, $event)">

              <dxo-search-panel [visible]="true" location="before" [width]="200"></dxo-search-panel>
              <dxo-paging [enabled]="false"></dxo-paging>
              <dxi-column dataField="vendorCode" caption="Code" sortOrder="asc" [width]="100"></dxi-column>
              <dxi-column dataField="vendorName"></dxi-column>
            </dx-data-grid>
          </div>
        </dx-drop-down-box>
      </div>

      <div *dxTemplate="let cellInfo of 'codeDropDown'">
        <dx-drop-down-box [dropDownOptions]="dropDownOptions" [dataSource]="recipeData" [(value)]="cellInfo.value"
          displayExpr="recipeCode" valueExpr="recipeCode" contentTemplate="contentTemplate"
          [disabled]="disableEntryField(cellInfo)">
          <!-- Note we have to use standard scrolling so the list will "autoNavigateToFocusedRow" if the item is not near the top of the list -->
          <div *dxTemplate="let e of 'contentTemplate'" class="dropdownClass">
            <dx-data-grid *ngIf="codeLookupVisible" [dataSource]="codeLookupData" [remoteOperations]="false"
              height="500px" [showBorders]="true" [selection]="{ mode: 'single' }" [focusedRowEnabled]="true"
              [focusedRowKey]="cellInfo.value" [(autoNavigateToFocusedRow)]="autoNavigateToFocusedRow"
              [selectedRowKeys]="[cellInfo.value]" [hoverStateEnabled]="true"
              (onSelectionChanged)="onSelectionChanged(cellInfo, e, $event)"
              (onToolbarPreparing)="onCodeGridPreparing($event)">

              <dxo-paging [enabled]="false"></dxo-paging>
              <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
              <dxo-search-panel [visible]="true" [width]="758"></dxo-search-panel>
              <dxo-group-panel [visible]="false"></dxo-group-panel>
              <dxo-grouping #expand [autoExpandAll]="true" [contextMenuEnabled]="true" expandMode="rowClick">
              </dxo-grouping>

              <dxi-column dataField="masterGroupCostCentre" dataType="string" caption="" [groupIndex]="0" [width]="220"
                groupCellTemplate="groupCellTemplate">
              </dxi-column>

              <dxi-column dataField="subGroupItemDesc" dataType="string" caption="" [groupIndex]="1" [width]="220"
                groupCellTemplate="groupCellTemplate">
              </dxi-column>

              <dxi-column dataField="recipeCode" caption="Code" [width]="110"></dxi-column>

              <dxi-column dataField="description"></dxi-column>

              <dxi-column dataField="rate" dataType="number" [width]="100"
                [format]="{ type: 'fixedPoint', precision: 2 }">
              </dxi-column>

              <dxi-column dataField="scale" [visible]="false"></dxi-column>

              <dxi-column dataField="unitOfMeasure" dataType="number" caption="Units" [width]="100">
              </dxi-column>

              <dxi-column dataField="vendorItemDescription" [visible]="false"></dxi-column>

              <div *dxTemplate="let data of 'groupCellTemplate'">
                {{ getGroupTitle(data) }}
              </div>
            </dx-data-grid>
          </div>
        </dx-drop-down-box>
      </div>


      <div *dxTemplate="let data of 'groupCellTemplate1'">
        <div id="groupHeaderRow">
          {{ getGroupTitle(data) }}

          <dx-button *ngIf="showEditButton(data)" id="groupHeaderButton" type="default" stylingMode='outlined'
            text="Edit Order" (onClick)="setEditingPO(data)"></dx-button>

          <dx-button *ngIf="showCancelButton(data)" id="groupDeleteButton" type="default" stylingMode='outlined'
            text="Delete Order" (onClick)="deletePO(data)"></dx-button>

          <dx-button *ngIf="showCancelButton(data)" id="cancelButton" type="default" stylingMode='outlined'
            text="Cancel Order" (onClick)="cancelPO(data)"></dx-button>

          <dx-button *ngIf="showCancelButton(data)" id="changeVendorButton" type="default" stylingMode='outlined'
            text="Change Vendor" (onClick)="changeVendorForPO(data)"></dx-button>

          <dx-button *ngIf="showCancelButton(data)" id="changePhaseButton" type="default" stylingMode='outlined'
            text="Change Phase" (onClick)="changePhaseForPO(data)"></dx-button>

          <dx-button *ngIf="showCancelButton(data)" id="revalueButton" type="default" stylingMode='outlined'
            text="Revalue Order" (onClick)="revaluePO(data)"></dx-button>

          <dx-button *ngIf="clearEditButton(data)" id="groupHeaderButton" type="default" stylingMode='outlined'
            text="Exit Edit" (onClick)="clearEditingPO()"></dx-button>

          <dx-button *ngIf="showEditButton(data)" id="copyPOItemsButton" type="default" stylingMode='outlined'
            text="Copy" (onClick)="copyPOItems(data)"></dx-button>

          <dx-button *ngIf="showCancelButton(data)" id="movePOItemsButton" type="default" stylingMode='outlined'
            text="Split" (onClick)="movePOItems(data)"></dx-button>

          <span class="ms-3 mt-2">{{ getVendorForOrder(data) }}</span>

          <span *ngIf="showDateSent(data)" class="ms-3 mt-2">Sent: {{ getSentDate(data) | date }}</span>

          <span *ngIf="getOrderTotal(data)" class="ms-3 mt-2">
            Total ${{ getOrderTotal(data) | number : '1.2' }}
          </span>

          <span *ngIf="getInvoiced(data)" class="ms-3 mt-2">
            Invoiced: ${{ getInvoiced(data) | number : '1.2' }}
          </span>
        </div>
      </div>

      <div *dxTemplate="let cellInfo of 'editQuantityCellTemplate'">
        <div *ngIf="!hasSizes">
          <dx-text-box [(value)]="cellInfo.value" (onValueChanged)="setEditedQtyValue($event, cellInfo)"
            [disabled]="disableEntryField(cellInfo)">
          </dx-text-box>
        </div>

        <div *ngIf="hasSizes">
          <dx-text-box id="qtyEditCell" [(value)]="cellInfo.value" [disabled]="true"></dx-text-box>
          <dx-button id="qtyEditButton" icon="edit" (onClick)="editLengths(cellInfo)"
            [disabled]="disableEntryField(cellInfo)">
          </dx-button>
        </div>
      </div>

      <dxo-summary>
        <dxi-group-item column="lineTotal" summaryType="sum" valueFormat="#,##0.00" displayFormat="${0}"
          [showInGroupFooter]="true">
        </dxi-group-item>
      </dxo-summary>
    </dx-data-grid>
  </dx-resizable>

  <div *ngIf="!isPriceListRight" class="gap5 ms-2"></div>

  <dx-data-grid *ngIf="!changingCostCentres && lookupDataGridData" #lookupDataGrid id="lookupDataGrid"
    [dataSource]="lookupDataGridData" [remoteOperations]="false" [height]="multiAddHeight" [width]="multiAddWidth"
    [rowAlternationEnabled]="true" [showBorders]="true" [focusedRowEnabled]="true"
    [(focusedRowKey)]="selectedPriceFileItemId" [autoNavigateToFocusedRow]="true" [allowColumnReordering]="true"
    [allowColumnResizing]="true" [hoverStateEnabled]="true" (onSaving)="addRecipesFromListGo($event)"
    (onToolbarPreparing)="onMultiLoadToolbarPreparing($event)" class="ms-2"
    (onRowPrepared)="onRowPreparedPriceList($event)" (onEditingStart)="onEditingAddGridStart($event)">

    <dxo-editing mode="batch" [allowUpdating]="true" startEditAction="click">
    </dxo-editing>

    <dxo-keyboard-navigation [editOnKeyPress]="true" enterKeyAction="moveFocus" enterKeyDirection="column">
    </dxo-keyboard-navigation>

    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-group-panel [visible]="false"></dxo-group-panel>
    <dxo-search-panel [visible]="true" [width]="250"></dxo-search-panel>
    <dxo-grouping #expand [autoExpandAll]="true" [contextMenuEnabled]="false" expandMode="rowClick">
    </dxo-grouping>

    <dxi-column dataField="masterGroupCostCentre" dataType="string" caption="" [groupIndex]="0" [width]="200"
      groupCellTemplate="groupCellTemplate">
    </dxi-column>

    <dxi-column dataField="subGroupItemDesc" dataType="string" caption="" [groupIndex]="1" [width]="220"
      groupCellTemplate="groupCellTemplate">
    </dxi-column>

    <dxi-column dataField="recipeCode" caption="Code" [width]="100" [allowEditing]="false"></dxi-column>

    <dxi-column dataField="description" [allowEditing]="false" cellTemplate="wrapTemplate"></dxi-column>

    <dxi-column *ngIf="isCommentVisible" dataField="comment" [width]="200" [allowEditing]="false"
      cellTemplate="wrapTemplate"></dxi-column>

    <dxi-column *ngIf="!isPriceListRight" dataField="vendorId" caption="Vendor" dataType="number" [width]="150"
      [allowEditing]="false">
      <dxo-lookup [dataSource]="vendors" displayExpr="vendorName" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="productCode" [width]="productCodeWidth" [allowEditing]="false"
      [visible]="multiAddWidth > 750"></dxi-column>

    <dxi-column dataField="phaseId" [width]="phaseWidth">
      <dxo-lookup [dataSource]="phases" displayExpr="phaseCode" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <!-- <dxi-column dataField="quantity" [width]="qtyWidth"></dxi-column> -->

    <dxi-column dataField="quantity" caption="Measured Qty" dataType="string" [width]="qtyWidth"
      editCellTemplate="editQuantityCellTemplate" [setCellValue]="setAddQtyCellValue">
    </dxi-column>

    <dxi-column dataField="unitOfMeasure" caption="Units" [width]="qtyWidth" [allowEditing]="false"></dxi-column>

    <dxi-column dataField="rate" dataType="number" [width]="100" [format]="{ type: 'fixedPoint', precision: 2 }"
      [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="scale" [visible]="false"></dxi-column>

    <div *dxTemplate="let data of 'groupCellTemplate'">
      {{ getGroupTitle(data) }}
    </div>

    <div *dxTemplate="let cellInfo of 'wrapTemplate'">
      <div id="wrapComment" class='mx-0 px-0'>{{cellInfo.value}}</div>
    </div>

    <div *dxTemplate="let cellInfo of 'editQuantityCellTemplate'">
      <div *ngIf="!hasSizes">
        <dx-text-box [(value)]="cellInfo.value" (onValueChanged)="setEditedQtyValue($event, cellInfo)">
        </dx-text-box>
      </div>

      <div *ngIf="hasSizes">
        <dx-text-box id="qtyEditCell" [(value)]="cellInfo.value" [disabled]="true"></dx-text-box>
        <dx-button id="qtyEditButton" icon="edit" (onClick)="editLengthsForAdd(cellInfo)">
        </dx-button>
      </div>
    </div>
  </dx-data-grid>
</div>

<!-- lengths popup -->
<dx-popup class="popup" [width]="300" [height]="475" [showTitle]="true" [dragEnabled]="true" [shading]="false"
  [hideOnOutsideClick]="false" [(visible)]="lengthPopupVisible" (onHiding)="hidingLengthPopup($event)"
  titleTemplate="title">
  <div *dxTemplate="let data of 'content'">

    <js-loading-spinner *ngIf="loadingLengths"></js-loading-spinner>

    <dx-data-grid *ngIf="!loadingLengths" id="lengthsDataGrid" class="m-auto" [dataSource]="lengthsDataSource"
      [height]="340" [width]="250" [allowColumnReordering]="false" [allowColumnResizing]="true"
      [repaintChangesOnly]="true" [showBorders]="true" [rowAlternationEnabled]="false" [wordWrapEnabled]="true">

      <dxo-scrolling mode="standard"></dxo-scrolling>
      <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"
        refreshMode="repaint">
      </dxo-editing>
      <dxo-paging [enabled]="false"></dxo-paging>

      <dxi-column dataField="quantity">
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-column>

      <dxi-column dataField="length">
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-column>
    </dx-data-grid>
    <dx-button id="button" class="mt-1 ms-1" text="Close" type="default" (onClick)="closeLengthPopup()"></dx-button>
  </div>

  <div *dxTemplate="let data of 'title'">
    <div class="qtyPopuptitle">{{orderLineDescription}}</div>
  </div>
</dx-popup>

<!-- delete -->
<dx-popup class="popup" [width]="500" [height]="230" [showTitle]="true" title="Extra" [dragEnabled]="false"
  [hideOnOutsideClick]="true" [(visible)]="deletePopupVisible" (onHiding)="hidingVariancePopup($event)">
  <div *dxTemplate="let data of 'content'">

    <js-extra-variance-select [varianceCodeId]=varianceCodeId [varianceReason]="varianceReason"
      (varianceCodeIdSelected)="varianceCodeId = $event" (varianceReasonSelected)="varianceReason = $event">
    </js-extra-variance-select>

    <dx-button *ngIf="!editingItem && varianceCodeId && varianceReason && !addingExtraToChange" id="deleteButton"
      text="Delete" type="danger" (onClick)="deleteByVO()">
    </dx-button>

    <dx-button *ngIf="editingItem && varianceCodeId && varianceReason && !addingExtraToChange" id="deleteButton"
      text="Begin Edit" type="default" (onClick)="beginEditByVO()">
    </dx-button>

    <dx-button *ngIf="varianceCodeId && varianceReason && addingExtraToChange" id="deleteButton" text="Begin Edit"
      type="default" (onClick)="saveEditByVO()">
    </dx-button>
  </div>
</dx-popup>

<!-- delete order -->
<dx-popup class="popup" [width]="220" [height]="250" [showTitle]="true" title="Delete Order" [dragEnabled]="false"
  [hideOnOutsideClick]="true" [(visible)]="deleteOrderPopupVisible">
  <div *dxTemplate="let data of 'content'">

    <js-loading-spinner *ngIf="deletingInProgress"></js-loading-spinner>

    <dx-button *ngIf="!deletingInProgress" id="deleteButton" text="Delete" type="danger" (onClick)="deleteOrderGo()">
    </dx-button>
  </div>
</dx-popup>

<!-- cancel order -->
<dx-popup class="popup" [width]="400" [height]="265" [showTitle]="true" title="Cancel Order" [dragEnabled]="false"
  [hideOnOutsideClick]="true" [(visible)]="cancelOrderPopupVisible">
  <div *dxTemplate="let data of 'content'">

    <js-loading-spinner *ngIf="deletingInProgress"></js-loading-spinner>

    <dx-select-box *ngIf="!deletingInProgress" id="varianceCode" [(ngModel)]="varianceCodeId" [showClearButton]="true"
      [searchEnabled]="true" [dataSource]="varianceCodes" valueExpr="id" displayExpr="description"
      placeholder="Select code">
    </dx-select-box>

    <dx-select-box *ngIf="!deletingInProgress" id="varianceReason" [(ngModel)]="varianceReason"
      [dataSource]="varianceReasons" valueExpr="reason" [acceptCustomValue]="true" [showClearButton]="true"
      [searchEnabled]="true" (onValueChanged)="onLookupValueChanged($event)"
      (onCustomItemCreating)="addCustomItem($event)" displayExpr="reason" placeholder="Select reason">
    </dx-select-box>

    <div *ngIf="!deletingInProgress && orderHasCallUp" class="dx-field">
      <div class="dx-field-label w210">Re-set the call-up for this order</div>
      <div class="w45">
        <dx-check-box [(ngModel)]="setCallUpToFalse" id="zeroRateClass"> </dx-check-box>
      </div>
    </div>

    <dx-button *ngIf="!deletingInProgress" id="deleteButton" text="Cancel" type="danger" (onClick)="cancelOrderGo()">
    </dx-button>
  </div>
</dx-popup>


<!-- extras pop-up -->
<dx-popup class="popup" [width]="1000" [height]="485" [showTitle]="true" [title]='currentRowDesc' [dragEnabled]="true"
  [hideOnOutsideClick]="true" [(visible)]="extrasPopupVisible" [shading]="false">
  <div *dxTemplate="let data of 'content'">
    <dx-data-grid [dataSource]="currentRowExtras" [remoteOperations]="false" [height]="400" [showBorders]="true"
      [scrolling]="{ mode: 'standard' }">

      <dxo-editing mode="row" refreshMode="full" [useIcons]="true" [allowDeleting]="isExtrasAdmin" [allowAdding]="false"
        [allowUpdating]="isExtrasAdmin">
      </dxo-editing>

      <dxi-column dataField="varianceCodeId" dataType="number" caption="Code">
        <dxo-lookup [dataSource]="varianceCodes" displayExpr="description" valueExpr="id"></dxo-lookup>
      </dxi-column>

      <dxi-column dataField="varianceReasonDescription" caption="Reason" [width]="300">
      </dxi-column>

      <dxi-column dataField="varianceTotal" dataType="number" caption="Value" [width]="120"
        [format]="{ type: 'fixedPoint', precision: 2 }">
      </dxi-column>

      <dxi-column dataField="modifiedDate" dataType="date" caption="Date" format="d/M/yy" sortOrder='asc' [width]="90"
        [allowEditing]="false">
      </dxi-column>

      <dxi-column dataField="modifiedUserId" caption="Modified By" dataType="number" [allowEditing]="false">
        <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id">
        </dxo-lookup>
      </dxi-column>

    </dx-data-grid>
  </div>
</dx-popup>


<!-- add - check extras pop-up -->
<dx-popup class="popup" [width]="500" [height]="230" [showTitle]="false" [dragEnabled]="false"
  [hideOnOutsideClick]="false" [(visible)]="isAddVariancePopupVisible" (onHiding)="hidingNewItemPopup($event)">
  <div *dxTemplate="let data of 'content'">

    <div *ngIf="!showExtraCodeOnAddSelected" class="marginAuto">
      <dx-button text="Add" type="default"
        (onClick)="varianceCodeId = null; varianceReason = null;isAddVariancePopupVisible = !isAddVariancePopupVisible">
      </dx-button>
      <dx-button text="Add as Extra" type="danger" (onClick)="showExtraCodeOnAddSelected = true" class="ms-3">
      </dx-button>
    </div>

    <div *ngIf="showExtraCodeOnAddSelected">
      <dx-select-box id="varianceCode" [(ngModel)]="varianceCodeId" [dataSource]="varianceCodes" valueExpr="id"
        displayExpr="description" [placeholder]="varianceCodePlaceHolder" [showClearButton]="true"
        [searchEnabled]="true">
      </dx-select-box>

      <dx-select-box id="varianceReason" [(ngModel)]="varianceReason" [dataSource]="varianceReasons" valueExpr="reason"
        [acceptCustomValue]="true" [showClearButton]="true" [searchEnabled]="true"
        (onValueChanged)="onLookupValueChanged($event)" (onCustomItemCreating)="addCustomItem($event)"
        displayExpr="reason" [placeholder]="varianceReasonPlaceHolder">
      </dx-select-box>

      <dx-button *ngIf="varianceCodeId && varianceReason" text="Add un-ordered Extra" type="danger"
        (onClick)="isAddVariancePopupVisible = !isAddVariancePopupVisible">
      </dx-button>
    </div>
  </div>
  <dxi-toolbar-item text="Add Item" location="before">
  </dxi-toolbar-item>
  <dxi-toolbar-item widget="dxButton" location="after" [options]="{ icon: 'close', onClick: cancelAddPopup }">
  </dxi-toolbar-item>
</dx-popup>

<!-- reset layout -->
<dx-popup class="popup" [width]="250" [height]="180" [showTitle]="true" title="Reset Layout" [dragEnabled]="false"
  [hideOnOutsideClick]="false" [(visible)]="resetPopupVisible">
  <div *dxTemplate="let data of 'content'">
    <dx-button id="reload-button" text="Clear & Reload" type="success" (onClick)="clearStatePersistanceGo()">
    </dx-button>
  </div>
</dx-popup>

<!-- mark all items as to-be-checked -->
<dx-popup class="popup" [width]="270" [height]="180" [showTitle]="true" title="Mark Items" [dragEnabled]="false"
  [hideOnOutsideClick]="false" [(visible)]="markItemsPopupVisible">
  <div *dxTemplate="let data of 'content'">
    <dx-button id="mark-items-button" text="Mark All Items: To-Be-Checked" type="success"
      (onClick)="markItemsToBeCheckedGo()">
    </dx-button>
  </div>
</dx-popup>

<!-- delete all items -->
<dx-popup class="popup" [width]="260" [height]="180" [showTitle]="true" title="Delete All" [dragEnabled]="false"
  [hideOnOutsideClick]="false" [(visible)]="deleteAllPopupVisible">
  <div *dxTemplate="let data of 'content'">
    <dx-button id="mark-items-button" text="Delete ALL Unordered items" type="danger" (onClick)="deleteAllGo()">
    </dx-button>
  </div>
</dx-popup>

<!-- change vendor for order -->
<dx-popup class="popup" [width]="500" [height]="425" [showTitle]="true" title="Change Vendor" [dragEnabled]="true"
  [hideOnOutsideClick]="false" [(visible)]="changeVendorOrderPopupVisible" [dragEnabled]="true">
  <div *dxTemplate="let data of 'content'">

    <js-loading-spinner *ngIf="deletingInProgress"></js-loading-spinner>

    <div *ngIf="!deletingInProgress">
      <dx-select-box id="varianceCode" [(ngModel)]="varianceCodeId" [showClearButton]="true" [searchEnabled]="true"
        [dataSource]="varianceCodes" valueExpr="id" displayExpr="description" placeholder="Select code">
      </dx-select-box>

      <dx-select-box id="varianceReason" [(ngModel)]="varianceReason" [dataSource]="varianceReasons" valueExpr="reason"
        [acceptCustomValue]="true" [showClearButton]="true" [searchEnabled]="true"
        (onValueChanged)="onLookupValueChanged($event)" (onCustomItemCreating)="addCustomItem($event)"
        displayExpr="reason" placeholder="Select reason">
      </dx-select-box>

      <dx-select-box id="varianceCode" [(ngModel)]="vendorId" [showClearButton]="true" [searchEnabled]="true"
        [dataSource]="activeVendors" valueExpr="id" displayExpr="vendorName" placeholder="New Vendor">
      </dx-select-box>

      <br>
      <div class="dx-field">
        <div class="dx-field-label costDateText">Date to price items:</div>
        <dx-date-box id="costDateBox" [width]="160" [(ngModel)]="costDate" [showClearButton]="true" type="date"
          pickerType="calendar">
        </dx-date-box>
      </div>
      <br>

      <div class="dx-field">
        <div class="dx-field-label changeVendorCheckBoxLabel">
          Zero rate if item not in price file for new vendor:
        </div>
        <div class="w45">
          <dx-check-box [(ngModel)]="isZeroRates" id="zeroRateClass"> </dx-check-box>
        </div>
      </div>

      <div *ngIf="orderHasCallUp" class="dx-field">
        <div class="dx-field-label changeVendorCheckBoxLabel">Change and reset the call-up for this order</div>
        <div class="w45">
          <dx-check-box [(ngModel)]="setCallUpToFalse" id="zeroRateClass"> </dx-check-box>
        </div>
      </div>

      <dx-button *ngIf="varianceCodeId && varianceReason && costDate" id="deleteButton" text="Change" type="danger"
        (onClick)="changeVendorForOrderGo()">
      </dx-button>
    </div>
  </div>
</dx-popup>

<!-- revalue order -->
<dx-popup class="popup" [width]="500" [height]="330" [showTitle]="true" title="Revalue Order" [dragEnabled]="true"
  [hideOnOutsideClick]="false" [(visible)]="revaluePopupVisible">
  <div *dxTemplate="let data of 'content'">

    <js-loading-spinner *ngIf="deletingInProgress"></js-loading-spinner>

    <div *ngIf="!deletingInProgress">
      <js-extra-variance-select [varianceCodeId]=varianceCodeId [varianceReason]="varianceReason"
        (varianceCodeIdSelected)="varianceCodeId = $event" (varianceReasonSelected)="varianceReason = $event">
      </js-extra-variance-select>

      <div class="dx-field">
        <div class="dx-field-label costDateText">Date to price items:</div>
        <dx-date-box id="costDateBox" [width]="160" [(ngModel)]="costDate" [showClearButton]="true" type="date"
          pickerType="calendar">
        </dx-date-box>
      </div>
      <br>

      <div class="dx-field">
        <div class="dx-field-label w210">Re-set the order to NOT sent?</div>
        <div class="w45">
          <dx-check-box [(ngModel)]="markOrderNotSent" id="zeroRateClass"> </dx-check-box>
        </div>
      </div>

      <dx-button *ngIf="varianceCodeId && varianceReason && costDate" id="deleteButton" text="Revalue" type="default"
        (onClick)="revaluePOGo()">
      </dx-button>
    </div>
  </div>
</dx-popup>

<!-- mark all items as to-be-checked -->
<dx-popup class="popup" [width]="320" [height]="180" [showTitle]="true" title="Mark Not Sent" [dragEnabled]="false"
  [hideOnOutsideClick]="false" [(visible)]="clearEditPopupVisible">
  <div *dxTemplate="let data of 'content'">

    <div class="dx-field">
      <div class="dx-field-label w210">Re-set the order to NOT sent?</div>
      <div class="w45">
        <dx-check-box [(ngModel)]="markOrderNotSent" id="zeroRateClass"> </dx-check-box>
      </div>
    </div>

    <dx-button id="mark-items-button" text="Exit" type="success" (onClick)="clearEditingPOGo()">
    </dx-button>
  </div>
</dx-popup>

<!-- lengths popup for add new price file items -->
<dx-popup class="popup" [width]="300" [height]="475" [showTitle]="true" title="Set lengths" [dragEnabled]="true"
  [hideOnOutsideClick]="true" [shading]="false" [(visible)]="addLengthPopupVisible"
  (onHiding)="hidingAddLengthPopup($event)">
  <div *dxTemplate="let data of 'content'">
    <dx-data-grid id="lengthsDataGrid" class="m-auto" [dataSource]="lengthsDataSource" [height]="340" [width]="250"
      [allowColumnReordering]="false" [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true"
      [rowAlternationEnabled]="false" [wordWrapEnabled]="true">

      <dxo-scrolling mode="standard"></dxo-scrolling>
      <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"
        refreshMode="repaint">
      </dxo-editing>
      <dxo-paging [enabled]="false"></dxo-paging>

      <dxi-column dataField="quantity">
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-column>

      <dxi-column dataField="length">
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-column>
    </dx-data-grid>
    <dx-button id="button" class="mt-1 ms-1" text="Close" type="default" (onClick)="closeAddLengthPopup()"></dx-button>
  </div>
</dx-popup>

<!-- change phase for order -->
<dx-popup class="popup" [width]="300" [height]="180" [showTitle]="true" title="Change Phase" [dragEnabled]="true"
  [hideOnOutsideClick]="false" [(visible)]="changePhasePopupVisible" [dragEnabled]="true">
  <div *dxTemplate="let data of 'content'">

    <js-loading-spinner *ngIf="deletingInProgress"></js-loading-spinner>

    <div *ngIf="!deletingInProgress">
      <dx-select-box id="newPhaseId" [(ngModel)]="newPhaseId" [searchEnabled]="true" [dataSource]="phases"
        valueExpr="id" displayExpr="description">
      </dx-select-box>
      <br>

      <dx-button id="deleteButton" text="Change" type="default" (onClick)="changePhaseForOrderGo()">
      </dx-button>
    </div>
  </div>
</dx-popup>
