import { Job } from './../../dtos/job';
import { GridService } from './../../services/grid.service';
import { CompanyActivityService } from './../../services/felixApi/company-activity.service';
import { CompanyService } from './../../services/felixApi/company.service';
import { Invoice } from './../../dtos/invoice';
import { UserService } from './../../services/felixApi/user.service';
import { PurchaseOrder } from '../../dtos/purchase-order';
import { PoService } from '../../services/felixApi/po.service';
import { JobService } from '../../services/felixApi/job.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Vendor } from '../../dtos/vendor';
import { MaintenanceService } from '../../services/felixApi/maintenance.service';
import { GlobalService } from '../../services/global.service';
import { InvoiceService } from '../../services/felixApi/invoice.service';
import { NotificationService } from '../../services/notification.service';
import { RoleTypeEnum } from '../../dtos/role-type.enum';
import { User } from '../../dtos/user';
import { PriceFileItem } from '../../dtos/price-file-item';
import { EstimatingService } from '../../services/felixApi/estimating.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { VendorGroup } from '../../dtos/vendor-group';
import { UserTypeEnum } from '../../dtos/user-type.enum';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { OrderLookup } from '../../dtos/order-lookup';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PreviousInvoicesComponent } from '../previous-invoices/previous-invoices.component';
import { RejectInvoiceComponent } from '../reject-invoice/reject-invoice.component';
import { BulkAddExtrasComponent } from '../bulk-add-extras/bulk-add-extras.component';
import { BackChargeComponent } from '../back-charge/back-charge.component';
import { InvoiceLogsComponent } from '../invoices-logs/invoice-logs.component';

@Component({
  selector: 'js-invoices-on-hold',
  templateUrl: './invoices-on-hold.component.html',
  styleUrls: ['./invoices-on-hold.component.scss']
})
export class InvoicesOnHoldComponent implements OnInit, OnDestroy {

  @ViewChild('invoiceGrid') invoiceHoldGrid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  dataSource: CustomStore;
  vendors: Vendor[];
  loadingData = true;
  loading = false;
  jobs: Job[];
  gridHeight: number;
  lastVendorId: number;
  attachmentsPopupVisible: boolean;
  currentInvoiceId: any;
  files: any[] = [];
  loadingFile: boolean;
  attachmentExists: any;
  attachmentsPopupHeight: number;
  users: User[];
  costCentres: PriceFileItem[];
  addExtraVisible = false; // add a simple extra for a PO
  purchaseOrderId: number;
  poNumber: string;
  jobNumber: string;
  invoices: Invoice[];
  editMode = 'row';
  selectedVendorGroupId: number;
  vendorGroups: VendorGroup[];
  jobId: any;
  showOrderLookupPopup: boolean;
  selectedOrderId: number;
  orderLookupSwitch = true;
  invoiceData: object;
  invoiceId: any;
  showOrderLookupPopupSplits: boolean;
  deletePopupVisible: boolean;
  processPopupVisible: boolean;
  selectionMode = 'none';
  bulkEditMode: any;
  selectedRowKeys: number[] = [];
  purchaseOrders: PurchaseOrder[];
  dropDownOptionsPOs: { width: number; minHeight: number; };
  purchaseOrderIdForPODropdown: number;

  constructor(
    private router: Router,
    private globalService: GlobalService,
    private invoiceService: InvoiceService,
    private notiService: NotificationService,
    private jobService: JobService,
    private poService: PoService,
    private userService: UserService,
    private estimatingService: EstimatingService,
    private companyService: CompanyService,
    private modalService: NgbModal,
    private companyActivityService: CompanyActivityService,
    public gridService: GridService,
    private maintenanceService: MaintenanceService) {
    this.setVendorCellValue = this.setVendorCellValue.bind(this);
    this.setOrderNumberCellValue = this.setOrderNumberCellValue.bind(this);
    this.setTotalIncGSTCellValue = this.setTotalIncGSTCellValue.bind(this);
    this.downloadAttachment = this.downloadAttachment.bind(this);
    this.calculateJobNumber = this.calculateJobNumber.bind(this);
    this.calculateSiteManager = this.calculateSiteManager.bind(this);
    this.calculateEstimator = this.calculateEstimator.bind(this);
    this.calculateRemainingBudget = this.calculateRemainingBudget.bind(this);
    this.onCellPrepared = this.onCellPrepared.bind(this);
    this.extrasPopup = this.extrasPopup.bind(this);
    this.extrasClose = this.extrasClose.bind(this);
    this.openPO = this.openPO.bind(this);
    this.calculateVendorSortValue = this.calculateVendorSortValue.bind(this);
    this.setEditMode = this.setEditMode.bind(this);
    this.setInvoiceDateValue = this.setInvoiceDateValue.bind(this);
    this.getOrderFromLookup = this.getOrderFromLookup.bind(this);
    this.onEditingStart = this.onEditingStart.bind(this);
    this.calculateEstimatorTitle = this.calculateEstimatorTitle.bind(this);
    this.openCostCentre = this.openCostCentre.bind(this);
    this.splitInvoice = this.splitInvoice.bind(this);
    this.splitOrdersFromLookup = this.splitOrdersFromLookup.bind(this);
    this.openHistory = this.openHistory.bind(this);
    this.calculateActivityDesc = this.calculateActivityDesc.bind(this);
    this.deleteInvoice = this.deleteInvoice.bind(this);
    this.calculateSiteManagerTitle = this.calculateSiteManagerTitle.bind(this);
    this.calculateCostCentreSortValue = this.calculateCostCentreSortValue.bind(this);
    this.onPurchaseOrderSelectionChanged = this.onPurchaseOrderSelectionChanged.bind(this);
    this.getPoNumberCellValue = this.getPoNumberCellValue.bind(this);
    this.getPoNumber = this.getPoNumber.bind(this);
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          this.setHeightWidths();
        }
      )
    );

    this.setHeightWidths();
    this.loadData(true);
  }

  setHeightWidths() {
    this.gridHeight = window.innerHeight - 127;
    this.dropDownOptionsPOs = {
      width: window.innerWidth < 950 ? window.innerWidth : 950,
      minHeight: window.innerHeight < 400 ? window.innerHeight : 400
    };
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  loadData(useCache: boolean) {
    this.subscriptions.push(
      this.invoiceService.getInvoicesDataForOnHold(useCache)
        .subscribe({
          next: () => {
            this.vendors = this.maintenanceService.allVendors;
            this.jobs = this.jobService.jobs;
            this.costCentres = this.estimatingService.costCentres;
            this.users = [];
            // only add the users that have min write permission to invoices
            this.userService.officeUsers.forEach(officeUser => {
              if (officeUser.userTypeId === UserTypeEnum.Admin) {
                this.users.push(officeUser);
              } else {
                const invoiceUser = this.userService.invoiceUsers.find(i => i.userId === officeUser.id);
                if (invoiceUser) {
                  this.users.push(officeUser);
                }
              }
            });
            this.loadingData = false;
            this.vendorGroups = [new VendorGroup(null, 'All Groups')];
            this.vendorGroups = this.vendorGroups.concat(this.maintenanceService.vendorGroups);
            this.setUpDataSource(useCache);
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loadingData = false;
          }
        })
    );
  }

  setUpDataSource(useCache: boolean) {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.invoiceService.getInvoicesOnHold(useCache).subscribe({
              next: (res) => {
                this.invoices = res;
                this.purchaseOrders = this.poService.allPurchaseOrdersForCompany;
                res.forEach(invoice => {
                  invoice.vendorGroupId = this.vendors.find(i => i.id === invoice.vendorId)?.vendorGroupId;
                });

                const filteredInvoices = res.filter(i => !this.selectedVendorGroupId || i.vendorGroupId === this.selectedVendorGroupId);
                return resolve(filteredInvoices);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.invoiceService.updateInvoice(encodeURIComponent(key), values).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.invoiceService.deleteInvoice(encodeURIComponent(key)).subscribe({
              next: () => {
                return resolve();
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      }
    });
  }

  onToolbarPreparing(e, templateName: string) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.push({
      location: 'after',
      locateInMenu: 'auto',
      template: templateName
    });

    if (this.bulkEditMode) {
      toolbarItems.unshift(
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxButton',
          options: {
            type: 'outline',
            icon: 'close',
            onClick: this.bulkAddExtraClicked.bind(this)
          }
        },
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxButton',
          options: {
            type: 'outline',
            text: 'Update',
            onClick: this.bulkAddExtraRun.bind(this)
          }
        });
    } else {
      toolbarItems.unshift(
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxButton',
          options: {
            type: 'outline',
            text: this.editMode === 'row' ? 'Batch Edit Mode' : 'Row Edit Mode',
            onClick: this.setEditMode.bind(this)
          }
        },
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxButton',
          options: {
            type: 'outline',
            text: 'Bulk Add Balance',
            onClick: this.bulkAddExtraClicked.bind(this)
          }
        },
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxButton',
          options: {
            type: 'default',
            text: 'Process Invoices',
            onClick: this.processInvoices.bind(this)
          }
        },
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxButton',
          options: {
            icon: 'refresh',
            onClick: this.refresh.bind(this)
          }
        },
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxButton',
          options: {
            type: 'default',
            stylingMode: 'outlined',
            text: 'Reset Layout',
            onClick: this.clearStatePersistance.bind(this)
          }
        });
    }
  }

  setVendorCellValue(rowData, value, originalData) {
    rowData.vendorId = value;
    this.lastVendorId = value;
    const vendor = this.vendors.find(i => i.id === value);

    // recalc GST
    if (vendor?.isGstFree) {
      rowData.totalGST = 0;
      this.notiService.showInfo('GST Free Vendor');
    } else {
      rowData.totalGST = Math.round((originalData.totalIncGST / (1 + this.invoiceService.globalGSTRate)) * 100) / 100;
    }
    rowData.totalExGST = originalData.totalIncGST - rowData.totalGST;
  }

  calculateVendorSortValue(data) {
    return this.vendors.find(i => i.id === data.vendorId)?.vendorName;
  }

  setTotalIncGSTCellValue(rowData, value, originalData) {
    rowData.totalIncGST = value;

    const vendor = this.vendors.find(i => i.id === originalData.vendorId);
    if (vendor?.isGstFree) {
      rowData.totalGST = 0;
      this.notiService.showInfo('GST Free Vendor');
    } else {
      rowData.totalGST = Math.round((value / (1 + this.invoiceService.globalGSTRate)) * 100) / 100;
    }
    rowData.totalExGST = value - rowData.totalGST;
  }

  setTotalGSTCellValue(rowData, value, originalData) {
    rowData.totalGST = value;
    rowData.totalExGST = originalData.totalIncGST - value;
  }

  setOrderNumberCellValue(rowData, value, originalData) {
    if (this.purchaseOrderIdForPODropdown) {
      const purchaseOrder = this.purchaseOrders.find(i => i.id === this.purchaseOrderIdForPODropdown);
      const job = this.jobs.find(i => i.id === purchaseOrder?.jobId);
      if (job) {
        rowData.purchaseOrderId = purchaseOrder.id;
        rowData.jobId = job.id;
        rowData.poNumber = purchaseOrder.poNumber;
        rowData.orderNumber = job.jobNumber + '.' + purchaseOrder.poNumber;
      }
      this.purchaseOrderIdForPODropdown = null;
    } else {
      rowData.orderNumber = value;

      let orderNumberArray = value.split('.');
      // let validOrder = false;
      rowData.purchaseOrderId = null;
      rowData.poNumber = null;
      rowData.jobId = null;

      if (orderNumberArray.length > 1) {
        const jobNumber = orderNumberArray[0];
        const job = this.jobs.find(i => i.jobNumber === jobNumber);
        if (job) {
          let orderNumber = orderNumberArray[1] + (orderNumberArray.length <= 2 ? '' : '.' + orderNumberArray[2]);

          if (orderNumberArray.length > 3) {
            orderNumber += '.' + orderNumberArray[3];
          }

          // look this up to see if we can find the matching PO
          const res = this.purchaseOrders.find(i => i.jobId === job.id && i.poNumber === orderNumber);
          if (res) {
            rowData.purchaseOrderId = res.id;
            rowData.jobId = job.id;
            rowData.poNumber = orderNumber;
          }
        }
      } else {
        // we may have a DataBuild PO - format job/po
        orderNumberArray = value.split('/');

        if (orderNumberArray.length === 2) {
          const jobNumber = orderNumberArray[0];
          const job = this.jobs.find(i => i.jobNumber === jobNumber);
          if (job) {
            const orderNumber = orderNumberArray[1];
            // look this up to see if we can find the matching PO
            const res = this.purchaseOrders.find(i => i.jobId === job.id && i.poNumber === orderNumber);
            if (res) {
              rowData.purchaseOrderId = res.id;
              rowData.jobId = job.id;
              rowData.poNumber = orderNumber;
            }
          }
        }
      }
    }
  }

  getPurchaseOrder(jobId: number, orderNumber: string): Promise<PurchaseOrder> {
    return new Promise((resolve, reject) =>
      this.subscriptions.push(
        this.poService.getPurchaseOrder(jobId, orderNumber).subscribe({
          next: (res) => {
            return resolve(res);
          }, error: (err) => {
            return reject(this.globalService.returnError(err));
          }
        })
      ));
  }

  calculateJobNumber(data) {
    return this.jobs.find(i => i.id === data.jobId)?.jobNumber;
  }

  calculateSiteManager(data) {
    return this.jobService.jobRoles.find(i => i.jobId === data.jobId && i.roleId === RoleTypeEnum.SiteManager)?.user.fullName;
  }

  calculateEstimator(data) {
    return this.jobService.jobRoles.find(i => i.jobId === data.jobId && i.roleId === RoleTypeEnum.ConstructionEstimator)?.user.fullName;
  }

  calculateRemainingBudget(data) {
    if (data.purchaseOrderId) {
      const po = this.poService.allPurchaseOrdersForCompany.find(i => i.id === data.purchaseOrderId);
      let remainingAmount = 0;
      if (po) {
        remainingAmount = po.orderTotal - (po.approvedInvoicesTotal ? po.approvedInvoicesTotal : 0);
      }

      // if we have previous invoices we calculate in order
      const dataSource = this.invoiceHoldGrid.instance.getDataSource();
      const sameInvoices = this.invoices.filter(i => i.purchaseOrderId === data.purchaseOrderId);

      let totalOfAllInvoices = 0;
      let finishCalc = false;

      sameInvoices.forEach(invoice => {
        if (!finishCalc) {
          if (invoice.id === data.id) {
            finishCalc = true;
          }
          totalOfAllInvoices += invoice.totalExGST;
        }
      });

      return remainingAmount - totalOfAllInvoices;
    } else {
      return null;
    }
  }

  downloadAttachment(e) {
    // download attachment
    this.loadingFile = true;
    this.subscriptions.push(
      this.invoiceService.getInvoiceAttachment(e.row.data.id).subscribe({
        next: (res) => {
          if (res) {
            this.poService.convertBlobAndOpen(res.attachment, res.attachmentTypeId, res.attachmentName, true, e.row.data.id);
          } else {
            this.notiService.showInfo('No invoice attached');
          }
          this.loadingFile = false;
        }, error: (err) => {
          this.notiService.notify(err);
          this.loadingFile = false;
        }
      })
    );
  }

  processInvoices() {
    this.processPopupVisible = true;
  }

  processInvoicesGo() {
    this.processPopupVisible = false;
    // authorise
    this.loadingFile = true;
    this.subscriptions.push(
      this.invoiceService.processOnHoldInvoices().subscribe({
        next: () => {
          this.loadingFile = false;
          this.setUpDataSource(false);
        }, error: (err) => {
          this.notiService.notify(err);
          this.loadingFile = false;
        }
      })
    );
  }

  extrasPopup(e) {
    this.purchaseOrderId = e.row.data.purchaseOrderId;
    this.poNumber = e.row.data.poNumber;
    this.jobId = e.row.data.jobId;
    this.invoiceId = e.row.data.id;
    this.addExtraVisible = true;
  }

  extrasClose(e: OrderLookup) {
    if (e.purchaseOrderId) {
      // change the PO for the invoice
      this.subscriptions.push(
        this.invoiceService.updateInvoice(this.invoiceId, { purchaseOrderId: e.purchaseOrderId })
          .subscribe({
            next: () => {
              this.addExtraVisible = false;
              this.setUpDataSource(false);
            },
            error: (err) => {
              this.notiService.notify(err);
            }
          })
      );
    } else {
      this.addExtraVisible = false;

      if (e.varianceAmount) {
        const po = this.poService.allPurchaseOrdersForCompany.find(i => i.id === this.purchaseOrderId);
        if (po) {
          po.orderTotal += e.varianceAmount;
        }
        this.setUpDataSource(false);
      }
    }
  }

  openPO(e) {
    if (e.row.data.jobId && e.row.data.purchaseOrderId) {
      const jobNumber = this.jobs.find(i => i.id === e.row.data.jobId)?.jobNumber;
      const dataRecord = {
        purchaseOrderIds: [e.row.data.purchaseOrderId],
        emailAddresses: [],
        ccToSelf: false,
        download: true,
        printPrices: true
      };

      this.notiService.showInfo('downloading order');

      this.subscriptions.push(
        this.poService.sendPurchaseOrders(e.row.data.jobId, dataRecord)
          .subscribe({
            next: (orderResponse) => {
              this.poService.convertAndSave(orderResponse.pdfOrders, jobNumber, e.row.data.poNumber, false);
            },
            error: (err) => {
              this.notiService.notify(err);
            }
          })
      );
    }
  }

  openCostCentre(e) {
    if (e.row.data.jobId && e.row.data.costCentreId) {
      this.jobService.setCurrentJob(this.jobs.find(i => i.id === e.row.data.jobId)?.jobNumber);
      localStorage.setItem('jobANNX-costcentreId', e.row.data.costCentreId);
      window.open(environment.applicationUrl + 'orders?coy=' + this.globalService.getCurrentCompanyId());
    }
  }

  openHistory(e) {
    if (e.row.data.purchaseOrderId) {
      // see other invoices against this PO
      const modalRef = this.modalService.open(PreviousInvoicesComponent, { windowClass: 'modal-1200' });
      modalRef.componentInstance.purchaseOrderId = e.row.data.purchaseOrderId;
      modalRef.componentInstance.invoiceId = e.row.data.id;
      modalRef.componentInstance.jobNumber = this.calculateJobNumber(e.row.data);
      modalRef.componentInstance.costCentreId = e.row.data.costCentreId;

      modalRef.result.then(() => {
      });
    }
  }

  splitInvoice(e) {
    // allow to split
    this.invoiceData = e.row.data;
    this.invoiceId = e.row.data.id;
    this.selectedOrderId = e.row.data.purchaseOrderId;
    this.orderLookupSwitch = !this.orderLookupSwitch;
    this.showOrderLookupPopupSplits = true;
  }

  deleteInvoice(e) {
    this.deletePopupVisible = true;
    this.invoiceId = e.row.data.id;
  }

  deleteInvoiceGo() {
    this.loadingFile = true; // spinner
    this.subscriptions.push(
      this.invoiceService.deleteInvoice(this.invoiceId).subscribe({
        next: () => {
          this.deletePopupVisible = false;
          this.loadingFile = false;
          this.setUpDataSource(false);
        },
        error: (err) => {
          this.notiService.notify(err);
        }
      })
    );
  }

  rejectInvoice() {
    this.deletePopupVisible = false;
    const modalRef = this.modalService.open(RejectInvoiceComponent, { windowClass: 'modal-edit' });
    modalRef.componentInstance.invoice = this.invoices.find(i => i.id === this.invoiceId);
    modalRef.result.then(() => {
      this.setUpDataSource(false);
    }, () => { });
  }

  showInvoiceLogs() {
    this.deletePopupVisible = false;
    const modalRef = this.modalService.open(InvoiceLogsComponent, { windowClass: 'modal-1200' });
    modalRef.componentInstance.invoiceId = this.invoiceId;
    modalRef.result.then(() => {
    }, () => { });
  }

  backChargeInvoice() {
    const invoice = this.invoices.find(i => i.id === this.invoiceId);
    if (invoice.totalIncGST < 0) {
      this.notiService.showWarning('Cannot back-charge a credit');
    } else {
      this.deletePopupVisible = false;
      const modalRef = this.modalService.open(BackChargeComponent, { windowClass: 'modal-edit' });
      modalRef.componentInstance.invoice = invoice;
      modalRef.result.then(() => {
        this.setUpDataSource(false);
      }, () => { });
    }
  }

  splitOrdersFromLookup(e) {
    this.showOrderLookupPopupSplits = false;
    this.setUpDataSource(false);
  }

  holdMessage(data) {
    console.log(data);
  }

  onCellPrepared(e) {
    if (e.rowType === 'data') {
      if (e.column.dataField === 'remainingBudget') {
        const remainingBudget = this.calculateRemainingBudget(e.data);
        if (remainingBudget < 0) {
          // check if there are other orders we could use
          const po = this.poService.allPurchaseOrdersForCompany.find(i => i.id === e.data.purchaseOrderId);

          if (po) {
            const pos = this.poService.allPurchaseOrdersForCompany
              .filter(i => i.jobId === po.jobId && i.costCentreId === po.costCentreId && i.id !== e.data.purchaseOrderId
                && (i.orderTotal - (i.approvedInvoicesTotal ? i.approvedInvoicesTotal : 0) > 0));

            let count = 0;
            pos.forEach(pOrder => {
              if (pOrder.vendorId === e.data.vendorId) {
                count++;
              } else {
                // check for other orders we could use
                const vendorCanInvoice = this.maintenanceService.allVendors.find(i => i.id === pOrder.vendorId);

                if (vendorCanInvoice?.canAnyVendorInvoice) {
                  count++;
                } else {
                  const vendorPayable = this.maintenanceService.vendorPayables
                    .find(i => i.orderVendorId === pOrder.vendorId && i.payVendorId === e.data.vendorId);

                  if (vendorPayable) {
                    count++;
                  }
                }
              }
            });

            if (count > 0) {
              e.cellElement.classList.add('orange');
            } else {
              e.cellElement.classList.add('red');
            }
          }
        }
      }
    }
  }

  clearStatePersistance() {
    this.loading = true;
    localStorage.removeItem('invoices-on-hold');
    setTimeout(() => {
      this.loading = false;
    }, 300); // wait
  }

  setEditMode() {
    if (this.editMode === 'batch' && this.invoiceHoldGrid.instance.hasEditData()) {
      this.notiService.showInfo('Please Save or Cancel the edited data');
    } else {
      this.editMode = this.editMode === 'batch' ? 'row' : 'batch';
      this.purchaseOrderIdForPODropdown = null;
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 300); // wait
    }
  }

  bulkAddExtraClicked() {
    this.bulkEditMode = !this.bulkEditMode;
    this.selectedRowKeys = [];
    if (this.bulkEditMode) {
      this.selectionMode = 'multiple';
      if (this.invoiceHoldGrid) {
        this.invoiceHoldGrid.instance.clearSelection();
      }
    } else {
      this.selectionMode = 'none';
    }
    // initial wait fo state store to save clear
    setTimeout(() => {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 300); // wait
    }, 600); // wait
  }

  bulkAddExtraRun() {
    if (this.selectedRowKeys.length) {
      // get the balance amounts
      let balanceAmounts: number[] = [];
      let purchaseOrderIds: number[] = [];
      this.selectedRowKeys.forEach(invoice => {
        const invoiceSelected = this.invoices.find(i => i.id === invoice);
        if (invoiceSelected) {
          purchaseOrderIds.push(invoiceSelected.purchaseOrderId);
          balanceAmounts.push(this.calculateRemainingBudget(invoiceSelected));
        }
      });
      const modalRef = this.modalService.open(BulkAddExtrasComponent, { windowClass: 'modal-edit' });
      modalRef.result.then((bulkFields) => {
        this.loadingFile = true;
        const updateData = {
          purchaseOrderIds: purchaseOrderIds,
          description: bulkFields.orderLineDescription,
          varianceCodeId: bulkFields.varianceCodeId,
          varianceReason: bulkFields.varianceReason,
          amounts: balanceAmounts
        };
        this.subscriptions.push(
          this.poService.bulkAddExtra(updateData).subscribe({
            next: () => {
              this.loadingFile = false;
              this.bulkEditMode = false;
              this.selectionMode = 'none';
              this.loading = true;
              setTimeout(() => {
                this.loading = false;
                this.setUpDataSource(false);
              }, 300); // wait
            }, error: (err) => {
              this.notiService.notify(err);
              this.loadingFile = false;
            }
          })
        );
      }, () => { });
    } else {
      this.notiService.showWarning('No invoices selected');
    }
  }

  onEditorPreparing(e: any) {
    if (e.parentType === 'dataRow' && e.dataField === 'description') {
      e.editorName = 'dxTextArea';

      // hack to resize text area
      e.editorOptions.autoResizeEnabled = true;
      let prevHeight = null;
      e.editorOptions.onInput = (args) => {
        const td = args.element.closest('td');
        if (prevHeight !== td.offsetHeight) {
          const overlay = e.element.querySelector('.dx-datagrid-focus-overlay');
          if (overlay != null) {
            overlay.style.height = (td.offsetHeight + 1) + 'px';
          }
          prevHeight = td.offsetHeight;
        }
      };
    }
  }

  async setInvoiceDateValue(rowData: Invoice, value: Date, originalData: Invoice) {
    if (value) {
      if (this.maintenanceService.orderControl.minPostingDate
        && this.invoiceService.dateDiffInDays(new Date(this.maintenanceService.orderControl.minPostingDate.valueOf()), new Date(value.toDateString())) > 0) {
        this.notiService.showWarning('Date older than minimum allowed');
      } else {
        rowData.invoiceDate = new Date(value.toDateString());

        if (this.invoiceService.dateDiffInDays(new Date(), rowData.invoiceDate) > 60) {
          this.notiService.showWarning('Possible old date entered');
        }
        rowData.dueDate = await this.invoiceService.calcDueDate(originalData.vendorId, rowData.invoiceDate, originalData.createDate);
      }
    } else {
      rowData.invoiceDate = null;
    }
  }

  refresh() {
    this.loadData(false);
  }

  setEditedValue(valueChangedEventArg, cellInfo) {
    cellInfo.setValue(valueChangedEventArg.value);
  }

  orderLookup(cellInfo) {
    this.selectedOrderId = cellInfo.data.purchaseOrderId;
    this.orderLookupSwitch = !this.orderLookupSwitch;
    this.showOrderLookupPopup = true;
  }

  getOrderFromLookup(e, cellInfo) {
    this.showOrderLookupPopup = false;
    if (e) {
      const po = this.poService.allPurchaseOrdersForCompany.find(i => i.id === e);
      if (po) {
        const job = this.jobs.find(i => i.id === po.jobId);
        cellInfo.setValue(job?.jobNumber + '.' + po.poNumber);
      }
    }
  }

  onEditingStart(e) {
    this.showOrderLookupPopup = false;
    this.showOrderLookupPopupSplits = false;
  }

  calculateEstimatorTitle() {
    return this.companyService.companyRoles.find(i => i.roleId === RoleTypeEnum.ConstructionEstimator)?.companyRoleDescription;
  }

  calculateSiteManagerTitle() {
    return this.companyService.companyRoles.find(i => i.roleId === RoleTypeEnum.SiteManager)?.companyRoleDescription;
  }

  calculateActivityDesc(data) {
    const jobExtra = this.jobService.jobExtras?.find(i => i.jobId === data.jobId);
    if (jobExtra && jobExtra.maintenanceCompleteDate) {
      return 'Maintenance Complete';
    } else if (jobExtra && jobExtra.currentActivityId) {
      const activity = this.companyActivityService.activities.find(i => i.id === jobExtra.currentActivityId);
      if (activity) {
        return activity.activityCode + ' - ' + activity.description;
      }
    }
    return '';
  }

  onPODropDownChanged(cellInfo, e) {
    if (!e.value) {
      cellInfo.setValue(null);
    }
  }

  onPurchaseOrderSelectionChanged(cellInfo, dropDownBox, event) {
    if (event.selectedRowKeys.length > 0) {
      this.purchaseOrderIdForPODropdown = event.selectedRowKeys[0];
      const purchaseOrder = this.purchaseOrders.find(i => i.id === event.selectedRowKeys[0]);
      const job = this.jobs.find(i => i.id === purchaseOrder?.jobId);
      cellInfo.setValue(job?.jobNumber + '.' + purchaseOrder?.poNumber);
      dropDownBox.close();
    }
  }

  onPOListCellPrepared(e) {
    if (e.rowType === 'data' && e.column.dataField === 'remainingBudgetExGst'
      && e.data.remainingBudgetExGst < 0) {
      e.cellElement.style.color = 'red';
    }
  }

  calculateCostCentreSortValue(data) {
    return this.costCentres.find(i => i.id === data?.costCentreId)?.orderNumber;
  }

  calcRemainingBudget(data) {
    return data.orderTotal - (data.approvedInvoicesTotal ?? 0);
  }

  getPoNumberCellValue(data): string {
    const job = this.jobs.find(i => i.id === data.jobId);
    return job?.jobNumber + '.' + data.poNumber;
  }

  getPoNumber(cellInfo): number {
    let orderNumberArray = cellInfo.value.split('.');

    if (orderNumberArray.length > 1) {
      const jobNumber = orderNumberArray[0];
      const job = this.jobs.find(i => i.jobNumber === jobNumber);
      if (job) {
        let orderNumber = orderNumberArray[1] + (orderNumberArray.length <= 2 ? '' : '.' + orderNumberArray[2]);

        if (orderNumberArray.length > 3) {
          orderNumber += '.' + orderNumberArray[3];
        }

        // look this up to see if we can find the matching PO
        const res = this.purchaseOrders.find(i => i.jobId === job.id && i.poNumber === orderNumber);
        if (res) {
          this.purchaseOrderIdForPODropdown = res.id;
          return this.purchaseOrderIdForPODropdown;
        }
      }
    }

    return null;
  }
}
