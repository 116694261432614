import { CompanyActivityService } from '../../services/felixApi/company-activity.service';
import { forkJoin, Observable, of, throwError as observableThrowError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { GlobalService } from '../global.service';
import { Company } from '../../dtos/company';
import { CompanyConfiguration } from '../../dtos/company-configuration';
import { HttpService } from '../http.service';
import { AuthApiService } from './auth-api.service';
import { CompanyRole } from '../../dtos/companyRole';
import { CompanyActivity } from '../../dtos/company-activity';

@Injectable()
export class CompanyService {

  authCompanies: Company[];
  companyConfigsCompany: string;
  companyConfigs: CompanyConfiguration[];
  companyRolesCompany: string;
  companyRoles: CompanyRole[];
  activities: CompanyActivity[] = [];
  currentCompanyForActivitiesId: string;

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private authApi: AuthApiService,
    private companyActivityService: CompanyActivityService,
    private globalService: GlobalService) { }

  setCurrentCompanies(comps: Company[]) {
    this.authCompanies = comps;
  }

  getCurrentCompanies() {
    return this.authCompanies;
  }

  getCompanies() {
    return this.http.get<Company[]>(this.globalService.getApiUrl() + '/companies', this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  getCompaniesByUserEmail(email: string) {
    return this.http.get<Company[]>(this.globalService.getApiUrl() + '/email-addresses/' + email + '/authorised-companies',
      this.httpService.getHttpOptions());
  }

  getCompany(id) {
    return this.http.get<Company>(this.globalService.getApiUrl() + '/companies/' + id, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  // updateComp(company: Company) {
  //   const url = this.globalService.getApiUrl() + '/companies/' + company.id;
  //   return this.http.patch(url, JSON.stringify(company), this.httpService.getHttpOptions());
  // }

  // addComp(company: Company) {
  //   const url = this.globalService.getApiUrl() + '/companies';
  //   return this.http.post(url, JSON.stringify(company), this.httpService.getHttpOptions());
  // }

  getCompanyRoles(useCache: boolean): Observable<CompanyRole[]> {
    if (useCache && this.companyRolesCompany === this.globalService.getCurrentCompanyId()
      && this.companyRoles && this.companyRoles.length) {
      return of(this.companyRoles);
    } else {
      const url = this.globalService.getApiUrl() + '/company-roles';
      return this.http.get<CompanyRole[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.companyRoles = res; this.companyRolesCompany = this.globalService.getCurrentCompanyId();
        }), catchError(this.handleError));
    }
  }

  getCompanyConfigs() {
    if (this.companyConfigsCompany === this.globalService.getCurrentCompanyId()
      && this.companyConfigs && this.companyConfigs.length) {
      return of(this.companyConfigs);
    } else {
      return this.http.get<CompanyConfiguration[]>(this.globalService.getApiUrl() + '/company-configurations',
        this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.companyConfigs = res; this.companyConfigsCompany = this.globalService.getCurrentCompanyId();
          }), catchError(this.handleError));
    }
  }

  getCompanyConfigurations(): Observable<CompanyConfiguration[]> {
    return forkJoin(
      [
        this.getCompanyConfigs(),
        this.authApi.getAreaPermissions(),
        this.companyActivityService.getCompanyActivities()
      ]
    )
      .pipe(map(
        ([configs]) => {
          return configs;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getCompanyActivities(activeOnly: boolean = true, useCache: boolean = true) {
    if (useCache && this.activities && this.activities.length && this.currentCompanyForActivitiesId === this.globalService.getCurrentCompanyId()) {
      return of(this.activities);
    } else {
      const url = this.globalService.getApiUrl() + '/activities?activeOnly=' + activeOnly;
      return this.http.get<CompanyActivity[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.activities = res;
          this.currentCompanyForActivitiesId = this.globalService.getCurrentCompanyId();
        }),
        catchError(this.handleError));
    }
  }

  getXeroConnect(xeroCode: string, applicationUrl: string) {
    return this.http.get<Company>(this.globalService.getApiUrl()
      + '/accounting-system/connect?integrationType=1'
      + '&accessCode=' + xeroCode
      + '&redirectUri=' + applicationUrl,
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  getQuickBooksConnect(quickBooksCode: string, applicationUrl: string, realmId: string) {
    return this.http.get<Company>(this.globalService.getApiUrl()
      + '/accounting-system/connect?integrationType=8'
      + '&accessCode=' + quickBooksCode
      + '&redirectUri=' + applicationUrl
      + '&tenantId=' + realmId,
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  getMyobConnect(myobCode: string, applicationUrl: string) {
    return this.http.get<Company>(this.globalService.getApiUrl()
      + '/accounting-system/connect?integrationType=9'
      + '&accessCode=' + myobCode
      + '&redirectUri=' + applicationUrl,
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
