<dx-drop-down-box [dropDownOptions]="dropDownOptions" [dataSource]="accountingSystemCategories"
  [(value)]="oldCategoryID" displayExpr="name" valueExpr="trackingCategoryID" contentTemplate="contentTemplate">
  <!-- Note we have to use standard scrolling so the list will "autoNavigateToFocusedRow" if the item is not near the top of the list -->
  <div *dxTemplate="let e of 'contentTemplate'" class="dropdownClass">
    <dx-data-grid [dataSource]="accountingSystemCategories" keyExpr="trackingCategoryID" [remoteOperations]="false"
      height="500px" [showBorders]="true" [selection]="{ mode: 'single' }" [focusedRowEnabled]="true"
      [focusedRowKey]="oldCategoryID" [autoNavigateToFocusedRow]="true" [selectedRowKeys]="[oldCategoryID]"
      [hoverStateEnabled]="true" [scrolling]="{ mode: 'virtual', preloadEnabled: true }"
      (onSelectionChanged)="onSelectionChanged(e, $event)">

      <dxo-paging [enabled]="false"></dxo-paging>
      <dxo-search-panel [visible]="true" [width]="200"></dxo-search-panel>

      <dxi-column dataField="name"></dxi-column>
    </dx-data-grid>
  </div>
</dx-drop-down-box>
