<div class="page">

  <h4>
    Load History Invoices - use with caution!
  </h4>

  <div>fields: (NOTE - No Header Row for .TXT file) Job Number, Cost Centre, PO Number (if blank the system will try to
    find a matching PO), Comment, Vendor Code, Invoice Number, Date, Due Date, Total Inc GST, GST, Paid/Unpaid</div>

  <js-loading-spinner *ngIf="loadingFile"></js-loading-spinner>

  <div id="fileuploader">
    <dx-file-uploader #fileUploader selectButtonText="Select file" labelText="or drop file here" [multiple]="false"
      [(value)]="fileValue" uploadMode="useForm">
    </dx-file-uploader>
  </div>

  <dx-select-box [(ngModel)]="ignoreExistingInvoices" [dataSource]="ignoreExistingInvoicesOptions" valueExpr="id"
    [width]="200" displayExpr="name" class="inlineBlock">
  </dx-select-box>

  <dx-check-box *ngIf="!loadingFile" text="Skip missing jobs" [(value)]='skipMissingJobs' class="ms-3 me-3">
  </dx-check-box>

  <br><br>
  Include completions from:
  <dx-date-box *ngIf="!loadingFile" [(value)]='includeCompletionsFrom' class="ms-2" [width]="150"
    class="inlineBlock ms-2">
  </dx-date-box>

  <dx-button *ngIf="fileValue.length && !loadingFile" text="Load" type="default" class="ms-4" (onClick)="loadFileGo()">
  </dx-button>

  <dx-data-grid *ngIf="fileLoaded" id="dataGrid" class="m-auto" [height]="gridHeight" [dataSource]="dataSource"
    [allowColumnReordering]="true" [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true"
    [rowAlternationEnabled]="true" [wordWrapEnabled]="true" (onExporting)="gridService.onExporting($event, 'export')">

    <dxo-scrolling mode="virtual"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-search-panel [visible]="true" location="before" [width]="250"></dxo-search-panel>
    <dxo-paging [enabled]="false"></dxo-paging>

    <dxi-column dataField="rowNumber" caption="Row" dataType="number" width="85" [format]="0"> </dxi-column>
    <dxi-column dataField="column" dataType="string" width="85"> </dxi-column>

    <dxi-column dataField="jobNumber" dataType="string" [width]="110"> </dxi-column>
    <dxi-column dataField="costCentre" dataType="string" [width]="110"> </dxi-column>
    <dxi-column dataField="poNumber" dataType="string" [width]="110"> </dxi-column>
    <dxi-column dataField="vendorName" dataType="string"> </dxi-column>
    <dxi-column dataField="invoiceNumber" dataType="string" [width]="150"> </dxi-column>

    <dxi-column dataField="invoiceTotalExGst" dataType="number" width="140"
      [format]="{ type: 'fixedPoint', precision: 2 }">
    </dxi-column>

    <dxi-column dataField="orderTotalExGst" dataType="number" width="140"
      [format]="{ type: 'fixedPoint', precision: 2 }">
    </dxi-column>

    <dxi-column dataField="errorString" dataType="string" minWidth="500"> </dxi-column>

  </dx-data-grid>

  <div *ngIf="fileLoaded">Note: File will not load at all if there are any errors.</div>

</div>
