import { CompanyService } from './company.service';
import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable, forkJoin, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { MaintenanceService } from './maintenance.service';
import { HttpService } from '../http.service';
import { AuthApiService } from './auth-api.service';
import { UserService } from './user.service';
import { EstimatingService } from './estimating.service';
import { CompanyConfiguration } from '../../dtos/company-configuration';
import { AccountingSystemJob } from '../../dtos/accounting-system-job';
import { AccountingSystemAccountToCostCentre } from '../../dtos/accounting-system-account-to-cost-centre';

@Injectable({
  providedIn: 'root'
})
export class AccountingSystemService {

  constructor(
    private _http: HttpClient,
    private maintenanceService: MaintenanceService,
    private companyService: CompanyService,
    private globalService: GlobalService,
    private authApi: AuthApiService,
    private userService: UserService,
    private estimatingService: EstimatingService,
    private httpService: HttpService) { }

    jobsForTenant: AccountingSystemJob[];
    jobsForTenantCompany: string;
    jobTenantId: string;

  getDataForInvoiceQuery(): Observable<CompanyConfiguration[]> {
    return forkJoin(
      [
        this.companyService.getCompanyConfigs(),
        this.authApi.getAreaPermissions(),
        this.maintenanceService.getAccountingSystemTenants(),
        this.userService.getCurrCompUsers(true)
      ]
    )
      .pipe(
        map(([configs]) => { return configs; }),
        catchError((err) => { return this.handleError(err); })
      );
  }

  getAccountingSystemInvoices(tenantId: string, jobId: string, vendorId: string, lastModified: Date, maxInvoices: number) {
    let url = this.globalService.getApiUrl() + '/accounting-system/invoices?tenantId=' + tenantId;
    if (jobId) {
      url += '&jobIds=' + jobId;
    }
    if (vendorId) {
      url += '&vendorIds=' + vendorId;
    }
    if (lastModified) {
      url += '&ifModifiedSince=' + lastModified.toISOString();
    }
    if (maxInvoices) {
      url += '&maxInvoices=' + maxInvoices;
    }
    return this._http.get(url, this.httpService.getHttpOptions());
  }

  getAccountingSystemInvoicesCount(tenantId: string, vendorId: string, lastModified: Date) {
    let url = this.globalService.getApiUrl() + '/accounting-system/invoices-count?tenantId=' + tenantId;
    if (vendorId) {
      url += '&vendorIds=' + vendorId;
    }
    if (lastModified) {
      url += '&ifModifiedSince=' + lastModified.toISOString();
    }
    return this._http.get(url, this.httpService.getHttpOptions());
  }

  getAccountingSystemIncomeInvoices(tenantId: string, contactId: string, lastModified: Date, maxInvoices: number, splitByLines: boolean) {
    let url = this.globalService.getApiUrl() + '/accounting-system/income-invoices-compare?tenantId=' + tenantId;
    if (contactId) {
      url += '&contactIds=' + contactId;
    }
    if (lastModified) {
      url += '&ifModifiedSince=' + lastModified.toISOString();
    }
    if (maxInvoices) {
      url += '&maxInvoices=' + maxInvoices;
    }
    if (splitByLines) {
      url += '&splitByLines=true';
    }
    return this._http.get(url, this.httpService.getHttpOptions());
  }

  getAccountingSystemIncomeInvoicesCount(tenantId: string, contactId: string, lastModified: Date) {
    let url = this.globalService.getApiUrl() + '/accounting-system/income-invoices-count?tenantId=' + tenantId;
    if (contactId) {
      url += '&contactIds=' + contactId;
    }
    if (lastModified) {
      url += '&ifModifiedSince=' + lastModified.toISOString();
    }
    return this._http.get(url, this.httpService.getHttpOptions());
  }

  importSelectedIncomeInvoices(selectedRows: any[]) {
    const url = this.globalService.getApiUrl() + '/accounting-system/income-invoices-import';
    return this._http.post(url, JSON.stringify(selectedRows), this.httpService.getHttpOptions());
  }

  submitInvoices(vendorGroupId: number, includeExpiredInsurances: boolean, selectedIds: any) {
    let url = this.globalService.getApiUrl() + '/accounting-system/submit-invoices?includeExpiredInsurances=' + includeExpiredInsurances;
    if (vendorGroupId) {
      url += '&vendorGroupId=' + vendorGroupId;
    }
    return this._http.post(url, JSON.stringify(selectedIds), this.httpService.getHttpOptions());
  }

  getJobsForTenant(useCache: boolean = true, tenantId: string): Observable<AccountingSystemJob[]> {
    if (useCache && this.jobsForTenant && this.jobsForTenant.length
      && this.jobsForTenantCompany === this.globalService.getCurrentCompanyId()
      && this.jobTenantId === tenantId) {
      return of(this.jobsForTenant);
    } else {
      let url = this.globalService.getApiUrl() + '/accounting-system/jobs';

      if (tenantId && tenantId !== '') {
        url += '?tenantId=' + tenantId;
      }
      return this._http.post<AccountingSystemJob[]>(url, null, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.jobsForTenant = res;
          this.jobsForTenantCompany = this.globalService.getCurrentCompanyId();
          this.jobTenantId = tenantId;
        }),
        catchError(this.handleError));
    }
  }

  getDataForPurchaseOrderQuery(): Observable<CompanyConfiguration[]> {
    return forkJoin(
      [
        this.companyService.getCompanyConfigs(),
        this.authApi.getAreaPermissions(),
        this.maintenanceService.getAccountingSystemTenants(),
        this.userService.getCurrCompUsers(true),
        this.estimatingService.getPriceFileItemGroups(true)
      ]
    )
      .pipe(
        map(([configs]) => { return configs; }),
        catchError((err) => { return this.handleError(err); })
      );
  }

  getAccountingSystemPurchaseOrders(tenantId: string, jobId: string, vendorId: string, lastModified: Date, maxPurchaseOrders: number) {
    let url = this.globalService.getApiUrl() + '/accounting-system/purchase-orders?tenantId=' + tenantId;
    if (jobId) {
      url += '&jobIds=' + jobId;
    }
    if (vendorId) {
      url += '&vendorIds=' + vendorId;
    }
    if (lastModified) {
      url += '&ifModifiedSince=' + lastModified.toISOString();
    }
    if (maxPurchaseOrders) {
      url += '&maxPurchaseOrders=' + maxPurchaseOrders;
    }
    return this._http.get(url, this.httpService.getHttpOptions());
  }

  getAccountingSystemPurchaseOrdersCompare(tenantId: string, jobId: number, dateFrom: Date, accountingSystemjobId: string) {
    let url = this.globalService.getApiUrl() + '/accounting-system/purchase-orders-compare'
    url += '?tenantId=' + tenantId;
    url += '&jobId=' + jobId;
    url += '&dateFrom=' + dateFrom.toISOString();
    url += '&accountingSystemjobId=' + accountingSystemjobId;
    return this._http.get(url, this.httpService.getHttpOptions());
  }

  importSelectedPurchaseOrders(jobId: number, selectedRows: any[]) {
    let url = this.globalService.getApiUrl() + '/accounting-system/purchase-orders-import';
    url += '?jobId=' + jobId;
    return this._http.post(url, JSON.stringify(selectedRows), this.httpService.getHttpOptions());
  }

  // Accounting System Account to Cost Centre
  getAccountingSystemAccountToCostCentres(tenantId: string): Observable<AccountingSystemAccountToCostCentre[]> {
    const url = this.globalService.getApiUrl() + '/accounting-system/account-to-cost-centre?tenantId=' + tenantId;
    return this._http.get<AccountingSystemAccountToCostCentre[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  addAccountingSystemAccountToCostCentre(accountId: string, costCentreId: string, accountingSystemTenantId: string) {
    const url = this.globalService.getApiUrl() + '/accounting-system/account-to-cost-centre';
    const accountToCostCentre = {
      accountId: accountId,
      costCentreId: costCentreId,
      tenantId: accountingSystemTenantId
    };
    return this._http.post<AccountingSystemAccountToCostCentre>(url, JSON.stringify(accountToCostCentre), this.httpService.getHttpOptions());
  }

  updateAccountingSystemAccountToCostCentre(id: number, costCentreId: string) {
    const url = this.globalService.getApiUrl() + '/accounting-system/account-to-cost-centre/' + id;
    const updatedRecord = {
      costCentreId: costCentreId,
      hasCostCentreId: true
    };
    return this._http.patch<AccountingSystemAccountToCostCentre>(url, JSON.stringify(updatedRecord), this.httpService.getHttpOptions());
  }

  deleteAccountingSystemAccountToCostCentre(id: number) {
    const url = this.globalService.getApiUrl() + '/accounting-system/account-to-cost-centre/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  jsonReplacer(key, value) {
    if (this[key] instanceof Date) {
      return this[key].toDateString();
    }
    return value;
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
