import { AccountingSystemTenant } from '../../dtos/accounting-system-tenant';
import { UserService } from '../../services/felixApi/user.service';
import { AccountingSystemService } from '../../services/felixApi/accounting-system.service';
import { MaintenanceService } from '../../services/felixApi/maintenance.service';
import { Component, OnInit, ViewChild, OnDestroy, OnChanges, Input } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { Subscription } from 'rxjs';
import { GlobalService } from '../../services/global.service';
import { GridService } from '../../services/grid.service';
import { NotificationService } from '../../services/notification.service';
import { AuthService } from '../../services/auth.service';
import { User } from '../../dtos/user';
import { Vendor } from '../../dtos/vendor';
import { ConfigurationEnum } from '../../dtos/configuration-enum';
import CustomStore from 'devextreme/data/custom_store';
import { AccountingSystemPurchaseOrder } from '../../dtos/accounting-system-purchase-order';
import { AccountingSystemJob } from '../../dtos/accounting-system-job';

@Component({
  selector: 'js-purchase-order-query',
  templateUrl: './purchase-order-query.component.html',
  styleUrls: ['./purchase-order-query.component.scss']
})
export class PurchaseOrderQueryComponent implements OnInit, OnChanges, OnDestroy {
  @Input() purchaseOrderQueryCounter: number;

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  purchaseOrders: AccountingSystemPurchaseOrder[];
  dataSource: CustomStore;
  loading = true;
  gridHeight: number;
  purchaseOrderAdmin: boolean;
  loadingAccounts: boolean;
  users: User[];
  accountingSystemType: number;
  vendors: Vendor[];
  jobs: AccountingSystemJob[];
  selectedJobId: string;
  selectedVendorId: number;
  externalVendorId: string;
  accountingSystemTenantId: string;
  accountingCompanies: AccountingSystemTenant[];
  lastModifiedDate: Date;
  maxPurchaseOrders: number = 1000;
  dropDownOptions: { width: number; minHeight: number; };
  autoConnectPopupVisible: boolean;

  constructor(
    private globalService: GlobalService,
    private notiService: NotificationService,
    public gridService: GridService,
    private authService: AuthService,
    private accountingSystemService: AccountingSystemService,
    private userService: UserService,
    private maintenanceService: MaintenanceService
  ) {
    this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
    this.dropDownOptions = { width: 300, minHeight: 400 };
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.gridHeight = window.innerHeight - 130;
    this.getData();
  }

  getData() {
    this.subscriptions.push(
      this.accountingSystemService.getDataForInvoiceQuery().subscribe({
        next: () => {
          this.users = this.userService.users;
          this.accountingCompanies = this.maintenanceService.accountingSystemTenantsList();
          this.accountingSystemType = this.globalService.getCompanyConfigValue(ConfigurationEnum.AccountingSystem);

          if (this.authService.isAdminOrSuper()
            || this.authService.areaPermissions.find(i => i.applicationArea === 'PurchaseOrders')?.permissionType === 'Admin') {
            this.purchaseOrderAdmin = true;
          }

          this.loading = false;
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }

  setUpDataSource() {
    this.dataSource = new CustomStore({
      key: 'orderID',
      loadMode: 'raw',
      load: () => this.purchaseOrders
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onToolbarPreparing(e, templateName: string) {
    const toolbarItems = e.toolbarOptions.items;
    toolbarItems.push({
      location: 'before',
      locateInMenu: 'auto',
      template: templateName
    });

    toolbarItems.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          type: 'outline',
          icon: 'refresh',
          onClick: this.refresh.bind(this)
        }
      });
  }

  refresh() {
    if (this.accountingSystemTenantId) {
      this.loading = true;
      this.subscriptions.push(
        this.accountingSystemService.getAccountingSystemPurchaseOrders(this.accountingSystemTenantId, this.selectedJobId, this.externalVendorId, this.lastModifiedDate, this.maxPurchaseOrders)
          .subscribe({
            next: (res: AccountingSystemPurchaseOrder[]) => {
              this.purchaseOrders = res;
              this.setUpDataSource();
              this.loading = false;
            }, error: (err) => {
              this.loading = false;
              this.notiService.notify(err);
            }
          }));
    }
    else {
      this.notiService.showInfo('No Company selected');
    }
  }

  async onCompanySelectionChanged() {
    this.vendors = null;
    if (this.accountingSystemTenantId) {
      this.subscriptions.push(
        this.maintenanceService.getVendorsForTenant(true, this.accountingSystemTenantId).subscribe({
          next: (res) => {
            this.vendors = res.filter(item => item.accountId !== null);
          }, error: (err) => {
            this.notiService.notify(err);
          }
        })
      );
      if (this.accountingSystemType === 3) {
        this.subscriptions.push(
          this.accountingSystemService.getJobsForTenant(true, this.accountingSystemTenantId).subscribe({
            next: (res) => {
              this.jobs = res;
            }, error: (err) => {
              this.notiService.notify(err);
            }
          })
        );
      }
    }
  }

  async onVendorSelectionChanged(e) {
    this.externalVendorId = null;
    if (e.value) {
      const selectedObject = this.vendors.find(item => item.id === e.value);
      if (selectedObject) {
        this.externalVendorId = selectedObject.accountId;
      }
    }
  }

  async onJobSelectionChanged(dropDownBox, event) {
    if (event.selectedRowKeys.length > 0) {
      this.selectedJobId = event.selectedRowsData[0].id;
      dropDownBox.close();
    }
    else {
      this.selectedJobId = null;
    }
  }

  async onJobValueChanged(event) {
    if (event.value === null) {
      this.selectedJobId = null;
    }
  }

  onLookupValueChanged(ea, e) {
    e.setValue(ea.value);
  }

  onDropDownChanged(cellInfo, e) {
    if (!e.value) {
      cellInfo.setValue(null);
    }
  }
}