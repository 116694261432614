import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { DOC_ORIENTATION, NgxImageCompressService } from 'ngx-image-compress';

export const DEFAULT_FILENAME = 'image.png';


@Injectable({
    providedIn: 'root',
})
export class FileProcessingService {

    constructor(
        // private imgMaxService: Ng2ImgMaxService,
        private imageCompress: NgxImageCompressService
    ) { }

    compressImage(fileToUpload: string, width: number = 600, height: number = 400): Observable<FormData> {
        const beforeSize = this.imageCompress.byteCount(fileToUpload);
        console.log('Size in bytes of the uploaded image was:', beforeSize);
        const resultImage = this.imageCompress
            .compressFile(fileToUpload, DOC_ORIENTATION.Default, 100, 100, width, height)
            .then(
                (compressedImage) => {
                    const aftersize = this.imageCompress.byteCount(compressedImage);
                    console.log('Size in bytes after compression is now:', aftersize);
                    const blobData = this.dataURItoBlob(aftersize < beforeSize ? compressedImage : fileToUpload);
                    return this.blobToFormData(blobData);
                }
            );

        return from(resultImage);
    }

    blobToFormData(blobData, fileName = DEFAULT_FILENAME) {
        const fd = new FormData();
        fd.append(blobData.name ? blobData.name : fileName, blobData, blobData.name ? blobData.name : fileName);
        return fd;
    }

    // blobToFile(theBlob: Blob, fileName: string = DEFAULT_FILENAME): File {
    //     const b: any = theBlob;
    //     b.lastModifiedDate = new Date();
    //     b.name = fileName;

    //     return <File>theBlob;
    // }

    dataURItoBlob(dataURI: string) {
        let byteString: any;
        if (dataURI.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(dataURI.split(',')[1]);
        } else {
            byteString = unescape(dataURI.split(',')[1]);
        }
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], { type: mimeString });
    }

    // imageDataURItoBlob(dataURI: any): Blob {
    //     const byteString = atob(dataURI);
    //     return this.stringToBlob(byteString);
    //     // const arrayBuffer = new ArrayBuffer(byteString.length);
    //     // const int8Array = new Uint8Array(arrayBuffer);
    //     // for (let i = 0; i < byteString.length; i++) {
    //     //     int8Array[i] = byteString.charCodeAt(i);
    //     // }
    //     // const blob = new Blob([arrayBuffer], { type: 'image/jpeg' });
    //     // return blob;
    // }

    // stringToBlob(byteString: any): Blob {
    //     const arrayBuffer = new ArrayBuffer(byteString.length);
    //     const int8Array = new Uint8Array(arrayBuffer);
    //     for (let i = 0; i < byteString.length; i++) {
    //         int8Array[i] = byteString.charCodeAt(i);
    //     }
    //     const blob = new Blob([arrayBuffer], { type: 'image/jpeg' });
    //     return blob;
    // }

    // base64ToArrayBuffer(base64): [ArrayBuffer | SharedArrayBuffer, Blob] {
    //     const binary_string = window.atob(base64);
    //     const len = binary_string.length;
    //     const bytes = new Uint8Array(len);
    //     for (let i = 0; i < len; i++) {
    //         bytes[i] = binary_string.charCodeAt(i);
    //     }
    //     const blob = new Blob([bytes], { type: 'application/vnd.ms-excel;charset=utf-8' });
    //     return [bytes.buffer, blob];
    // }

    // getBase64FromFile(file): Promise<string> {
    //     return new Promise((resolve, reject) => {
    //         const reader = new FileReader();
    //         reader.readAsDataURL(file);
    //         reader.onload = () => resolve(reader.result);
    //         reader.onerror = error => reject(error);
    //     });
    // }

}
