import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AccountingSystemCategory } from '../../../dtos/accounting-system-category';

@Component({
  selector: 'js-accounts-category',
  templateUrl: './accounts-category.component.html',
  styleUrls: ['./accounts-category.component.scss']
})
export class CategoriesCategoryComponent implements OnInit {

  @Input() accountingSystemCategories: AccountingSystemCategory[];
  @Input() oldCategoryID: string;

  @Output() newCategoryID: EventEmitter<string> = new EventEmitter<string>();

  dropDownOptions: { width: number; minHeight: number; };

  constructor() { }

  ngOnInit() {
    this.dropDownOptions = { width: 500, minHeight: 500 };
  }

  onSelectionChanged(e, event) {
    if (event.selectedRowKeys.length > 0) {
      this.newCategoryID.emit(event.selectedRowsData[0].trackingCategoryID);
      this.oldCategoryID = event.selectedRowsData[0].trackingCategoryID;
      e.component.close();
    }
  }
}
