<div class="modal-header">
  <h4 class="modal-title">Invoice Logs</h4>
  <button *ngIf="!loading" type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <dx-data-grid *ngIf="!loading" id="grid-container" [dataSource]="invoiceLogs" keyExpr="id" [showBorders]="true"
    [height]="popupHeight" [rowAlternationEnabled]="true" [wordWrapEnabled]="true" [allowColumnReordering]="false"
    [allowColumnResizing]="false" [columnAutoWidth]="false"
    (onExporting)="gridService.onExporting($event, 'invoice-logs')">

    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-search-panel [visible]="true" location="after" [width]="200"></dxo-search-panel>

    <dxi-column dataField="poNumber" caption="PO Number" width="110">
    </dxi-column>

    <dxi-column dataField="vendorId" caption="Vendor" dataType="number">
      <dxo-lookup [dataSource]="vendors" displayExpr="vendorName" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="invoiceNumber" width="110"> </dxi-column>

    <dxi-column dataField="invoiceDate" caption="Invoice Date" dataType="date" [width]="95" alignment="center"
      format="d-MMM-yy">
    </dxi-column>

    <dxi-column dataField="description" caption="Comment"> </dxi-column>

    <dxi-column dataField="totalExGST" caption="Invoice Ex GST" dataType="number" width="95" format="#,##0.00">
    </dxi-column>

    <dxi-column dataField="totalIncGST" caption="Invoice Inc GST" dataType="number" width="95" format="#,##0.00">
    </dxi-column>

    <dxi-column dataField="prevModifiedDate" caption="Modified" dataType="date" [width]="95" alignment="center"
      format="d-MMM-yy">
    </dxi-column>

    <dxi-column dataField="prevModifiedUserId" caption="Modified by" dataType="number" width="120"
      [allowEditing]="false">
      <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id"></dxo-lookup>
    </dxi-column>
  </dx-data-grid>
</div>

<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loading" [showIndicator]="true" [showPane]="true"
  [shading]="false" [hideOnOutsideClick]="false">
</dx-load-panel>