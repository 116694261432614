import { GridService } from './../../services/grid.service';
import { CompanyActivityService } from './../../services/felixApi/company-activity.service';
import { AuthService } from './../../services/auth.service';
import { JobService } from '../../services/felixApi/job.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { GlobalService } from '../../services/global.service';
import { ClaimsService } from '../../services/felixApi/claims.service';
import { NotificationService } from '../../services/notification.service';
import { Job } from '../../dtos/job';
import { IncomeInvoiceTypeEnum } from '../../dtos/income-invoice';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'js-all-income-invoices',
  templateUrl: './all-income-invoices.component.html',
  styleUrls: ['./all-income-invoices.component.scss']
})
export class AllIncomeInvoicesComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  dataSource: CustomStore;
  loading = true;
  jobs: Job[];
  gridHeight: number;
  submitFutureInvoices = false;
  currentJobId: number;
  isAdmin: boolean;
  deletePopupVisible = false;
  invoiceToDelete: number;
  claimDesc: string;

  constructor(
    private globalService: GlobalService,
    private authService: AuthService,
    private claimsService: ClaimsService,
    private notiService: NotificationService,
    private companyActivityService: CompanyActivityService,
    public gridService: GridService,
    private jobService: JobService) {
    this.calculateClientName = this.calculateClientName.bind(this);
    this.calculateClaimNumber = this.calculateClaimNumber.bind(this);
    this.calculateDescription = this.calculateDescription.bind(this);
    this.calculateClaimStage = this.calculateClaimStage.bind(this);
    this.calculateSiteAddress = this.calculateSiteAddress.bind(this);
    this.deleteRecord = this.deleteRecord.bind(this);
    this.checkIfPaid = this.checkIfPaid.bind(this);
  }

  ngOnInit(): void {
    if (this.authService.isAdminOrSuper()
      || this.authService.areaPermissions.find(i => i.applicationArea === 'IncomeInvoices')?.permissionType === 'Admin') {
      this.isAdmin = true;
    }


    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          this.setHeightWidths();
        }
      )
    );

    this.setHeightWidths();
    this.loadData(true);
  }

  setHeightWidths() {
    this.gridHeight = window.innerHeight - 107;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  loadData(useCache: boolean) {
    this.subscriptions.push(
      this.claimsService.getClaimsData(useCache)
        .subscribe({
          next: () => {
            this.jobs = this.jobService.jobs;
            this.loading = false;
            this.setUpDataSource();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  setUpDataSource() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.claimsService.getPostedIncomeInvoices()
              .subscribe({
                next: (res) => {
                  return resolve(res);
                }, error: (err) => {
                  return reject(this.globalService.returnError(err));
                }
              })
          ));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.claimsService.updateIncomeInvoices(encodeURIComponent(key), values).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      }
    });
  }

  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          type: 'outline',
          icon: 'refresh',
          onClick: this.refresh.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          type: 'default',
          stylingMode: 'outlined',
          text: 'Reset Layout',
          onClick: this.clearStatePersistance.bind(this)
        }
      });
  }

  calculateClientName(data) {
    return this.jobs.find(i => i.id === data.jobId)?.contractName;
  }

  calculateClaimNumber(data) {
    if (data.incomeInvoiceTypeId === IncomeInvoiceTypeEnum.Deposit) {
      return 'Deposit';
    }
    if (data.incomeInvoiceTypeId === IncomeInvoiceTypeEnum.Claim) {
      const claim = this.claimsService.allClaimJobLines.find(i => i.id === data.claimJobLineId);
      return 'Claim ' + claim?.orderNo;
    }
    if (data.incomeInvoiceTypeId === IncomeInvoiceTypeEnum.Variation) {
      const jobVariation = this.claimsService.jobVariationsInvoiced.find(i => i.id === data.jobVariationId);
      let result = 'Variation ' + jobVariation?.displayedVariationNumber;
      if (data.variationSplitId) {
        const variationSplit = this.claimsService.allVariationSplits.find(i => i.id === data.variationSplitId);
        result += ' Split ' + variationSplit.orderNo;
      }
      return result;
    }
    return '';
  }

  calculateDescription(data) {
    if (data.incomeInvoiceTypeId === IncomeInvoiceTypeEnum.Deposit) {
      return 'Deposit';
    }
    if (data.incomeInvoiceTypeId === IncomeInvoiceTypeEnum.Claim) {
      const claim = this.claimsService.allClaimJobLines.find(i => i.id === data.claimJobLineId);
      return claim?.description;
    }
    if (data.incomeInvoiceTypeId === IncomeInvoiceTypeEnum.Variation) {
      const jobVariation = this.claimsService.jobVariationsInvoiced.find(i => i.id === data.jobVariationId);
      return jobVariation?.title;
    }
    return '';
  }

  calculateClaimStage(data) {
    if (data.incomeInvoiceTypeId === IncomeInvoiceTypeEnum.Claim) {
      const claim = this.claimsService.allClaimJobLines.find(i => i.id === data.claimJobLineId);
      if (claim?.companyActivityId) {
        const activity = this.companyActivityService.activities.find(i => i.id === claim.companyActivityId);
        return activity?.description;
      }
    }
    if (data.incomeInvoiceTypeId === IncomeInvoiceTypeEnum.Variation) {
      if (data.variationSplitId) {
        const variationSplit = this.claimsService.allVariationSplits.find(i => i.id === data.variationSplitId);
        if (variationSplit?.companyActivityId) {
          const activity = this.companyActivityService.activities.find(i => i.id === variationSplit.companyActivityId);
          return activity?.description;
        }
      } else {
        const jobVariation = this.claimsService.jobVariationsInvoiced.find(i => i.id === data.jobVariationId);
        if (jobVariation?.companyActivityId) {
          const activity = this.companyActivityService.activities.find(i => i.id === jobVariation.companyActivityId);
          return activity?.description;
        }
      }
    }
    return '';
  }

  calculateRemaining(data) {
    return data.totalIncGST - (data.totalPaid ? data.totalPaid : 0)
  }

  refresh() {
    this.setUpDataSource();
  }

  clearStatePersistance() {
    this.loading = true;
    localStorage.removeItem('all-income-invoices');
    setTimeout(() => {
      this.loading = false;
    }, 300); // wait
  }

  deleteRecord(e) {
    this.invoiceToDelete = e.row.data.id;
    const jobNumber = this.jobs.find(i => i.id === e.row.data.jobId)?.jobNumber;
    this.claimDesc = 'Delete: ' + jobNumber + ': ' + this.calculateDescription(e.row.data);
    this.deletePopupVisible = true;
  }

  deleteClicked() {
    this.deletePopupVisible = false;
    this.loading = true;
    this.subscriptions.push(
      this.claimsService.deleteIncomeInvoice(this.invoiceToDelete.toString())
        .subscribe({
          next: () => {
            this.loading = false;
            this.setUpDataSource();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  calculateSiteAddress(data) {
    return this.jobs.find(i => i.id === data.jobId)?.jobAddressString;
  }

  checkIfPaid(e) {
    console.log(e);
    if (!e.row.data.fullyPaidOnDate) {
      this.subscriptions.push(
        this.claimsService.checkIfIncomeInvoicePaid(e.row.data.id)
          .subscribe({
            next: () => {
              this.setUpDataSource();
            },
            error: (err) => {
              this.notiService.notify(err);
            }
          })
      );
    }
  }
}
