import { Injectable, OnDestroy } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of, forkJoin, Subscription } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';

import { GlobalService } from './global.service';
import { AuthService } from './auth.service';
import { TruthEngineSession } from '../dtos/session';
import { NotificationService } from './notification.service';
import { UserService } from './felixApi/user.service';
import { AuthApiService } from './felixApi/auth-api.service';
import { LogService } from './log.service';
import { environment } from '../../environments/environment';

@Injectable()
export class RefreshGuard implements CanActivate, OnDestroy {

  subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private authService: AuthService,
    private authApi: AuthApiService,
    private globalService: GlobalService,
    private notiService: NotificationService,
    private userService: UserService,
    private logger: LogService) { }

  canActivate() {
    this.authService.currentVersion = environment.fullVersion;
    this.checkCorrectVersion();

    if (this.authService.getUserEmail()) {
      return true;
    } else {
      const session = this.globalService.getSessionObject();
      const email = this.authService.getMsalUserEmail();
      if (session.currentCompanyId && email) {
        return this.restoreFromCache(session, email);
      } else {
        this.router.navigate(['']);
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  checkCorrectVersion() {
    this.subscriptions.push(
      this.authApi.getCurrentApplicationVersion().subscribe(appVersion => {
        if (!appVersion || appVersion.version.trim() !== environment.majorVersion) {
          this.authService.versionIncorrect = true;
          this.authService.correctVersion = appVersion.version.trim();
        } else {
          this.authService.versionIncorrect = false;
        }
      },
        err => {
          this.logger.log('refresh-guard', '', err, 'couldn\'t get app version', true);
        })
    );
  }

  restoreFromCache(session: TruthEngineSession, email: string): Observable<boolean> {
    if (!email) {
      this.authService.signOut();
    } else {
      this.authService.setUserEmail(email);
      return this.authApi.getAuthorisedCompanies()
        .pipe(mergeMap(
          comps => {
            const company = comps.filter(x => x.id === session.currentCompanyId);
            if (company.length !== 1) {
              return of(this.error());
            }
            return forkJoin(
              [this.userService.getUserCompany(company[0].userId, company[0].id)]
            )
              .pipe(map(
                ([user]) => {
                  this.authService.setCurrentUser(user);
                  this.globalService.setCurrentCompany(company[0]);
                  this.authService.signIn();
                  console.log('restoring');
                  const costCentreId = localStorage.getItem('jobANNX-costcentreId');
                  if (costCentreId && costCentreId !== '') {
                    this.globalService.selectedCostCentreId = +costCentreId;
                    localStorage.setItem('jobANNX-costcentreId', '');
                    this.globalService.setAreaSelected('orders');
                  } else {
                    this.globalService.setAreaSelected(session.areaSelected);
                  }
                  return true;
                }, () => {
                  return this.error();
                }
              ));
          }
        ));
    }
  }

  error() {
    this.router.navigate(['']);
    this.notiService.showError('There was an error fetching data from the session so you will have to login again');
    return false;
  }
}
