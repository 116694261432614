<div class="page">

  <dx-data-grid *ngIf="!loading" id="dataGrid" [height]="gridHeight" [dataSource]="dataSource"
    [allowColumnReordering]="true" [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true"
    [rowAlternationEnabled]="true" [wordWrapEnabled]="true" (onExporting)="gridService.onExporting($event, 'receipts')">

    <dxo-search-panel [visible]="true" location="after" [width]="200"></dxo-search-panel>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping #expand [autoExpandAll]="true" [contextMenuEnabled]="false"></dxo-grouping>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-column-chooser [enabled]="true" mode="dragAndDrop"> </dxo-column-chooser>

    <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"
      refreshMode="repaint">
    </dxo-editing>

    <dxi-column dataField="jobId" caption="Job" dataType="number" width="110">
      <dxo-lookup [dataSource]="jobs" displayExpr="jobNumber" valueExpr="id"></dxo-lookup>
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="receiptDate" caption="Date" dataType="date" width="130" alignment="center">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="clientName" caption="Client" dataType="string" [calculateCellValue]='calculateClientName'
      [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="siteAddress" dataType="string" [calculateCellValue]='calculateSiteAddress'
      [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="receiptNumber" dataType="string" width="150"> </dxi-column>

    <dxi-column dataField="amount" dataType="number" width="130" format="#,##0.00">
    </dxi-column>

    <dxi-column dataField="modifiedUserId" caption="Modified By" dataType="number" [allowEditing]="false" width="200">
      <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="modifiedDate" dataType="date" width="120" format="d/M/yy" alignment="center"
      [allowEditing]="false">
    </dxi-column>

    <dxo-summary>
      <dxi-total-item column="jobId" summaryType="count" displayFormat="{0} rows">
      </dxi-total-item>
      <dxi-total-item column="amount" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}">
      </dxi-total-item>
    </dxo-summary>

  </dx-data-grid>


  <!-- loading spinner - DevExtreme -->
  <dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [visible]="loading" [showIndicator]="true" [showPane]="true"
    [shading]="false" [hideOnOutsideClick]="false">
  </dx-load-panel>

</div>
