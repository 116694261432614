import { UserService } from './../services/felixApi/user.service';
import { VariationService } from './../services/felixApi/variation.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { Subscription } from 'rxjs';
import { Phase } from '../dtos/phase';
import { PriceFileItem } from '../dtos/price-file-item';
import { PurchaseOrder } from '../dtos/purchase-order';
import { Vendor } from '../dtos/vendor';
import { EstimatingService } from '../services/felixApi/estimating.service';
import { JobService } from '../services/felixApi/job.service';
import { MaintenanceService } from '../services/felixApi/maintenance.service';
import { PoService } from '../services/felixApi/po.service';
import { GlobalService } from '../services/global.service';
import { NotificationService } from '../services/notification.service';
import { CallUpDocsType } from '../dtos/call-up-docs-type';
import { JobDocument } from '../dtos/job-document';
import { AuthService } from '../services/auth.service';
import { formatDate } from 'devextreme/localization';
import { Variation } from '../dtos/variation';
import { User } from '../dtos/user';
import { JobDocumentRecordTypeEnum } from '../dtos/job-document-record-type.enum';
import { CompanyService } from '../services/felixApi/company.service';
import { RoleTypeEnum } from '../dtos/role-type.enum';
import { GridService } from '../services/grid.service';

@Component({
  selector: 'js-order-send',
  templateUrl: './order-send.component.html',
  styleUrls: ['./order-send.component.scss']
})
export class OrderSendComponent implements OnInit, OnDestroy {

  @ViewChild('poGrid') grid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  loadingData = true;
  jobNumber: string;
  jobId: number;
  phases: Phase[] = [{ id: 0, orderNo: 0, phaseCode: '', description: 'Default' }];
  vendors: Vendor[];
  loading = false;
  costCentres: PriceFileItem[];
  purchaseOrders: PurchaseOrder[];
  selectedOrders: number[] = [];
  gridHeight: number;
  unSentOnly = true;
  shownPurchaseOrders: PurchaseOrder[];
  purchaseOrderData: CustomStore;
  ccToSelf = true;
  sendHistoryOfChanges = false;
  boldAddedExtras = true;
  ccToSiteManager = false;
  showPrices = true;
  emailAddresses: string[];
  missingAttachmentsPopupVisible: boolean;
  missingAttachments: CallUpDocsType[];
  anyMissingAttachments: boolean;
  currentOrderId: number;
  currentCostCentreId: number;
  attachmentsPopupVisible = false;
  loadingDocs: boolean;
  jobDocuments: JobDocument[];
  selectedJobDocs: string[] = [];
  selectedDocTemp: string[] = [];
  popupHeight: number;
  popupWidth: number;
  currentOrder: any;
  emailSubject: string;
  emailMessage = 'Please see order attached.';
  addendumLetter: string;
  sendWithoutAttachments: boolean;
  variationParamsPopupVisible = false;
  variationId = null;
  variations: Variation[] = [];
  printImages = true;
  printVariationPrices = false;
  printNotApplicable = true;
  printNonPrintItems = false;
  printConnectedTogether = true;
  printVONumber = true;
  users: User[];
  downloadSeparatelyPopupVisible: boolean;
  callUpTemplateDocs: string[] = [];
  siteManagerRoleName: string;

  constructor(
    private globalService: GlobalService,
    private companyService: CompanyService,
    private maintenanceService: MaintenanceService,
    private authService: AuthService,
    private jobService: JobService,
    private notiService: NotificationService,
    private estimatingService: EstimatingService,
    private poService: PoService,
    private variationService: VariationService,
    public gridService: GridService,
    private userService: UserService) {
    this.cancelAttachedDocs = this.cancelAttachedDocs.bind(this);
    this.saveAttachedDocs = this.saveAttachedDocs.bind(this);
    this.calculateHasAttachments = this.calculateHasAttachments.bind(this);
    this.attachDocs = this.attachDocs.bind(this);
    this.calculateCallUpDocsType = this.calculateCallUpDocsType.bind(this);
    this.missingAttachmentsPopup = this.missingAttachmentsPopup.bind(this);
    this.calculateCostCentreSortValue = this.calculateCostCentreSortValue.bind(this);
    this.calculateVendorSortValue = this.calculateVendorSortValue.bind(this);
    this.calculateSentFromQueueStatus = this.calculateSentFromQueueStatus.bind(this);
  }

  ngOnInit() {
    this.jobService.jobDocuments = []; // reset
    this.addendumLetter = this.globalService.getAddendumLetter();

    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          this.setHeightWidths();
        }
      )
    );

    this.setHeightWidths();
    this.loadData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setHeightWidths() {
    this.gridHeight = window.innerHeight - 335;
    this.popupHeight = window.innerHeight < 575 ? window.innerHeight : 575;
    this.popupWidth = window.innerWidth < 600 ? window.innerWidth : 600;
  }

  loadData() {
    this.subscriptions.push(
      this.poService.getOrdersData()
        .subscribe({
          next: () => {
            if (!this.maintenanceService.orderControl) {
              this.notiService.showError('Control record not set up. See Maintenance.');
            } else {
              this.vendors = this.maintenanceService.allVendors;
              this.phases = this.phases.concat(this.maintenanceService.phases);
              this.costCentres = this.estimatingService.costCentres;
              this.users = this.userService.users;

              this.emailMessage = this.maintenanceService.orderControl?.defaultEmailMessage;
              this.emailMessage = this.emailMessage.replace('[MyEmailAddress]', this.authService.getCurrentUser().email);
              this.siteManagerRoleName = this.companyService.companyRoles.find(i => i.roleId === RoleTypeEnum.SiteManager)?.companyRoleDescription;

              // if we have set up the global BCC then we default to NO to cc to yourself
              if (this.maintenanceService.orderControl?.ccEmailAddress
                && this.maintenanceService.orderControl?.ccEmailAddress.trim() !== '') {
                this.ccToSelf = false;
              }
            }
            this.loadingData = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loadingData = false;
          }
        })
    );
  }

  onJobNumberChanged(jobNo: string) {
    if (this.jobNumber !== jobNo && this.jobService.currentJob) {
      this.jobNumber = jobNo;
      this.jobId = this.jobService.currentJob.id;
      this.emailSubject = 'Job# ' + this.jobService.currentJob.jobNumber
        + ' - ' + this.globalService.getJobString(this.jobService.currentJob)
        + ' - ORDER For: [Cost Centre]';
      this.getData();
      this.getVariations();
    } else {
      this.jobNumber = jobNo;
      this.purchaseOrderData = null;
      this.variations = [];
    }
  }

  getData() {
    this.loading = true;
    this.subscriptions.push(
      this.poService.getPurchaseOrderData(this.jobId)
        .subscribe({
          next: (purchaseOrders) => {
            this.purchaseOrders = purchaseOrders;

            this.purchaseOrders.forEach(po => {
              po.costCentreDescription = this.costCentres.find(i => i.id === po.costCentreId)?.description;
              const vendor = this.vendors.find(i => i.id === po.vendorId);
              po.vendorEmail = !vendor?.ordersEmail || vendor.ordersEmail === '' ? vendor?.email : vendor.ordersEmail;

              // check we have the auto-attachments we expect
              po.missingAttachments = [];
              const costCentre = this.estimatingService.priceFileItemGroups.find(i => i.id === po.costCentreId);
              if (costCentre?.callUpDocsTypes?.length) {
                // do we have them all
                costCentre?.callUpDocsTypes.forEach(callUpDocsType => {
                  const foundJobDoc = this.jobService.jobDocuments.find(i => i.callUpDocsTypeId === callUpDocsType);
                  if (!foundJobDoc) {
                    po.missingAttachments.push(callUpDocsType);
                  }
                });
              }
            });

            this.filterPOs();
            this.loading = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  filterPOs() {
    if (this.unSentOnly) {
      this.shownPurchaseOrders = this.purchaseOrders?.filter(i => !i.printedDate);
    } else {
      this.shownPurchaseOrders = this.purchaseOrders;
    }

    this.purchaseOrderData = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: () => this.shownPurchaseOrders,
      update: async (key, values) => {
        const changedPO = this.shownPurchaseOrders.find(i => i.id === key);
        changedPO.vendorEmail = values.vendorEmail;
        return changedPO;
      }
    });
  }

  showUnSentOnlyChanged(e) {
    this.unSentOnly = e.value;
    this.filterPOs();
    this.grid.instance.refresh();
  }

  onToolbarPreparing(e, templateName: string) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.push(
      {
        location: 'before',
        locateInMenu: 'auto',
        template: templateName
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          type: 'outline',
          icon: 'refresh',
          onClick: this.refresh.bind(this)
        }
      }
    );
  }

  onDocTreePreparinng(e) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.push(
      {
        location: 'before',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          type: 'outline',
          icon: 'refresh',
          onClick: this.refreshJobDocuments.bind(this)
        }
      }
    );
  }

  refresh() {
    this.getData();
  }

  refreshJobDocuments() {
    this.getJobDocuments(false);
  }

  onMultiRowClick(e) {
    let keys = e.component.getSelectedRowKeys();
    const index = keys.indexOf(e.key);

    if (index > -1) {
      keys.splice(index, 1);
    } else {
      keys = keys.concat(e.key);
    }

    e.component.selectRows(keys);
  }

  sendOrders() {
    this.anyMissingAttachments = false;

    this.purchaseOrders.forEach(po => {
      const isSelected = this.selectedOrders.find(i => i === po.id);

      if (isSelected) {
        if (po.missingAttachments?.length) {
          this.anyMissingAttachments = true;
        }
      }
    });

    if (!this.anyMissingAttachments) {
      this.sendOrdersGo(false, false, false);
    } else {
      this.notiService.showWarning('Selected call-ups are missing attachments. Click the error symbol for more information.');
    }
  }

  sendOrdersWithoutAttachments() {
    this.sendWithoutAttachments = true;
  }

  downloadOrders() {
    if (this.selectedOrders && this.selectedOrders.length) {
      if (this.selectedOrders.length === 1) {
        this.sendOrdersGo(true, false, false);
      } else {
        this.downloadSeparatelyPopupVisible = true;
      }
    }
  }

  downloadSeparateSepareteFiles() {
    const selectedOrders = this.selectedOrders;
    this.selectedOrders = [];
    let counter = 1;

    selectedOrders.forEach(selectedOrder => {
      setTimeout(() => {
        this.selectedOrders = [];
        this.selectedOrders.push(selectedOrder);
        this.sendOrdersGo(true, false, true);
      }, 500 * counter); // wait for each
    });
  }

  sendOrdersGo(download: boolean, doNotSendAttachments: boolean, saveFile: boolean) {
    this.sendWithoutAttachments = false;
    this.downloadSeparatelyPopupVisible = false;
    const dataRecord = {
      purchaseOrderIds: this.selectedOrders,
      emailAddresses: [],
      ccToSelf: this.ccToSelf,
      download: download,
      emailSubject: this.emailSubject,
      emailMessage: this.emailMessage,
      doNotSendAttachments: doNotSendAttachments,
      sendHistoryOfChanges: this.sendHistoryOfChanges,
      boldAddedExtras: this.boldAddedExtras,
      ccToSiteManager: this.ccToSiteManager,
      jobVariationId: this.variationId,
      printImages: this.printImages,
      printVariationPrices: this.printVariationPrices,
      printNotApplicable: this.printNotApplicable,
      printNonPrintItems: this.printNonPrintItems,
      printConnectedTogether: this.printConnectedTogether,
      printVONumber: this.printVONumber,
      printPrices: this.showPrices
    };

    this.emailAddresses = [];
    let count = 0;
    let poNumber = '';
    let isVendorValid = true;
    const dateNow = new Date();
    const dateNowString = formatDate(dateNow, 'yyyy-MM-dd');

    this.selectedOrders.forEach(selectedOrder => {
      const purchaseOrder = this.purchaseOrders.find(i => i.id === selectedOrder);

      if (purchaseOrder) {
        this.emailAddresses[count] = purchaseOrder.vendorEmail;
        poNumber = purchaseOrder.poNumber;

        const vendor = this.vendors.find(i => i.id === purchaseOrder.vendorId);

        if (vendor?.incomeProtectionExpiry && vendor.incomeProtectionExpiry <= dateNowString) {
          // isVendorValid = false;
          this.notiService.showWarning('PO ' + poNumber + ': Vendor ' + vendor.vendorName + ' has expired Income Protection Insurance.');
        }
        if (vendor?.publicLiabilityExpiry && vendor.publicLiabilityExpiry <= dateNowString) {
          // isVendorValid = false;
          this.notiService.showWarning('PO ' + poNumber + ': Vendor ' + vendor.vendorName + ' has expired Public Liability.');
        }
        if (vendor?.workCoverExpiry && vendor.workCoverExpiry <= dateNowString) {
          // isVendorValid = false;
          this.notiService.showWarning('PO ' + poNumber + ': Vendor ' + vendor.vendorName + ' has expired WorkCover.');
        }
      } else {
        this.notiService.showError('Cannot find purchase order for id= ' + selectedOrder + ' - Skipping it.');
      }
      count++;
    });

    if (isVendorValid) {
      this.loading = true;
      dataRecord.emailAddresses = this.emailAddresses;

      this.subscriptions.push(
        this.poService.sendPurchaseOrders(this.jobId, dataRecord)
          .subscribe({
            next: (orderResponse) => {
              if (download) {
                // download orders
                this.poService.convertAndSave(orderResponse.pdfOrders, this.jobNumber, count === 1 ? poNumber : '', saveFile);
              } else {
                this.selectedOrders = [];
              }
              if (orderResponse.errorMessages) {
                this.notiService.showError('Sent ' + orderResponse.successCount + ' orders');
                this.notiService.showError(orderResponse.errorMessages);
              }
              this.anyMissingAttachments = false;
              this.variationId = null;

              if (!download) {
                this.getData();
              } else {
                this.loading = false;
              }
            },
            error: (err) => {
              this.notiService.notify(err);
              this.selectedOrders = [];
              this.anyMissingAttachments = false;
              this.variationId = null;
              this.getData();
            }
          })
      );
    }
  }

  markOrdersSent() {
    this.loading = true;
    const dataRecord = {
      purchaseOrderIds: this.selectedOrders,
      emailAddresses: [],
      ccToSelf: this.ccToSelf,
      download: false,
      emailSubject: this.emailSubject,
      emailMessage: this.emailMessage,
      doNotSendAttachments: false,
      sendHistoryOfChanges: this.sendHistoryOfChanges,
      boldAddedExtras: this.boldAddedExtras,
      ccToSiteManager: false
    };

    this.subscriptions.push(
      this.poService.markPurchaseOrdersSent(this.jobId, dataRecord)
        .subscribe({
          next: () => {
            this.selectedOrders = [];
            this.variationId = null;
            this.anyMissingAttachments = false;
            this.getData();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  calculateHasAttachments(data) {
    let result = [];

    if (data.missingAttachments?.length) {
      result = data.missingAttachments;
    } else {
      const costCentre = this.costCentres.find(i => i.id === data.costCentreId);
      if (costCentre?.includeAddendum) {
        result = ['A'];
      }

      const foundOrderDocs = this.poService.purchaseOrderDocuments.find(i => i.purchaseOrderId === data.id);
      if (foundOrderDocs) {
        result.push('D');
      }
    }
    return result;
  }

  missingAttachmentsPopup(missingAttachments: number[]) {
    this.missingAttachmentsPopupVisible = true;
    this.missingAttachments = [];

    missingAttachments.forEach(missingAttachment => {
      this.missingAttachments.push(
        {
          id: missingAttachment,
          description: this.maintenanceService.callUpDocsTypes.find(i => i.id === missingAttachment).description
        }
      );
    });
  }

  attachDocs(e) {
    // attach docs from the Info section of Truth Engine
    this.currentOrderId = e.row.data.id;
    this.currentCostCentreId = e.row.data.costCentreId;
    this.attachmentsPopupVisible = true;
    this.selectedJobDocs = [];
    this.getJobDocuments(true);
  }

  getJobDocuments(useCache: boolean) {
    this.selectedJobDocs = [];
    this.callUpTemplateDocs = [];
    this.loadingDocs = true;
    this.subscriptions.push(
      this.jobService.getJobDocuments(useCache, this.jobId)
        .subscribe({
          next: (res) => {
            this.jobDocuments = res;

            // add checked
            this.addCheckedToDocs();
            this.loadingDocs = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.jobDocuments = [];
            this.loadingDocs = false;
          }
        })
    );
  }

  addCheckedToDocs() {
    // add checked
    this.jobDocuments.forEach(jobDoc => {
      const foundRecord = this.poService.purchaseOrderDocuments
        .find(i => i.jobDocumentId && i.jobDocumentId === jobDoc.id && i.purchaseOrderId === this.currentOrderId);
      if (foundRecord) {
        this.selectedJobDocs.push(jobDoc.id.toString());
      } else {
        const foundSharePointRecord = this.poService.purchaseOrderDocuments
          .find(i => !i.jobDocumentId && i.sharePointId === jobDoc.sharePointId && i.purchaseOrderId === this.currentOrderId);
        if (foundSharePointRecord) {
          this.selectedJobDocs.push(jobDoc.sharePointId);
        } else if (jobDoc.recordTypeId === JobDocumentRecordTypeEnum.Detail) {
          // maybe auto attached
          if (this.calculateCallUpDocsType(jobDoc)) {
            const callUpDocId = jobDoc.sharePointId === null || jobDoc.sharePointId === '' ? jobDoc.id.toString() : jobDoc.sharePointId;
            this.selectedJobDocs.push(callUpDocId);
            this.callUpTemplateDocs.push(callUpDocId);
          }
        }
      }
    });
  }

  cancelAttachedDocs() {
    this.attachmentsPopupVisible = false;
  }

  saveAttachedDocs() {
    this.loadingDocs = true;
    let selectedDocs: string[] = [];

    this.selectedJobDocs.forEach(selectedJobDoc => {
      // exclude if a callUpTemplateDoc
      if (!this.callUpTemplateDocs.includes(selectedJobDoc)) {
        selectedDocs.push(selectedJobDoc);
      }
    });

    this.subscriptions.push(
      this.poService.addPODocuments(this.currentOrderId, { keyIds: selectedDocs })
        .subscribe({
          next: () => {
            // get the PurchaseOrderDocs again after a change
            this.getPurchaseOrderDocs();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loadingDocs = false;
          }
        })
    );
  }

  getPurchaseOrderDocs() {
    this.poService.purchaseOrderDocuments = [];
    this.subscriptions.push(
      this.poService.getPODocuments(this.jobId)
        .subscribe({
          next: () => {
            this.attachmentsPopupVisible = false;

            // add checked
            this.addCheckedToDocs();
            this.loadingDocs = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loadingDocs = false;
          }
        })
    );
  }

  attachmentSelectionChanged(e): void {
    // get all leaves as new list
    this.selectedDocTemp = e.component.getSelectedRowKeys('all').filter(this.globalService.onlyUnique);
    this.selectedJobDocs = [];

    this.selectedDocTemp.forEach(idString => {
      // only add leaves
      const foundRec = this.jobDocuments.find(i => i.parentKeyId == idString);
      if (!foundRec) {
        this.selectedJobDocs.push(idString);
      }
    });
  }

  calculateCallUpDocsType(data) {
    let result = false;

    if (data.callUpDocsTypeId) {
      const costCentre = this.costCentres.find(i => i.id === this.currentCostCentreId);
      const founcDoc = costCentre?.callUpDocsTypes?.find(i => i === data.callUpDocsTypeId);
      if (founcDoc) {
        result = true;
      }
    }
    return result;
  }

  calculateCostCentreSortValue(data) {
    return this.costCentres.find(i => i.id === data.costCentreId)?.orderNumber;
  }

  calculateVendorSortValue(data) {
    return this.vendors.find(i => i.id === data.vendorId)?.vendorName;
  }

  calculateSentFromQueueStatus(data: PurchaseOrder): string {
    if (data.sentFromQueueDate) {
      return 'Sent';
    } else if (data.printedDate) {
      return 'Queued';
    }
    return '';
  }

  getVariations() {
    this.variations = [];
    this.subscriptions.push(
      this.variationService.getVariations(this.jobNumber).subscribe({
        next: (variations) => {
          this.variations = variations;
        },
        error: (err) => {
          this.notiService.notify(err);
        }
      })
    );
  }

  getVariationParams(e) {
    if (this.variationId !== e.value) {
      this.variationId = e.value;
      if (this.variationId) {
        this.variationParamsPopupVisible = true;
      }
    }
  }

  onRowPrepared(e) {
    if (e.rowType === 'data') {
      if (e.data.isCancelled) {
          e.rowElement.style.textDecoration = 'line-through';
      }
    }
  }
}
