<div class="modal-header">
  <h4 class="modal-title">Other related invoices</h4>
  <button *ngIf="!loading" type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <dx-data-grid *ngIf="!loading" id="grid-container" [dataSource]="invoices" keyExpr="id" [showBorders]="true"
    [height]="popupHeight - 90" [rowAlternationEnabled]="true" [wordWrapEnabled]="true" [allowColumnReordering]="false"
    [allowColumnResizing]="false" [columnAutoWidth]="false" (onToolbarPreparing)="onToolbarPreparing($event)"
    (onExporting)="gridService.onExporting($event, 'previous-invoices')">

    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-grouping #expand [autoExpandAll]="false" [contextMenuEnabled]="false" expandMode="rowClick"></dxo-grouping>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-search-panel [visible]="true" location="after" [width]="200"></dxo-search-panel>

    <dxi-column dataField="poNumber" caption="PO Number" width="110" sortOrder="asc" [groupIndex]="0">
    </dxi-column>

    <dxi-column dataField="vendorId" caption="Vendor" dataType="number" sortOrder="asc">
      <dxo-lookup [dataSource]="vendors" displayExpr="vendorName" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="invoiceNumber" width="110" sortOrder="asc">
    </dxi-column>

    <dxi-column dataField="invoiceDate" caption="Date" dataType="date" [width]="95" alignment="center" format="d-MMM-yy"
      sortOrder="asc">
    </dxi-column>

    <dxi-column dataField="description">
    </dxi-column>

    <dxi-column dataField="totalExGST" caption="Invoice Ex GST" dataType="number" width="95" format="#,##0.00">
    </dxi-column>

    <dxi-column dataField="totalIncGST" caption="Invoice Inc GST" dataType="number" width="95" format="#,##0.00">
    </dxi-column>

    <dxi-column type="buttons" [width]="80">
      <dxi-button icon="doc" [onClick]="downloadAttachment" hint='Download Invoice'></dxi-button>
      <dxi-button icon="download" [onClick]="openPO" hint='Download PO'></dxi-button>
    </dxi-column>

    <dxo-summary>
      <dxi-group-item column="totalExGST" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}"
        [showInGroupFooter]="true">
      </dxi-group-item>
      <dxi-group-item column="totalIncGST" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}"
        [showInGroupFooter]="true">
      </dxi-group-item>
    </dxo-summary>
  </dx-data-grid>
</div>

<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loadingFile" [showIndicator]="true"
  [showPane]="true" [shading]="false" [hideOnOutsideClick]="false">
</dx-load-panel>
