import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApplicationVersion } from '../../../app/dtos/applicationVersion';
import { AreaPermission } from '../../../app/dtos/areaPermission';
import { Company } from '../../../app/dtos/company';
import { environment } from '../../../environments/environment';
import { AuthService } from '../auth.service';
import { HttpService } from '../http.service';

@Injectable({
  providedIn: 'root'
})
export class AuthApiService {

  appBaseURL = environment.apiUrl + '/applications/';

  constructor(
    private auth: AuthService,
    private http: HttpClient,
    private httpService: HttpService) { }

  getCurrentApplicationVersion(): Observable<ApplicationVersion> {
    const url = environment.apiUrl + '/applications/' + environment.appId + '/latest-version';
    return this.http.get<ApplicationVersion>(url, this.httpService.getHttpOptions());
  }

  getAreaPermissions(): Observable<AreaPermission[]> {
    const url = environment.apiUrl + '/applications/' + environment.appId
        + '/area-permissions/me';
    return this.http.get<AreaPermission[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
            this.auth.areaPermissions = res;
        }));
  }

  getAuthorisedCompanies() {
    if (this.auth.getUserEmail()) {
      const url = environment.apiUrl + '/email-addresses/' + this.auth.getUserEmail().replace('@', '%40')
        + '/authorised-companies';
      return this.http.get<Company[]>(url, this.httpService.getHttpOptions());
    } else {
        of([]);
    }
  }
}
