<div class="modal-header">
  <h2 class="modal-title">Tools Menu</h2>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <button class="btn btn-sm btn-secondary" (click)="globalChangeOrderVendor()">
    ALL Jobs: Change orders to new vendor
  </button>
  <br>
  <button class="btn btn-sm btn-secondary mt-3" (click)="globalRevalueOrdersForVendor()">
    ALL Jobs: Revalue orders for a vendor
  </button>
  <br>
  <button class="btn btn-sm btn-secondary mt-3" (click)="globalAddItem()">
    ALL Jobs: Add item to a purchase order
  </button>
  <br>
  <button class="btn btn-sm btn-secondary mt-3" (click)="globalItemSearch()">
    ALL Jobs: Find an item
  </button>
</div>
