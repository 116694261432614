import { DivisionService } from './../../services/felixApi/division.service';
import { MaintenanceService } from './../../services/felixApi/maintenance.service';
import { NotificationService } from './../../services/notification.service';
import { AuthService } from './../../services/auth.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { GridService } from '../../services/grid.service';
import { Subscription } from 'rxjs';
import CustomStore from 'devextreme/data/custom_store';
import { CompanyActivityService } from '../../services/felixApi/company-activity.service';
import { AccountingSystemTenant } from '../../dtos/accounting-system-tenant';
import { AccountingSystemAccount } from '../../dtos/accounting-system-account';
import { Division } from '../../dtos/division';

@Component({
  selector: 'js-company-activities',
  templateUrl: './company-activities.component.html',
  styleUrls: ['./company-activities.component.scss']
})
export class CompanyActivitiesComponent implements OnInit, OnDestroy {

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  dataSource: CustomStore;
  loading = true;
  activityTypes = [
    { id: 1, description: 'Slab' },
    { id: 2, description: 'Practical Completion' }
  ];
  gridHeight: number;
  invoiceAdmin: boolean;
  accountingSystemTenants: AccountingSystemTenant[];
  accountsLoaded: boolean;
  loadingAccounts: boolean;
  accountingSystemAccounts: AccountingSystemAccount[];
  divisionId: number;
  divisions: Division[];
  canEdit: boolean;

  constructor(
    private companyActivityService: CompanyActivityService,
    private authService: AuthService,
    private globalService: GlobalService,
    public gridService: GridService,
    private notiService: NotificationService,
    private maintenanceService: MaintenanceService,
    private divisionService: DivisionService
  ) { }

  ngOnInit(): void {
    this.gridHeight = window.innerHeight - 175;
    this.getCompanyConfigurations();
    this.connectToAccountingSystemTenants();
  }

  getCompanyConfigurations() {
    this.subscriptions.push(
      this.maintenanceService.getDivisionAccountsData().subscribe({
        next: () => {
          if (this.authService.isAdminOrSuper()
            || this.authService.areaPermissions.find(i => i.applicationArea === 'Invoices')?.permissionType === 'Admin') {
            this.invoiceAdmin = true;
          }

          this.divisions = this.divisionService.divisions;
          this.loading = false;
          this.setUpDataSource();
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }

  refresh() {
    this.companyActivityService.activities = null;
    this.grid.instance.refresh();
  }

  setUpDataSource() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.companyActivityService.getCompanyActivitiesWithDivisionAccounts(this.divisionId).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.companyActivityService.updateDivisionActivityAccount(this.divisionId, encodeURIComponent(key), values).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      }
    });
  }

  ngOnDestroy() {
    this.companyActivityService.activities = null;
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onToolbarPreparing(e, templateName: string) {
    const toolbarItems = e.toolbarOptions.items;

    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          type: 'default',
          stylingMode: 'outlined',
          icon: 'refresh',
          onClick: this.refresh.bind(this)
        }
      });

    if (this.accountsLoaded) {
      toolbarItems.push({
        location: 'before',
        locateInMenu: 'auto',
        template: templateName
      });
    }
  }

  connectToAccountingSystemTenants() {
    // get the accounts from Accounting System to do the connection
    this.loading = true;

    this.subscriptions.push(
      this.maintenanceService.getAccountingSystemTenants()
        .subscribe({
          next: () => {
            this.accountingSystemTenants = this.maintenanceService.accountingSystemTenantsList();
            this.accountsLoaded = true;
            setTimeout(() => {
              this.loading = false;
            }, 250); // wait so grid loading will work in case we have cached data
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  connectToAccountingSystemAccounts(tenantId: string) {
    // get the accounts from Accounting System to do the connection
    if (this.accountsLoaded && tenantId && tenantId.trim() !== '') {
      this.loadingAccounts = true;
      this.subscriptions.push(
        this.maintenanceService.getAccountingSystemAccounts(tenantId)
          .subscribe({
            next: (accountingSystemAccounts) => {
              this.accountingSystemAccounts = accountingSystemAccounts;
              this.loadingAccounts = false;
            },
            error: (err) => {
              this.notiService.notify(err);
              this.loadingAccounts = false;
            }
          })
      );
    }
  }

  onSelectionChanged(event) {
    this.accountingSystemAccounts = [];
    this.divisionId = event.value;

    if (this.accountsLoaded) {
      const division = this.divisionService.divisions.find(i => i.id === this.divisionId);
      if (division?.accountingSystemTenantId && division.accountingSystemTenantId.trim() !== '') {
        this.connectToAccountingSystemAccounts(division.accountingSystemTenantId);
        this.canEdit = true;
        this.setUpDataSource();
      } else {
        this.notiService.showInfo('Division not linked to an accounting company. See Divisions');
        this.canEdit = false;
        this.setUpDataSource();
      }
    } else {
      this.notiService.showInfo('Please Load Accounting Connections');
    }
  }

}
