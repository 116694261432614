import { NotificationService } from './../../services/notification.service';
import { PoService } from './../../services/felixApi/po.service';
import { EstimatingService } from './../../services/felixApi/estimating.service';
import { JobService } from './../../services/felixApi/job.service';
import { MaintenanceService } from './../../services/felixApi/maintenance.service';
import { Component, OnInit, OnChanges, OnDestroy } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { Subscription } from 'rxjs';
import { PriceFileItem } from '../../dtos/price-file-item';
import { Vendor } from '../../dtos/vendor';
import { VarianceCode } from '../../dtos/variance-code';
import { VarianceReason } from '../../dtos/variance-reason';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../services/auth.service';
import { ConstructionStageTypeEnum } from '../../dtos/construction-stage-type';

@Component({
  selector: 'js-create-zero-po',
  templateUrl: './create-zero-po.component.html',
  styleUrls: ['./create-zero-po.component.scss']
})
export class CreateZeroPoComponent implements OnInit, OnDestroy, OnChanges {

  subscriptions: Subscription[] = [];
  vendorId: number;
  vendors: Vendor[];
  purchaseOrderData: CustomStore;
  costCentres: PriceFileItem[];
  selectedOrderId: number;
  loading = false;
  jobId: number;
  costCentreId: number;
  description = '';
  varianceCodeId: number;
  varianceReason = '';
  varianceCodes: VarianceCode[];
  varianceReasons: VarianceReason[];
  adHocReason: VarianceReason;
  popupVisible: boolean;
  dropDownOptions: { width: number; minHeight: number; };
  isExtrasWrite: boolean;
  isAddAmount = false;
  amount: number;
  constructionStageId: number;
  constructionStages: object[] = [
    { id: 1, description: 'Construction' },
    { id: 2, description: 'Maintenance' },
    { id: 3, description: 'Warranty' }
  ];
  isHandedOver: boolean;

  constructor(
    private _activeModal: NgbActiveModal,
    private maintenanceService: MaintenanceService,
    private jobService: JobService,
    private estimatingService: EstimatingService,
    private poService: PoService,
    private notiService: NotificationService,
    private authService: AuthService
  ) {
    this.cancel = this.cancel.bind(this);
    this.onCCDropDownChanged = this.onCCDropDownChanged.bind(this);
  }

  ngOnInit() {
    // this.jobService.setCurrentJob('');
    this.costCentres = this.estimatingService.costCentres;
    this.vendors = this.maintenanceService.allVendors.filter(i => !i.hideFromOrders);
    this.dropDownOptions = { width: 500, minHeight: 400 };
    this.isExtrasWrite = this.authService.isAdminOrSuper();
    const poPerm = this.authService.areaPermissions.find(i => i.applicationArea === 'OrderExtras');
    if (this.authService.isAdminOrSuper() || poPerm?.permissionType === 'Write' || poPerm?.permissionType === 'Admin') {
      this.isExtrasWrite = true;
    }

    this.loadData();
  }

  ngOnChanges(): void {
    this.popupVisible = true;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  loadData() {
    this.subscriptions.push(
      this.poService.getExtrasData()
        .subscribe({
          next:
            () => {
              this.varianceCodes = this.maintenanceService.varianceCodes;
              this.varianceReasons = this.maintenanceService.varianceReasons;
              this.loading = false;
            },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  onJobNumberChanged(jobNo: string) {
    if (jobNo && this.jobService.currentJob) {
      this.jobId = this.jobService.currentJob.id;

      this.isHandedOver = this.jobService.currentJobExtra?.handoverDate ? true : false;

      if (this.isHandedOver) {
        if (this.jobService.currentJobExtra?.maintenanceCompleteDate) {
          this.constructionStageId = ConstructionStageTypeEnum.Warranty;
          this.constructionStages = [
            { id: 3, description: 'Warranty' }
          ];
        } else {
          this.constructionStageId = null; //must be selected
        }
      } else {
        this.constructionStageId = ConstructionStageTypeEnum.Prehandover;
      }
    } else {
      this.jobId = null;
    }
  }

  create() {
    if (this.description === '') {
      this.notiService.showInfo('Please enter a description');
    } else {
      this.loading = true;
      this.subscriptions.push(
        this.poService.createZeroValuePurchaseOrder(this.jobId, this.costCentreId, this.vendorId, this.description,
          this.varianceCodeId, this.varianceReason, this.amount ? this.amount : 0, this.constructionStageId).subscribe(res => {
            this.loading = false;
            this._activeModal.close(res.id);
          }, err => {
            this.notiService.notify(err);
            this.loading = false;
          })
      );
    }
  }

  addCustomItem(data) {
    if (!data.text) {
      data.customItem = null;
      return;
    }

    this.addToList(data.text);

    this.varianceReason = data.text;
    return this.adHocReason;
  }

  addToList(adhocText: string) {
    const productIds = this.varianceReasons.map(function (item) {
      return item.id;
    });
    const incrementedId = Math.max.apply(null, productIds) + 1;

    this.adHocReason = new VarianceReason(incrementedId, adhocText);
    this.varianceReasons.push(this.adHocReason);
  }

  onLookupValueChanged(ea) {
    if (!this.adHocReason) {
      this.varianceReason = ea.value;
    } else {
      this.varianceReason = this.adHocReason.reason;
      this.adHocReason = null;
    }
  }

  cancel() {
    this._activeModal.dismiss();
  }

  onCCDropDownChanged(dropDownBox, e) {
    if (e.selectedRowKeys.length > 0) {
      this.costCentreId = e.selectedRowsData[0].id;
      dropDownBox.close();
    }
  }
}
