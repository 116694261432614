export class OrderAttachment {
  public id: number;
  public orderAttachmentTypeId: number;
  public attachment: Blob;
  public description: string;
  public attachmentTypeId: number;
  public modifiedDate: Date;
  public modifiedUserId: number;
}

export enum OrderAttachmentTypeEnum {
  Logo = 1,
  Footer = 2
}
