import { Component, Input } from '@angular/core';


@Component({
  selector: 'js-background-logo',
  templateUrl: './background-logo.component.html',
  styles: ['.transLogo {' +
              'min-width: 100%;' +
              'max-width: 100%;' +
              'position: fixed;' +
              'top: 200px;' +
              'left: 0;' +
              'margin: 0 auto;' +
              'opacity: 0.10;' +
              'z-index: -1;' +
           '}']
})
export class BackgroundLogoComponent {

  @Input() showImg: boolean;

  constructor() { }

}
