import { GridService } from './../../services/grid.service';
import { OrderHeader } from './../../dtos/order-header';
import { MaintenanceService } from '../../services/felixApi/maintenance.service';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PoService } from '../../services/felixApi/po.service';
import { NotificationService } from '../../services/notification.service';
import { Subscription } from 'rxjs';
import { OrderLine, OrderLineMove } from '../../dtos/order-line';
import { Phase } from '../../dtos/phase';
import { Vendor } from '../../dtos/vendor';
import { DxDataGridComponent } from 'devextreme-angular';
import { EstimatingService } from '../../services/felixApi/estimating.service';

@Component({
  selector: 'js-move-items-to-new-po',
  templateUrl: './move-items-to-new-po.component.html',
  styleUrls: ['./move-items-to-new-po.component.scss']
})
export class MoveItemsToNewPoComponent implements OnInit, OnDestroy {
  @Input() orderHeader: OrderHeader;
  @Input() purchaseOrderId: number;

  @ViewChild('copyGrid', { static: false }) copyGrid: DxDataGridComponent;

  loading = false;
  subscriptions: Subscription[] = [];
  orderLines: OrderLine[] = [];
  gridHeight: number;
  phases: Phase[] = [{ id: 0, orderNo: 0, phaseCode: 'Default', description: 'Default' }];
  vendors: Vendor[];
  itemsToPost: OrderLineMove[] = [];
  varianceCodeId: number;
  varianceReason: string;
  remainingBudget: number;
  vendorId: number;

  constructor(
    private poService: PoService,
    private maintenanceService: MaintenanceService,
    private estimatingService: EstimatingService,
    private _activeModal: NgbActiveModal,
    public gridService: GridService,
    private notiService: NotificationService) {
  }

  ngOnInit() {
    this.poService.orderLines.filter(i => i.purchaseOrderId === this.purchaseOrderId && !i.hasSizes && i.measuredQuantity)
      .forEach(orderLine => {
        this.orderLines.push({ ...orderLine });
      });

    const po = this.poService.purchaseOrdersForJob?.find(i => i.id === this.purchaseOrderId);
    if (po) {
      this.remainingBudget = po.orderTotal;

      const invoicesExist = this.poService.invoicesForThisJob.filter(i => i.purchaseOrderId === this.purchaseOrderId);
      if (invoicesExist) {
        invoicesExist.forEach(invoice => {
          this.remainingBudget -= invoice.totalExGST;
        });
      }
      this.vendorId = po.vendorId
    }

    this.gridHeight = window.innerHeight - 330;
    this.phases = this.phases.concat(this.maintenanceService.phases);
    this.vendors = this.maintenanceService.allVendors.filter(i => i.isActive);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  cancel() {
    this._activeModal.dismiss();
  }

  runCopy() {
    this.copyGrid.instance.saveEditData();
    const orderLinesToMove = this.orderLines.filter(i => i.quantityToMove);

    if (orderLinesToMove && orderLinesToMove.length) {
      this.itemsToPost = [];
      let errorsInMove = false;
      let totalOfLinesToMove = 0;

      orderLinesToMove.forEach(element => {
        if (element.quantityToMove > element.measuredQuantity) {
          errorsInMove = true;
          this.notiService.showWarning('Quantity to move cannot be greater than measured. Line: ' + element.description);
        } else {
          this.itemsToPost.push({ orderLineId: element.id, measuredQuantity: element.quantityToMove });

          let supplierQty = element.quantityToMove

          if (element.priceFileItemId) {
            const priceFileItem = this.estimatingService
              .getPriceFileItemForVendor(element.priceFileItemId, element.vendorId, this.orderHeader.baseDate, this.orderHeader.districtId);

            supplierQty = this.maintenanceService
              .calcRoundedQty(element.quantityToMove, priceFileItem?.qtySizeControlId, priceFileItem?.hasSizes);
          }

          totalOfLinesToMove += supplierQty * this.estimatingService.calculateRate(element.rate, element.unitOfMeasure);
        }
      });

      if (totalOfLinesToMove > this.remainingBudget) {
        errorsInMove = true;
        this.notiService.showWarning('Total of lines to move cannot be greater than remaining budget');
      }

      if (!errorsInMove) {
        this.loading = true;

        this.subscriptions.push(
          this.poService.moveOrderLinesToNewPO(this.orderHeader.jobId, this.itemsToPost, this.vendorId).subscribe(
            () => {
              this._activeModal.close();
            },
            err => {
              this.notiService.notify(err);
              this.loading = false;
            })
        );
      }
    }
  }
}
