import { GlobalService } from './../global.service';
import { forkJoin, Observable, of, throwError as observableThrowError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { UtilsService } from '../../services/utils.service';
import { CompanyActivity } from '../../dtos/company-activity';
import { HttpService } from '../../services/http.service';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { DivisionActivityAccount } from '../../dtos/division-activity-account';


@Injectable({
  providedIn: 'root'
})
export class CompanyActivityService {

  baseURL = environment.apiUrl + '/activities/';
  activities: CompanyActivity[] = [];
  currentCompanyForActivitiesId: string;

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private utilsService: UtilsService,
    private globalService: GlobalService
  ) { }

  getCompanyActivities(activeOnly: boolean = true) {
    if (this.activities && this.activities.length && this.currentCompanyForActivitiesId === this.globalService.getCurrentCompanyId()) {
      return of(this.activities);
    } else {
      const url = this.globalService.getApiUrl() + '/activities?activeOnly=' + activeOnly;
      return this.http.get<CompanyActivity[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.activities = res;
          this.currentCompanyForActivitiesId = this.globalService.getCurrentCompanyId();
        }),
        catchError(this.handleError));
    }
  }


  getCompanyActivitiesWithDivisionAccounts(divisionId: number): Observable<CompanyActivity[]> {
    if (!divisionId) {
      return of([]);
    }
    return forkJoin(
      [
        this.getCompanyActivities(true),
        this.getDivisionActivityAccounts(divisionId)
      ]
    )
      .pipe(map(
        ([activities, divisionActivityAccounts]) => {
          activities.forEach(activity => {
            const divisionActivityAccount = divisionActivityAccounts
              .find(i => i.divisionId === divisionId && i.companyActivityId === activity.id);
            if (divisionActivityAccount) {
              activity.claimIncomeAccountId = divisionActivityAccount.claimIncomeAccountId;
              activity.variationIncomeAccountId = divisionActivityAccount.variationIncomeAccountId;
            }
          });
          return activities;
        }), catchError((err) => {
          return this.handleError(err);
        }
      ));
  }

  getDivisionActivityAccounts(divisionId: number): Observable<DivisionActivityAccount[]> {
    if (!divisionId) {
      return of([]);
    }

    const url = this.globalService.getApiUrl() + '/division-activity/' + divisionId + '/accounts';
    return this.http.get<DivisionActivityAccount[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  updateDivisionActivityAccount(divisionId: number, companyActivityId: string, updatedRecord: any) {
    return this.http.patch(environment.apiUrl + '/division/' + divisionId + '/activity/' + companyActivityId + '/accounts',
       JSON.stringify(updatedRecord), this.httpService.getHttpOptions());
  }

  runUpdateToActiveCampaign(dateFrom: string) {
    const baseURL = environment.apiUrl + '/active-campaign/update-ac?dateFrom=' + dateFrom;
    return this.http.patch(baseURL, JSON.stringify({}), this.httpService.getHttpOptions());
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
