import { NotificationService } from './../../../services/notification.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import CustomStore from 'devextreme/data/custom_store';
import { IntegrationService } from '../../../services/felixApi/integration.service';

@Component({
  selector: 'js-addenda-writer-load',
  templateUrl: './addenda-writer-load.component.html',
  styleUrls: ['./addenda-writer-load.component.scss']
})
export class AddendaWriterLoadComponent implements OnInit, OnDestroy {

  dataSource: CustomStore;
  subscriptions: Subscription[] = [];
  loadingFile = false;
  fileValue: any[] = [];
  jobNumber: string;
  deleteExistingJobLines = false;

  constructor(
    private integrationService: IntegrationService,
    private notiService: NotificationService
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onJobNumberChanged(jobNo: string) {
    this.jobNumber = jobNo;
  }

  loadFileGo() {
    this.loadingFile = true;
    const formData: FormData = new FormData();
    formData.append('image', this.fileValue[0], this.fileValue[0].name);

    this.subscriptions.push(
      this.integrationService.loadAddendaWriterFile(formData, this.jobNumber, this.deleteExistingJobLines)
        .subscribe({
          next: () => {
            this.loadingFile = false;
            this.notiService.showSuccess('Load successful');
          }, error: (err) => {
            this.notiService.notify(err);
            this.fileValue = [];
            this.loadingFile = false;
          }
        })
    );
  }
}
