<div class="page">
  <dx-data-grid *ngIf="!loading" #allOrders id="allOrders" [dataSource]="purchaseOrderData" [height]="gridHeight"
    [allowColumnReordering]="false" [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true"
    [rowAlternationEnabled]="true" [wordWrapEnabled]="true" [autoNavigateToFocusedRow]="true" [hoverStateEnabled]="true"
    (onToolbarPreparing)="onToolbarPreparing($event)" (onSelectionChanged)="onSelectionChanged($event)"  (onExporting)="gridService.onExporting($event, 'all-orders')">

    <dxo-search-panel [visible]="true" location="before" [width]="400"></dxo-search-panel>
    <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping #expand [autoExpandAll]="false" [contextMenuEnabled]="true"></dxo-grouping>
    <dxo-column-chooser [enabled]="true" mode="dragAndDrop"> </dxo-column-chooser>
    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="all-orders" [savingTimeout]="500">
    </dxo-state-storing>
    <dxo-selection mode="multiple"></dxo-selection>

    <dxo-editing mode="row" [allowUpdating]="isAdmin" [allowAdding]="false" [allowDeleting]="false" [useIcons]="true">
    </dxo-editing>

    <dxi-column dataField="jobNumber" caption="Job" dataType="string" width="110" [allowEditing]="false"
      [calculateCellValue]='calculateJobNumber'>
    </dxi-column>

    <dxi-column dataField="jobId" caption="Job Address" dataType="number" [allowEditing]="false">
      <dxo-lookup [dataSource]="jobs" displayExpr="jobAddressString" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="currentActivity" width="140" [calculateCellValue]="calculateActivityDesc" [visible]='false'>
    </dxi-column>

    <dxi-column dataField="salesRepId" dataType="number" [caption]="salesRepWording" [width]="100" alignment="left"
      [allowEditing]="false" [visible]='false' [calculateCellValue]="calculateSalesRep">
    </dxi-column>

    <dxi-column dataField="costCentreId" caption="Cost Code" dataType="number" width="110" alignment="center"
      [calculateSortValue]="calculateCostCentreSortValue" [allowEditing]="false">
      <dxo-lookup [dataSource]="costCentres" displayExpr="priceFileCode" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="costCentreDescription" caption="Cost Centre" [calculateCellValue]='calculateCostCentreDesc'
      [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="poNumber" caption="PO Number" width="130" [calculateCellValue]="calculatePONumber">
    </dxi-column>

    <dxi-column dataField="vendorId" caption="Vendor" dataType="number" [calculateSortValue]="calculateVendorSortValue"
      [allowEditing]="false">
      <dxo-lookup [dataSource]="vendors" displayExpr="vendorName" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="orderTotal" dataType="number" width="130" [format]="{ type: 'fixedPoint', precision: 2 }"
      [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="approvedInvoicesTotal" caption="Approved Invoices Ex GST" dataType="number" width="130"
      [format]="{ type: 'fixedPoint', precision: 2 }" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="remainingBudgetExGST" caption="Remaining Budget Ex GST" dataType="number" width="130"
      [format]="{ type: 'fixedPoint', precision: 2 }" [calculateCellValue]="calculateRemainingBudget"
      [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="orderTotalIncGST" caption="Order + GST" dataType="number" width="130"
      [format]="{ type: 'fixedPoint', precision: 2 }" [calculateCellValue]="calculateOrderIncGSTValue"
      [allowEditing]="false">
    </dxi-column>

    <dxo-master-detail [enabled]="true" template="masterDetail"></dxo-master-detail>

    <div *dxTemplate="let masterDetailItem of 'masterDetail'">
      <js-order-invoices [purchaseOrder]=masterDetailItem.data></js-order-invoices>
    </div>

    <dxi-column type="buttons" [width]="80">
      <dxi-button *ngIf="isAdmin" name="edit"> </dxi-button>
      <dxi-button icon="download" [onClick]="openPO" hint='Download PO'> </dxi-button>
    </dxi-column>


    <!-- summary totals -->
    <dxo-summary [calculateCustomSummary]="calculateSelectedRow">
      <dxi-total-item name="SelectedOrderTotal" showInColumn="orderTotal" summaryType="custom" valueFormat="#,##0.00">
      </dxi-total-item>
      <dxi-total-item name="SelectedApprovedInvoices" showInColumn="approvedInvoicesTotal" summaryType="custom"
        valueFormat="#,##0.00">
      </dxi-total-item>
      <dxi-total-item name="SelectedRemainingBudget" showInColumn="remainingBudgetExGST" summaryType="custom"
        valueFormat="#,##0.00">
      </dxi-total-item>
      <dxi-total-item name="SelectedOrderTotalIncGST" showInColumn="orderTotalIncGST" summaryType="custom"
        valueFormat="#,##0.00">
      </dxi-total-item>
    </dxo-summary>
  </dx-data-grid>
</div>

<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loadingData" [showIndicator]="true"
  [showPane]="true" [shading]="false" [hideOnOutsideClick]="false">
</dx-load-panel>
