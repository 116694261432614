export class PriceFileItem {
  public id: number;
  public priceFileItemParentId: number;
  public priceFileItemTypeId: number;
  public orderNumber: number;
  public priceFileCode: string;
  public description: string;
  public unitOfMeasureId: number;
  public hasSizes: boolean;
  public qtySizeControlId: number;
  public centralCompanyPriceFileItemId: number;
  public isActive: boolean;
  public comment: string;

  public costCentreId: number;
  public costCentreDescription: string;
  public costCentreOrderNumber: number;
  public itemGroupDescription: string;
  public itemGroupOrderNumber: number;
  public priceFileGroupId: number;
  public rate: number;
  public vendorId: number;
  public vendorItemDescription: string;

  public callUpDocsTypes: number[];
  public includeAddendum: boolean;
  public doNotAllowCosts: boolean;

  public total: number; // used for the cost centre total
  public unorderedLineCount: number; // calculated
  public orderedLineCount: number; // calculated
  public orderSentLineCount: number; // calculated

  constructor(id: number, orderNumber: number, priceFileCode: string, description: string) {
    this.id = id;
    this.orderNumber = orderNumber;
    this.priceFileCode = priceFileCode;
    this.description = description;
  }
}
