import { Directive, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[jsFocusElement]'
})
export class FocusElementDirective implements AfterViewInit {

  constructor(private element: ElementRef) { }

  ngAfterViewInit() {
      this.element.nativeElement.focus();
      document.body.scrollTop = 0;
  }
}
