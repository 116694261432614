<div *ngIf="!loadingData" class="page">
  <dx-data-grid class="m-auto" [height]="gridHeight" [dataSource]="dataSource" [allowColumnReordering]="true"
    [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true" [rowAlternationEnabled]="true"
    [wordWrapEnabled]="true" [hoverStateEnabled]="true" (onToolbarPreparing)="onToolbarPreparing($event)"
    (onExporting)="gridService.onExporting($event, 'extras')">

    <dxo-search-panel [visible]="true" location="before" [width]="400"></dxo-search-panel>
    <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping #expand [autoExpandAll]="true" [contextMenuEnabled]="false"></dxo-grouping>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-column-chooser [enabled]="true" mode="dragAndDrop"> </dxo-column-chooser>
    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="extras-report" [savingTimeout]="500">
    </dxo-state-storing>

    <dxi-column dataField="jobId" caption="Job" width="90">
      <dxo-lookup [dataSource]="jobs" displayExpr="jobNumber" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="costCentreId" caption="Cost Centre" dataType="number" width="180"
      [calculateSortValue]="calculateCostCentreSortValue">
      <dxo-lookup [dataSource]="costCentres" displayExpr="description" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="poNumber" caption="Order" [width]="120" dataType="string"> </dxi-column>

    <dxi-column dataField="vendorCode" dataType="string" width="80" [visible]="false"> </dxi-column>

    <dxi-column dataField="vendorName" dataType="string" width="150" [visible]="false"> </dxi-column>

    <dxi-column dataField="siteAddress" minWidth="200" [calculateCellValue]="calculateSiteAddress"> </dxi-column>

    <dxi-column dataField="varianceCodeId" caption="Code" dataType="number" width="130">
      <dxo-lookup [dataSource]="allVarianceCodes" displayExpr="description" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="varianceReasonDescription" caption="Reason" dataType="string"> </dxi-column>

    <dxi-column dataField="description" caption="Order line description" dataType="string"> </dxi-column>

    <dxi-column dataField="varianceTotal" dataType="number" width="120" [format]="{ type: 'fixedPoint', precision: 2 }">
    </dxi-column>

    <dxi-column dataField="siteManager" width="130" [calculateCellValue]="calculateSiteManager">
    </dxi-column>

    <dxi-column dataField="createDate" caption="Created" dataType="date" width="100" format="d/M/yy" alignment="center">
    </dxi-column>

    <dxi-column dataField="createUserId" caption="Created by" dataType="number" width="130">
      <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="modifiedDate" caption="Modified" dataType="date" width="100" format="d/M/yy"
      alignment="center">
    </dxi-column>

    <dxi-column dataField="modifiedUserId" caption="Modifed by" dataType="number" width="130">
      <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="estimator" [caption]="calculateEstimatorTitle()" dataType="string" [allowEditing]="false"
      width="110" [calculateCellValue]='calculateEstimator'>
    </dxi-column>


    <dxo-summary>
      <dxi-total-item column="jobId" summaryType="count" valueFormat="#,###" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="varianceTotal" summaryType="sum" valueFormat="#,###" displayFormat="{0}">
      </dxi-total-item>
    </dxo-summary>
  </dx-data-grid>
</div>

<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loadingData" [showIndicator]="true"
  [showPane]="true" [shading]="false" [hideOnOutsideClick]="false">
</dx-load-panel>
