import { AccountingSystemTenant } from '../../dtos/accounting-system-tenant';
import { UserService } from './../../services/felixApi/user.service';
import { AccountingSystemService } from './../../services/felixApi/accounting-system.service';
import { MaintenanceService } from './../../services/felixApi/maintenance.service';
import { JobService } from './../../services/felixApi/job.service';
import { Component, OnInit, ViewChild, OnDestroy, OnChanges, Input, ChangeDetectorRef } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import dxDataGrid from 'devextreme/ui/data_grid';
import dxCheckBox, {
  InitializedEvent,
  ValueChangedEvent,
} from 'devextreme/ui/check_box';
import { SelectionChangedEvent } from 'devextreme/ui/data_grid';
import { Subscription } from 'rxjs';
import { GlobalService } from '../../services/global.service';
import { GridService } from '../../services/grid.service';
import { NotificationService } from '../../services/notification.service';
import { AuthService } from '../../services/auth.service';
import { User } from '../../dtos/user';
import { Job } from '../../dtos/job'
import { EstimatingService } from '../../services/felixApi/estimating.service';
import { Vendor } from '../../dtos/vendor';
import { PriceFileItem } from '../../dtos/price-file-item';
import { ConfigurationEnum } from '../../dtos/configuration-enum';
import CustomStore from 'devextreme/data/custom_store';
import { AccountingSystemPurchaseOrderCompare } from '../../dtos/accounting-system-purchase-order-compare';
import { AccountingSystemJob } from '../../dtos/accounting-system-job';
import { AccountingSystemAccount } from '../../dtos/accounting-system-account';
import { AccountingSystemAccountToCostCentre } from '../../dtos/accounting-system-account-to-cost-centre';

@Component({
  selector: 'js-order-import',
  templateUrl: './order-import.component.html',
  styleUrls: ['./order-import.component.scss']
})
export class OrderImportComponent implements OnInit, OnChanges, OnDestroy {
  @Input() orderImportCounter: number;

  @ViewChild('purchaseOrdersGrid') grid: DxDataGridComponent;
  @ViewChild('accountMappingGrid') gridAccountMapping: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  purchaseOrders: AccountingSystemPurchaseOrderCompare[];
  dataSource: CustomStore;
  selectAllCheckBox!: dxCheckBox;
  checkBoxUpdating = false;
  loading = true;
  gridHeight: number;
  purchaseOrderAdmin: boolean;
  loadingAccounts: boolean;
  users: User[];
  jobs: Job[];
  vendors: Vendor[];
  costCentres: PriceFileItem[];
  selectedJobId: number;
  accountingSystemType: number;
  accountingSystemJobs: AccountingSystemJob[];
  selectedAccountingSystemJobId: string;
  accountingSystemTenantId: string;
  accountingCompanies: AccountingSystemTenant[];
  dropDownOptions: { width: number; minHeight: number; };
  autoConnectPopupVisible: boolean;
  allowAccountMapping: boolean = false;
  showAccountMappingPopup: boolean;
  accountMappingData: any;
  accounts: AccountingSystemAccount[];
  accountsToCostCentres: AccountingSystemAccountToCostCentre[];
  dateFrom: Date = new Date(2020, 0, 1);

  constructor(
    private globalService: GlobalService,
    private notiService: NotificationService,
    public gridService: GridService,
    private authService: AuthService,
    private accountingSystemService: AccountingSystemService,
    private userService: UserService,
    private maintenanceService: MaintenanceService,
    private jobService: JobService,
    private estimatingService: EstimatingService,
    private cdr: ChangeDetectorRef
  ) {
    this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
    this.dropDownOptions = { width: 300, minHeight: 400 };
    this.onEditorPreparing = this.onEditorPreparing.bind(this);
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.gridHeight = window.innerHeight - 130;
    this.getData();
  }

  getData() {
    this.subscriptions.push(
      this.accountingSystemService.getDataForPurchaseOrderQuery().subscribe({
        next: () => {
          this.users = this.userService.users;
          this.accountingCompanies = this.maintenanceService.accountingSystemTenantsList();
          this.accountingSystemType = this.globalService.getCompanyConfigValue(ConfigurationEnum.AccountingSystem);
          this.costCentres = this.estimatingService.costCentres;

          this.subscriptions.push(
            this.jobService.getJobsByAddress(false).subscribe({
              next: (res) => {
                this.jobs = res;
              }, error: (err) => {
                this.notiService.notify(err);
              }
            })
          );

          if (this.authService.isAdminOrSuper()
            || this.authService.areaPermissions.find(i => i.applicationArea === 'PurchaseOrders')?.permissionType === 'Admin') {
            this.purchaseOrderAdmin = true;
          }

          this.loading = false;
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }

  setUpDataSource() {
    this.dataSource = new CustomStore({
      key: 'orderID',
      loadMode: 'raw',
      load: () => this.purchaseOrders,
      update: async (key, values) => {
        const changedRec = this.purchaseOrders.find(i => i.orderID === key);
        if (values.importMessage) {
          changedRec.importMessage = values.importMessage;
        }
        if (values.importToCostCentreId) {
          changedRec.importToCostCentreId = values.importToCostCentreId;
        }
        if (values.importToVendorId) {
          changedRec.importToVendorId = values.importToVendorId;
        }
        if (values.importToInvoiceVendorId) {
          changedRec.importToInvoiceVendorId = values.importToInvoiceVendorId;
        }
        if (values.truthEnginePurchaseOrderId) {
          changedRec.truthEnginePurchaseOrderId = values.truthEnginePurchaseOrderId;
        }
        if (values.truthEngineInvoiceId) {
          changedRec.truthEngineInvoiceId = values.truthEngineInvoiceId;
        }
        return changedRec;
      }
    });
  }

  importSelected() {
    return async () => {
      this.loading = true;
      this.grid.instance.saveEditData();
      const selectedRows: AccountingSystemPurchaseOrderCompare[] = this.grid.instance.getSelectedRowsData();
      if (selectedRows.length > 0) {
        
        const hasCostCentre = selectedRows.every(row => row.importToCostCentreId !== undefined);
        if (!hasCostCentre) {
          this.loading = false;
          this.notiService.showInfo('Some selected purchase orders do not have a Cost Centre assigned');
          return;
        }
        const hasVendor = selectedRows.every(row => row.importToVendorId !== undefined && row.importToInvoiceVendorId !== undefined);
        if (!hasVendor) {
          this.loading = false;
          this.notiService.showInfo('Some selected purchase orders do not have a Vendor assigned');
          return;
        }

        this.subscriptions.push(
          this.accountingSystemService.importSelectedPurchaseOrders(this.selectedJobId, selectedRows).subscribe({
            next: (res: AccountingSystemPurchaseOrderCompare[]) => {
              var importedCount = 0;
              var notImportedCount = 0;
              var errorCount = 0;
              for (const row of res) {
                this.dataSource.update(row.orderID, row);
                if (row.importMessage.startsWith('Imported')) {
                  importedCount++;
                }
                if (row.importMessage.startsWith('Not Imported')) {
                  notImportedCount++;
                }
                if (row.importMessage.startsWith('Error')) {
                  errorCount++;
                }
              }
              this.loading = false;
              if (importedCount > 0 && notImportedCount === 0 && errorCount === 0) {
                this.notiService.showSuccess(`All selected purchase orders Imported successfully (${importedCount})`);
              }
              else {
                this.notiService.showWarning(`Imported - ${importedCount}, Not Imported - ${notImportedCount}, Errors - ${errorCount}`);
              }
            }, error: (err) => {
              this.loading = false;
              this.notiService.notify(err);
            }
          })
        );
      }
      else {
        this.loading = false;
        this.notiService.showInfo('No purchase orders selected');
      }
    };
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onToolbarPreparing(e, templateName: string) {
    const toolbarItems = e.toolbarOptions.items;
    toolbarItems.push({
      location: 'before',
      locateInMenu: 'auto',
      template: templateName
    });

    toolbarItems.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          type: 'outline',
          icon: 'refresh',
          onClick: this.refresh.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          type: 'default',
          stylingMode: 'outlined',
          text: 'Import Selected',
          onClick: this.importSelected()
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          type: 'default',
          stylingMode: 'outlined',
          text: 'Account Mapping',
          onClick: this.accountMappingPopup(),
          enabled: this.allowAccountMapping
        }
      });
  }

  refresh() {
    if (this.accountingSystemTenantId) {
      this.loading = true;
      this.subscriptions.push(
        this.accountingSystemService.getAccountingSystemPurchaseOrdersCompare(this.accountingSystemTenantId, this.selectedJobId, this.dateFrom, this.selectedAccountingSystemJobId)
          .subscribe({
            next: (res: AccountingSystemPurchaseOrderCompare[]) => {
              this.purchaseOrders = res;
              this.setUpDataSource();
              this.checkData(this.purchaseOrders);
              this.loading = false;
            }, error: (err) => {
              this.loading = false;
              this.notiService.notify(err);
            }
          }));
    }
    else {
      this.notiService.showInfo('No Company selected');
    }
  }

  checkData(purchaseOrders: AccountingSystemPurchaseOrderCompare[]) {
    var dataCheck = '';
    if (purchaseOrders.length !== 0) {
      if (!purchaseOrders.every(row => row.importToCostCentreId !== undefined)) {
        dataCheck = 'Some selected purchase orders do not have a Cost Centre assigned';
      }
      if (!purchaseOrders.every(row => row.importToVendorId !== undefined && row.importToInvoiceVendorId !== undefined)) {
        if (dataCheck !== '') {
          dataCheck += '\n';
        }
        dataCheck += 'Some selected purchase orders do not have a Vendor assigned';
      }
    }
    if (dataCheck !== '') {
      this.notiService.showInfo(dataCheck);
    }
  }

  async onCompanySelectionChanged() {
    this.vendors = null;
    this.accounts = null;
    this.allowAccountMapping = false;
    if (this.accountingSystemTenantId) {
      this.subscriptions.push(
        this.maintenanceService.getVendorsForTenant(true, this.accountingSystemTenantId).subscribe({
          next: (res) => {
            this.vendors = res.filter(item => item.accountId !== null);
          }, error: (err) => {
            this.notiService.notify(err);
          }
        })
      );
      if (this.accountingSystemType === 3) {
        this.subscriptions.push(
          this.accountingSystemService.getJobsForTenant(true, this.accountingSystemTenantId).subscribe({
            next: (res) => {
              this.accountingSystemJobs = res;
            }, error: (err) => {
              this.notiService.notify(err);
            }
          })
        );
      }
      if (this.accountingSystemType === 1 || this.accountingSystemType === 3) {
        this.subscriptions.push(
          this.maintenanceService.getAccountingSystemAccounts(this.accountingSystemTenantId).subscribe({
            next: (res) => {
              this.accounts = res;
              this.allowAccountMapping = true;
            }, error: (err) => {
              this.notiService.notify(err);
            }
          })
        );
      }
    }
  }

  async onAccountingSystemJobSelectionChanged(dropDownBox, event) {
    if (event.selectedRowKeys.length > 0) {
      this.selectedAccountingSystemJobId = event.selectedRowsData[0].id;
      const selectedAccountingSystemJobNumber = event.selectedRowsData[0].number;
      if (selectedAccountingSystemJobNumber) {
         const jobId = this.jobs.find(i => i.jobNumber === selectedAccountingSystemJobNumber)?.id;
         if (jobId) {
          this.selectedJobId = jobId;
          this.cdr.detectChanges();
         }
      }
      dropDownBox.close();
    }
    else {
      this.selectedAccountingSystemJobId = null;
    }
  }

  async onJobSelectionChanged(dropDownBox, event) {
    if (event.selectedRowKeys.length > 0) {
      this.selectedJobId = event.selectedRowsData[0].id;
      dropDownBox.close();
    }
    else {
      this.selectedJobId = null;
    }
  }

  calculatePOExistsInTruthEngine(data) {
    if (data.truthEnginePurchaseOrderId && data.truthEnginePurchaseOrderId !== '') {
      return true;
    }
    else {
      return false;
    }
  }

  calculateInvoiceExistsInTruthEngine(data) {
    if (data.truthEngineInvoiceId && data.truthEngineInvoiceId !== '') {
      return true;
    }
    else {
      return false;
    }
  }

  async onAccountingSystemJobValueChanged(event) {
    if (event.value === null) {
      this.selectedAccountingSystemJobId = null;
    }
  }

  async onJobValueChanged(event) {
    if (event.value === null) {
      this.selectedJobId = null;
    }
  }

  onLookupValueChanged(ea, e) {
    e.setValue(ea.value);
  }

  onDropDownChanged(cellInfo, e) {
    if (!e.value) {
      cellInfo.setValue(null);
    }
  }

  onEditorPreparing(e) {
    if (e.dataField === 'importToCostCentreId' || e.dataField === 'importToVendorId' || e.dataField === 'importToInvoiceVendorId') {
      e.editorOptions.dropDownOptions = { minWidth: 350 };
    }
    if (e.dataType === 'boolean') {
      e.editorOptions.dropDownOptions = { minWidth: 70 };
    }
    if (e.dataField === "lineDetails" || e.dataField === "invoiceLineDetails") {
      e.editorName = "dxTextArea";
    }
    if (e.type === 'selection') {
      if (e.parentType === 'dataRow' && e.row && !this.isSelectable(e.row.data)) {
        e.editorOptions.disabled = true;
      }
      if (e.parentType === 'headerRow') {
        const dataGrid = e.component;
        e.editorOptions.value = this.isSelectAll(dataGrid);
        e.editorOptions.onInitialized = (e: InitializedEvent) => {
          if (e.component) this.selectAllCheckBox = e.component;
        };
        e.editorOptions.onValueChanged = (e: ValueChangedEvent) => {
  
          if (!e.event) {
            if (e.previousValue && !this.checkBoxUpdating) {
              e.component.option('value', e.previousValue);
            }
            return;
          }
  
          if (this.isSelectAll(dataGrid) === e.value) {
            return;
          }
  
          e.value ? dataGrid.selectAll() : dataGrid.deselectAll();
          e.event.preventDefault();
        };
      }
    }
  }

  isSelectable(purchaseOrder: AccountingSystemPurchaseOrderCompare) {
    return purchaseOrder.truthEnginePurchaseOrderId === null;
  }

  isSelectAll = (dataGrid: dxDataGrid) => {
    if (!this.purchaseOrders) {
      return false;
    }
    const selectableItems = this.purchaseOrders.filter(this.isSelectable);
    const selectedRowKeys = dataGrid.option('selectedRowKeys');
    if (!selectedRowKeys?.length) {
      return false;
    }
    return selectedRowKeys.length >= selectableItems.length ? true : undefined;
  };

  onSelectionChanged(e: SelectionChangedEvent<AccountingSystemPurchaseOrderCompare, number>) {
    const deselectRowKeys: number[] = [];
    const dataGrid = e.component
    e.selectedRowsData.forEach((item) => {
      if (!this.isSelectable(item))
        deselectRowKeys.push(dataGrid.keyOf(item));
    });
    if (deselectRowKeys.length) {
      dataGrid.deselectRows(deselectRowKeys);
    }
    this.checkBoxUpdating = true;
    this.selectAllCheckBox.option('value', this.isSelectAll(dataGrid));
    this.checkBoxUpdating = false;
  }

  accountMappingPopup() {
    return () => {
      this.subscriptions.push(
        this.accountingSystemService.getAccountingSystemAccountToCostCentres(this.accountingSystemTenantId).subscribe({
          next: (res) => {
            this.accountsToCostCentres = res;

            this.accountMappingData = this.accounts.map(account => {
              const costCentre = this.accountsToCostCentres.find(item => item.accountId === account.accountID);
              return {
                accountId: account.accountID,
                costCentreId: costCentre ? costCentre.costCentreId : null
              };
            });

            this.showAccountMappingPopup = true;
          },
          error: (err) => {
            this.notiService.notify(err);
          }
        })
      );
    };
  }

  saveAccountMapping() {
    this.accountMappingData.forEach((mapping) => {
      const existingMapping = this.accountsToCostCentres.find((item) => item.accountId === mapping.accountId);
      if (!existingMapping) {
        if (mapping.costCentreId !== null) {
          this.accountingSystemService.addAccountingSystemAccountToCostCentre(mapping.accountId, mapping.costCentreId, this.accountingSystemTenantId).subscribe({
            next: (res) => {
              this.accountsToCostCentres.push(res);
            },
            error: (err) => {
              this.notiService.notify(err);
            }
          });
        }
      }
      else {
        if (existingMapping.costCentreId !== mapping.costCentreId) {
          if (mapping.costCentreId === null) {
            this.accountingSystemService.deleteAccountingSystemAccountToCostCentre(existingMapping.id).subscribe({
              next: () => {
                this.accountsToCostCentres = this.accountsToCostCentres.filter(item => item.id !== existingMapping.id);
              },
              error: (err) => {
                this.notiService.notify(err);
              }
            });
          }
          else {
            this.accountingSystemService.updateAccountingSystemAccountToCostCentre(existingMapping.id, mapping.costCentreId).subscribe({
              next: () => {
                this.accountsToCostCentres = this.accountsToCostCentres.map(item => {
                  if (item.id === existingMapping.id) {
                    item.costCentreId = mapping.costCentreId;
                  }
                  return item;
                });
              },
              error: (err) => {
                this.notiService.notify(err);
              }
            });
          }
        }
      }
    });
  }

  cancelAccountMapping() {
    this.gridAccountMapping.instance.cancelEditData();
    this.showAccountMappingPopup = false;
  }
}