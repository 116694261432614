import { GridService } from './../../services/grid.service';
import { DivisionService } from './../../services/felixApi/division.service';
import { JobService } from '../../services/felixApi/job.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { GlobalService } from '../../services/global.service';
import { CompanyService } from './../../services/felixApi/company.service';
import { ConfigurationEnum } from '../../dtos/configuration-enum';
import { ClaimsService } from '../../services/felixApi/claims.service';
import { NotificationService } from '../../services/notification.service';
import { Job } from '../../dtos/job';
import { IncomeInvoiceTypeEnum } from '../../dtos/income-invoice';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'js-variation-invoices',
  templateUrl: './variation-invoices.component.html',
  styleUrls: ['./variation-invoices.component.scss']
})
export class VariationInvoicesComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  dataSource: CustomStore;
  loading = false;
  jobs: Job[];
  gridHeight: number;
  submitFutureInvoices = false;
  currentJobId: number;
  selectedRecords: string[] = [];
  dateOldInvoicesToday = false;
  accountingSystemType: number;
  loadingData = true;
  minuteCountdown = 0;
  inEditMode: boolean;

  constructor(
    private globalService: GlobalService,
    private compService: CompanyService,
    private claimsService: ClaimsService,
    private notiService: NotificationService,
    private divisionService: DivisionService,
    public gridService: GridService,
    private jobService: JobService) {
    this.calculateClientName = this.calculateClientName.bind(this);
    this.calculateSiteAddress = this.calculateSiteAddress.bind(this);
    this.calculateConnectionStatus = this.calculateConnectionStatus.bind(this);
    this.calculateClientEmail = this.calculateClientEmail.bind(this);
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          this.setHeightWidths();
        }
      )
    );

    this.setHeightWidths();
    this.getCompanyConfigurations();
    this.loadData(true);
    this.setupDataRefresh();
  }

  setHeightWidths() {
    this.gridHeight = window.innerHeight - 107;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getCompanyConfigurations() {
    this.subscriptions.push(
      this.compService.getCompanyConfigurations().subscribe({
        next: () => {
          this.accountingSystemType = this.globalService.getCompanyConfigValue(ConfigurationEnum.AccountingSystem);
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }

  loadData(useCache: boolean) {
    this.loadingData = true;
    this.resetCountdown();
    this.subscriptions.push(
      this.claimsService.getClaimsData(useCache)
        .subscribe({
          next: () => {
            this.jobs = this.jobService.jobs;
            this.loadingData = false;
            this.setUpDataSource();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loadingData = false;
          }
        })
    );
  }

  setUpDataSource() {
    this.dataSource = new CustomStore({
      key: 'selectedCode',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.claimsService.getVariationClaimsDue(this.dateOldInvoicesToday)
              .subscribe({
                next: (res) => {
                  return resolve(res);
                }, error: (err) => {
                  return reject(this.globalService.returnError(err));
                }
              })
          ));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.claimsService.updateVariation(key,
              { extraDescription: values.extraDescription }).subscribe({
                next: () => {
                  return resolve({ extraDescription: values.extraDescription });
                }, error: (err) => {
                  return reject(this.globalService.returnError(err));
                }
              })
          ));
      }
    });
  }

  setupDataRefresh(): void {
    // Refresh data every 10 minutes by counting down the minutes
    this.subscriptions.push(
      timer(0, 60000).subscribe(() => {
        if (!this.inEditMode) {
          if (this.minuteCountdown > 0 && !this.loading) {
            this.minuteCountdown--; // Decrement the countdown
          }

          if (this.minuteCountdown <= 0) {
            this.loadData(false);
          }
        }
      })
    );
  }

  onToolbarPreparing(e, templateName2: string, toolbarTemplate3: string) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        template: toolbarTemplate3
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        template: templateName2
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          type: 'default',
          text: 'Submit',
          onClick: this.processInvoices.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          type: 'outline',
          icon: 'refresh',
          onClick: this.refresh.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          type: 'default',
          stylingMode: 'outlined',
          text: 'Reset Layout',
          onClick: this.clearStatePersistance.bind(this)
        }
      });
  }

  calculateClientName(data) {
    return this.jobs.find(i => i.id === data.jobId)?.contractName;
  }

  calculateConnectionStatus(data) {
    if (data.variationSplitId < 0) {
      return 'Splits do not add up to the variation total.';
    }
    if (!data.invoiceDate || data.invoiceDate === '') {
      return 'Stage Skipped or Activity not specified - mark appropriate task complete.';
    }

    const job = this.jobs.find(i => i.id === data.jobId);
    if (job) {
      const division = this.divisionService.divisions.find(i => i.id === job.divisionId);

      if (!division) {
        return 'Division not specified';
      }

      if (!division.accountingSystemTenantId || division.accountingSystemTenantId.trim() === '') {
        return 'Division not linked to accounts';
      }

      // Xero/MYOB requires an Income Account
      if (this.accountingSystemType == 1 || this.accountingSystemType == 3) {
        if (!division.incomeAccountId || division.incomeAccountId.trim() === '') {
          return 'Division not linked to an Income Account';
        }
      }

      // Xero also requires a Job Tracking Category
      if (this.accountingSystemType == 1) {
        if (!division.jobTrackingCategoryId || division.jobTrackingCategoryId.trim() === '') {
          return 'Division not linked to a Job Tracking Category';
        }
      }

      // QuickBooks and Business Central require Service for Variations
      if (this.accountingSystemType == 2 || this.accountingSystemType == 4) {
        if (!division.variationIncomeAccountId || division.variationIncomeAccountId.trim() === '') {
          return 'Division not linked to a Service Code for Variations';
        }
      }
    } else {
      return 'Job Invalid';
    }

    const incomeError = this.claimsService.incomeInvoiceErrors
      .find(i => i.jobId === data.jobId
        && i.incomeInvoiceTypeId === IncomeInvoiceTypeEnum.Variation
        && i.jobVariationId === data.jobVariationId
        && i.orderNumber === data.orderNumber
        && i.variationSplitId === (data.variationSplitId === 0 ? null : data.variationSplitId));

    if (incomeError) {
      if (incomeError.errorString.toLowerCase().indexOf("pending processing in business central") != -1) {
        return incomeError.errorString;
      } 
      return 'Integration Error: ' + incomeError.errorString + ' - Process again to add .2 to the end';
    }
    return 'OK to Submit';
  }

  calculateClientEmail(data) {
    return this.jobs.find(i => i.id === data.jobId)?.clientEmail;
  }

  processInvoices() {
    // authorise
    if (!this.loading) {
      this.loading = true;
      this.subscriptions.push(
        this.claimsService.submitVariationClaimsDue(this.submitFutureInvoices, this.dateOldInvoicesToday,
          { selectedCodes: this.selectedRecords })
          .subscribe({
            next: () => {
              this.loading = false;
              this.selectedRecords = [];
              this.setUpDataSource();
            }, error: (err) => {
              this.notiService.notify(err);
              this.loading = false;
            }
          })
      );
    }
  }

  refresh() {
    this.selectedRecords = [];
    this.loadData(false);
  }

  clearStatePersistance() {
    this.loadingData = true;
    localStorage.removeItem('variation-invoices');
    setTimeout(() => {
      this.loadingData = false;
    }, 300); // wait
  }

  calculateSiteAddress(data) {
    return this.jobs.find(i => i.id === data.jobId)?.jobAddressString;
  }
  resetCountdown() {
    this.minuteCountdown = 10; // Reset the countdown
  }
}
