<div class="modal-header">
  <h3 class="modal-title">Move items to a new purchase order</h3>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <dx-data-grid *ngIf="!loading" #copyGrid id="copyGrid" [height]="gridHeight" [dataSource]="orderLines"
    [allowColumnReordering]="false" [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true"
    [rowAlternationEnabled]="true" [wordWrapEnabled]="true" [hoverStateEnabled]="true"
    (onExporting)="gridService.onExporting($event, 'move-items')">

    <dxo-search-panel [visible]="true" location="before" [width]="400"></dxo-search-panel>
    <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-sorting mode='multiple'></dxo-sorting>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>

    <dxo-editing mode="batch" [allowUpdating]="true"></dxo-editing>

    <dxo-keyboard-navigation [editOnKeyPress]="true" enterKeyAction="moveFocus" enterKeyDirection="column">
    </dxo-keyboard-navigation>

    <dxi-column dataField="priceFileCode" caption="Code" [width]="110" sortOrder="asc" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="description" dataType="string" sortOrder="asc" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="unitOfMeasure" dataType="number" caption="Units" [width]="80" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="hasSizes" dataType="boolean" [visible]="false"> </dxi-column>

    <dxi-column dataField="measuredQuantity" caption="Current Measured Quantity" dataType="number" [width]="90"
      [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="rate" dataType="number" [width]="100" [format]="{ type: 'fixedPoint', precision: 2 }"
      [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="lineTotal" caption="Total" dataType="number" [width]="100"
      [format]="{ type: 'fixedPoint', precision: 2 }" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="quantityToMove" caption="Quantity to Move" dataType="number" [width]="90"
      [allowEditing]="true">
    </dxi-column>
  </dx-data-grid>
</div>

<div class="modal-footer">
  <div>Note: Cannot move items with lengths and items will be re-priced if a new vendor is selected:</div>

  <!-- select possibly a different vendor -->
  <dx-select-box [(ngModel)]="vendorId" [showClearButton]="true" [searchEnabled]="true" [width]="300"
    [dataSource]="vendors" valueExpr="id" displayExpr="vendorName" placeholder="New Vendor">
  </dx-select-box>

  <dx-button type="success" text="Run Move" (onClick)="runCopy()"></dx-button>
</div>
