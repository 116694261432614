export const SessionName = 'TruthEngineSession';

export class TruthEngineSession {
    currentCompanyId?: number;
    areaSelected?: string;
    currentJobNo?: string;
    externalInvoiceId?: string;
    xeroCode: string;
    xeroState: string;
    quickBooksCode: string;
    quickBooksState: string;
    quickBooksRealmId: string;
    myobCode: string;
    myobState: string;
    passedCompanyId?: string;
    loginProcessed?: boolean;
    login?: boolean;
    isDebugMode?: boolean;
}
