<div class="page">

  <dx-data-grid *ngIf="!loadingData" #depositGrid id="depositGrid" [height]="gridHeight - 20" [dataSource]="dataSource"
    [allowColumnReordering]="false" [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true"
    [rowAlternationEnabled]="true" [wordWrapEnabled]="true" [(selectedRowKeys)]="selectedRecords"
    [hoverStateEnabled]="true"
    (onToolbarPreparing)="onToolbarPreparing($event, 'toolbarTemplate2', 'toolbarTemplate3')"  (onExporting)="gridService.onExporting($event, 'variations')">

    <dxo-search-panel [visible]="true" location="after" [width]="200"></dxo-search-panel>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-column-chooser [enabled]="true" mode="dragAndDrop"> </dxo-column-chooser>
    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="variation-invoices" [savingTimeout]="500">
    </dxo-state-storing>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>

    <dxo-selection selectAllMode='allPages' showCheckBoxesMode='always' mode="multiple">
    </dxo-selection>

    <dxo-editing mode="row" [allowUpdating]="true" [allowAdding]="false" [allowDeleting]="false" [useIcons]="true"
      [selectTextOnEditStart]="false" refreshMode="repaint">
    </dxo-editing>

    <dxi-column dataField="jobId" caption="Job" dataType="number" width="110" [allowEditing]="false">
      <dxo-lookup [dataSource]="jobs" displayExpr="jobNumber" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="clientName" caption="Client" dataType="string" [calculateCellValue]='calculateClientName'
      [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="siteAddress" dataType="string" [calculateCellValue]='calculateSiteAddress' [visible]="false"
      [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="variationNumber" caption="VO#" dataType="number" width="70" alignment="center"
      [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="claimDescription" caption="Variation Title / Invoice Description" dataType="string"
      [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="extraDescription" caption="Variation Extra Description" dataType="string">
    </dxi-column>

    <dxi-column dataField="variationSplitId" dataType="string" [visible]='false'>
    </dxi-column>

    <dxi-column dataField="approvedDate" caption="Approved" dataType="date" width="110" alignment="center"
      [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="invoiceDate" caption="Invoice Date" dataType="date" width="100" alignment="center"
      [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="totalExGST" dataType="number" width="100" [format]="{ type: 'fixedPoint', precision: 0 }"
      [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="totalGST" dataType="number" width="120" [format]="{ type: 'fixedPoint', precision: 0 }"
      [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="totalIncGST" dataType="number" width="120" [format]="{ type: 'fixedPoint', precision: 0 }"
      [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="connectionStatus" caption="Status" dataType="string" width="200"
      [calculateCellValue]='calculateConnectionStatus' [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="clientEmail" dataType="string" width="200"
      [calculateCellValue]='calculateClientEmail' [allowEditing]="false" [visible]="false">
    </dxi-column>

    <!-- Templates -->
    <div *dxTemplate="let data of 'toolbarTemplate2'">
      <div>
        <dx-check-box text="Submit future dated invoices" [(value)]='submitFutureInvoices'>
        </dx-check-box>
      </div>
    </div>
    <div *dxTemplate="let data of 'toolbarTemplate3'">
      <div>
        <dx-check-box text="Date invoices today if in the past" [(value)]='dateOldInvoicesToday'>
        </dx-check-box>
      </div>
    </div>

    <dxo-summary>
      <dxi-total-item column="jobId" summaryType="count" displayFormat="{0} rows">
      </dxi-total-item>
      <dxi-total-item column="totalExGST" summaryType="sum" valueFormat="#,###" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="totalGST" summaryType="sum" valueFormat="#,###" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="totalIncGST" summaryType="sum" valueFormat="#,###" displayFormat="{0}">
      </dxi-total-item>
    </dxo-summary>

  </dx-data-grid>

  <span *ngIf="!loading">{{minuteCountdown}} minute(s) before auto refresh</span>

</div>


<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [visible]="loading" [showIndicator]="true" [showPane]="true"
  [shading]="false" [hideOnOutsideClick]="false">
</dx-load-panel>
