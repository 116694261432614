import { MaintenanceService } from './maintenance.service';
import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable, of, forkJoin } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { Recipe } from '../../dtos/recipe';
// import { RecipeLine } from '../../dtos/recipe-line';
import { PriceFileItemVendorRate } from '../../dtos/price-file-item-vendor-rate';
import { District } from '../../dtos/district';
import { RecipeTypeEnum } from '../../dtos/recipe-type.enum';
import { PriceFileItem } from '../../dtos/price-file-item';
import { PriceFileItemTypeEnum } from '../../dtos/price-file-item-type.enum';
import { formatDate } from 'devextreme/localization';
import { HttpService } from '../http.service';
import { JobEstimateSummary } from '../../dtos/Job-estimate-summary';

@Injectable({
  providedIn: 'root'
})
export class EstimatingService {
  _jobItemUrl: string;
  cachCompanyDistricts: string;
  cacheEffectiveDate: string;
  cacheDistrictId: number;
  priceFileEffectiveDate: string;
  priceFileDistrictId: number;
  recipesAndItems: Recipe[]; // recipes only for combined lookup
  recipeGroups: Recipe[]; // classes only
  allRecipes: Recipe[];
  cacheCompanyRecipes: string;
  cacheCompanyRecipeLines: string;
  cacheCompanyRecipeRates: string;
  cacheCompanyPriceFileItems: string;
  allPriceFileItems: PriceFileItem[];
  priceFileItemVendorRates: PriceFileItemVendorRate[];
  cacheCompanyPriceFileItemVendorRates: string;
  cachCompanyPriceFileItemGroups: string;
  priceFileItemGroups: PriceFileItem[];
  costCentres: PriceFileItem[];

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private maintenanceService: MaintenanceService,
    private globalService: GlobalService
  ) { }



  getRecipeGroups(): Observable<Recipe[]> {
    const url = this.globalService.getApiUrl() + '/recipes?recipeTypeId=' + RecipeTypeEnum.Group + '&includeRates=false';

    return this._http.get<Recipe[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  getAllRecipes(useCache: boolean): Observable<Recipe[]> {
    if (useCache && this.allRecipes && this.allRecipes.length
      && this.cacheCompanyRecipes === this.globalService.getCurrentCompanyId()) {
      return of(this.allRecipes);
    } else {
      let url = this.globalService.getApiUrl() + '/recipes?includeRates=false';

      return this._http.get<Recipe[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.allRecipes = res.filter(i => i.recipeTypeId === RecipeTypeEnum.Recipe);
          this.recipeGroups = res.filter(i => i.recipeTypeId === RecipeTypeEnum.Group);

          // set classes and sub-groups
          this.allRecipes.forEach(recipe => {
            recipe.masterGroupCostCentre = '000000;RECIPES';

            const subGroup = this.recipeGroups.find(i => i.id === recipe.recipeParentId);
            if (subGroup) {
              recipe.subGroupItemDesc = '000000;' + subGroup.description;
            } else {
              recipe.subGroupItemDesc = 'Recipes';
            }
          });
          this.cacheCompanyRecipes = this.globalService.getCurrentCompanyId();
        }),
        catchError(this.handleError));
    }
  }

  getRecipesWithRates(useCache: boolean): Observable<Recipe[]> {
    return forkJoin(
      [this.getAllRecipes(useCache),
      this.getPriceFileItemGroups(useCache),
      this.getAllPriceFileItems(),
      this.getPriceFileItemVendorRates()]
    )
      .pipe(map(
        ([recipes]) => {
          return recipes;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getPriceFileItemGroups(useCache: boolean): Observable<PriceFileItem[]> {
    if (useCache && this.priceFileItemGroups && this.priceFileItemGroups.length
      && this.cachCompanyPriceFileItemGroups === this.globalService.getCurrentCompanyId()) {
      return of(this.priceFileItemGroups);
    } else {
      let url = this.globalService.getApiUrl() + '/price-file-items';

      url += '?priceFileItemTypeId=' + PriceFileItemTypeEnum.Group;

      url += '&includeCostCentreData=' + true;

      return this._http.get<PriceFileItem[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.priceFileItemGroups = res; this.cachCompanyPriceFileItemGroups = this.globalService.getCurrentCompanyId();
          this.costCentres = res.filter(i => !i.priceFileItemParentId);
        }),
        catchError(this.handleError));
    }
  }

  getAllPriceFileItems(): Observable<PriceFileItem[]> {
    if (this.allPriceFileItems && this.allPriceFileItems.length
      && this.cacheCompanyPriceFileItems === this.globalService.getCurrentCompanyId()) {
      return of(this.allPriceFileItems);
    } else {
      const url = this.globalService.getApiUrl() + '/price-file-items';

      return this._http.get<PriceFileItem[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.allPriceFileItems = res.filter(i => i.priceFileItemTypeId === PriceFileItemTypeEnum.Item);
          this.cacheCompanyPriceFileItems = this.globalService.getCurrentCompanyId();

          this.allPriceFileItems.forEach(item => {
            const priceFileItem = res.find(i => i.id === item.priceFileItemParentId);
            if (priceFileItem) {
              item.itemGroupDescription = priceFileItem.priceFileCode ? priceFileItem.priceFileCode + ' - ' + priceFileItem.description : priceFileItem.description;
              item.itemGroupOrderNumber = priceFileItem.orderNumber;

              const costCentre = res.find(i => i.id === priceFileItem.priceFileItemParentId);
              if (costCentre) {
                item.costCentreDescription = costCentre.priceFileCode ? costCentre.priceFileCode + ' - ' + costCentre.description : costCentre.description;
                item.costCentreOrderNumber = costCentre.orderNumber;
              }
            }
          });
        }),
        catchError(this.handleError));
    }
  }

  getPriceFileItemVendorRates(): Observable<PriceFileItemVendorRate[]> {
    if (this.priceFileItemVendorRates && this.priceFileItemVendorRates.length
      && this.cacheCompanyPriceFileItemVendorRates === this.globalService.getCurrentCompanyId()) {
      return of(this.priceFileItemVendorRates);
    } else {
      const url = this.globalService.getApiUrl() + '/price-file-item-vendor-rates';
      return this._http.get<PriceFileItemVendorRate[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.priceFileItemVendorRates = res; this.cacheCompanyPriceFileItemVendorRates = this.globalService.getCurrentCompanyId();

          // sort to get latest rate first for find queries to work
          this.priceFileItemVendorRates = this.priceFileItemVendorRates
            .sort(this.globalService.sortBy('priceFileItemId', this.globalService.sortBy('effectiveDate', false, false)));

          const itemCheck = this.priceFileItemVendorRates.find(i => i.isActive && !i.effectiveDate);

          if (itemCheck) {
            console.log('Item with no date: ' + itemCheck.priceFileCode);
            alert('Item with no date: ' + itemCheck.priceFileCode);
          }
        }),
        catchError(this.handleError));
    }
  }

  getPriceFileItemsWithRates(effectiveDate: Date, districtId: number): Observable<PriceFileItemVendorRate[]> {
    return forkJoin(
      [this.getPriceFileItemVendorRates()]
    )
      .pipe(map(
        () => {
          return this.getItemsForDateAndDistrict(effectiveDate, districtId);
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getItemsForDateAndDistrict(effectiveDate: Date, districtId: number): PriceFileItemVendorRate[] {
    let effectiveDateString = '';
    if (effectiveDate instanceof Date) {
      effectiveDateString = formatDate(effectiveDate, 'yyyy-MM-dd');
    } else {
      effectiveDateString = effectiveDate;
      effectiveDateString = effectiveDateString.slice(0, 10);
    }

    const district = this.maintenanceService.districts.find(i => i.id === districtId);

    const priceFileItemsForDateAndDistrict: PriceFileItemVendorRate[] = [];

    this.allPriceFileItems.forEach(item => {
      // we deep calc the rate for this recipe
      const itemWithRate = this.getItemWithRate(item.id, district, effectiveDateString);

      if (itemWithRate) {
        const priceFileItem = this.priceFileItemGroups.find(i => i.id === item.priceFileItemParentId);
        if (priceFileItem) {
          itemWithRate.subGroupItemDesc =
            priceFileItem.priceFileCode ? priceFileItem.priceFileCode + ' - ' + priceFileItem.description : priceFileItem?.description;
          itemWithRate.subGroupOrderNumber = priceFileItem.orderNumber;

          const costCentre = this.priceFileItemGroups.find(i => i.id === priceFileItem.priceFileItemParentId);
          if (costCentre) {
            itemWithRate.masterGroupCostCentre =
              costCentre.priceFileCode ? costCentre.priceFileCode + ' - ' + costCentre.description : costCentre.description;
            itemWithRate.groupOrderNumber = costCentre.orderNumber;
          }
        }
        priceFileItemsForDateAndDistrict.push(itemWithRate);
      }
    });

    return priceFileItemsForDateAndDistrict;
  }

  getItemWithRate(priceFileItemId: number, district: District, effectiveDateString: string): PriceFileItemVendorRate {
    const itemRec = this.priceFileItemVendorRates.find(i => i.priceFileItemId === priceFileItemId
      && i.districtId === district?.id
      && i.preferredVendorId === i.priceFileVendorId
      && i.isActive
      && i.effectiveDate.toString().slice(0, 10) <= effectiveDateString);

    if (!itemRec && district?.districtParentId) {
      // we go up to the district parent
      const parentDistrict = this.maintenanceService.districts.find(i => i.id === district.districtParentId);
      return (this.getItemWithRate(priceFileItemId, parentDistrict, effectiveDateString));
    } else if (!itemRec || (itemRec.expiryDate && itemRec.expiryDate.toString().slice(0, 10) < effectiveDateString)) {
      return null;
    } else {
      return itemRec;
    }
  }

  getVendorItemWithRate(priceFileItemId: number, district: District,
    effectiveDateString: string, vendorId: number): PriceFileItemVendorRate {
    const itemRec = this.priceFileItemVendorRates.find(i => i.priceFileItemId === priceFileItemId
      && i.districtId === district?.id
      && i.vendorId === vendorId
      && i.isActive
      && i.effectiveDate.toString().slice(0, 10) <= effectiveDateString);

    if (!itemRec && district?.districtParentId) {
      // we go up to the district parent
      const parentDistrict = this.maintenanceService.districts.find(i => i.id === district.districtParentId);
      return (this.getVendorItemWithRate(priceFileItemId, parentDistrict, effectiveDateString, vendorId));
    } else if (!itemRec) {
      return null;
    } else {
      return itemRec;
    }
  }


  getDistrictPreferredRate(district: District, priceFileItemId: number, currentCostingDateString: string): number {
    const itemRec = this.priceFileItemVendorRates.find(i => i.priceFileItemId === priceFileItemId
      && i.districtId === district.id
      && i.preferredVendorId === i.priceFileVendorId
      && i.isActive
      && i.effectiveDate.toString().slice(0, 10) <= currentCostingDateString);

    if (!itemRec && district.districtParentId) {
      // we go up to the district parent
      const parentDistrict = this.maintenanceService.districts.find(i => i.id === district.districtParentId);
      return (this.getDistrictPreferredRate(parentDistrict, priceFileItemId, currentCostingDateString));
    } else if (!itemRec || (itemRec.expiryDate && itemRec.expiryDate.toString().slice(0, 10) < currentCostingDateString)) {
      return null;
    } else {
      return itemRec.rate;
    }
  }

  getPriceFileItemForVendor(priceFileItemId: number, vendorId: number, baseDate: Date, districtId: number): PriceFileItemVendorRate {
    const district = this.maintenanceService.districts.find(i => i.id === districtId);
    let effectiveDateString = '';
    if (baseDate instanceof Date) {
      effectiveDateString = formatDate(baseDate, 'yyyy-MM-dd');
    } else {
      effectiveDateString = baseDate;
      effectiveDateString = effectiveDateString.substr(0, 10);
    }

    return this.getVendorItemWithRate(priceFileItemId,
      district, effectiveDateString, vendorId);
  }

  calculateRate(originalRate: number, unitOfMeasure: string): number {
    let newRate = originalRate ? originalRate : 0;

    const uom = this.maintenanceService.unitOfMeasures.find(i => i.description === unitOfMeasure);
    if (uom && uom.costIsPer) {
      newRate /= uom.costIsPer;
    }
    return newRate;
  }

  getEnterKeyActions() {
    return ['startEdit', 'moveFocus'];
  }
  getEnterKeyDirections() {
    return ['none', 'column', 'row'];
  }

  copyOrderLinesToRecipe(jobId: number, recipeData: any) {
    const url = this.globalService.getApiUrl() + '/order-lines/' + jobId + '/copy-to-recipe';
    return this._http.post(url, JSON.stringify(recipeData), this.httpService.getHttpOptions());
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }

  getJobEstimateSummaries(jobId: number): Observable<JobEstimateSummary[]> {
    const url = this.globalService.getApiUrl() + '/job-estimate-summaries/' + jobId + '/all';

    return this._http.get<JobEstimateSummary[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }
}
