import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'js-subbie-invoice-date-check',
  templateUrl: './subbie-invoice-date-check.component.html',
  styleUrls: ['./subbie-invoice-date-check.component.scss']
})
export class SubbieInvoiceDateCheckComponent implements OnInit {
  @Input() invoiceDate: Date;
  @Input() dueDate: Date;
  @Input() dueDateFromEntry: Date;
  acceptDueDate: string;
  acceptDueDateFromEntry: string;

  constructor(
    private activeModal: NgbActiveModal
  ) {  }

  ngOnInit() {
    this.acceptDueDate = 'Accept due date from invoice date ' + this.dueDate.toLocaleDateString();
    this.acceptDueDateFromEntry = 'Accept calculated due date ' + this.dueDateFromEntry.toLocaleDateString();
  }

  accept() {
    this.activeModal.dismiss();
  }

  close() {
    this.activeModal.close();
  }

}
