import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { filter, takeUntil } from 'rxjs/operators';

import { Subject } from 'rxjs';
import { NotificationService } from '../services/notification.service';
import { AuthService } from '../services/auth.service';
import { LogService } from '../services/log.service';
import { GlobalService } from '../services/global.service';
import { environment } from '../../environments/environment';


@Component({
  selector: 'js-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit, OnDestroy {

  isHttps = true;
  activelyLoggingIn = false;
  showLoginButton = false;
  private readonly _destroying$ = new Subject<void>();
  showRefreshButton = false;
  isAlive = true;
  currentVersion: string;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private broadcastService: MsalBroadcastService,
    private msalService: MsalService,
    private router: Router,
    private auth: AuthService,
    private notiService: NotificationService,
    private logger: LogService,
    private globalService: GlobalService
  ) { }

  ngOnInit() {
    // will get cryptic msal errors if trying to login from http
    if (!this.globalService.isHttps()) {
      this.isHttps = false;
    }

    this.currentVersion = environment.fullVersion;

    this.msalService.instance.enableAccountStorageEvents();
    this.broadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
        takeUntil(this._destroying$)
      )
      .subscribe({
        next: () => {
          if (this.msalService.instance.getAllAccounts().length === 0) {
            window.location.pathname = '/';
          } else {
            this.setLoginDisplay();
          }
        }, error: () => {
          // error - reload
          window.location.reload();
        }
      });

    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe({
        next: () => {
          this.setLoginDisplay();
          this.checkAndSetActiveAccount();
        }, error: () => {
          // error - reload
          window.location.reload();
        }
      });


    setTimeout(() => {
      this.showRefreshButton = true;
    }, 2000); // wait for msal as it's possibly reading

    setTimeout(() => {
      if (!this.showLoginButton && !this.activelyLoggingIn && this.isHttps && this.showRefreshButton && this.isAlive) {
        this.refresh();
      }
    }, 8000); // time out
  }

  setLoginDisplay() {
    this.showLoginButton = this.msalService.instance.getAllAccounts().length <= 0;
  }

  checkAndSetActiveAccount() {
    const activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      const accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }

    const allAccounts = this.msalService.instance.getAllAccounts();

    if (allAccounts.length !== 0 && (allAccounts.length > 1 || !allAccounts[0] || !allAccounts[0].idTokenClaims || !allAccounts[0].idTokenClaims['emails'])) {
      this.auth.signOut();
      this.setLoginDisplay();
    } else if (allAccounts.length > 0) {
      const email = allAccounts[0].idTokenClaims['emails'][0];
      this.auth.setUserEmail(email);

      this.activelyLoggingIn = false;
      this.router.navigateByUrl('welcome');
    } else {
      setTimeout(() => {
        this.showLoginButton = true;
      }, 2000); // wait for msal as it's possibly reading
      if (this.activelyLoggingIn) {
        this.notiService.showError('There was an error logging you in. Please try again in a moment.');
        this.activelyLoggingIn = false;
      }
    }
  }

  loginClicked() {
    this.showLoginButton = false;
    this.activelyLoggingIn = true;
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.msalService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          this.msalService.instance.setActiveAccount(response.account);
        });
    } else {
      this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    }
  }

  goToHttps() {
    window.location.href = environment.applicationUrl;
  }

  ngOnDestroy() {
    this._destroying$.next(null);
    this._destroying$.complete();
    this.isAlive = false;
  }

  refresh(): void {
    window.location.reload();
  }
}
