<dx-tab-panel #panel height="calc(100vh - 50px)" [dataSource]="sections" [selectedIndex]="0" [loop]="false"
  [swipeEnabled]="false" (onTitleClick)="tabSelected($event)">

  <div *dxTemplate="let title of 'title'">
    <span>{{title}}</span>
  </div>

  <div *dxTemplate="let section of 'item'">
    <div style="height: calc(100vh - 100px);">
      <js-margins-report *ngIf="section === 'Margins'"></js-margins-report>
      <js-wip-report *ngIf="section === 'WIP Report'"></js-wip-report>
      <js-accruals *ngIf="section === 'Accruals'"></js-accruals>
      <js-extras *ngIf="section === 'Extras'"></js-extras>
    </div>
  </div>
</dx-tab-panel>
