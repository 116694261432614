import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { HttpService } from '../http.service';
import { InvoiceLoadError } from '../../dtos/invoice-load-error';
import { OrderLoadError } from '../../dtos/order-load-error';
import { ClaimLoadError } from '../../dtos/claim-load-error';
import { formatDate } from 'devextreme/localization';

@Injectable({
  providedIn: 'root'
})
export class IntegrationService {

  constructor(
    private _http: HttpClient,
    private globalService: GlobalService,
    private httpService: HttpService
  ) { }


  // load history invoices for company take-up - super user only
  loadHistoryInvoices(loadFile, ignoreExistingInvoices: number, skipMissingJobs: boolean, includeCompletionsFrom: Date): Observable<InvoiceLoadError[]> {
    const options = this.httpService.getHttpFileOptions();
    const url = this.globalService.getApiUrl() + '/integration/load-invoices/tab-delimited?ignoreExistingInvoices=' + ignoreExistingInvoices
      + '&skipMissingJobs=' + skipMissingJobs
      + '&includeCompletionsFrom=' + formatDate(includeCompletionsFrom, 'yyyy-MM-dd');

    return this._http.post<InvoiceLoadError[]>(url, loadFile, options)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  // load history orders for company take-up - super user only
  loadHistoryOrders(loadFile, skipWhereOrdersExist: boolean, skipMissingJobs: boolean,
    includeCompletionsFrom: Date): Observable<OrderLoadError[]> {
    const options = this.httpService.getHttpFileOptions();
    const url = this.globalService.getApiUrl() + '/integration/load-orders?skipWhereOrdersExist=' + skipWhereOrdersExist
      + '&skipMissingJobs=' + skipMissingJobs
      + '&includeCompletionsFrom=' + formatDate(includeCompletionsFrom, 'yyyy-MM-dd');

    return this._http.post<OrderLoadError[]>(url, loadFile, options)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  // load history orders for company take-up - super user only
  loadHistoryOrderLines(loadFile, skipWhereOrdersExist: boolean, loadingUnorderedLines: boolean,
    defaultVendorCode: string, skipMissingJobs: boolean, skipInactiveJobs: boolean,
    includeCompletionsFrom: Date): Observable<OrderLoadError[]> {
    const options = this.httpService.getHttpFileOptions();
    let url = this.globalService.getApiUrl() + '/integration/load-order-lines?skipWhereOrdersExist=' + skipWhereOrdersExist
      + '&skipMissingJobs=' + skipMissingJobs
      + '&skipInactiveJobs=' + skipInactiveJobs
      + '&includeCompletionsFrom=' + formatDate(includeCompletionsFrom, 'yyyy-MM-dd');

    if (loadingUnorderedLines) {
      url = this.globalService.getApiUrl() + '/integration/load-unordered-lines?defaultVendorCode=' + defaultVendorCode
        + '&skipWhereItemExists=' + skipWhereOrdersExist + '&skipMissingJobs=' + skipMissingJobs;
    }

    return this._http.post<OrderLoadError[]>(url, loadFile, options)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  // load history claims for company take-up - super user only
  loadHistoryClaims(loadFile, skipWhereClaimsExists: boolean, skipMissingJobs: boolean,
    assumeMissingActivitiesAreDeposit: boolean,
    skipInactiveJobs: boolean): Observable<ClaimLoadError[]> {
    const options = this.httpService.getHttpFileOptions();
    const url = this.globalService.getApiUrl() + '/integration/load-claim-lines?skipWhereClaimsExists=' + skipWhereClaimsExists
      + '&skipMissingJobs=' + skipMissingJobs
      + '&assumeMissingActivitiesAreDeposit=' + assumeMissingActivitiesAreDeposit
      + '&skipInactiveJobs=' + skipInactiveJobs;

    return this._http.post<ClaimLoadError[]>(url, loadFile, options)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  // load history claims for company take-up - super user only
  loadHistoryVariations(loadFile, skipMissingJobs: boolean, skipInactiveJobs: boolean): Observable<ClaimLoadError[]> {
    const options = this.httpService.getHttpFileOptions();
    const url = this.globalService.getApiUrl()
      + '/integration/load-variations-invoiced?skipMissingJobs=' + skipMissingJobs
      + '&skipInactiveJobs=' + skipInactiveJobs;

    return this._http.post<ClaimLoadError[]>(url, loadFile, options)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  // load history claims for company take-up - super user only
  loadHistoryClientManagerVariations(loadFile, skipMissingJobs: boolean, skipInactiveJobs: boolean): Observable<ClaimLoadError[]> {
    const options = this.httpService.getHttpFileOptions();
    const url = this.globalService.getApiUrl()
      + '/integration/load-client-manager-variations?skipMissingJobs=' + skipMissingJobs
      + '&skipInactiveJobs=' + skipInactiveJobs;

    return this._http.post<ClaimLoadError[]>(url, loadFile, options)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  // load history claims for company take-up - super user only
  loadHistoryVariationHeaders(loadFile, skipMissingJobs: boolean, skipInactiveJobs: boolean): Observable<ClaimLoadError[]> {
    const options = this.httpService.getHttpFileOptions();
    const url = this.globalService.getApiUrl()
      + '/integration/load-variation-headers?skipMissingJobs=' + skipMissingJobs
      + '&skipInactiveJobs=' + skipInactiveJobs;

    return this._http.post<ClaimLoadError[]>(url, loadFile, options)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  // load history claims for company take-up - super user only
  loadHistoryStopAccruals(loadFile, skipMissingCostCentres: boolean): Observable<OrderLoadError[]> {
    const options = this.httpService.getHttpFileOptions();
    const url = this.globalService.getApiUrl() + '/integration/load-budget-accruals?skipMissingCostCentres=' + skipMissingCostCentres;

    return this._http.post<OrderLoadError[]>(url, loadFile, options)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  // load databuild price file
  loadDataBuildPriceFile(loadFile, skipMissingCostCentres: boolean): Observable<OrderLoadError[]> {
    const options = this.httpService.getHttpFileOptions();
    const url = this.globalService.getApiUrl() + '/integration/load-databuild-price-file?skipMissingCostCentres=' + skipMissingCostCentres;

    return this._http.post<OrderLoadError[]>(url, loadFile, options)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  // load addenda write file
  loadAddendaWriterFile(loadFile, jobNumber: string, deleteExistingJobLines: boolean): Observable<OrderLoadError[]> {
    const options = this.httpService.getHttpFileOptions();
    const url = this.globalService.getApiUrl() + '/integration/load-addenda-writer-file?jobNumber=' + jobNumber
      + '&deleteExistingJobLines=' + deleteExistingJobLines;

    return this._http.post<OrderLoadError[]>(url, loadFile, options)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  // load history jobs
  loadHistoryJobs(loadFile, skipExistingJobs: boolean): Observable<ClaimLoadError[]> {
    const options = this.httpService.getHttpFileOptions();
    const url = this.globalService.getApiUrl() + '/integration/load-jobs?skipExistingJobs=' + skipExistingJobs;

    return this._http.post<ClaimLoadError[]>(url, loadFile, options)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  // load history jobs - key dates
  loadHistoryJobKeyDates(loadFile, skipMissingJobs: boolean): Observable<ClaimLoadError[]> {
    const options = this.httpService.getHttpFileOptions();
    const url = this.globalService.getApiUrl() + '/integration/load-key-dates?skipMissingJobs=' + skipMissingJobs;

    return this._http.post<ClaimLoadError[]>(url, loadFile, options)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  // load history tasks
  loadHistoryTasks(loadFile, skipMissingJobs: boolean,
    ignoreMissingTaskWorkflows: boolean, ignoreMissingVendors: boolean): Observable<ClaimLoadError[]> {
    const options = this.httpService.getHttpFileOptions();
    const url = this.globalService.getApiUrl() + '/integration/load-tasks?skipMissingJobs=' + skipMissingJobs
      + '&ignoreMissingTaskWorkflows=' + ignoreMissingTaskWorkflows
      + '&ignoreMissingVendors=' + ignoreMissingVendors;

    return this._http.post<ClaimLoadError[]>(url, loadFile, options)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  // load history tracking field data
  loadHistoryTrackingFieldData(loadFile, skipMissingJobs: boolean): Observable<ClaimLoadError[]> {
    const options = this.httpService.getHttpFileOptions();
    const url = this.globalService.getApiUrl() + '/integration/load-tracking-fields?skipMissingJobs=' + skipMissingJobs;

    return this._http.post<ClaimLoadError[]>(url, loadFile, options)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  // load users
  loadHistoryUsers(loadFile, skipExistingUsers: boolean): Observable<InvoiceLoadError[]> {
    const options = this.httpService.getHttpFileOptions();
    const url = this.globalService.getApiUrl() + '/integration/load-users?skipExistingUsers=' + skipExistingUsers;

    return this._http.post<InvoiceLoadError[]>(url, loadFile, options)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
