<div class="page">

  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <dx-data-grid *ngIf="!loading" id="purchaseOrdersGrid" class="m-auto" [height]="gridHeight" [dataSource]="dataSource"
    [allowColumnReordering]="true" [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true"
    [rowAlternationEnabled]="true" [wordWrapEnabled]="true"
    (onToolbarPreparing)="onToolbarPreparing($event, 'toolbarTemplate')"
    (onExporting)="gridService.onExporting($event, 'po-query')">

    <dxo-search-panel [visible]="true" location="before" [width]="250"></dxo-search-panel>
    <dxo-scrolling mode="virtual"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-editing mode="row" [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false" [useIcons]="true"
      refreshMode="repaint">
    </dxo-editing>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-column-chooser [enabled]="true" mode="dragAndDrop"> </dxo-column-chooser>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>

    <dxi-column dataField="orderNumber" width="120" [allowEditing]="false" sortOrder="asc"></dxi-column>

    <dxi-column dataField="date" dataType="date" width="100" format="d/M/yy" alignment="center"
      [allowEditing]="false"></dxi-column>

    <dxi-column dataField="supplierInvoiceNumber" width="100" [allowEditing]="false"></dxi-column>

    <dxi-column dataField="supplier.name" caption="Vendor" [allowEditing]="false"> </dxi-column>

    <dxi-column dataField="shipToAddress" [allowEditing]="false"></dxi-column>

    <dxi-column dataField="isTaxInclusive" width="100" [allowEditing]="false"> </dxi-column>

    <dxi-column *ngIf="accountingSystemType === 3" caption="Job Number" dataField="job.number" width="100"
      [allowEditing]="false"> </dxi-column>

    <dxi-column *ngIf="accountingSystemType === 3" caption="Job Name" dataField="job.name" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="subtotal" width="100" [allowEditing]="false"> </dxi-column>

    <dxi-column dataField="totalTax" width="100" [allowEditing]="false"> </dxi-column>

    <dxi-column dataField="comment" [allowEditing]="false"> </dxi-column>

    <dxi-column dataField="status" width="100" [allowEditing]="false"> </dxi-column>

    <!-- summary totals -->
    <dxo-summary>
      <dxi-total-item column="orderNumber" summaryType="count" displayFormat="{0} rows">
      </dxi-total-item>
      <dxi-total-item column="subtotal" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="totalTax" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}">
      </dxi-total-item>
    </dxo-summary>

    <!-- templates -->
    <div *dxTemplate="let data of 'toolbarTemplate'">
      <div>
        Company: <dx-select-box id="selectAccountingCompany" [dataSource]="accountingCompanies" displayExpr="tenantName"
          valueExpr="tenantId" placeholder="Select Accounting Company" [width]="200"
          [(value)]="accountingSystemTenantId" (onValueChanged)="onCompanySelectionChanged()">
        </dx-select-box>
        <span *ngIf="accountingSystemType === 3"> Job: <dx-drop-down-box id="selectJob" [deferRendering]="false"
            [dataSource]="jobs" displayExpr="number" valueExpr="id" [showClearButton]="true" placeholder="Select job"
            contentTemplate="contentTemplate" [width]="150" [value]="selectedJobId"
            [dropDownOptions]="{ minWidth: 500 }" (onValueChanged)="onJobValueChanged($event)">
            <div *dxTemplate="let e of 'contentTemplate'">
              <dx-data-grid [dataSource]="jobs" [hoverStateEnabled]="true" showBorders="true" height="100%" keyExpr="id"
                [focusedRowKey]="selectedJobId" (onSelectionChanged)="onJobSelectionChanged(e.component, $event)">
                <dxo-selection mode="single"></dxo-selection>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-scrolling mode="virtual"></dxo-scrolling>
                <dxo-paging [enabled]="false"></dxo-paging>
                <dxi-column dataField="number" [width]="150"></dxi-column>
                <dxi-column dataField="name"></dxi-column>
              </dx-data-grid>
            </div>
          </dx-drop-down-box></span>
        Vendor: <dx-select-box id="selectVendor" [dataSource]="vendors" displayExpr="vendorName" valueExpr="id"
          [showClearButton]="true" placeholder="Select vendor" [width]="150" [(value)]="selectedVendorId"
          [searchEnabled]="true" (onValueChanged)="onVendorSelectionChanged($event)"
          [dropDownOptions]="{ minWidth: 500 }">
        </dx-select-box>
        Last Modified: <dx-date-box id="lastModifiedDateBox" [width]="150" [(value)]="lastModifiedDate"></dx-date-box>
        Max. Orders to Retrieve: <dx-number-box id="maxPurchaseOrders" [width]="100"
          [(value)]="maxPurchaseOrders"></dx-number-box>
      </div>
    </div>
  </dx-data-grid>

</div>

<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loadingAccounts" [showIndicator]="true"
  [showPane]="true" [shading]="true" [hideOnOutsideClick]="false">
</dx-load-panel>
