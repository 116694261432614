import { IntegrationService } from './../../../services/felixApi/integration.service';
import { NotificationService } from './../../../services/notification.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { GridService } from '../../../services/grid.service';
import { Subscription } from 'rxjs';
import CustomStore from 'devextreme/data/custom_store';
import { InvoiceLoadError } from '../../../dtos/invoice-load-error';

@Component({
  selector: 'js-invoice-history-load',
  templateUrl: './invoice-history-load.component.html',
  styleUrls: ['./invoice-history-load.component.scss']
})
export class InvoiceHistoryLoadComponent implements OnInit, OnDestroy {

  dataSource: CustomStore;
  subscriptions: Subscription[] = [];
  loadingFile = false;
  fileLoaded = false;
  fileValue: any[] = [];
  errors: InvoiceLoadError[];
  gridHeight: number;
  ignoreExistingInvoices = 1;
  includeCompletionsFrom: Date;
  skipMissingJobs = false;
  ignoreExistingInvoicesOptions: { id: number; name: string; }[];

  constructor(
    protected gridService: GridService,
    private notiService: NotificationService,
    private integrationService: IntegrationService
  ) { }

  ngOnInit(): void {
    this.ignoreExistingInvoicesOptions = [
      { id: 1, name: 'Fail on duplicate' },
      { id: 2, name: 'Skip duplicate' },
      { id: 3, name: 'Allow and load duplicate' }
    ];
    this.setUpDataSource();
    this.gridHeight = window.innerHeight - 360;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  loadFileGo() {
    this.loadingFile = true;
    this.fileLoaded = false;

    const formData: FormData = new FormData();
    formData.append('image', this.fileValue[0], this.fileValue[0].name);

    this.subscriptions.push(
      this.integrationService.loadHistoryInvoices(formData, this.ignoreExistingInvoices, this.skipMissingJobs, this.includeCompletionsFrom)
        .subscribe({
          next: (errors) => {
            this.errors = errors;
            this.loadingFile = false;
            this.fileLoaded = true;
            this.setUpDataSource();
          }, error: (err) => {
            this.notiService.notify(err);
            this.fileValue = [];
            this.loadingFile = false;
            this.errors = [];
            this.setUpDataSource();
          }
        })
    );
  }

  setUpDataSource() {
    this.dataSource = new CustomStore({
      key: 'rowNumber',
      load: async () => this.errors
    });
  }
}
