import { DivisionService } from './../../services/felixApi/division.service';
import { Component, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { JobService } from '../../services/felixApi/job.service';
import { OrderHeader, OrderHeaderForm } from '../../dtos/order-header';
import { District } from '../../dtos/district';
import { HouseType } from '../../dtos/house-type';
import { MaintenanceService } from '../../services/felixApi/maintenance.service';
import { Subscription } from 'rxjs';
import { PoService } from '../../services/felixApi/po.service';
import { NotificationService } from '../../services/notification.service';
import { DxFormComponent } from 'devextreme-angular';
import { of } from 'rxjs';
import { formatDate } from 'devextreme/localization';

@Component({
  selector: 'js-order-header-modal',
  templateUrl: './order-header-modal.component.html',
  styleUrls: ['./order-header-modal.component.scss']
})
export class OrderHeaderModalComponent implements OnInit, OnDestroy {
  @Input() jobId: number;
  @Input() orderHeader: OrderHeader;

  @ViewChild(DxFormComponent, { static: false }) form: DxFormComponent;

  subscriptions: Subscription[] = [];
  orderHeaderForm: OrderHeaderForm;
  loading = false;
  updatedData: any;
  districts: District[];
  houseTypes: HouseType[];

  updateButtonOptions = {
    text: 'Save',
    type: 'default',
    stylingMode: 'contained',
    onClick: () => {
      this.update();
    },
    useSubmitBehavior: true
  };

  currentBaseDate: Date;
  currentDistrictId: number;
  createHeader = false; // is we are creating we ask
  isLockedDateDisabled: boolean;
  handoverComplete: boolean;
  checkBoxOptions: { disabled: boolean; onValueChanged: ({ value }: { value: boolean; }) => void; };

  constructor(
    private _activeModal: NgbActiveModal,
    private maintenanceService: MaintenanceService,
    private notiService: NotificationService,
    private jobService: JobService,
    private poService: PoService,
    private divisionService: DivisionService
  ) {
    this.compareDates = this.compareDates.bind(this);
    this.compareDistricts = this.compareDistricts.bind(this);
  }

  ngOnInit() {
    this.houseTypes = this.jobService.houseTypes;
    this.districts = this.maintenanceService.districts;
    this.handoverComplete = this.jobService.currentJobExtra?.handoverDate ? true : false;

    this.checkBoxOptions = {
      disabled: this.handoverComplete && (!this.orderHeader || this.orderHeader.isLocked) ? true : false,
      onValueChanged: ({ value }: { value: boolean }) => {
        this.isLockedClicked(value);
      }
    };

    this.orderHeaderForm = {
      baseDate: this.orderHeader ? this.orderHeader.baseDate : new Date(),
      districtId: null,
      recipeId: this.orderHeader?.recipeId,
      isLocked: this.orderHeader ? this.orderHeader.isLocked : this.handoverComplete ? true : false,
      houseTypeId: this.jobService.currentJob?.houseTypeId,
      jobComment: this.orderHeader?.jobComment,
      lockedDate: this.orderHeader?.lockedDate
    };

    this.isLockedDateDisabled = this.orderHeader && this.orderHeader.isLocked ? false : true;
    this.currentBaseDate = this.orderHeaderForm.baseDate;

    if (this.orderHeader) {
      this.orderHeaderForm.districtId = this.orderHeader.districtId;
      this.currentDistrictId = this.orderHeaderForm.districtId;
    } else if (this.jobService.currentJobExtra?.districtId) {
      this.orderHeaderForm.districtId = this.jobService.currentJobExtra.districtId;
      this.currentDistrictId = this.orderHeaderForm.districtId;
    } else {
      this.getDefaultDistrict();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getDefaultDistrict() {
    this.loading = true;

    this.subscriptions.push(
      this.divisionService.getDivisions(false)
        .subscribe({
          next: (divisions) => {
            const currentDivision = divisions.find(d => d.id === this.jobService.currentJob.divisionId);
            if (currentDivision) {
              this.orderHeaderForm.districtId = currentDivision.defaultDistrictId;
              this.currentDistrictId = this.orderHeaderForm.districtId;
            }
            this.loading = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }


  update() {
    if (this.orderHeader) {
      // patch
      this.loading = true;
      this.updatedData = {};
      let dataToUpdate = false;

      if (this.orderHeader.baseDate !== this.orderHeaderForm.baseDate) {
        this.updatedData.baseDate = this.orderHeaderForm.baseDate;
        dataToUpdate = true;
      }

      if (this.orderHeader.districtId !== this.orderHeaderForm.districtId) {
        this.updatedData.districtId = this.orderHeaderForm.districtId;
        dataToUpdate = true;
      }

      if (this.orderHeader.isLocked !== this.orderHeaderForm.isLocked) {
        this.updatedData.isLocked = this.orderHeaderForm.isLocked;
        dataToUpdate = true;
      }

      if (this.orderHeader.jobComment !== this.orderHeaderForm.jobComment) {
        this.updatedData.jobComment = this.orderHeaderForm.jobComment;
        dataToUpdate = true;
      }

      if (this.orderHeader.lockedDate !== this.orderHeaderForm.lockedDate) {
        if (this.orderHeaderForm.lockedDate && this.orderHeaderForm.lockedDate instanceof Date) {
          this.updatedData.lockedDate = formatDate(this.orderHeaderForm.lockedDate, 'yyyy-MM-dd');
        } else {
          this.updatedData.lockedDate = this.orderHeaderForm.lockedDate;
        }
        dataToUpdate = true;
      }

      if (dataToUpdate) {
        this.subscriptions.push(
          this.poService.updateOrderHeader(this.jobId, this.updatedData)
            .subscribe(
              orderHeader => {
                this._activeModal.close(orderHeader);
              },
              err => {
                this.notiService.notify(err);
                this.loading = false;
              })
        );
      } else {
        this._activeModal.close(this.orderHeader);
      }
    } else {
      // add
      this.loading = true;

      this.updatedData = {};
      this.updatedData.jobId = this.jobId;
      this.updatedData.baseDate = this.orderHeaderForm.baseDate;
      this.updatedData.districtId = this.orderHeaderForm.districtId;
      this.updatedData.isLocked = this.orderHeaderForm.isLocked;
      this.updatedData.jobComment = this.orderHeaderForm.jobComment;
      this.updatedData.isDeleted = false;

      if (this.orderHeaderForm.lockedDate instanceof Date) {
        this.updatedData.lockedDate = formatDate(this.orderHeaderForm.lockedDate, 'yyyy-MM-dd');
      } else {
        this.updatedData.lockedDate = this.orderHeaderForm.lockedDate;
      }

      this.subscriptions.push(
        this.poService.addOrderHeader(this.updatedData)
          .subscribe(
            orderHeader => {
              this._activeModal.close(orderHeader);
            },
            err => {
              this.notiService.notify(err);
              this.loading = false;
            })
      );
    }
  }


  compareDates(e) {
    if (this.currentBaseDate && e.value.toString() !== this.currentBaseDate.toString()) {
      this.notiService.showWarning('Changing the base date will force a recost of unordered lines');
    }
    return of(e.value).toPromise();
  }

  compareDistricts(e) {
    if (this.currentDistrictId && e.value !== this.currentDistrictId) {
      this.notiService.showWarning('Changing the price book will force a recost of unordered lines');
    }
    return of(e.value).toPromise();
  }

  cancel() {
    this._activeModal.dismiss();
  }

  isLockedClicked(e) {
    if (e) {
      this.orderHeaderForm.lockedDate = new Date();
      this.isLockedDateDisabled = false;

      // check we have any zero value lines
      const zeroItems = this.poService.orderLines.find(i => !i.rate && !i.purchaseOrderId);

      if (zeroItems) {
        this.notiService.showWarning('Note: There are items with a zero rate that will be ignored');
      }

    } else {
      this.orderHeaderForm.lockedDate = null;
      this.isLockedDateDisabled = true;
    }
  }
}
