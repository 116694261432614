import { JobService } from './../../services/felixApi/job.service';
import { GlobalService } from './../../services/global.service';
import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '../../services/notification.service';
import { EstimatingService } from '../../services/felixApi/estimating.service';
import { Subscription } from 'rxjs';
import { RecipeTypeEnum } from '../../dtos/recipe-type.enum';
import { Recipe } from '../../dtos/recipe';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DxTreeListComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'js-save-to-recipe',
  templateUrl: './save-to-recipe.component.html',
  styleUrls: ['./save-to-recipe.component.scss']
})
export class SaveToRecipeComponent implements OnInit, OnDestroy {

  loading = false;
  subscriptions: Subscription[] = [];
  recipeId: number;
  recipeClasses: Recipe[];
  recipeForm: UntypedFormGroup;
  treeGrid: DxTreeListComponent;
  treeListDataSource: CustomStore;
  recipeParentIds: any[] = [];

  @ViewChild('treeList') treeList: DxTreeListComponent;
  maxTreeHeight: number;

  constructor(private _activeModal: NgbActiveModal,
    private jobService: JobService,
    private notiService: NotificationService,
    private _formBuilder: UntypedFormBuilder,
    private globalService: GlobalService,
    private estimatingService: EstimatingService) { }

  ngOnInit() {
    this.maxTreeHeight = window.innerHeight - 300;
    this.getRecipeClasses();
    this.recipeForm = this._formBuilder.group({
      recipeCode: ['', Validators.required],
      description: ['', Validators.required]
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  getRecipeClasses() {
    this.treeListDataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.estimatingService.getRecipeGroups().subscribe(res => {
            return resolve(res);
          }, err => {
            return reject(this.globalService.returnError(err));
          }));
      }
    });
  }

  dismissModal() {
    this._activeModal.dismiss();
  }

  runCopy() {
    // check for existing recipe code
    const existingRecipe = this.estimatingService.allRecipes.filter(i => i.recipeCode === this.recipeForm.get('recipeCode').value);

    if (existingRecipe && existingRecipe[0]) {
      this.notiService.showInfo('Recipe code already exists');
    } else {
      this.loading = true;

      const recipeData = {
        recipeParentId: this.recipeParentIds[0],
        recipeTypeId: RecipeTypeEnum.Recipe,
        recipeCode: this.recipeForm.get('recipeCode').value,
        description: this.recipeForm.get('description').value
      };

      this.subscriptions = this.subscriptions.concat(
        this.estimatingService.copyOrderLinesToRecipe(this.jobService.currentJob.id, recipeData).subscribe(res => {
          this.notiService.showSuccess('Recipe saved');
          this._activeModal.close();
        }, err => {
          this.notiService.notify(err);
          this.loading = false;
        })
      );
    }
  }
}
