<dx-tab-panel #panel height="calc(100vh - 50px)" [dataSource]="sections" [selectedIndex]="selectedIndex" [loop]="false"
  [swipeEnabled]="false" (onTitleClick)="tabSelected($event)" width="99.9%">

  <div *dxTemplate="let title of 'title'">
    <span>{{title}}</span>
  </div>

  <div *dxTemplate="let section of 'item'">
    <div style="height: calc(100vh - 100px);">
      <js-variance-code *ngIf="section === 'Extras Codes'"></js-variance-code>
      <js-variance-reason *ngIf="section === 'Extras Reasons'"></js-variance-reason>
      <js-order-control *ngIf="section === 'Control'"></js-order-control>
      <js-divisions *ngIf="section === 'Job Divisions'"></js-divisions>
      <js-company-activities *ngIf="section === 'Activities'"></js-company-activities>
      <js-gl-account-type *ngIf="section === 'Vendor Types'"></js-gl-account-type>
      <js-division-account *ngIf="section === 'Division Accounts'" [divisionAccountCounter]=divisionAccountCounter></js-division-account>
      <js-division-product *ngIf="section === 'Division Products'" [divisionProductCounter]=divisionProductCounter></js-division-product>
      <js-vendor-group *ngIf="section === 'Vendor Groups'"></js-vendor-group>
      <js-job-accounting-customer *ngIf="section === 'Job-Accounting Customers'" [jobAccountingCustomerCounter]=jobAccountingCustomerCounter></js-job-accounting-customer>
      <js-purchase-order-query *ngIf="section === 'Purchase Order Query'" [purchaseOrderQueryCounter]=purchaseOrderQueryCounter></js-purchase-order-query>
      <js-invoice-query *ngIf="section === 'Invoice Query'" [invoiceQueryCounter]=invoiceQueryCounter></js-invoice-query>
      <js-order-import *ngIf="section === 'Order Import'" [orderImportCounter]=orderImportCounter></js-order-import>
      <js-income-invoice-query *ngIf="section === 'Income Invoice Query'" [incomeInvoiceQueryCounter]=incomeInvoiceQueryCounter></js-income-invoice-query>
      <js-invoice-history-load *ngIf="section === 'Invoice History Load'"></js-invoice-history-load>
      <js-order-history-load *ngIf="section === 'Order History Load'"></js-order-history-load>
      <js-order-line-history-load *ngIf="section === 'Order Line History Load'"></js-order-line-history-load>
      <js-claim-history-load *ngIf="section === 'Claim History Load'"></js-claim-history-load>
      <js-variation-history-load *ngIf="section === 'Variation History Load'"></js-variation-history-load>
      <js-budget-no-accuals *ngIf="section === 'Accrual History Load'"></js-budget-no-accuals>
      <js-databuild-price-file *ngIf="section === 'DataBuild Price File'"></js-databuild-price-file>
      <js-addenda-writer-load *ngIf="section === 'Addenda Writer'"></js-addenda-writer-load>
      <js-tasks-load *ngIf="section === 'Tasks'"></js-tasks-load>
      <js-job-load *ngIf="section === 'Jobs'"></js-job-load>
      <js-tracking-field-data *ngIf="section === 'Field Data'"></js-tracking-field-data>
      <js-job-key-dates *ngIf="section === 'Key Dates'"></js-job-key-dates>
      <js-users-load *ngIf="section === 'Users'"></js-users-load>
    </div>
  </div>
</dx-tab-panel>
