export class OrderLine {
  id: number;
  orderHeaderId: number;
  costCentreId: number;
  recipeItemId: number;
  priceFileItemId: number;
  description: string;
  unitOfMeasure: string;
  hasSizes: boolean;
  quantityString: string;
  measuredQuantity: number;
  supplierQuantity: number;
  rate: number;
  phaseId: number;
  vendorId: number;
  addendumCode: string;
  productCode: string;
  isToBeChecked: boolean;
  estimatedRate: number;
  hasBlob: boolean;
  comment: string;
  purchaseOrderId: number;
  isDeleted: boolean;
  originalOrderLineId: number;
  isLocked: boolean;
  modifiedDate: Date;
  modifiedUserId: number;
  createDate: Date;
  createUserId: number;
  lineTotal: number;

  jobNumber: string;
  priceFileCode: string;
  orderPhaseDescription: string;
  purchaseOrderNumber: string;

  varianceCode: number;
  varianceReason: string;
  costCentreCode: string;
  costCentreOrderNumber: number;
  varianceCodeId: number;
  costCentreCodeAndDesc: string;
  subGroupItemDesc: string;
  priceFileItemParentId: number;
  priceFileItemParentOrderNumber: number;
  quantityToMove: number;

  constructor(id: number, costCentreId: number, ccCode: string, costCentreOrderNumber: number,
    priceFileItemParentOrderNumber: number, costCentreCodeAndDesc: string, subGroupItemDesc: string,
    description: string, priceFileCode: string, measuredQuantity?: number, rate?: number) {
    this.id = id;
    this.costCentreId = costCentreId;
    this.costCentreCode = ccCode;
    this.costCentreOrderNumber = costCentreOrderNumber;
    this.priceFileItemParentOrderNumber = priceFileItemParentOrderNumber;
    this.costCentreCodeAndDesc = costCentreCodeAndDesc;
    this.subGroupItemDesc = subGroupItemDesc;
    this.description = description;
    this.isToBeChecked = false;
    this.priceFileItemParentOrderNumber = 0;
    this.measuredQuantity = measuredQuantity;
    this.rate = rate;
    this.priceFileCode = priceFileCode;
  }
}

export class OrderLineMove {
  orderLineId: number;
  measuredQuantity: number;
}
