import { GridService } from './../../services/grid.service';
import { EstimatingService } from './../../services/felixApi/estimating.service';
import { JobService } from './../../services/felixApi/job.service';
import { PoService } from './../../services/felixApi/po.service';
import { NotificationService } from './../../services/notification.service';
import { MaintenanceService } from './../../services/felixApi/maintenance.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Vendor } from '../../dtos/vendor';
import { Subscription } from 'rxjs';
import { Job } from '../../dtos/job';
import { PriceFileItem } from '../../dtos/price-file-item';
import { OrderLine } from '../../dtos/order-line';
import { CompanyActivityService } from '../../services/felixApi/company-activity.service';

@Component({
  selector: 'js-global-find-item',
  templateUrl: './global-find-item.component.html',
  styleUrls: ['./global-find-item.component.scss']
})
export class GlobalFindItemComponent implements OnInit, OnDestroy {

  loading = false;
  subscriptions: Subscription[] = [];
  vendorId: number;
  vendors: Vendor[];
  jobId: number;
  jobs: Job[];
  searchDescription: string;
  productCode: string;
  priceFileItemId: number;
  priceFileItems: PriceFileItem[];
  gridHeight: number;
  orderLines: OrderLine[];
  dropDownOptions: { width: number; minHeight: number; };

  constructor(
    private _activeModal: NgbActiveModal,
    private maintenanceService: MaintenanceService,
    private estimatingService: EstimatingService,
    private jobService: JobService,
    private notiService: NotificationService,
    private poService: PoService,
    public gridService: GridService,
    private companyActivityService: CompanyActivityService
  ) {
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.calculateActivityDesc = this.calculateActivityDesc.bind(this);
  }

  ngOnInit() {
    this.vendors = this.maintenanceService.allVendors.filter(i => !i.hideFromOrders);
    this.gridHeight = window.innerHeight < 590 ? 200 : window.innerHeight - 390;
    this.priceFileItems = this.estimatingService.allPriceFileItems;
    this.dropDownOptions = { width: 800, minHeight: 500 };

    this.subscriptions.push(
      this.jobService.getJobsByAddressWithExtras(true)
        .subscribe({
          next: (jobs) => {
            this.jobs = jobs;
          },
          error: (err) => {
            this.notiService.notify(err);
          }
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  close() {
    this._activeModal.close();
  }

  runSearch() {
    if (this.vendorId || this.jobId || this.searchDescription || this.productCode || this.priceFileItemId) {
      const searchData = {
        jobId: this.jobId,
        vendorId: this.vendorId,
        description: this.searchDescription,
        productCode: this.productCode,
        priceFileItemId: this.priceFileItemId
      }
      this.loading = true;

      this.subscriptions.push(
        this.poService.searchOrderLines(searchData)
          .subscribe({
            next: (orderLines) => {
              this.orderLines = orderLines;
              this.loading = false;
            },
            error: (err) => {
              this.notiService.notify(err);
              this.loading = false;
            }
          })
      );
    }
  }

  onSelectionChanged(e, event) {
    if (event.selectedRowKeys.length > 0) {
      this.priceFileItemId = event.selectedRowsData[0].id;
      e.component.close();
    }
  }

  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.unshift(
      {
        location: 'before',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          text: 'Run Search',
          type: 'default',
          onClick: this.runSearch.bind(this)
        }
      });
  }

  calculateActivityDesc(data) {
    const job = this.jobs?.find(i => i.jobNumber === data.jobNumber);
    const jobExtra = this.jobService.jobExtras?.find(i => i.jobId === job.id);
    if (jobExtra && jobExtra.maintenanceCompleteDate) {
      return 'Maintenance Complete';
    } else if (jobExtra && jobExtra.currentActivityId) {
      const activity = this.companyActivityService.activities.find(i => i.id === jobExtra.currentActivityId);
      if (activity) {
        return activity.activityCode + ' - ' + activity.description;
      }
    }
    return '';
  }
}
