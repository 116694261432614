import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { EstimatingService } from '../../services/felixApi/estimating.service';
import { JobService } from '../../services/felixApi/job.service';
import { MaintenanceService } from '../../services/felixApi/maintenance.service';
import { PoService } from '../../services/felixApi/po.service';
import { NotificationService } from '../../services/notification.service';
import { PriceFileItem } from '../../dtos/price-file-item';
import { Vendor } from '../../dtos/vendor';
import { Subscription } from 'rxjs';
import { VarianceCode } from '../../dtos/variance-code';
import { VarianceReason } from '../../dtos/variance-reason';
import { ConstructionStageTypeEnum } from '../../dtos/construction-stage-type';

@Component({
  selector: 'js-manual-order',
  templateUrl: './manual-order.component.html',
  styleUrls: ['./manual-order.component.scss']
})
export class ManualOrderComponent implements OnInit, OnDestroy, OnChanges {
  @Input() vendor: Vendor;
  @Input() reason: string;
  @Input() amount: number;
  @Input() jobId: number;
  @Input() forceChange: boolean;
  @Input() forBackCharge: boolean;

  @Output() purchaseOrderId: EventEmitter<number> = new EventEmitter<number>();

  subscriptions: Subscription[] = [];
  loading = false;
  costCentres: PriceFileItem[];
  dropDownOptions: { width: number; minHeight: number; };
  isExtrasWrite: boolean;
  popupVisible: boolean;
  varianceCodes: VarianceCode[];
  varianceReasons: VarianceReason[];
  costCentreId: number;
  description = '';
  varianceCodeId: number;
  varianceReason = '';
  constructionStageId: number;
  gridHeight: number;
  gridWidth: number;
  emailAddress: string;
  sendEmail = true;
  ccToSelf = true;

  constructor(
    private maintenanceService: MaintenanceService,
    private jobService: JobService,
    private estimatingService: EstimatingService,
    private poService: PoService,
    private notiService: NotificationService,
    private authService: AuthService) {
    this.onCCDropDownChanged = this.onCCDropDownChanged.bind(this);
  }

  ngOnInit() {
    if (this.forBackCharge) {
      this.gridHeight = window.innerHeight > 450 ? 430 : window.innerHeight - 20;
    } else {
      this.gridHeight = window.innerHeight > 610 ? 590 : window.innerHeight - 20;
    }
    this.gridWidth = window.innerWidth > 650 ? 600 : window.innerWidth - 50;
    this.description = this.reason;
    this.varianceReason = this.reason;
    this.emailAddress = this.vendor.callUpEmail && this.vendor.callUpEmail.trim() !== '' ? this.vendor.callUpEmail : this.vendor.email;

    this.costCentres = this.estimatingService.costCentres;
    this.constructionStageId = ConstructionStageTypeEnum.Prehandover;

    this.dropDownOptions = { width: 500, minHeight: 400 };
    this.isExtrasWrite = this.authService.isAdminOrSuper();
    const poPerm = this.authService.areaPermissions.find(i => i.applicationArea === 'OrderExtras');
    if (this.authService.isAdminOrSuper() || poPerm?.permissionType === 'Write' || poPerm?.permissionType === 'Admin') {
      this.isExtrasWrite = true;
    }

    this.loadData();
  }

  ngOnChanges(): void {
    this.popupVisible = true;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  loadData() {
    this.subscriptions.push(
      this.poService.getExtrasData()
        .subscribe({
          next:
            () => {
              this.varianceCodes = this.maintenanceService.varianceCodes;
              this.varianceReasons = this.maintenanceService.varianceReasons;
              this.loading = false;
            },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  onCCDropDownChanged(dropDownBox, e) {
    if (e.selectedRowKeys.length > 0) {
      this.costCentreId = e.selectedRowsData[0].id;
      dropDownBox.close();
    }
  }

  create() {
    if (this.description === '') {
      this.notiService.showInfo('Please enter a description');
    } else {
      this.loading = true;
      this.subscriptions.push(
        this.poService.createZeroValuePurchaseOrder(this.jobId, this.costCentreId, this.vendor.id, this.description,
          this.varianceCodeId, this.varianceReason, this.amount ? this.amount : 0, this.constructionStageId, this.sendEmail, this.emailAddress, this.ccToSelf).subscribe(res => {
            this.loading = false;
            this.popupVisible = false;
            this.poService.purchaseOrdersForJob.push(res);
            this.purchaseOrderId.emit(res.id);
          }, err => {
            this.notiService.notify(err);
            this.loading = false;
          })
      );
    }
  }
}
