import { Injectable } from '@angular/core';
// import mathString from 'math-string';
import stringMath from 'string-math';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
  ) { }

  moveArrayItemToNewIndex(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }

  getErrorString(err): string {
    if (err && err.error && err.error.message) {
      return JSON.stringify(err.error.message);
    } else {
      return JSON.stringify(err);
    }
  }

  editCache(object, cache: any[]) {
    const match = cache.filter(t => t.id === object.id)[0];
    if (match) {
      const index = cache.indexOf(match);
      cache[index] = object;
    } else {
      console.log('couldnt edit cache!');
    }
  }

  // assumes INPUT id is string and object ids are numbers
  deleteFromCache(id: string, cache: any[]) {
    const match = cache.filter(t => t.id.toString() === id)[0];
    if (match) {
      const index = cache.indexOf(match);
      cache.splice(index, 1);
    } else {
      console.log('couldnt delete from cache!');
    }
  }

  calcQtyFromString(quantityString: string): number {
    let validQty = true;
    // let quantity = 0;
    let quantityStringNew = '';

    if (quantityString) {
      try {
        quantityStringNew = stringMath(quantityString);
      } catch (err) {
        validQty = false;
        // quantity = 0;
      }

      // if (isNaN(quantity)) {
      //   validQty = false;
      //   quantity = 0;
      // }
    }

    if (validQty) {
      return Math.round(((+quantityStringNew) + Number.EPSILON) * 10000) / 10000;
    } else {
      return null;
    }
  }

  sanitizeQty(quantityString: string): string {
    if (quantityString && (typeof quantityString === 'string')) {
      quantityString = quantityString.replace(/(^\s+|\s+$)/g, '');
      quantityString = quantityString.replace(/=/g, '');
      quantityString = quantityString.replace(',', '');
      if (quantityString.search(' ') >= 0) {
        quantityString = quantityString.replace(/\s/g, '');
      }
    }
    return quantityString.toString();
  }

  roundEven(val: number): number {
    if (Math.abs(val % 1) === 0.5) {
      return (val = Math.round(val), val - (val % 2) * Math.sign(val));
    }
    return Math.round(val);
  }

  convertDateStringToDate(dateString: string | Date): Date {
    if (!dateString) {
      return null;
    }

    if (dateString instanceof Date) {
      return dateString;
    }

    return new Date(+dateString.substr(0, 4), +dateString.substr(5, 2) - 1, +dateString.toString().substr(8, 2), 0);
  }

  // enumToObject(enumObject) {
  //   const result = {};
  //   for (const key in enumObject) {
  //     if (Object.prototype.hasOwnProperty.call(enumObject, key)) {
  //       result[key] = enumObject[key];
  //     }
  //   }
  //   return result;
  // }

}
