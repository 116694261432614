<div class="modal-header">
  <h3 class="modal-title">Job Header Details</h3>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="card p-2">
    <!-- Loading Spinner -->
    <js-loading-spinner *ngIf="loading" [usePopUp]="false"></js-loading-spinner>

    <button *ngIf="!loading && !orderHeader && !createHeader" type="button" class="btn-sm btn-primary"
      (click)="createHeader = true">
      Create Order Header
    </button>

    <dx-form *ngIf="!loading && (orderHeader || createHeader)" id="form" [(formData)]="orderHeaderForm"
      [readOnly]="false" [showColonAfterLabel]="true" labelLocation="left" [minColWidth]="200" [colCount]="1"
      [width]="650">

      <dxi-item dataField="districtId" editorType="dxSelectBox"
        [editorOptions]="{ items: districts, required: true, displayExpr: 'description', valueExpr: 'id', width: '200' }">
        <dxo-label location="left" alignment="right" text="Price Book"></dxo-label>
        <dxi-validation-rule type="required" message="Price Book is required"> </dxi-validation-rule>
        <dxi-validation-rule type="async" [validationCallback]="compareDistricts" message=""></dxi-validation-rule>
      </dxi-item>

      <dxi-item dataField="baseDate" editorType="dxDateBox"
        [editorOptions]="{ calendarOptions: { showTodayButton: true }, width: '150px', displayFormat: 'd-MMM-yy' }">
        <dxo-label location="left" alignment="right" text="Base Date"> </dxo-label>
        <dxi-validation-rule type="required" message="Please specify a base date"> </dxi-validation-rule>
        <dxi-validation-rule type="async" [validationCallback]="compareDates" message=""></dxi-validation-rule>
      </dxi-item>

      <dxi-item dataField="jobComment" editorType="dxTextArea">
        <dxo-label location="left" alignment="right" text="Order Comment"></dxo-label>
      </dxi-item>

      <dxi-item dataField="isLocked" editorType="dxCheckBox" [editorOptions]="checkBoxOptions">
        <dxo-label location="left" alignment="right" text="Job Locked"> </dxo-label>
      </dxi-item>

      <dxi-item dataField="lockedDate" editorType="dxDateBox"
        [editorOptions]="{ calendarOptions: { showTodayButton: true }, width: '150px', displayFormat: 'd-MMM-yy', disabled: isLockedDateDisabled }">
        <dxo-label location="left" alignment="right" text="Locked Date"> </dxo-label>
      </dxi-item>

      <dxi-item itemType="button" horizontalAlignment="right" [buttonOptions]="updateButtonOptions">
      </dxi-item>
    </dx-form>
  </div>
</div>
