import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AccountingSystemAccount } from '../../../dtos/accounting-system-account';

@Component({
  selector: 'js-accounts-code',
  templateUrl: './accounts-code.component.html',
  styleUrls: ['./accounts-code.component.scss']
})
export class AccountsCodeComponent implements OnInit {
  @Input() accountingSystemAccounts: AccountingSystemAccount[];
  @Input() oldAccountID: string;

  @Output() newAccountID: EventEmitter<string> = new EventEmitter<string>();

  dropDownOptions: { width: number; minHeight: number; };

  constructor() { }

  ngOnInit() {
    this.dropDownOptions = { width: 500, minHeight: 500 };
  }

  onSelectionChanged(e, event) {
    if (event.selectedRowKeys.length > 0) {
      this.newAccountID.emit(event.selectedRowsData[0].accountID);
      this.oldAccountID = event.selectedRowsData[0].accountID;
      e.component.close();
    }
  }
}
