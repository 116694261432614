<div class="modal-header">
  <h3 class="modal-title">Enter Invoice</h3>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="card p-2">

    <js-loading-spinner *ngIf="loading" [usePopUp]="false"></js-loading-spinner>
    <form (submit)="update()">
      <dx-form *ngIf="!loading" id="form" [(formData)]="invoiceForm" [readOnly]="false" [showColonAfterLabel]="true"
        labelLocation="left" width="100%" [showValidationSummary]="false" validationGroup="customerData">

        <dxi-item itemType="group" [colCount]="4" [colSpan]="2">
          <dxi-item dataField="vendorId" [colSpan]="4" editorType="dxSelectBox" [editorOptions]="vendorOptions">
            <dxo-label location="left" text="Vendor"></dxo-label>
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dxi-item>
        </dxi-item>

        <dxi-item itemType="group" [colCount]="16" [colSpan]="2">
          <dxi-item dataField="orderNumber" [colSpan]="8" editorType="dxTextBox" [editorOptions]="orderNumberOptions">
            <dxo-label location="left" text="Order"></dxo-label>
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dxi-item>

          <dxi-item cssClass="order-button" itemType="button" [colSpan]="1" horizontalAlignment="left"
            [buttonOptions]="orderButtonOptions">
          </dxi-item>

          <dxi-item dataField="jobNumber" [colSpan]="7" [editorOptions]="{ disabled: true }"></dxi-item>
        </dxi-item>

        <dxi-item itemType="group" [colCount]="4" [colSpan]="2">
          <dxi-item dataField="invoiceNumber" [colSpan]="2" editorType="dxTextBox"
            [editorOptions]="invoiceNumberOptions">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dxi-item>
        </dxi-item>

        <dxi-item itemType="group" [colCount]="4" [colSpan]="2">
          <dxi-item dataField="invoiceDate" [colSpan]="2" editorType="dxDateBox" [editorOptions]="invoiceDateOptions">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dxi-item>
        </dxi-item>

        <dxi-item itemType="group" [colCount]="4" [colSpan]="2">
          <dxi-item dataField="totalIncGST" [colSpan]="2" [editorOptions]="totalIncGSTOptions">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dxi-item>
        </dxi-item>

        <dxi-item itemType="group" [colCount]="4" [colSpan]="2">
          <dxi-item dataField="totalGST" [colSpan]="2" editorType="dxNumberBox" [editorOptions]="totalGSTOptions">
          </dxi-item>
        </dxi-item>

        <dxi-item itemType="group" [colCount]="16" [colSpan]="2">
          <dxi-item dataField="totalExGST" [colSpan]="8" editorType="dxNumberBox" [disabled]="true"></dxi-item>

          <dxi-item *ngIf="isOverBudget" cssClass="order-button" itemType="button" [colSpan]="8"
            horizontalAlignment="left" [buttonOptions]="overBudgetButtonOptions">
          </dxi-item>
        </dxi-item>

        <dxi-item itemType="group" [colCount]="4" [colSpan]="2">
          <dxi-item dataField="jobAddressString" [colSpan]="4" [editorOptions]="{ disabled: true }">
            <dxo-label location="left" text="Job Address"></dxo-label>
          </dxi-item>

          <dxi-item dataField="costCentreDesc" [colSpan]="4" [editorOptions]="{ disabled: true }">
            <dxo-label location="left" text="Cost Centre"></dxo-label>
          </dxi-item>

          <dxi-item dataField="siteManager" [colSpan]="4" [editorOptions]="{ disabled: true }">
            <dxo-label location="left" [text]="calculateSiteManagerTitle()"></dxo-label>
          </dxi-item>
        </dxi-item>

        <dxi-item itemType="group" [colCount]="4" [colSpan]="2">
          <dxi-item dataField="description" editorType="dxTextArea" [colSpan]="4" [editorOptions]="{ height: 70 }">
            <dxo-label location="left" text="Comment"></dxo-label>
          </dxi-item>
        </dxi-item>

        <dxi-item itemType="group" [colCount]="4" [colSpan]="2">
          <dxi-item cssClass="fileUpload" dataField="hasBlob" editorType="dxFileUploader" [colSpan]="4"
            [editorOptions]="fileUploadOptions">
            <dxo-label location="left" text="Invoice"></dxo-label>
          </dxi-item>
        </dxi-item>

        <dxi-item itemType="group" [colCount]="32" [colSpan]="1">
          <dxi-item itemType="button" [colSpan]="6" horizontalAlignment="left" [buttonOptions]="extrasButtonOptions">
          </dxi-item>
          <dxi-item itemType="button" [colSpan]="16" horizontalAlignment="left"
            [buttonOptions]="zeroOrderButtonOptions">
          </dxi-item>
          <dxi-item itemType="button" [colSpan]="10" horizontalAlignment="right" [buttonOptions]="updateButtonOptions">
          </dxi-item>
        </dxi-item>
      </dx-form>
    </form>
  </div>
</div>

<!-- order lookup -->
<js-order-lookup *ngIf="showOrderLookupPopup" [originalOrderId]="selectedOrderId"
  [orderLookupSwitch]="orderLookupSwitch" [vendorId]='selectedVendorId' [fromAdd]='true'
  (purchaseOrderId)="getOrderFromLookup($event)" (splitPurchaseOrders)="getOrdersFromLookup($event)">
</js-order-lookup>

<js-extra-select *ngIf="addExtraVisible" [purchaseOrderId]="invoiceForm.purchaseOrderId"
  [poNumber]="invoiceForm.poNumber" [defaultDescription]="defaultDescription" [jobId]="invoiceForm.jobId"
  (popupClose)="extrasClose()">
</js-extra-select>

<!-- <js-create-zero-po *ngIf="addZeroOrderVisible" [vendorId]="invoiceForm.vendorId"
  (purchaseOrderId)="setPOFromAddZero($event)">
</js-create-zero-po> -->
