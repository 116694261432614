import { BackCharge } from './../../dtos/back-charge';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { HttpService } from '../http.service';

@Injectable({
  providedIn: 'root'
})
export class BackChargesService {

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService) { }


  getBackCharges(includeCompleted: boolean): Observable<BackCharge[]> {
    let url = this.globalService.getApiUrl() + '/back-charges';
    if (includeCompleted) {
      url += '?includeCompleted=true';
    }
    return this._http.get<BackCharge[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.globalService.handleError));
  }

  addBackCharge(dataRecord: any): Observable<BackCharge> {
    const url = this.globalService.getApiUrl() + '/back-charges';
    return this._http.post<BackCharge>(url, JSON.stringify(dataRecord, this.globalService.jsonReplacer), this.httpService.getHttpOptions());
  }

  updateBackCharge(id: string, itm: any): Observable<BackCharge> {
    const url = this.globalService.getApiUrl() + '/back-charges/' + id;
    return this._http.patch<BackCharge>(url, JSON.stringify(itm, this.globalService.jsonReplacer), this.httpService.getHttpOptions());
  }

  deleteBackCharge(id: string) {
    const url = this.globalService.getApiUrl() + '/back-charges/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }
}
