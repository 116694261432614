<div class="page">

  <div><b>Optionally</b> you can specify overriding accounts for a claim or variation. If no account is specified
    the system will use the accounts set up for the division</div>
  <br>

  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <dx-data-grid *ngIf="!loading" class="maintenance-grid" [height]="gridHeight" [dataSource]="dataSource"
    [allowColumnReordering]="false" [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true"
    [rowAlternationEnabled]="true" [wordWrapEnabled]="true"
    (onToolbarPreparing)="onToolbarPreparing($event, 'toolbarTemplate')"
    (onExporting)="gridService.onExporting($event, 'company-activities')">

    <dxo-search-panel [visible]="true" location="before" [width]="250"></dxo-search-panel>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-editing mode="row" [allowAdding]="false" [allowUpdating]="canEdit" [allowDeleting]="false" [useIcons]="true">
    </dxo-editing>
    <dxo-paging [enabled]="false"></dxo-paging>

    <dxi-column dataField="activityCode" width="100" sortOrder="asc" [allowEditing]="false">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="description" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="claimIncomeAccountId" dataType="string" caption="Claim Income Account"
      [editorOptions]="{ showClearButton: true }">
      <dxo-lookup [dataSource]="accountingSystemAccounts" valueExpr="accountID" displayExpr="name">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="variationIncomeAccountId" dataType="string" caption="Variation Income Account"
      [editorOptions]="{ showClearButton: true }">
      <dxo-lookup [dataSource]="accountingSystemAccounts" valueExpr="accountID" displayExpr="name">
      </dxo-lookup>
    </dxi-column>

    <div *dxTemplate="let data of 'toolbarTemplate'">
      <div>
        <dx-select-box id="selectDivision" [dataSource]="divisions" displayExpr="description" valueExpr="id"
          placeholder="Select Division" [width]="286" [(value)]='divisionId'
          (onValueChanged)='onSelectionChanged($event)'>
        </dx-select-box>
      </div>
    </div>
  </dx-data-grid>
</div>


<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loadingAccounts" [showIndicator]="true"
  [showPane]="true" [shading]="true" [hideOnOutsideClick]="false">
</dx-load-panel>
