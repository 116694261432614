<div class="page">

  <h4>
    Load Addenda Writer data
  </h4>

  <div>(fields (strings except for amounts): JobNumber, SectionName, SectionNote, Heading TypeID, ItemDescription,
    Selection, Extra Description, Extra Note
  </div>

  <js-loading-spinner *ngIf="loadingFile"></js-loading-spinner>

  <div *ngIf="!loadingFile" id="fileuploader">
    <dx-file-uploader #fileUploader selectButtonText="Select file" labelText="or drop file here" [multiple]="false"
      [(value)]="fileValue" uploadMode="useForm">
    </dx-file-uploader>
  </div>

  <js-job-number *ngIf="!loadingFile" (refreshJobNumber)='onJobNumberChanged($event)'></js-job-number>
  <br>

  <dx-check-box *ngIf="!loadingFile" text="Delete existing job lines" [(value)]='deleteExistingJobLines'>
  </dx-check-box>

  <br>
  <br>

  <dx-button *ngIf="!loadingFile" text="Load" type="default" (onClick)="loadFileGo()"
    [disabled]="!jobNumber || jobNumber === ''">
  </dx-button>

  <br>
  <br>

  <div>Note: File will not load at all if there are any errors.</div>

</div>
