import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError as observableThrowError, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { User } from '../../dtos/user';
import { GlobalService } from '../global.service';
import { HttpService } from '../http.service';
import { AuthService } from '../auth.service';
import { UserTypeEnum } from '../../dtos/user-type.enum';

@Injectable()
export class UserService {
  users: User[];
  cachCompanyUsers: string;
  officeUsers: User[];

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private auth: AuthService,
    private globalService: GlobalService) { }

  getCurrCompUsers(useCache: boolean): Observable<User[]> {
    if (useCache && this.users && this.users.length
      && this.cachCompanyUsers === this.globalService.getCurrentCompanyId()) {
      return of(this.users);
    } else {
      const url = this.globalService.getApiUrl() + '/companies/users';

      return this.http.get<User[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.users = res; this.cachCompanyUsers = this.globalService.getCurrentCompanyId();
          this.getOfficeUsers();
        }),
        catchError(this.handleError));
    }
  }

  getUser(id: number): Observable<User> {
    return this.http.get<User>(
      this.globalService.getApiUrl() + '/users/' + id + '?includeUserType=true',
      this.httpService.getHttpOptions());
  }

  getUserCompany(id: number, companyId: number): Observable<User> {
    return this.http.get<User>(
      this.globalService.getApiUrl() + '/companies/users/' + id,
      this.httpService.getHttpOptions(id, companyId));
  }

  getUserByEmail(email: string) {
    return this.http.get<User>(
      this.globalService.getApiUrl() + '/users/' + email,
      this.httpService.getHttpOptions());
  }


  getOfficeUsers() {
    this.officeUsers = [];
    this.users.forEach(user => {
      if ((user.isActive) && (user.userTypeId === UserTypeEnum.Admin || user.userTypeId === UserTypeEnum.Office)) {
        this.officeUsers.push(user);
      }
    });
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
