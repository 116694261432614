<div class="page">

  <js-loading-spinner *ngIf="loadingData"></js-loading-spinner>

  <js-job-number *ngIf="!loadingData" (refreshJobNumber)='onJobNumberChanged($event)'></js-job-number>

  <dx-toolbar *ngIf="!loadingData" class="after-box">
    <dxi-item *ngIf="jobNumber && orderHeader" location="center" widget="dxButton" locateInMenu="never">
      <div *dxTemplate>
        <div>
          <span *ngIf="orderHeader">Price Book:
            <b (click)="openHeader()">
              {{priceBookDescription}}
            </b></span>
          <span *ngIf="orderHeader" class='ms-4' [class.red]="isDatePast">Pricing Date:
            <b (click)="openHeader()">
              {{orderHeader.baseDate | date}}
            </b>
            <b *ngIf="orderHeader.isLocked">
              (Locked)
            </b>
          </span>
        </div>
      </div>
    </dxi-item>
    <dxi-item *ngIf="jobNumber && orderHeader && !loading" location="after" widget="dxButton" locateInMenu="auto"
      [options]="generateButtonOptions">
    </dxi-item>
    <dxi-item *ngIf="jobNumber && orderHeader && !loading" location="after" widget="dxButton" locateInMenu="auto"
      [options]="changeSupplierButtonOptions">
    </dxi-item>
    <dxi-item *ngIf="jobNumber && orderHeader && !loading" location="after" widget="dxButton" locateInMenu="auto"
      [options]="recostButtonOptions">
    </dxi-item>
    <dxi-item *ngIf="jobNumber && orderHeader && !loading" location="after" widget="dxButton" locateInMenu="auto"
      [options]="loadRecipeButtonOptions">
    </dxi-item>
    <dxi-item *ngIf="jobNumber && orderHeader && !loading && canUseGlobalTools" location="after" widget="dxButton"
      locateInMenu="auto" [options]="toolsMenuButtonOptions">
    </dxi-item>
    <dxi-item *ngIf="jobNumber && orderHeader && !loading" location="after" widget="dxButton" locateInMenu="auto"
      [options]="loadButtonOptions">
    </dxi-item>
    <dxi-item *ngIf="jobNumber && orderHeader && !loading" location="after" widget="dxButton" locateInMenu="auto"
      [options]="exportButtonOptions">
    </dxi-item>
    <dxi-item *ngIf="jobNumber && orderHeader && !loading" location="after" widget="dxButton" locateInMenu="auto"
      [options]="copyButtonOptions">
    </dxi-item>
    <dxi-item *ngIf="jobNumber && orderHeader && !loading" location="after" widget="dxButton" locateInMenu="auto"
      [options]="deleteButtonOptions">
    </dxi-item>
    <dxi-item *ngIf="jobNumber && orderHeader && !loading" location="after" widget="dxButton" locateInMenu="auto"
      [options]="showRolesOptions">
    </dxi-item>
    <dxi-item *ngIf="jobNumber && orderHeader && !loading" location="after" widget="dxButton" locateInMenu="auto"
      [options]="refreshButtonOptions">
    </dxi-item>
    <dxi-item *ngIf="jobNumber !loading" location="after" widget="dxButton" locateInMenu="auto"
      [options]="toolBarEditButtonOptions">
    </dxi-item>
  </dx-toolbar>

  <div *ngIf="jobNumber && !loadingData">

    <js-loading-spinner *ngIf="loading"></js-loading-spinner>

    <div *ngIf="!loading && !orderHeader">No orders exist for this job</div>

    <dx-box *ngIf="!loading && orderHeader" direction="row" class="p-t-10 full-width">
      <dxi-item [ratio]="0" [baseSize]="400">
        <dx-data-grid #costCentreGrid id="costCentreGrid" [height]="gridHeight" [dataSource]="filteredCostCentres"
          keyExpr="id" [allowColumnReordering]="false" [allowColumnResizing]="true" [repaintChangesOnly]="true"
          [showBorders]="true" [rowAlternationEnabled]="false" [selection]="{ mode: 'single' }"
          [focusedRowEnabled]="true" [wordWrapEnabled]="true" [(selectedRowKeys)]="selectedCostCentres"
          [focusedRowKey]="selectedCostCentres[0]" [autoNavigateToFocusedRow]="true" [hoverStateEnabled]="true"
          (onRowPrepared)="onRowPrepared($event)" (onSelectionChanged)="onCostCentreSelectionChanged($event)">

          <dxo-search-panel [visible]="true" location="before" [width]="400"></dxo-search-panel>
          <dxo-scrolling mode="standard"></dxo-scrolling>
          <dxo-paging [enabled]="false"></dxo-paging>

          <dxi-column dataField="priceFileCode" caption="Code" width="65" alignment="center">
          </dxi-column>

          <dxi-column dataField="description">
          </dxi-column>

          <dxi-column dataField="total" dataType="number" width="90" [format]="{ type: 'fixedPoint', precision: 0 }">
          </dxi-column>

          <dxi-column dataField="orderNumber" [visible]="false" sortOrder="asc">
          </dxi-column>

          <dxi-column dataField="colourID" [visible]="true" width="37" [calculateCellValue]='calculateColour'
            cellTemplate="colourTemplate" headerCellTemplate="colourHeaderCellTemplate">
          </dxi-column>

          <dxo-summary [calculateCustomSummary]="calculateCustomSummary">
            <dxi-total-item name="TotalLine" column="Total" summaryType="custom" valueFormat="fixedPoint"
              displayFormat="{0}">
            </dxi-total-item>
          </dxo-summary>

          <!-- Templates -->
          <div *dxTemplate="let cellInfo of 'colourHeaderCellTemplate'"> </div>

          <div *dxTemplate="let cellInfo of 'colourTemplate'">
            <div *ngIf="cellInfo.value.comment === ''">
              <i *ngIf="cellInfo.value.colourId === 0" class="material-icons-outlined"
                (click)="changeColour(cellInfo.value)">flag</i>
              <i *ngIf="cellInfo.value.colourId !== 0" class="material-icons"
                [class.blue]="cellInfo.value.colourId === 1" [class.orange]="cellInfo.value.colourId === 2"
                [class.red]="cellInfo.value.colourId === 3" [class.magenta]="cellInfo.value.colourId === 4"
                (click)="changeColour(cellInfo.value)">flag</i>
            </div>
            <div *ngIf="cellInfo.value.comment !== ''">
              <i class="material-icons" [class.blue]="cellInfo.value.colourId === 1"
                [class.orange]="cellInfo.value.colourId === 2" [class.red]="cellInfo.value.colourId === 3"
                [class.magenta]="cellInfo.value.colourId === 4" (click)="changeColour(cellInfo.value)">comment</i>
            </div>
          </div>
        </dx-data-grid>
      </dxi-item>

      <dxi-item [ratio]="1">
        <js-order-lines [orderHeader]="orderHeader" [selectedCostCentreId]=selectedCostCentres[0]
          [gridHeight]="gridHeight" [gridWidth]="gridWidth" [isRefresh]="isRefresh"
          (refreshTotals)="refreshTotals()" (refreshOrders)="getPurchaseOrders(true)">
        </js-order-lines>
      </dxi-item>

    </dx-box>
  </div>
</div>



<dx-popup class="popup" [width]="550" [height]="225" [showTitle]="true" title="Recost" [dragEnabled]="false"
  [hideOnOutsideClick]="!isUpdating" [(visible)]="recostPopupVisible">
  <div *dxTemplate="let data of 'content'">

    <!-- <js-loading-spinner *ngIf="isUpdating"></js-loading-spinner> -->

    <div *ngIf="!isUpdating" class="dx-field">
      <div class="dx-field-label">Recost unordered items for:</div>
      <div class="dx-field-value">
        <dx-select-box [(ngModel)]="generateOrdersForId" [dataSource]="generateOrdersFor" valueExpr="id"
          displayExpr="description">
        </dx-select-box>
      </div>
    </div>

    <div *ngIf="!isUpdating" class="dx-field">
      <div class="dx-field-label">Recost locked items:</div>
      <div>
        <dx-check-box [(ngModel)]="recostLockedLines">
        </dx-check-box>
      </div>
    </div>

    <dx-button *ngIf="!isUpdating" id="generateOrdersButton" text="Go" type="success" (onClick)="recostItemsGo()">
    </dx-button>
  </div>
</dx-popup>


<!-- Load from Recipe -->
<dx-popup class="popup" [width]="450" [height]="270" [showTitle]="true" title="Load from a Recipe" [dragEnabled]="false"
  [(visible)]="recipeLoadPopupVisible">
  <div *dxTemplate="let data of 'content'">

    <div *ngIf="!loadingExcelFile" class="dx-field">
      <div class="dx-field-label">Recipe:</div>
      <div class="dx-field-value">
        <dx-drop-down-box [dropDownOptions]="dropDownOptions" [dataSource]="recipes" [(value)]="recipeId"
          displayExpr="description" valueExpr="id" contentTemplate="contentTemplate">
          <!-- Note we have to use standard scrolling so the list will "autoNavigateToFocusedRow" if the item is not near the top of the list -->
          <div *dxTemplate="let e of 'contentTemplate'" class="dropdownClass">
            <dx-data-grid [dataSource]="recipes" keyExpr="id" [remoteOperations]="false" height="500px"
              [showBorders]="true" [selection]="{ mode: 'single' }" [focusedRowEnabled]="true"
              [focusedRowKey]="recipeId" [autoNavigateToFocusedRow]="true" [selectedRowKeys]="selectedRowKeys"
              [hoverStateEnabled]="true" [scrolling]="{ mode: 'virtual', preloadEnabled: true }"
              (onSelectionChanged)="onSelectionChanged(e, $event)">

              <dxo-paging [enabled]="false"></dxo-paging>
              <dxo-search-panel [visible]="true" [width]="758"></dxo-search-panel>
              <dxo-group-panel [visible]="false"></dxo-group-panel>
              <dxo-grouping #expand [autoExpandAll]="true" [contextMenuEnabled]="true" expandMode="rowClick">
              </dxo-grouping>

              <dxi-column dataField="subGroupItemDesc" dataType="string" caption="" [groupIndex]="0" [width]="220"
                groupCellTemplate="groupCellTemplate">
              </dxi-column>

              <dxi-column dataField="recipeCode" caption="Code" [width]="110"></dxi-column>

              <dxi-column dataField="description"></dxi-column>

              <dxi-column dataField="scale" [visible]="false"></dxi-column>

              <dxi-column dataField="unitOfMeasure" dataType="number" caption="Units" [width]="100">
              </dxi-column>

              <dxi-column dataField="vendorItemDescription" [visible]="false"></dxi-column>

              <div *dxTemplate="let data of 'groupCellTemplate'">
                {{ getGroupTitle(data) }}
              </div>
            </dx-data-grid>
          </div>
        </dx-drop-down-box>
      </div>
    </div>

    <div *ngIf="!loadingExcelFile" class="dx-field">
      <div class="dx-field-label">Quantity:</div>
      <div class="dx-field-value">
        <dx-number-box [(value)]="loadRecipeQuantity" width="80"> </dx-number-box>
      </div>
    </div>

    <div *ngIf="!loadingExcelFile" class="dx-field">
      <div class="dx-field-label">If item exists:</div>
      <div class="dx-field-value">
        <dx-select-box [(ngModel)]="stopIfExistingId" [dataSource]="stopIfExistingTypes" valueExpr="id"
          displayExpr="description">
        </dx-select-box>
      </div>
    </div>

    <dx-button *ngIf="recipeId && !loadingExcelFile" id="load-button" text="Load" type="default"
      (onClick)="loadFromRecipeGo()">
    </dx-button>

    <js-loading-spinner *ngIf="loadingExcelFile"></js-loading-spinner>
  </div>
</dx-popup>


<!-- Load from Excel -->
<dx-popup class="popup" [width]="450" [height]="425" [showTitle]="true" title="Load from Excel" [dragEnabled]="false"
  [hideOnOutsideClick]="!loadingExcelFile" [(visible)]="excelLoadPopupVisible">
  <div *dxTemplate="let data of 'content'">

    <div class="dx-field">
      <div class="dx-field-label">Use description:</div>
      <div class="dx-field-value">
        <dx-select-box [(ngModel)]="loadDescriptionTypeId" [dataSource]="loadDescriptionTypes" valueExpr="id"
          displayExpr="description">
        </dx-select-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">If item exists:</div>
      <div class="dx-field-value">
        <dx-select-box [(ngModel)]="stopIfExistingId" [dataSource]="stopIfExistingTypes" valueExpr="id"
          displayExpr="description">
        </dx-select-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label w20pc">Use rate from:</div>
      <div class="dx-field-value w80pc">
        <dx-select-box [(ngModel)]="useLoadedRateId" [dataSource]="useRateFromPriceFileTypes" valueExpr="id"
          displayExpr="description">
        </dx-select-box>
      </div>
    </div>

    <div *ngIf="!loadingExcelFile" id="fileuploader-pricefile">
      <dx-file-uploader selectButtonText="Select file" labelText="or drop file here" [multiple]="false" accept="*"
        [(value)]="fileValue" uploadMode="useForm" [allowedFileExtensions]="['.xls', '.xlsx']">
      </dx-file-uploader>
    </div>

    <js-loading-spinner *ngIf="loadingExcelFile"></js-loading-spinner>

    <dx-button *ngIf="!loadingExcelFile" id="sample-button" text="Sample" type="default" stylingMode="outlined"
      (onClick)="downloadSample()">
    </dx-button>
    <dx-button *ngIf="fileValue.length && !loadingExcelFile" id="load-button" text="Load" type="default"
      (onClick)="loadFromExcelGo()">
    </dx-button>
  </div>
</dx-popup>


<dx-popup class="popup" [width]="650" [height]="320" [showTitle]="true" title="Change Vendor" [dragEnabled]="false"
  [hideOnOutsideClick]="!isUpdating" [(visible)]="changeVendorPopupVisible">
  <div *dxTemplate="let data of 'content'">

    <div *ngIf="!isUpdating" class="dx-field">
      <div class="dx-field-label">Change vendor for:</div>
      <div class="dx-field-value">
        <dx-select-box [(ngModel)]="generateOrdersForId" [dataSource]="generateOrdersFor" valueExpr="id"
          displayExpr="description">
        </dx-select-box>
      </div>
    </div>

    <div *ngIf="!isUpdating" class="dx-field">
      <div class="dx-field-label">Change vendor:</div>
      <div class="dx-field-value">
        <dx-select-box [(ngModel)]="oldVendorId" [dataSource]="vendors" valueExpr="id" displayExpr="vendorName"
          [showClearButton]="true" [searchEnabled]="true">
        </dx-select-box>
      </div>
    </div>

    <div *ngIf="!isUpdating" class="dx-field">
      <div class="dx-field-label">To new vendor:</div>
      <div class="dx-field-value">
        <dx-select-box [(ngModel)]="newVendorId" [dataSource]="vendors" valueExpr="id" displayExpr="vendorName"
          [searchEnabled]="true">
        </dx-select-box>
      </div>
    </div>

    <div *ngIf="!isUpdating" class="dx-field">
      <div class="dx-field-label w60pc">Zero rate if item not in price file for new vendor:</div>
      <div class="w40pc">
        <dx-check-box [(ngModel)]="isZeroRates" id="zeroRateClass"> </dx-check-box>
      </div>
    </div>

    <dx-button *ngIf="!isUpdating" id="generateOrdersButton" text="Go" type="success" (onClick)="changeSupplierGo()"
      [disabled]="!newVendorId">
    </dx-button>
  </div>
</dx-popup>


<!-- delete all items -->
<dx-popup class="popup" [width]="400" [height]="220" [showTitle]="true" title="Delete All Unordered"
  [dragEnabled]="false" [hideOnOutsideClick]="false" [(visible)]="deleteAllPopupVisible">
  <div *dxTemplate="let data of 'content'">

    <b>Delete ALL Unordered items for this job</b>
    <br>
    <br>
    <div class="dx-field">
      <span>Type in the job number to confirm:</span>
      <dx-text-box id="jobNumberBox" [(ngModel)]="jobNumberCheck" [width]="100"> </dx-text-box>
    </div>

    <br>
    <dx-button text="Delete" type="danger" (onClick)="deleteAllGo()"> </dx-button>
  </div>
</dx-popup>


<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="isUpdating" [showIndicator]="true"
  [showPane]="true" [shading]="true" [hideOnOutsideClick]="false">
</dx-load-panel>
