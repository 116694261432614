<div class="page">

  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <dx-data-grid *ngIf="!loading" id="divisionsGrid" class="m-auto grid-height" [height]="gridHeight"
    [dataSource]="dataSource" [allowColumnReordering]="false" [allowColumnResizing]="true" [repaintChangesOnly]="true"
    [showBorders]="true" [rowAlternationEnabled]="true" [wordWrapEnabled]="true"
    (onToolbarPreparing)="onToolbarPreparing($event, 'toolbarTemplate')"
    (onExporting)="gridService.onExporting($event, 'division-accounts')">

    <dxo-search-panel [visible]="true" location="before" [width]="250"></dxo-search-panel>
    <dxo-scrolling mode="virtual"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-editing mode="row" [allowAdding]="accountsLoaded" [allowUpdating]="true" [allowDeleting]="true"
      [useIcons]="true" refreshMode="full">
    </dxo-editing>
    <dxo-row-dragging [allowReordering]="true" [onReorder]="reorder"></dxo-row-dragging>

    <div *dxTemplate="let data of 'refreshTemplate'">
      <div class="m-l-10">
        <dx-button icon="refresh" (onClick)="refresh()"></dx-button>
      </div>
    </div>

    <dxi-column dataField="glAccountTypeId" caption="Vendor Type">
      <dxo-lookup [dataSource]="glAccountTypes" valueExpr="id" displayExpr="description">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="accountId" caption="Account">
      <dxo-lookup [dataSource]="accountingSystemAccounts" valueExpr="accountID" displayExpr="name">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="maintenanceAccountId" caption="Maintenance Account">
      <dxo-lookup [dataSource]="accountingSystemAccounts" valueExpr="accountID" displayExpr="name">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="warrantyAccountId" caption="Warranty Account">
      <dxo-lookup [dataSource]="accountingSystemAccounts" valueExpr="accountID" displayExpr="name">
      </dxo-lookup>
    </dxi-column>

    <div *dxTemplate="let data of 'toolbarTemplate'">
      <div>
        <dx-select-box id="selectDivision" [dataSource]="divisionService.divisions" displayExpr="description"
          valueExpr="id" placeholder="Select Division" [width]="286" [(value)]='divisionId'
          (onValueChanged)='onSelectionChanged($event)'>
        </dx-select-box>
      </div>
    </div>
  </dx-data-grid>

</div>

<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loadingAccounts" [showIndicator]="true"
  [showPane]="true" [shading]="true" [hideOnOutsideClick]="false">
</dx-load-panel>
