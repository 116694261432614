<div class="page">

  <dx-data-grid *ngIf="!loadingData" #orderInvoices id="orderInvoices" [dataSource]="dataSource"
    [allowColumnReordering]="false" [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true"
    [rowAlternationEnabled]="true" [wordWrapEnabled]="true" [hoverStateEnabled]="true"
    (onExporting)="gridService.onExporting($event, 'order-invoices')">

    <dxo-search-panel [visible]="true" location="after" [width]="200"></dxo-search-panel>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-paging [enabled]="false"></dxo-paging>
    <!-- <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping #expand [autoExpandAll]="true" [contextMenuEnabled]="false"></dxo-grouping> -->
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <!-- <dxo-column-chooser [enabled]="true" mode="dragAndDrop"> </dxo-column-chooser> -->

    <dxo-editing mode="row" [allowUpdating]="false" [allowAdding]="false" [allowDeleting]="false" [useIcons]="true">
    </dxo-editing>

    <dxi-column dataField="vendorId" caption="Vendor" dataType="number" [setCellValue]="setVendorCellValue"
      [calculateSortValue]="calculateVendorSortValue">
      <dxo-lookup [dataSource]="vendors" displayExpr="vendorName" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="invoiceNumber" width="150">
    </dxi-column>

    <dxi-column dataField="invoiceDate" dataType="date" width="120" format="d/M/yy" alignment="center">
    </dxi-column>

    <dxi-column dataField="description" caption="Comment">
    </dxi-column>

    <dxi-column dataField="authorisedByUserId" caption="Approved by" dataType="number">
      <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="totalIncGST" caption="Inc GST" dataType="number" width="130"
      [format]="{ type: 'fixedPoint', precision: 2 }">
    </dxi-column>

    <dxi-column dataField="totalGST" caption="GST" dataType="number" width="130"
      [format]="{ type: 'fixedPoint', precision: 2 }">
    </dxi-column>

    <dxi-column dataField="totalExGST" caption="Ex GST" dataType="number" width="130"
      [format]="{ type: 'fixedPoint', precision: 2 }">
    </dxi-column>

    <dxi-column dataField="invoiceStatusId" caption="Status" dataType="number" width="120">
      <dxo-lookup [dataSource]="invoiceStatuses" displayExpr="description" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column type="buttons" [width]="70">
      <dxi-button icon="download" [onClick]="downloadAttachment" hint='Download Invoice'></dxi-button>
    </dxi-column>


    <!-- summary totals -->
    <dxo-summary>
      <dxi-total-item column="vendorId" summaryType="count" displayFormat="{0} rows">
      </dxi-total-item>
      <dxi-total-item column="totalExGST" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="totalGST" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="totalIncGST" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}">
      </dxi-total-item>
    </dxo-summary>
  </dx-data-grid>

</div>

<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#employee' }" [(visible)]="loadingFile"
  [showIndicator]="true" [showPane]="true" [shading]="true" [hideOnOutsideClick]="false">
</dx-load-panel>
