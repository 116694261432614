<div class="modal-header">
  <h4 class="modal-title">Due date check</h4>
</div>

<div class="modal-body">
  <div>Invoice date has missed cut-off!</div>

  <dx-button id="acceptButton" [text]="acceptDueDateFromEntry" class="mt-2" type="default" stylingMode="outlined"
    (onClick)="close()" width="100%">
  </dx-button>
  <br>
  <dx-button id="acceptButton" [text]="acceptDueDate" class="mt-2" type="danger" stylingMode="outlined"
    (onClick)="accept()" width="100%">
  </dx-button>
</div>
