<div class="page">

  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <dx-data-grid *ngIf="!loading" id="divisionsGrid" class="max-w-800 m-auto grid-height" [height]="gridHeight"
    [dataSource]="dataSource" [allowColumnReordering]="false" [allowColumnResizing]="true" [repaintChangesOnly]="true"
    [showBorders]="true" [rowAlternationEnabled]="true" [wordWrapEnabled]="true"
    (onEditingStart)="onEditingStart($event)" (onExporting)="gridService.onExporting($event, 'divisions')">

    <dxo-search-panel [visible]="true" location="before" [width]="250"></dxo-search-panel>
    <dxo-scrolling mode="virtual"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-editing mode="popup" [allowAdding]="false" [allowUpdating]="accountsLoaded" [allowDeleting]="false"
      [useIcons]="true" refreshMode="full">
      <dxo-popup title="" [showTitle]="false" [width]="600" [height]="520">
      </dxo-popup>
      <dxo-form>
        <dxi-item itemType="group" [colCount]="1" [colSpan]="2">
          <dxi-item dataField="description"></dxi-item>
          <dxi-item dataField="accountingSystemTenantName"></dxi-item>
        </dxi-item>

        <dxi-item *ngIf="loadingAccounts" itemType="group" [colCount]="1" [colSpan]="2">
          <js-loading-spinner *ngIf="loadingAccounts"></js-loading-spinner>
        </dxi-item>

        <dxi-item *ngIf="!loadingAccounts && (accountingSystemType === 1 || accountingSystemType === 3)"
          itemType="group" [colCount]="1" [colSpan]="2">
          <dxi-item *ngIf="accountingSystemType === 1" dataField="jobTrackingCategoryId"></dxi-item>
          <dxi-item dataField="incomeAccountId"></dxi-item>
        </dxi-item>

        <dxi-item *ngIf="!loadingAccounts" itemType="group" [colCount]="1" [colSpan]="2">
          <dxi-item dataField="depositIncomeAccountId"></dxi-item>
          <dxi-item dataField="claimIncomeAccountId"></dxi-item>
          <dxi-item dataField="variationIncomeAccountId"></dxi-item>
          <dxi-item *ngIf="accountingSystemType === 2" dataField="paymentTermsId"></dxi-item>
        </dxi-item>

        <dxi-item *ngIf="!loadingAccounts" itemType="group" [colCount]="1" [colSpan]="2">
          <dxi-item dataField="isActive"></dxi-item>
        </dxi-item>
      </dxo-form>
    </dxo-editing>

    <div *dxTemplate="let data of 'refreshTemplate'">
      <div class="m-l-10">
        <dx-button icon="refresh" (onClick)="refresh()"></dx-button>
      </div>
    </div>

    <dxi-column dataField="description" caption="Job Division">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="accountingSystemTenantId" dataType="number" [visible]="false">
    </dxi-column>

    <dxi-column dataField="accountingSystemTenantName" caption="Accounting Company" [allowEditing]="accountsLoaded"
      [setCellValue]="setCompanyCellValue" [editorOptions]="{ showClearButton: true }">
      <dxo-lookup [dataSource]="accountingSystemTenants" valueExpr="tenantName" displayExpr="tenantName">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="jobTrackingCategoryId" dataType="string" caption="Job Number Tracking Category"
      [visible]="false" [editorOptions]="{ showClearButton: true }">
      <dxo-lookup [dataSource]="accountingSystemCategories" valueExpr="trackingCategoryID" displayExpr="name">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="incomeAccountId" dataType="string" caption="Default Income Account" [visible]="false"
      [editorOptions]="{ showClearButton: true }">
      <dxo-lookup [dataSource]="accountingSystemAccounts" valueExpr="accountID" displayExpr="name">
      </dxo-lookup>
    </dxi-column>

    <dxi-column *ngIf="(accountingSystemType === 1 || accountingSystemType === 3)" dataField="depositIncomeAccountId"
      dataType="string" caption="Optional: Deposit Income Account" [visible]="false"
      [editorOptions]="{ showClearButton: true }">
      <dxo-lookup [dataSource]="accountingSystemAccounts" valueExpr="accountID" displayExpr="name">
      </dxo-lookup>
    </dxi-column>

    <dxi-column *ngIf="(accountingSystemType === 1 || accountingSystemType === 3)" dataField="claimIncomeAccountId"
      dataType="string" caption="Optional: Claim Income Account" [visible]="false"
      [editorOptions]="{ showClearButton: true }">
      <dxo-lookup [dataSource]="accountingSystemAccounts" valueExpr="accountID" displayExpr="name">
      </dxo-lookup>
    </dxi-column>

    <dxi-column *ngIf="(accountingSystemType === 1 || accountingSystemType === 3)" dataField="variationIncomeAccountId"
      dataType="string" caption="Optional: Variation Income Account" [visible]="false"
      [editorOptions]="{ showClearButton: true }">
      <dxo-lookup [dataSource]="accountingSystemAccounts" valueExpr="accountID" displayExpr="name">
      </dxo-lookup>
    </dxi-column>

    <dxi-column *ngIf="(accountingSystemType === 2 || accountingSystemType === 4)" dataField="depositIncomeAccountId"
      dataType="string" caption="Deposit Service Code" [visible]="false" [editorOptions]="{ showClearButton: true }">
      <dxo-lookup [dataSource]="accountingSystemServices" valueExpr="id" displayExpr="name">
      </dxo-lookup>
    </dxi-column>

    <dxi-column *ngIf="(accountingSystemType === 2 || accountingSystemType === 4)" dataField="claimIncomeAccountId"
      dataType="string" caption="Claim Service Code" [visible]="false" [editorOptions]="{ showClearButton: true }">
      <dxo-lookup [dataSource]="accountingSystemServices" valueExpr="id" displayExpr="name">
      </dxo-lookup>
    </dxi-column>

    <dxi-column *ngIf="(accountingSystemType === 2 || accountingSystemType === 4)" dataField="variationIncomeAccountId"
      dataType="string" caption="Variation Service Code" [visible]="false" [editorOptions]="{ showClearButton: true }">
      <dxo-lookup [dataSource]="accountingSystemServices" valueExpr="id" displayExpr="name">
      </dxo-lookup>
    </dxi-column>

    <dxi-column *ngIf="accountingSystemType === 2" dataField="paymentTermsId" dataType="string" caption="Payment Terms"
      [visible]="false" [editorOptions]="{ showClearButton: true }">
      <dxo-lookup [dataSource]="accountingSystemTerms" valueExpr="id" displayExpr="name">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="isActive" dataType="boolean" width="100">
    </dxi-column>
  </dx-data-grid>

</div>

<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loadingAccounts" [showIndicator]="true"
  [showPane]="true" [shading]="true" [hideOnOutsideClick]="false">
</dx-load-panel>
