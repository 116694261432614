import { IntegrationService } from './../../../services/felixApi/integration.service';
import { NotificationService } from './../../../services/notification.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { GridService } from '../../../services/grid.service';
import { Subscription } from 'rxjs';
import CustomStore from 'devextreme/data/custom_store';
import { ClaimLoadError } from '../../../dtos/claim-load-error';

@Component({
  selector: 'js-variation-history-load',
  templateUrl: './variation-history-load.component.html',
  styleUrls: ['./variation-history-load.component.scss']
})
export class VariationHistoryLoadComponent implements OnInit, OnDestroy {

  dataSource: CustomStore;
  subscriptions: Subscription[] = [];
  loadingFile = false;
  fileLoaded = false;
  fileValue: any[] = [];
  gridHeight: number;
  skipMissingJobs = false;
  skipInactiveJobs = false;
  errors: ClaimLoadError[];
  loadTypes: { id: number; name: string; }[];
  loadType: number;
  // loadClientManagerVariations = false;


  constructor(
    protected gridService: GridService,
    private notiService: NotificationService,
    private integrationService: IntegrationService
  ) { }

  ngOnInit(): void {
    this.loadTypes = [
      { id: 1, name: 'Variation Headers' },
      { id: 2, name: 'Variation Invoices' },
      { id: 3, name: 'Client Manager Variations' }
    ];
    this.setUpDataSource();
    this.gridHeight = window.innerHeight - 500;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  loadFileGo() {
    this.loadingFile = true;
    const formData: FormData = new FormData();
    formData.append('image', this.fileValue[0], this.fileValue[0].name);

    if (this.loadType === 3) {
      this.subscriptions.push(
        this.integrationService
          .loadHistoryClientManagerVariations(formData, this.skipMissingJobs, this.skipInactiveJobs)
          .subscribe({
            next: (errors) => {
              this.errors = errors;
              this.loadingFile = false;
              this.fileLoaded = true;
              this.setUpDataSource();
            }, error: (err) => {
              this.notiService.notify(err);
              this.fileValue = [];
              this.loadingFile = false;
            }
          })
      );
    } else if (this.loadType === 2) {
      this.subscriptions.push(
        this.integrationService
          .loadHistoryVariations(formData, this.skipMissingJobs, this.skipInactiveJobs)
          .subscribe({
            next: (errors) => {
              this.errors = errors;
              this.loadingFile = false;
              this.fileLoaded = true;
              this.setUpDataSource();
            }, error: (err) => {
              this.notiService.notify(err);
              this.fileValue = [];
              this.loadingFile = false;
            }
          })
      );
    } else if (this.loadType === 1) {
      this.subscriptions.push(
        this.integrationService
          .loadHistoryVariationHeaders(formData, this.skipMissingJobs, this.skipInactiveJobs)
          .subscribe({
            next: (errors) => {
              this.errors = errors;
              this.loadingFile = false;
              this.fileLoaded = true;
              this.setUpDataSource();
            }, error: (err) => {
              this.notiService.notify(err);
              this.fileValue = [];
              this.loadingFile = false;
            }
          })
      );
    }
  }

  setUpDataSource() {
    this.dataSource = new CustomStore({
      key: 'rowNumber',
      load: async () => this.errors
    });
  }
}
