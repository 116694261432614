import { GridService } from './../../services/grid.service';
import { InvoiceService } from './../../services/felixApi/invoice.service';
import { GlobalService } from './../../services/global.service';
import { EstimatingService } from './../../services/felixApi/estimating.service';
import { JobService } from './../../services/felixApi/job.service';
import { MaintenanceService } from './../../services/felixApi/maintenance.service';
import { NotificationService } from './../../services/notification.service';
import { PoService } from './../../services/felixApi/po.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Vendor } from '../../dtos/vendor';
import { Job } from '../../dtos/job';
import { PurchaseOrder } from '../../dtos/purchase-order';
import { PriceFileItem } from '../../dtos/price-file-item';
import DataSource from 'devextreme/data/data_source';
import { UtilsService } from '../../services/utils.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';

@Component({
  selector: 'js-accruals',
  templateUrl: './accruals.component.html',
  styleUrls: ['./accruals.component.scss']
})
export class AccrualsComponent implements OnInit, OnDestroy {

  @ViewChild('accrualGrid', { static: false }) grid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  loadingData = true;
  vendors: Vendor[];
  jobs: Job[];
  jobNumber: string;
  loading = false;
  jobId: number;
  purchaseOrders: PurchaseOrder[];
  costCentres: PriceFileItem[];
  dataSource: DataSource;
  gridHeight: number;
  markAllPopupVisible = false;
  invoiceCutOffDate: Date = new Date();
  addExtrasPopupVisible = false;
  varianceCodeId: number;
  varianceReason = '';
  orderLineDescription: string;
  updateCount: number;

  constructor(
    private poService: PoService,
    private invoiceService: InvoiceService,
    private jobService: JobService,
    private estimatingService: EstimatingService,
    private maintenanceService: MaintenanceService,
    private notiService: NotificationService,
    private globalService: GlobalService,
    public gridService: GridService,
    private utilsService: UtilsService
  ) {
    this.calculateOrderTotal = this.calculateOrderTotal.bind(this);
    this.calculateInvoiceTotal = this.calculateInvoiceTotal.bind(this);
    this.calculateRemainingBudget = this.calculateRemainingBudget.bind(this);
    this.openPO = this.openPO.bind(this);
    this.calculateCostCentreSortValue = this.calculateCostCentreSortValue.bind(this);
    this.calculateVendorSortValue = this.calculateVendorSortValue.bind(this);
    this.calculatePONumber = this.calculatePONumber.bind(this);
    this.setIsNoAccrualCellValue = this.setIsNoAccrualCellValue.bind(this);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          this.setHeightWidths();
        }
      )
    );

    this.setHeightWidths();
    this.getAccrualData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setHeightWidths() {
    this.gridHeight = window.innerHeight - 170;
  }

  getAccrualData() {
    this.subscriptions.push(
      this.poService.getOrdersDataForAccruals()
        .subscribe(
          () => {
            this.vendors = this.maintenanceService.allVendors;
            this.jobs = this.jobService.jobs;
            this.loadingData = false;
          },
          err => {
            this.notiService.notify(err);
            this.loadingData = false;
          })
    );
  }

  onJobNumberChanged(jobNo: string) {
    if (this.jobNumber !== jobNo && this.jobService.currentJob) {
      this.jobNumber = jobNo;
      this.jobId = this.jobService.currentJob.id;
      this.setUpDataSource();
    } else {
      this.jobNumber = jobNo;
      this.dataSource = null;
    }
  }

  setUpDataSource() {
    this.loading = false;
    this.dataSource = new DataSource({
      key: 'id',
      // load: () => this.purchaseOrders,
      load: async () => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.invoiceService.getPurchaseOrdersWithLines(this.jobId).subscribe({
              next: (res) => {
                this.purchaseOrders = res;
                this.costCentres = this.estimatingService.priceFileItemGroups.filter(i => !i.priceFileItemParentId);

                res.forEach(po => {
                  po.costCentreDescription = this.costCentres.find(i => i.id === po.costCentreId)?.description;
                });
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.poService.updatePurchaseOrder(encodeURIComponent(key), values).subscribe(res => {
              return resolve(res);
            }, err => {
              return reject(this.globalService.returnError(err));
            })
          ));
      }
    });
  }

  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.unshift(
      {
        location: 'before',
        locateInMenu: 'auto',
        widget: 'dxTextBox',
        options: {
          width: 90,
          value: 'Cut-Off Date:',
          readOnly: true,
          stylingMode: 'underlined'
        }
      },
      {
        location: 'before',
        locateInMenu: 'auto',
        widget: 'dxDateBox',
        options: {
          value: new Date(),
          onValueChanged: this.invoiceCutOffDateChanged.bind(this),
          calendarOptions: { showTodayButton: true }
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          type: 'default',
          stylingMode: 'outlined',
          text: 'Stop ALL Accruals',
          onClick: this.openMarkAllPopup.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          type: 'default',
          stylingMode: 'outlined',
          text: 'Add Extras',
          onClick: this.openExtrasPopup.bind(this)
        }
      });
  }

  invoiceCutOffDateChanged(e) {
    this.invoiceCutOffDate = e.value;
    this.setUpDataSource();
  }

  calculateOrderTotal(data) {
    let orderTotal = 0;
    this.poService.orderLines.filter(i => i.purchaseOrderId === data.id).forEach(orderLine => {
      if (orderLine.lineTotal) {
        orderTotal += orderLine.lineTotal;
      }
    });
    return orderTotal;
  }

  calculateInvoiceTotal(data) {
    let invoicesTotal = 0;
    this.poService.invoicesForThisJob
      .filter(i => i.purchaseOrderId === data.id && this.utilsService.convertDateStringToDate(i.invoiceDate) <= this.invoiceCutOffDate)
      .forEach(invoice => {
        invoicesTotal += invoice.totalExGST;
      });
    return invoicesTotal;
  }

  calculateRemainingBudget(data) {
    let orderTotal = 0;
    this.poService.orderLines.filter(i => i.purchaseOrderId === data.id).forEach(orderLine => {
      if (orderLine.lineTotal) {
        orderTotal += orderLine.lineTotal;
      }
    });

    return orderTotal - this.calculateInvoiceTotal(data);
  }

  openPO(e) {
    const dataRecord = {
      purchaseOrderIds: [e.row.data.id],
      emailAddresses: [],
      ccToSelf: false,
      download: true,
      printPrices: true
    };

    this.notiService.showInfo('downloading order');

    this.subscriptions.push(
      this.poService.sendPurchaseOrders(this.jobId, dataRecord)
        .subscribe(
          orderResponse => {
            this.poService.convertAndSave(orderResponse.pdfOrders, this.jobNumber, e.row.data.poNumber, false);
          },
          err => {
            this.notiService.notify(err);
          })
    );
  }

  calculateCostCentreSortValue(data) {
    return this.costCentres.find(i => i.id === data.costCentreId)?.orderNumber;
  }

  calculateVendorSortValue(data) {
    return this.vendors.find(i => i.id === data.vendorId)?.vendorName;
  }

  calculatePONumber(data) {
    return this.jobs.find(i => i.id === data.jobId)?.jobNumber + '.' + data.poNumber;
  }

  openMarkAllPopup() {
    this.markAllPopupVisible = true;
  }

  markAllStopped() {
    this.markAllPopupVisible = false;
    this.loading = true;
    this.subscriptions.push(
      this.poService.stopAllAccruals(this.jobId)
        .subscribe(
          () => {
            this.setUpDataSource();
          },
          err => {
            this.notiService.notify(err);
            this.loading = false;
          })
    );
  }

  setIsNoAccrualCellValue(rowData, value, originalData) {
    rowData.isNoAccrual = value;

    if (value) {
      // check if there are any invoices not included due to the cut-off date
      let invoiceTotal = 0;
      this.poService.invoicesForThisJob
        .filter(i => i.purchaseOrderId === originalData.id)
        .forEach(invoice => {
          invoiceTotal += invoice.totalExGST;
        });

      const shownTotal = this.calculateInvoiceTotal(originalData);
      if (invoiceTotal !== shownTotal) {
        this.notiService.showWarning('There are invoices not included in the cut-off date');
      }
    }
  }

  openExtrasPopup() {
    if (this.grid.instance.hasEditData()) {
      this.notiService.showWarning('Please save your changes before adding extras');
      return;
    }
    this.addExtrasPopupVisible = true;
  }

  addExtras() {
    this.addExtrasPopupVisible = false;
    this.loading = true;
    this.updateCount = 0;
    this.purchaseOrders.filter(i => i.isNoAccrual).forEach(po => {
      po.approvedInvoicesTotal = this.calculateInvoiceTotal(po);
      po.orderTotal = po.orderTotal ?? 0;
      if (po.orderTotal !== po.approvedInvoicesTotal) {
        this.updateCount++;
        this.runAddExtra(po);
      }
    });

    if (!this.updateCount) {
      this.loading = false;
    }
  }

  runAddExtra(po: PurchaseOrder) {
    const values = {
      purchaseOrderId: po.id,
      description: this.orderLineDescription,
      rate: Math.round((po.approvedInvoicesTotal - po.orderTotal) * 100) / 100,
      varianceCodeId: this.varianceCodeId,
      varianceReason: this.varianceReason
    };

    this.subscriptions.push(
      this.poService.addOrderLineWithExtra(values).subscribe(
        () => {
          this.updateCount--;
          if (this.updateCount === 0) {
            this.setUpDataSource();
          }
        },
        (err) => {
          this.notiService.notify(err);
          this.updateCount--;
          if (this.updateCount === 0) {
            this.setUpDataSource();
          }
        })
    );
  }
}
