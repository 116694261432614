<div class="modal-header">
  <h3 class="modal-title">Copy Job</h3>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <div *ngIf="!loading" class="jobHeight">
    <div>
      <div class="w100">Job Number</div>

      <dx-text-box class="inlineBlock" [(value)]="jobNumber" (onEnterKey)="onJobNumberChanged()" [width]="130"
        (onBlur)="onJobNumberChanged()">
      </dx-text-box>
    </div>

    <dx-button type="default" icon="search" (onClick)="jobSearch()"></dx-button>
  </div>

  <br>
  <div>{{jobString}}</div>
  <div>{{jobAddress}}</div>

  <div class="mt-2">
    <dx-check-box text="Ignore Cost Centres that have existing items in this job" [(value)]='ignoreExistingCostCentres'>
    </dx-check-box>
  </div>

  <div class="mt-2">
    <dx-check-box text="Zero all quantities" [(value)]='zeroQuantities'>
    </dx-check-box>
  </div>

  <!-- now select the lines -->
  <dx-tree-list *ngIf="!loading" [dataSource]="orderLines" keyExpr="id" [height]="treeHeight"
    parentIdExpr="costCentreId" [showRowLines]="true" [showBorders]="true" [columnAutoWidth]="false"
    [allowColumnResizing]="true" [(selectedRowKeys)]="selectedRowKeys" [wordWrapEnabled]="true"
    (onSelectionChanged)="onSelectionChanged($event)">

    <dxo-search-panel [visible]="true" [width]="200"></dxo-search-panel>
    <dxo-selection mode="multiple" [recursive]="true" [allowSelectAll]="true"> </dxo-selection>
    <dxo-header-filter [visible]="true"></dxo-header-filter>

    <dxi-column dataField="costCentreOrderNumber" dataType="number" sortOrder="asc" [sortIndex]="1" [visible]="false"></dxi-column>
    <dxi-column dataField="priceFileCode" caption="Code" [width]="160" sortOrder="asc" [sortIndex]="3"></dxi-column>
    <dxi-column dataField="purchaseOrderNumber" caption="PO#" [width]="90" sortOrder="asc" [sortIndex]="2"></dxi-column>
    <dxi-column dataField="description"></dxi-column>
    <dxi-column dataField="unitOfMeasure" caption="Units" width="90"></dxi-column>
    <dxi-column dataField="measuredQuantity" caption="Quantity" dataType="number" [width]="90"></dxi-column>
    <dxi-column dataField="rate" dataType="number" [width]="80" format="#,##0.00"></dxi-column>
    <dxi-column dataField="lineTotal" dataType="number" [width]="100" format="#,##0.00"></dxi-column>
  </dx-tree-list>

</div>

<div class="modal-footer">
  <dx-button *ngIf="jobString !== ''" type="success" text="Run Copy" (onClick)="runCopy()"
    [disabled]="!selectedIds || !this.selectedIds.length"></dx-button>
</div>
