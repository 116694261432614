import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'accountsCode'
})
export class AccountsCodePipe implements PipeTransform {
  transform(value: string): string {
    if (value && value !== '') {
      return 'Yes';
    } else {
      return 'NO';
    }
  }
}
