export enum ConfigurationEnum {
    LogoPosition = 1,
    ReportFooterImage = 2,
    SalesQuotePrintWholeAddendum = 3,
    WordingOfToBeSelected = 4,
    WordingOfToBeSelectedByConsultant = 5,
    DefaultConstructionAdminFee = 6,
    CustomerUpdateAllowed = 7,
    SalesQuoteCoverImage = 8,
    DefaultJobDepositValue = 9,
    AddendumHeadingWording = 10,
    ManualOrders = 11,
    ClientNotificationOfInfoChanges = 12,
    VariationPriceEstimatingEnabled = 13,
    InvoiceUploadEnabled = 14,
    AutoAskForFirstSalesVariation = 15,
    PriceFileUsesCatalogues = 16,
    PriceFileUsesDistricts = 17,
    AutoCreateFirstSalesOfficeVariation = 18,
    VariationIntegrationEmailAddress = 19,
    VariationReportType = 20,
    ConstructionActivitiesUsed = 21,
    ClientDashboardVisible = 22,
    JobReceiptsUsed = 23,
    JobActivityUsed = 24,
    QuoteVOSectionHeadingPrintWholeAddendum = 25,
    QuoteVOSectionHeading = 26,
    QuotePSSectionHeading = 27,
    QuoteOfficeSectionHeading = 28,
    BalanceOfDepositAmountsUsed = 29,
    EstimatorRequired = 30,
    QuoteXMLExport = 31,
    VariationNumbersStartAgain = 32,
    CustomerAcceptReject = 33,
    HouseTypeRequired = 34,
    EstimatingPreContractFeesMargin = 35,
    ClaimSchedule = 36,
    AskSalesToShowOnlyItemsInThisVO = 37,
    ShowFinanceCashCheckboxOnPostContractVO = 38,
    CanSalesVariationItemsBeUnChecked = 39,
    ContractPrintFromSalesPriceOnwards = 40,
    TrackingSystemActive = 41,
    PostContractPendingApprovalDueDateBlankWarning = 42,
    PurchaseOrderSystemActive = 43,
    ShowActivityOnPostContractVO = 44,
    SalesQuoteSignedDateShown = 45,
    AccountingSystem = 46,
    DocumentManagementSystem = 47,
    SalesQuoteFooterHTMLText = 48,
    ContractQuoteFooterHTMLText = 49,
    ConstructiveIntegrationEnabled = 50,
    ActiveCampaignOrHubSpotIntegrationEnabled = 51,
    CanMarkSoldWhenVariationsPending = 52,
    JobDepositPaidNeedsInvoicePermission = 53,
    JobCostUsesGreaterOfEstimatedVsScheduled = 54,
    DataBuildIntegrationEnabled = 55,
    DashboardHubEnabled = 56,
    SalesQuoteReportName = 57,
    ContractQuoteReportName = 58,
    LenderInformationEnabled = 59,
    DoNotAskToCreateTaskForVariation = 60,
    CreateTaskAlsoForPreContractVariation = 61,
    PriceFileUpdateMatchingVendorAndProductCode = 62,
    PackageSystemActive = 63,
    ActiveCampaignContactNameType = 64,
    CADTakeOffSystemActive = 65,
    EmailUpdatesToClientsActive = 66,
    InvoiceScanningActive = 67,
    EstimatingAddRecipeUsesRecipeMarkup = 68,
    AddendaPDFDefaultShowPSAsTrue = 69,
    LandDatabase = 70,
    IncomeInvoiceReferenceExcludeBankInformation = 71,
    XeroUseSiteAddressForContact = 72,
    AllowCreateDeal = 73,
    SkipPriceCheckPopup = 74,
    TruthEngineClientPortalActive = 75
}
