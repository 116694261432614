<div class="login">
  <br>
  <img src="../assets/TElogo.png" />
  <br><br><br>

  <div class="login" *ngIf="isHttps && showLoginButton">
    <button class="btn btn-primary bton-lg" id="signIn" (click)="loginClicked()">Login</button>
  </div>

  <div *ngIf="!isHttps" class="danger-box">
    For security reasons you must use HTTPS when accessing Truth Engine.
    <br>Please use the button below to go to the secure https site.
    <br><br>Update your bookmark to avoid seeing this message again.
    <br>
    <button class="btn btn-primary bton-lg m-t-30" (click)="goToHttps()">Proceed to secure site</button>
  </div>

  <js-loading-spinner *ngIf="!showLoginButton"></js-loading-spinner>

  <br>
  <br>
  <br>
  <button *ngIf="!showLoginButton && !activelyLoggingIn && isHttps && showRefreshButton" class="btn btn-secondary"
    (click)="refresh()">
    Refresh
  </button>

  <div *ngIf="currentVersion && showLoginButton" class='versionString'>version {{currentVersion}}</div>
</div>
