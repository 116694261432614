import { GridService } from './../../services/grid.service';
import { CompanyService } from './../../services/felixApi/company.service';
import { EstimatingService } from './../../services/felixApi/estimating.service';
import { PurchaseOrder } from '../../dtos/purchase-order';
import { PoService } from '../../services/felixApi/po.service';
import { JobService } from '../../services/felixApi/job.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Vendor } from '../../dtos/vendor';
import { MaintenanceService } from '../../services/felixApi/maintenance.service';
import { GlobalService } from '../../services/global.service';
import { InvoiceService } from '../../services/felixApi/invoice.service';
import { NotificationService } from '../../services/notification.service';
import { Job } from '../../dtos/job';
import { InvoiceBatch } from '../../dtos/invoice-batch';
import { InvoiceStatusTypeEnum } from '../../dtos/invoice';
import { RoleTypeEnum } from '../../dtos/role-type.enum';
import { PriceFileItem } from '../../dtos/price-file-item';
import { formatDate } from 'devextreme/localization';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DuplicateInvoiceComponent } from '../duplicate-invoice/duplicate-invoice.component';
import { AddInvoiceComponent } from './add-invoice/add-invoice.component';
import CustomStore from 'devextreme/data/custom_store';
import { ConfigurationEnum } from '../../dtos/configuration-enum';
import { PreviousInvoicesComponent } from '../previous-invoices/previous-invoices.component';

@Component({
  selector: 'js-invoice-entry',
  templateUrl: './invoice-entry.component.html',
  styleUrls: ['./invoice-entry.component.scss']
})
export class InvoiceEntryComponent implements OnInit, OnDestroy {

  @ViewChild('invoiceGrid') invoiceGrid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  dataSource: CustomStore;
  vendors: Vendor[];
  loadingData = true;
  loading = false;
  jobs: Job[];
  gridHeight: number;
  invoiceBatches: InvoiceBatch[];
  selectedInvoiceBatchId = 0; // null is used for manual invoices entered by trades
  invoiceBatchesPopupVisible: boolean;
  invoiceBatchDataSource: CustomStore;
  lastVendorId: number;
  attachmentsPopupVisible: boolean;
  currentInvoiceId: any;
  files: any[] = [];
  loadingFile: boolean;
  attachmentExists: any;
  attachmentsPopupHeight: number;
  costCentres: PriceFileItem[];
  currentDateString: string;
  selectedJobId: number;
  showOrderPopup: boolean;
  jobNumber: string;
  showOrderLookupPopup: boolean;
  selectedOrderId: number;
  orderLookupSwitch = true;
  deletebuttonOptions: { text: string; onClick: any; };
  dropDownOptions: { width: number; minHeight: number; };
  showOrderLookupPopupSplits: boolean;
  invoiceData: object;
  invoiceId: any;
  scanDropVisible: boolean;
  invoiceScanningActive: boolean;
  processPopupVisible: boolean;
  fileCount: number;

  constructor(
    private globalService: GlobalService,
    private companyService: CompanyService,
    private invoiceService: InvoiceService,
    private notiService: NotificationService,
    private jobService: JobService,
    private poService: PoService,
    private estimatingService: EstimatingService,
    private maintenanceService: MaintenanceService,
    public gridService: GridService,
    private modalService: NgbModal) {
    this.setVendorCellValue = this.setVendorCellValue.bind(this);
    this.setOrderNumberCellValue = this.setOrderNumberCellValue.bind(this);
    this.setTotalIncGSTCellValue = this.setTotalIncGSTCellValue.bind(this);
    this.calculateJobNumber = this.calculateJobNumber.bind(this);
    this.attachDoc = this.attachDoc.bind(this);
    this.downloadAttachment = this.downloadAttachment.bind(this);
    this.calculateSiteManager = this.calculateSiteManager.bind(this);
    this.calculateRemainingBudget = this.calculateRemainingBudget.bind(this);
    this.onCellPrepared = this.onCellPrepared.bind(this);
    this.getOrderFromLookup = this.getOrderFromLookup.bind(this);
    this.openPO = this.openPO.bind(this);
    this.onEditingStart = this.onEditingStart.bind(this);
    this.isDuplicateInvoice = this.isDuplicateInvoice.bind(this);
    this.showDuplicateInvoices = this.showDuplicateInvoices.bind(this);
    this.calculateVendorSortValue = this.calculateVendorSortValue.bind(this);
    this.setInvoiceDateValue = this.setInvoiceDateValue.bind(this);
    this.splitInvoice = this.splitInvoice.bind(this);
    this.splitOrdersFromLookup = this.splitOrdersFromLookup.bind(this);
    this.openHistory = this.openHistory.bind(this);
    this.calculateSiteManagerTitle = this.calculateSiteManagerTitle.bind(this);
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          this.setHeightWidths();
        }
      )
    );

    const costingDate = new Date();
    this.currentDateString = costingDate.getFullYear() + '-'
      + ('0' + (costingDate.getMonth() + 1)).toString().slice(-2) + '-'
      + ('0' + costingDate.getDate()).slice(-2);

    this.poService.allPurchaseOrdersForCompany = []; // refresh on init

    this.dropDownOptions = { width: 636, minHeight: 400 };
    this.deletebuttonOptions = { text: 'Delete Current Attachment', onClick: this.deleteAttachment.bind(this) };
    this.setHeightWidths();
    this.loadData(true);
  }

  setHeightWidths() {
    this.gridHeight = window.innerHeight - 127;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  loadData(useCache: boolean) {
    this.subscriptions.push(
      this.invoiceService.getInvoicesData(useCache)
        .subscribe({
          next: (invoiceBatches) => {
            this.invoiceBatches = invoiceBatches;

            if (invoiceBatches?.length === 1) {
              this.selectedInvoiceBatchId = this.invoiceBatches[0].id;
            }

            if (this.globalService.getCompanyConfigValue(ConfigurationEnum.InvoiceScanningActive) === 1) {
              this.invoiceScanningActive = true;
            }

            this.vendors = this.maintenanceService.allVendors.filter(i => i.canAcceptInvoices);
            this.jobs = [new Job(null, 'Not Found')];
            this.jobs = this.jobs.concat(this.jobService.jobs);
            this.costCentres = [new PriceFileItem(null, 0, '', 'Not Found')];
            this.costCentres = this.costCentres.concat(this.estimatingService.costCentres);
            this.loadingData = false;
            this.setUpDataSource(useCache);
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loadingData = false;
          }
        })
    );
  }

  setUpDataSource(useCache: boolean) {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.invoiceService.getInvoicesAndPOs(this.selectedInvoiceBatchId, InvoiceStatusTypeEnum.Entered, useCache).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      },
      insert: async (values) => {
        values.invoiceBatchId = this.selectedInvoiceBatchId;
        values.invoiceStatusId = 1;
        values.hasBlob = false;
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.invoiceService.addInvoice(values).subscribe({
              next: (res) => {
                this.currentInvoiceId = res.id;
                if (this.files && this.files.length) {
                  this.uploadClick(true);
                  res.hasBlob = true;
                }
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.invoiceService.updateInvoice(encodeURIComponent(key), values).subscribe({
              next: (res) => {
                this.currentInvoiceId = res.id;
                if (this.files && this.files.length) {
                  this.uploadClick(true);
                  res.hasBlob = true;
                }
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.invoiceService.deleteInvoice(encodeURIComponent(key)).subscribe({
              next: () => {
                return resolve();
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      }
    });
  }

  onToolbarPreparing(e, templateName: string, toolbarTemplate2: string) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.push({
      location: 'before',
      locateInMenu: 'auto',
      template: templateName
    });

    toolbarItems.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        template: toolbarTemplate2
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          width: 140,
          type: 'default',
          text: 'Process Invoices',
          onClick: this.processInvoices.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: this.refresh.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          type: 'default',
          stylingMode: 'outlined',
          text: 'Reset Layout',
          onClick: this.clearStatePersistance.bind(this)
        }
      });

    if (this.invoiceScanningActive) {
      toolbarItems.unshift(
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxButton',
          options: {
            type: 'default',
            stylingMode: 'outlined',
            text: 'Scan',
            onClick: this.openScanDropZone.bind(this)
          }
        });
    }
  }

  manageBatches() {
    this.invoiceBatchesPopupVisible = true;
    this.setUpInvoiceBatchesDataSource();
  }

  setUpInvoiceBatchesDataSource() {
    this.invoiceBatchDataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.invoiceService.getInvoiceBatches().subscribe({
              next: (res) => {
                this.invoiceBatches = res;
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.invoiceService.addInvoiceBatch(values).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.invoiceService.updateInvoiceBatch(encodeURIComponent(key), values).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.invoiceService.deleteInvoiceBatch(encodeURIComponent(key)).subscribe({
              next: () => {
                return resolve();
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      }
    });
  }

  setVendorCellValue(rowData, value, originalData) {
    rowData.vendorId = value;
    const vendor = this.vendors.find(i => i.id === value);

    if (this.lastVendorId !== value) {
      this.lastVendorId = value;
      const dateNow = new Date();
      const dateNowString = formatDate(dateNow, 'yyyy-MM-dd');

      if ((vendor?.incomeProtectionExpiry && vendor.incomeProtectionExpiry <= dateNowString)
        || (vendor?.publicLiabilityExpiry && vendor.publicLiabilityExpiry <= dateNowString)
        || (vendor?.workCoverExpiry && vendor.workCoverExpiry <= dateNowString)) {
        this.notiService.showWarning('Insurances Expired');
      }
    }

    // recalc GST
    if (vendor?.isGstFree) {
      rowData.totalGST = 0;
      this.notiService.showInfo('GST Free Vendor');
    } else {
      rowData.totalGST = Math.round((originalData.totalIncGST / (1 + this.invoiceService.globalGSTRate)) * 100) / 100;
    }
    rowData.totalExGST = originalData.totalIncGST - rowData.totalGST;
  }

  calculateVendorSortValue(data) {
    return this.vendors.find(i => i.id === data.vendorId)?.vendorName;
  }

  setTotalIncGSTCellValue(rowData, value, originalData) {
    rowData.totalIncGST = value;

    const vendor = this.vendors.find(i => i.id === originalData.vendorId);
    if (vendor?.isGstFree) {
      rowData.totalGST = 0;
      this.notiService.showInfo('GST Free Vendor');
    } else {
      rowData.totalGST = Math.round((value / (1 + this.invoiceService.globalGSTRate)) * 100) / 100;
    }
    rowData.totalExGST = value - rowData.totalGST;
  }

  setTotalGSTCellValue(rowData, value, originalData) {
    rowData.totalGST = value;
    rowData.totalExGST = originalData.totalIncGST - value;
  }

  async setOrderNumberCellValue(rowData, value, originalData) {
    rowData.orderNumber = value;

    let orderNumberArray = value.split('.');
    // let validOrder = false;
    rowData.purchaseOrderId = null;
    rowData.poNumber = null;
    rowData.jobId = null;

    if (orderNumberArray.length > 1) {
      const jobNumber = orderNumberArray[0];
      const job = this.jobs.find(i => i.jobNumber === jobNumber);
      if (job) {
        let orderNumber = orderNumberArray[1] + (orderNumberArray.length <= 2 ? '' : '.' + orderNumberArray[2]);

        if (orderNumberArray.length > 3) {
          orderNumber += '.' + orderNumberArray[3];
        }
        // look this up to see if we can find the matching PO
        const po = this.poService.allPurchaseOrdersForCompany.find(i => i.jobId === job.id && i.poNumber === orderNumber);
        if (po) {
          rowData.purchaseOrderId = po.id;
          rowData.jobId = job.id;
          rowData.poNumber = orderNumber;
          rowData.remainingBudget = po.orderTotal - (po.approvedInvoicesTotal ? po.approvedInvoicesTotal : 0);
          rowData.costCentreId = po.costCentreId;

          if (originalData.vendorId !== po.vendorId) {
            const vendor = this.maintenanceService.allVendors.find(i => i.id === po.vendorId);
            if (!vendor?.canAnyVendorInvoice) {
              if (!this.maintenanceService.vendorPayables
                .find(i => i.orderVendorId === po.vendorId && i.payVendorId === originalData.vendorId)) {
                this.notiService.showError('Cannot accept invoices from this vendor for this order');
              }
            }
          }
        }
      } else {
        // we may have a DataBuild PO - format job/po
        orderNumberArray = value.split('/');

        if (orderNumberArray.length === 2) {
          const jobNum = orderNumberArray[0];
          const jobDB = this.jobs.find(i => i.jobNumber === jobNum);
          if (jobDB) {
            const orderNumber = orderNumberArray[1];
            // look this up to see if we can find the matching PO
            await this.getPurchaseOrder(jobDB.id, orderNumber)
              .then(
                po => {
                  if (po) {
                    rowData.purchaseOrderId = po.id;
                    rowData.jobId = jobDB.id;
                    rowData.poNumber = orderNumber;
                    rowData.remainingBudget = po.orderTotal - (po.approvedInvoicesTotal ? po.approvedInvoicesTotal : 0);
                    rowData.costCentreId = po.costCentreId;
                  }
                });
          }
        }
      }
    }
  }

  getPurchaseOrder(jobId: number, orderNumber: string): Promise<PurchaseOrder> {
    return new Promise((resolve, reject) =>
      this.subscriptions.push(
        this.poService.getPurchaseOrder(jobId, orderNumber).subscribe({
          next: (res) => {
            return resolve(res);
          }, error: (err) => {
            return reject(this.globalService.returnError(err));
          }
        })
      ));
  }

  attachDoc(e) {
    this.currentInvoiceId = e.row.data.id;
    this.attachmentExists = e.row.data.hasBlob;
    this.attachmentsPopupHeight = this.attachmentExists ? 140 : 320;
    this.attachmentsPopupVisible = true;
    this.files = [];
  }

  attachDocClick(e) {
    this.currentInvoiceId = e.data.id;
    this.attachmentExists = e.data.hasBlob;
    this.attachmentsPopupHeight = this.attachmentExists ? 140 : 320;
    this.attachmentsPopupVisible = true;
    this.files = [];
  }

  uploadClick(fromAddEdit: boolean) {
    // load the file
    if (this.files && this.files.length && this.currentInvoiceId) {
      this.loadingFile = true;
      this.attachmentsPopupVisible = false;
      const formData: FormData = new FormData();
      formData.append('image', this.files[0], this.files[0].name);

      this.subscriptions.push(
        this.invoiceService.uploadInvoice(this.currentInvoiceId, formData).subscribe({
          next: () => {
            this.files = [];
            this.loadingFile = false;
            // we need to tell the grid that an attachment now exists for this row
            if (!fromAddEdit) {
              this.setUpDataSource(true);
            }
          }, error: (err) => {
            this.notiService.notify(err);
            this.files = [];
            this.loadingFile = false;
          }
        })
      );
    }
  }

  deleteAttachment() {
    this.loadingFile = true;
    this.attachmentsPopupVisible = false;

    this.subscriptions.push(
      this.invoiceService.deleteInvoiceAttachment(this.currentInvoiceId).subscribe({
        next: () => {
          this.loadingFile = false;
          this.setUpDataSource(true);
        }, error: (err) => {
          this.notiService.notify(err);
          this.loadingFile = false;
        }
      })
    );
  }

  calculateHasAttachments(data) {
    return data.hasBlob ? data.id : null;
  }

  calculateJobNumber(data) {
    return this.jobs.find(i => i.id === data.jobId)?.jobNumber;
  }

  calculateSiteManager(data) {
    return this.jobService.jobRoles.find(i => i.jobId === data.jobId && i.roleId === RoleTypeEnum.SiteManager)?.user.fullName;
  }

  calculateSiteManagerTitle() {
    return this.companyService.companyRoles.find(i => i.roleId === RoleTypeEnum.SiteManager)?.companyRoleDescription;
  }

  calculateRemainingBudget(data) {
    if (data.purchaseOrderId) {
      const po = this.poService.allPurchaseOrdersForCompany.find(i => i.id === data.purchaseOrderId);
      let remainingAmount = 0;
      if (po) {
        remainingAmount = po.orderTotal - (po.approvedInvoicesTotal ? po.approvedInvoicesTotal : 0);
      }

      // if we have previous invoices we calculate in order
      const dataSource = this.invoiceGrid.instance.getDataSource();
      const invoices = dataSource.items();
      const sameInvoices = invoices.filter(i => i.purchaseOrderId === data.purchaseOrderId);

      let totalOfAllInvoices = 0;
      let finishCalc = false;

      sameInvoices.forEach(invoice => {
        if (!finishCalc) {
          if (invoice.id === data.id) {
            finishCalc = true;
          }
          totalOfAllInvoices += invoice.totalExGST;
        }
      });

      return remainingAmount - totalOfAllInvoices;
    } else {
      return null;
    }
  }

  isAttachmentIconVisible = (e) => {
    if (e.row.data.id) {
      return true;
    }
    return false;
  }

  isDuplicateInvoice(e) {
    if (e.row.data.id && e.row.data.purchaseOrderId) {
      const dataSource = this.invoiceGrid.instance.getDataSource();
      const invoices = dataSource.items();
      const sameInvoices = invoices.find(i => i.id !== e.row.data.id && i.purchaseOrderId === e.row.data.purchaseOrderId);
      return sameInvoices === undefined ? false : true;
    }
    return false;
  }

  downloadAttachment(e) {
    // download attachment
    this.loadingFile = true;
    this.subscriptions.push(
      this.invoiceService.getInvoiceAttachment(e).subscribe({
        next: (res) => {
          // saveAs(this.globalService.getBlobFromBase64(res.attachment, res.attachmentTypeId), res.attachmentName);
          this.poService.convertBlobAndOpen(res.attachment, res.attachmentTypeId, res.attachmentName, true, e);
          this.loadingFile = false;
        }, error: (err) => {
          this.notiService.notify(err);
          this.loadingFile = false;
        }
      })
    );
  }

  processInvoices() {
    if (this.selectedInvoiceBatchId) {
      this.processPopupVisible = true;
    } else {
      this.notiService.showInfo('Select a batch');
    }
  }

  processInvoicesGo() {
    this.processPopupVisible = false;
    // authorise
    this.loadingFile = true;
    this.subscriptions.push(
      this.invoiceService.processInvoiceBatch(this.selectedInvoiceBatchId).subscribe({
        next: () => {
          this.loadingFile = false;
          this.setUpDataSource(false);
        }, error: (err) => {
          this.notiService.notify(err);
          this.loadingFile = false;
        }
      })
    );
  }

  openPO(e) {
    if (e.row.data.jobId && e.row.data.purchaseOrderId) {
      const jobNumber = this.jobs.find(i => i.id === e.row.data.jobId)?.jobNumber;
      const dataRecord = {
        purchaseOrderIds: [e.row.data.purchaseOrderId],
        emailAddresses: [],
        ccToSelf: false,
        download: true,
        printPrices: true
      };

      this.notiService.showInfo('downloading order');

      this.subscriptions.push(
        this.poService.sendPurchaseOrders(e.row.data.jobId, dataRecord)
          .subscribe({
            next: (orderResponse) => {
              this.poService.convertAndSave(orderResponse.pdfOrders, jobNumber, e.row.data.poNumber, false);
            },
            error: (err) => {
              this.notiService.notify(err);
            }
          })
      );
    }
  }

  orderLookup(cellInfo) {
    this.selectedOrderId = cellInfo.data.purchaseOrderId;
    this.orderLookupSwitch = !this.orderLookupSwitch;
    this.showOrderLookupPopup = true;
  }

  getOrderFromLookup(e, cellInfo) {
    this.showOrderLookupPopup = false;
    if (e) {
      const po = this.poService.allPurchaseOrdersForCompany.find(i => i.id === e);
      if (po) {
        const job = this.jobs.find(i => i.id === po.jobId);
        cellInfo.setValue(job?.jobNumber + '.' + po.poNumber);
      }
    }
  }

  setEditedValue(valueChangedEventArg, cellInfo) {
    cellInfo.setValue(valueChangedEventArg.value);
  }

  onEditingStart(e) {
    this.showOrderLookupPopup = false;
    this.attachmentExists = e.data?.hasBlob;
    this.currentInvoiceId = e.data?.id;
    this.files = [];
  }

  showDuplicateInvoices(e) {
    // attach docs from the Info section of Truth Engine
    const dataSource = this.invoiceGrid.instance.getDataSource();
    const invoices = dataSource.items();
    const sameInvoices = invoices.filter(i => i.purchaseOrderId === e.row.data.purchaseOrderId);

    const modalRef = this.modalService.open(DuplicateInvoiceComponent, { windowClass: 'modal-1000' });
    modalRef.componentInstance.invoices = sameInvoices;

    modalRef.result.then(() => {
    });
  }

  onCellPrepared(e) {
    if (e.rowType === 'data') {
      if (e.column.dataField === 'remainingBudget') {
        const remainingBudget = this.calculateRemainingBudget(e.data);
        if (remainingBudget < 0) {
          // check if there are other orders we could use
          const po = this.poService.allPurchaseOrdersForCompany.find(i => i.id === e.data.purchaseOrderId);

          if (po) {
            const pos = this.poService.allPurchaseOrdersForCompany
              .filter(i => i.jobId === po.jobId && i.costCentreId === po.costCentreId && i.id !== e.data.purchaseOrderId
                && (i.orderTotal - (i.approvedInvoicesTotal ? i.approvedInvoicesTotal : 0) > 0));

            let count = 0;
            pos.forEach(pOrder => {
              if (pOrder.vendorId === e.data.vendorId) {
                count++;
              } else {
                // check for other orders we could use
                const vendorCanInvoice = this.maintenanceService.allVendors.find(i => i.id === pOrder.vendorId);

                if (vendorCanInvoice?.canAnyVendorInvoice) {
                  count++;
                } else {
                  const vendorPayable = this.maintenanceService.vendorPayables
                    .find(i => i.orderVendorId === pOrder.vendorId && i.payVendorId === e.data.vendorId);

                  if (vendorPayable) {
                    count++;
                  }
                }
              }
            });

            if (count > 0) {
              e.cellElement.classList.add('orange');
            } else {
              e.cellElement.classList.add('red');
            }
          }
        }
      }
    }
  }

  onVendorDropDownChanged(cellInfo, e) {
    if (!e.value) {
      cellInfo.setValue(null);
    }
  }

  onVendorSelectionChanged(cellInfo, dropDownBox, event) {
    if (event.selectedRowKeys.length > 0) {
      cellInfo.setValue(event.selectedRowsData[0].id);
      dropDownBox.close();
    }
  }

  refresh() {
    if (this.selectedInvoiceBatchId) {
      this.poService.allPurchaseOrdersForCompany = [];
      this.loadData(false);
    } else {
      this.notiService.showInfo('Select a batch');
    }
  }

  addInvoice() {
    if (this.selectedInvoiceBatchId) {
      if (this.invoiceGrid.instance.hasEditData()) {
        this.notiService.showInfo('Save or cancel editing first.');
      } else {
        const modalRef = this.modalService.open(AddInvoiceComponent, { windowClass: 'modal-edit', backdrop: 'static', keyboard: false });
        modalRef.componentInstance.selectedInvoiceBatchId = this.selectedInvoiceBatchId;

        const dataSource = this.invoiceGrid.instance.getDataSource();
        modalRef.componentInstance.currentBatchInvoices = dataSource.items();

        modalRef.result.then(() => {
          this.setUpDataSource(true);
        });
      }
    } else {
      this.notiService.showInfo('Select a batch');
    }
  }

  clearStatePersistance() {
    this.loading = true;
    localStorage.removeItem('invoice-entry');
    setTimeout(() => {
      this.loading = false;
    }, 300); // wait
  }

  async setInvoiceDateValue(rowData, value: Date, originalData) {
    if (value) {
      if (this.maintenanceService.orderControl.minPostingDate
        && this.invoiceService.dateDiffInDays(new Date(this.maintenanceService.orderControl.minPostingDate.valueOf()), new Date(value.toDateString())) > 0) {
        this.notiService.showWarning('Date older than minimum allowed');
      } else {
        rowData.invoiceDate = new Date(value.toDateString());

        if (this.invoiceService.dateDiffInDays(new Date(), rowData.invoiceDate) > 60) {
          this.notiService.showWarning('Possible old date entered');
        }
        rowData.dueDate = await this.invoiceService.calcDueDate(originalData.vendorId, rowData.invoiceDate, new Date());
      }
    } else {
      rowData.invoiceDate = null;
    }
  }

  splitInvoice(e) {
    // allow to split
    this.invoiceData = e.row.data;
    this.invoiceId = e.row.data.id;
    this.selectedOrderId = e.row.data.purchaseOrderId;
    this.orderLookupSwitch = !this.orderLookupSwitch;
    this.showOrderLookupPopupSplits = true;
  }

  splitOrdersFromLookup(e) {
    this.showOrderLookupPopupSplits = false;
    this.setUpDataSource(false);
  }

  openScanDropZone() {
    if (this.selectedInvoiceBatchId) {
      this.scanDropVisible = true;
    } else {
      this.notiService.showInfo('Select a batch');
    }
  }

  uploadForScan() {
    // Check if there are files to upload
    if (this.files && this.files.length) {
      this.loadingFile = true;
      this.fileCount = this.files.length;
      let itemNumber = -1;


      this.files.forEach(file => {
        itemNumber++;
        setTimeout(() => {
          const formData: FormData = new FormData();
          formData.append('pdf', file, file.name);

          this.subscriptions.push(
            this.invoiceService.scanInvoice(this.selectedInvoiceBatchId, formData).subscribe({
              next: () => {
                this.fileCount--;
                if (this.fileCount === 0) {
                  this.files = [];
                  this.scanDropVisible = false;
                  this.loadingFile = false;
                  this.setUpDataSource(true);
                }
              }, error: (err) => {
                this.notiService.notify(err);
                this.fileCount--;
                if (this.fileCount === 0) {
                  this.files = [];
                  this.scanDropVisible = false;
                  this.loadingFile = false;
                }
              }
            })
          );
        }, itemNumber * 1000);
      });
    }
  }


  openHistory(e) {
    if (e.row.data.purchaseOrderId) {
      // see other invoices against this PO
      const modalRef = this.modalService.open(PreviousInvoicesComponent, { windowClass: 'modal-1200' });
      modalRef.componentInstance.purchaseOrderId = e.row.data.purchaseOrderId;
      modalRef.componentInstance.invoiceId = e.row.data.id;
      modalRef.componentInstance.jobNumber = this.calculateJobNumber(e.row.data);
      modalRef.componentInstance.costCentreId = e.row.data.costCentreId;

      modalRef.result.then(() => {
      });
    } else {
      this.notiService.showInfo('No Purchase Order for this invoice');
    }
  }
}
