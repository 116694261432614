import { MaintenanceService } from './../../../services/felixApi/maintenance.service';
import { AuthService } from './../../../services/auth.service';
import { NotificationService } from './../../../services/notification.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Division } from '../../../dtos/division';
import { Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FileProcessingService } from '../../../services/file-processing.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { FileAttachment } from '../../../dtos/file-attachment';
import { OrderAttachmentTypeEnum } from '../../../dtos/order-attachment';

@Component({
  selector: 'js-order-logo',
  templateUrl: './order-logo.component.html',
  styleUrls: ['./order-logo.component.scss']
})
export class OrderLogoComponent implements OnInit, OnDestroy {

  division: Division;
  loading = true;
  subscriptions: Subscription[] = [];
  imageFound = false;
  logo: FileAttachment;
  isAdmin: boolean;
  fileToUpload: any;
  imageChangedEvent: any;

  constructor(
    private maintenanceService: MaintenanceService,
    private notiService: NotificationService,
    private auth: AuthService,
    public activeModal: NgbActiveModal,
    private imgService: FileProcessingService
  ) { }

  ngOnInit() {
    this.isAdmin = this.auth.isAdminOrSuper();
    this.loadLogo();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  loadLogo() {
    this.logo = null;
    this.subscriptions.push(
      this.maintenanceService.getOrderLogo().subscribe({
        next: (attachment) => {
          if (attachment) {
            this.logo = attachment;
          }
          this.loading = false;
        }, error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }

  close() {
    this.activeModal.close();
  }

  fileChangeEvent(event: any): void {
    this.fileToUpload = event;
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.fileToUpload = event.base64;
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  fileSelected() {
    if (this.fileToUpload) {
      this.loading = true;
      this.imgService.compressImage(this.fileToUpload, 600, 400).subscribe({
        next: (compImageFormData) => {
          this.subscriptions.push(
            this.maintenanceService.uploadOrderLogo(compImageFormData, OrderAttachmentTypeEnum.Logo).subscribe({
              next: () => {
                this.loadLogo();
              }, error: (err) => {
                this.notiService.notify(err);
                this.loading = false;
              }
            })
          );
        }, error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      });
    }
  }

  deleteLogo() {
    this.loading = true;
    this.subscriptions.push(
      this.maintenanceService.deleteOrderLogo().subscribe({
        next: () => {
          this.logo = null;
          this.loading = false;
        }, error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }
}
