<div *ngIf="!loadingData" class="page">
  <dx-data-grid class="m-auto" [height]="gridHeight" [dataSource]="dataSource" [allowColumnReordering]="true"
    [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true" [rowAlternationEnabled]="true"
    [wordWrapEnabled]="true" [hoverStateEnabled]="true" (onToolbarPreparing)="onToolbarPreparing($event)"  (onExporting)="gridService.onExporting($event, 'splits')">

    <dxo-search-panel [visible]="true" location="before" [width]="400"></dxo-search-panel>
    <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping #expand [autoExpandAll]="true" [contextMenuEnabled]="false"></dxo-grouping>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-column-chooser [enabled]="true" mode="dragAndDrop"> </dxo-column-chooser>
    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="splits-report" [savingTimeout]="500">
    </dxo-state-storing>

    <dxi-column dataField="jobNumber" dataType="string"> </dxi-column>

    <dxi-column dataField="variation" dataType="string"> </dxi-column>

    <dxi-column dataField="split" dataType="number" alignment="left"> </dxi-column>

    <dxi-column dataField="splitType" dataType="string"> </dxi-column>

    <dxi-column dataField="amount" dataType="number"> </dxi-column>

    <dxi-column dataField="invoiceToClient" dataType="string"> </dxi-column>

    <dxi-column dataField="splitInvoiceDate" dataType="date" format="d/M/yy" alignment="center"> </dxi-column>

    <dxi-column dataField="claimInvoiceDate" dataType="date" format="d/M/yy" alignment="center"> </dxi-column>

    <dxo-summary>
      <dxi-total-item column="jobNumber" summaryType="count" valueFormat="#,###" displayFormat="{0} rows">
      </dxi-total-item>
      <dxi-total-item column="amount" summaryType="sum" valueFormat="#,###" displayFormat="{0}">
      </dxi-total-item>
    </dxo-summary>
  </dx-data-grid>
</div>

<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loadingData" [showIndicator]="true"
  [showPane]="true" [shading]="false" [hideOnOutsideClick]="false">
</dx-load-panel>
