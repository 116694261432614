<div class="modal-header">
  <h3 class="modal-title">Duplicate invoices for PO</h3>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="card p-2">
    <dx-data-grid [height]="400" [dataSource]="dataSource" [allowColumnReordering]="false" [allowColumnResizing]="true"
      [repaintChangesOnly]="true" [showBorders]="true" [rowAlternationEnabled]="false" [wordWrapEnabled]="true"
      [hoverStateEnabled]="true"  (onExporting)="gridService.onExporting($event, 'duplicates')">

      <dxo-export [enabled]="true"></dxo-export>
      <dxo-scrolling mode="standard"></dxo-scrolling>
      <dxo-paging [enabled]="false"></dxo-paging>

      <dxi-column dataField="vendorId" caption="Vendor" dataType="number"
        [calculateSortValue]="calculateVendorSortValue">
        <dxo-lookup [dataSource]="vendors" displayExpr="vendorName" valueExpr="id"></dxo-lookup>
      </dxi-column>

      <dxi-column dataField="orderNumber" width="130">
      </dxi-column>

      <dxi-column dataField="invoiceNumber" width="130">
      </dxi-column>

      <dxi-column dataField="totalIncGST" dataType="number" width="110" [format]="{ type: 'fixedPoint', precision: 2 }">
      </dxi-column>

      <dxi-column dataField="totalGST" dataType="number" width="90" [format]="{ type: 'fixedPoint', precision: 2 }">
      </dxi-column>

      <dxi-column dataField="totalExGST" dataType="number" width="110" [format]="{ type: 'fixedPoint', precision: 2 }">
      </dxi-column>

      <dxi-column dataField="purchaseOrderId" dataType="number" width="120" [visible]="false">
      </dxi-column>
    </dx-data-grid>

    <div>
      <div class='totalText'>Order Total:</div>
      <div class="totalAmount">{{po.orderTotal | number : '1.2'}}</div>
    </div>
    <div>
      <div class='totalText'>Previously Approved Invoices:</div>
      <div class="totalAmount">{{po.approvedInvoicesTotal | number : '1.2'}}</div>
    </div>
    <div>
      <div class='totalText'>These Invoices:</div>
      <div class="totalAmount">{{invoicesTotalExGst | number : '1.2'}}</div>
    </div>
    <div>
      <div class='totalText'>Remaining Budget Will Be:</div>
      <div class="totalAmount" [class.red]="totalOverBudget < 0">{{totalOverBudget | number : '1.2'}}</div>
    </div>
  </div>
</div>
