<dx-popup class="popup" [width]="gridWidth" [height]="gridHeight" [showTitle]="true" [title]="title"
  [dragEnabled]="true" [hideOnOutsideClick]="false" [(visible)]="popupVisible" [shading]="true">
  <div *dxTemplate="let data of 'content'">
    <dx-data-grid *ngIf="!loading" #poGrid id="poGrid" [height]="gridHeight - 130" [dataSource]="purchaseOrderData"
      [allowColumnReordering]="false" [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true"
      [rowAlternationEnabled]="true" [wordWrapEnabled]="true" [focusedRowEnabled]="true"
      [(focusedRowKey)]="selectedOrderId" [autoNavigateToFocusedRow]="true" [(selectedRowKeys)]="selectedOrders"
      [hoverStateEnabled]="true" (onToolbarPreparing)="onToolbarPreparing($event)" (onSaving)="onSaving($event)"
      (onExporting)="gridService.onExporting($event, 'order-lookup')">

      <dxo-search-panel [visible]="true" location="before" [width]="400"></dxo-search-panel>
      <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
      <dxo-export [enabled]="true"></dxo-export>
      <dxo-paging [enabled]="false"></dxo-paging>
      <dxo-sorting mode='multiple'></dxo-sorting>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-filter-panel [visible]="true"></dxo-filter-panel>

      <dxo-editing *ngIf="selectionMode === 'multiple'" mode="batch" [allowUpdating]="true" startEditAction="click">
      </dxo-editing>

      <dxo-selection [allowSelectAll]="false" showCheckBoxesMode='never' mode="selectionMode">
      </dxo-selection>

      <dxo-keyboard-navigation [editOnKeyPress]="true" enterKeyAction="moveFocus" enterKeyDirection="column">
      </dxo-keyboard-navigation>

      <dxi-column dataField="jobNumber" caption="Job" dataType="string" width="100"
        [calculateCellValue]='calculateJobNumber' [allowEditing]="false">
      </dxi-column>

      <dxi-column dataField="jobId" caption="Job Address" dataType="number" [allowEditing]="false">
        <dxo-lookup [dataSource]="jobs" displayExpr="jobAddressString" valueExpr="id"></dxo-lookup>
      </dxi-column>

      <dxi-column dataField="costCentreId" caption="Cost Code" dataType="number" width="100" alignment="center"
        [calculateSortValue]="calculateCostCentreSortValue" [allowEditing]="false">
        <dxo-lookup [dataSource]="costCentres" displayExpr="priceFileCode" valueExpr="id"></dxo-lookup>
      </dxi-column>

      <dxi-column dataField="costCentreDescription" caption="Cost Centre" [calculateCellValue]='calculateCostCentreDesc'
        [allowEditing]="false">
      </dxi-column>

      <dxi-column dataField="poNumber" caption="PO Number" width="110" [allowEditing]="false">
      </dxi-column>

      <dxi-column dataField="phaseId" caption="Phase" dataType="number" [width]="70">
        <dxo-lookup [dataSource]="phases" displayExpr="phaseCode" valueExpr="id">
        </dxo-lookup>
      </dxi-column>

      <dxi-column dataField="vendorId" caption="Vendor" dataType="number" [(filterValue)]="vendorId"
        [allowEditing]="false" [calculateSortValue]="calculateVendorSortValue">
        <dxo-lookup [dataSource]="vendors" displayExpr="vendorName" valueExpr="id"></dxo-lookup>
      </dxi-column>

      <dxi-column dataField="orderTotal" dataType="number" width="110" [format]="{ type: 'fixedPoint', precision: 2 }"
        [allowEditing]="false">
      </dxi-column>

      <dxi-column dataField="approvedInvoicesTotal" caption="Approved Invoices Ex.GST" dataType="number" width="110"
        [format]="{ type: 'fixedPoint', precision: 2 }" [allowEditing]="false">
      </dxi-column>

      <dxi-column dataField="remainingBudgetExGST" caption="Remaining Budget Ex.GST" dataType="number" width="110"
        [format]="{ type: 'fixedPoint', precision: 2 }" [calculateCellValue]="calculateRemainingBudget"
        [allowEditing]="false">
      </dxi-column>

      <dxi-column *ngIf="selectionMode === 'multiple'" dataField="invoiceAmountEx" caption="Invoice Amount Ex.GST"
        dataType="number" width="105" [format]="{ type: 'fixedPoint', precision: 2 }"
        [editorOptions]="amountFieldOptions" [setCellValue]="setExInvoiceAmount">
      </dxi-column>

      <dxi-column *ngIf="selectionMode === 'multiple'" dataField="invoiceAmount" caption="Invoice Amount Inc.GST"
        dataType="number" width="105" [format]="{ type: 'fixedPoint', precision: 2 }"
        [editorOptions]="amountFieldOptions" [setCellValue]="setIncInvoiceAmount">
      </dxi-column>


      <!-- summary totals -->
      <dxo-summary [recalculateWhileEditing]="true" [calculateCustomSummary]="calculateCustomSummary">
        <dxi-total-item column="jobId" summaryType="count" displayFormat="{0} rows">
        </dxi-total-item>
        <dxi-total-item name="remaining1" column="remainingBudgetExGST" summaryType="custom" displayFormat="Total">
        </dxi-total-item>
        <dxi-total-item column="invoiceAmountEx" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}">
        </dxi-total-item>
        <dxi-total-item column="invoiceAmount" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}">
        </dxi-total-item>
        <dxi-total-item *ngIf="rowData" name="remaining" column="remainingBudgetExGST" summaryType="custom"
          displayFormat="Remaining">
        </dxi-total-item>
        <dxi-total-item *ngIf="rowData" name="totalExRemaining" column="invoiceAmountEx" summaryType="custom"
          valueFormat="#,##0.00" displayFormat="{0}">
        </dxi-total-item>
        <dxi-total-item *ngIf="rowData" name="totalIncRemaining" column="invoiceAmount" summaryType="custom"
          valueFormat="#,##0.00" displayFormat="{0}">
        </dxi-total-item>
      </dxo-summary>

      <dxi-column type="buttons" [width]="50">
        <dxi-button icon="download" [onClick]="openPO" hint='Download PO'>
        </dxi-button>
      </dxi-column>
    </dx-data-grid>

    <dx-button *ngIf="!loading && selectionMode === 'single'" text="Select" class="mt-1" type="default"
      stylingMode="default" (onClick)="select()">
    </dx-button>

    <dx-button *ngIf="!loading && selectionMode === 'single'" text="Add New Order" class="mt-1 ms-3" type="default"
      stylingMode="outlined" (onClick)="zeroOrderPopup()">
    </dx-button>

  </div>
</dx-popup>


<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#employee' }" [(visible)]="savingData"
  [showIndicator]="true" [showPane]="true" [shading]="true" [hideOnOutsideClick]="false">
</dx-load-panel>
