import { GlobalService } from './../../services/global.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'js-report-menu',
  templateUrl: './report-menu.component.html',
  styleUrls: ['./report-menu.component.scss']
})
export class ReportMenuComponent implements OnInit {

  sections: string[];
  counter = 0;
  // selectedSection = 'WIP Report';

  constructor(
    private globalService: GlobalService
  ) { }

  ngOnInit() {
    if (this.globalService.canSeeAdminReports) {
      this.sections = ['Margins', 'WIP Report', 'Accruals', 'Extras'];
    } else {
      this.sections = ['Extras'];
    }
  }

  tabSelected(e) {
    this.counter++;
    // this.selectedSection = e.itemData;
  }

}
