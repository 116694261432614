<div *ngIf="!loadingData" class="page">

  <dx-data-grid class="m-auto" [height]="gridHeight" [dataSource]="dataSource" [allowColumnReordering]="true"
    [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true" [rowAlternationEnabled]="true"
    [wordWrapEnabled]="true" [hoverStateEnabled]="true" (onToolbarPreparing)="onToolbarPreparing($event)"
    (onEditorPreparing)="onEditorPreparing($event)" (onExporting)="gridService.onExporting($event, 'wip-report')">

    <dxo-search-panel [visible]="true" location="before" [width]="200"></dxo-search-panel>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-column-chooser [enabled]="true" mode="dragAndDrop"> </dxo-column-chooser>
    <dxo-state-storing [enabled]="true" type="custom" [savingTimeout]="500" [customSave]="saveState"
      [customLoad]="loadState">
    </dxo-state-storing>

    <dxo-editing mode="row" [allowUpdating]="true" [useIcons]="true" startEditAction="click"
      [selectTextOnEditStart]="true" refreshMode="repaint">
    </dxo-editing>

    <dxi-column dataField="jobNumber" caption="Job" width="90" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="masterJob" width="85" [allowEditing]="false" [calculateCellValue]="calculateMasterJob"
      [visible]="false">
    </dxi-column>

    <dxi-column dataField="division" width="120" [allowEditing]="false" [calculateCellValue]="calculateDivision">
    </dxi-column>

    <dxi-column dataField="contractName" [allowEditing]="false" minWidth="200"> </dxi-column>

    <dxi-column dataField="currentActivity" [allowEditing]="false" minWidth="100"> </dxi-column>

    <dxi-column dataField="contractPrice" caption="Contract Price Inc.GST" dataType="number" width="110"
      [format]="{ type: 'fixedPoint', precision: 0 }" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="contractPriceIncGST" caption="Forecast Price Inc.GST" dataType="number" width="110"
      headerCellTemplate="headerTemplate" [format]="{ type: 'fixedPoint', precision: 0 }"> </dxi-column>

    <dxi-column dataField="postContractVariations" dataType="number" width="100"
      [format]="{ type: 'fixedPoint', precision: 0 }" [allowEditing]="false"> </dxi-column>

    <dxi-column dataField="contractTotal" caption="Contract Total Inc.GST" dataType="number" width="110"
      [calculateCellValue]="calcTotalContract" [format]="{ type: 'fixedPoint', precision: 0 }" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="contractTotalExGST" caption="Contract Total Ex.GST" dataType="number" width="110"
      [calculateCellValue]="calcContractExGst" [format]="{ type: 'fixedPoint', precision: 0 }" [allowEditing]="false"
      [visible]="false">
    </dxi-column>

    <dxi-column *ngIf="showOrderedTotal" dataField="allOrdersTotal" dataType="number" width="110"
      [format]="{ type: 'fixedPoint', precision: 0 }" [allowEditing]="false"> </dxi-column>

    <dxi-column dataField="jobCostExcGST" caption="Job Cost Ex.GST" dataType="number" width="110"
      headerCellTemplate="headerTemplate" [format]="{ type: 'fixedPoint', precision: 0 }"> </dxi-column>

    <dxi-column dataField="isOrdersLocked" caption="Orders Locked?" dataType="boolean" width="25"
      [calculateCellValue]="calcIsOrdersLocked" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="profit" caption="Gross Profit" dataType="number" width="100"
      [calculateCellValue]="calcProfit" [format]="{ type: 'fixedPoint', precision: 0 }" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="markUp" caption="Mark Up%" dataType="number" width="85"
      [calculateCellValue]="calcMarkUpPercent" [format]="{ type: 'fixedPoint', precision: 2 }" alignment='center'
      [setCellValue]="setPercentCellValue">
    </dxi-column>

    <dxi-column dataField="margin" caption="Margin %" dataType="number" width="95"
      [calculateCellValue]="calcMarginPercent" [format]="{ type: 'fixedPoint', precision: 2 }" alignment='center'
      [setCellValue]="setMarginCellValue">
    </dxi-column>

    <dxi-column *ngIf="invoiceBreakDown" dataField="claimInvoices" caption="Deposits & Claims Invoiced Ex.GST"
      dataType="number" width="110" [format]="{ type: 'fixedPoint', precision: 0 }" [allowEditing]="false">
    </dxi-column>

    <dxi-column *ngIf="invoiceBreakDown" dataField="variationInvoices" caption="Variations Invoiced Ex.GST"
      dataType="number" width="110" [format]="{ type: 'fixedPoint', precision: 0 }" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="incomeInvoices" caption="Total Income Ex.GST" dataType="number" width="110"
      [format]="{ type: 'fixedPoint', precision: 0 }" [allowEditing]="false"> </dxi-column>

    <dxi-column dataField="costInvoices" [caption]="invoiceCaption" dataType="number" width="110"
      [format]="{ type: 'fixedPoint', precision: 0 }" [allowEditing]="false"> </dxi-column>

    <dxi-column dataField="plAdjustment" caption="Income Adjustment" dataType="number" width="110"
      headerCellTemplate="headerTemplate" [calculateCellValue]="calcAdjustment"
      [format]="{ type: 'fixedPoint', precision: 0 }" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="costAdjustment" caption="Cost Adjustment" dataType="number" width="110"
      headerCellTemplate="headerTemplate" [calculateCellValue]="calcCostAdjustment"
      [format]="{ type: 'fixedPoint', precision: 0 }" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="siteAddress" minWidth="200" [calculateCellValue]="calculateSiteAddress" [visible]="false"
      [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="completionDate" caption="Practical Completion" dataType="date" width="100" format="d/M/yy"
      alignment="center" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="contractSigned" caption="Contract Signed" dataType="date" width="100" format="d/M/yy"
      alignment="center" [visible]="false" [allowEditing]="false" [calculateCellValue]="calculateContractSignedDate">
    </dxi-column>

    <dxi-column dataField="houseTypeId" caption="House Type" dataType="number" width="130" [allowEditing]="false"
      [visible]="false">
      <dxo-lookup [dataSource]="houseTypes" displayExpr="description" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="orderExtraTotal" caption="Total Extras Ex.GST" dataType="number" width="110" format="#,###"
      [allowEditing]="false" [visible]="false"> </dxi-column>

    <dxi-column dataField="siteStartInDate" caption="Site Start In" dataType="date" width="100" format="d/M/yy"
      [allowEditing]="false" [visible]="false"> </dxi-column>

    <dxi-column dataField="siteStartOutDate" caption="Site Start Out" dataType="date" width="100" format="d/M/yy"
      [allowEditing]="false" [visible]="false"> </dxi-column>


    <dxo-summary>
      <dxi-total-item column="contractPrice" summaryType="sum" valueFormat="#,###" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="contractPriceIncGST" summaryType="sum" valueFormat="#,###" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="postContractVariations" summaryType="sum" valueFormat="#,###" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="contractTotal" summaryType="sum" valueFormat="#,###" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="contractTotalExGST" summaryType="sum" valueFormat="#,###" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="allOrdersTotal" summaryType="sum" valueFormat="#,###" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="jobCostExcGST" summaryType="sum" valueFormat="#,###" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="profit" summaryType="sum" valueFormat="#,###" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="plAdjustment" summaryType="sum" valueFormat="#,###" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="costAdjustment" summaryType="sum" valueFormat="#,###" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="incomeInvoices" summaryType="sum" valueFormat="#,###" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="claimInvoices" summaryType="sum" valueFormat="#,###" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="variationInvoices" summaryType="sum" valueFormat="#,###" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="costInvoices" summaryType="sum" valueFormat="#,###" displayFormat="{0}">
      </dxi-total-item>
    </dxo-summary>

    <div *dxTemplate="let info of 'headerTemplate'">
      <p>{{info.column.caption}}</p>
      <i (click)="$event.preventDefault(); $event.stopPropagation();help(info.column.dataField)"
        class="material-icons md-18 help">help_outline</i>
    </div>
  </dx-data-grid>

</div>

<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [visible]="loadingData" [showIndicator]="true"
  [showPane]="true" [shading]="false" [hideOnOutsideClick]="false">
</dx-load-panel>
