import { GridService } from './../../services/grid.service';
import { SelectedDeposit } from './../../dtos/selected-deposit';
import { DivisionService } from './../../services/felixApi/division.service';
import { JobService } from '../../services/felixApi/job.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { GlobalService } from '../../services/global.service';
import { CompanyService } from './../../services/felixApi/company.service';
import { ConfigurationEnum } from '../../dtos/configuration-enum';
import { ClaimsService } from '../../services/felixApi/claims.service';
import { NotificationService } from '../../services/notification.service';
import { Job } from '../../dtos/job';
import { JobSearchModalComponent } from '../../shared/job-search-modal/job-search-modal.component';
import { JobSearchTypeEnum } from '../../dtos/job-search-type.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IncomeInvoice, IncomeInvoiceTypeEnum } from '../../dtos/income-invoice';
import CustomStore from 'devextreme/data/custom_store';
import { formatDate } from 'devextreme/localization';


@Component({
  selector: 'js-deposits',
  templateUrl: './deposits.component.html',
  styleUrls: ['./deposits.component.scss']
})
export class DepositsComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  dataSource: CustomStore;
  loading = true;
  jobs: Job[];
  gridHeight: number;
  jobId: number;
  jobNumber = '';
  minuteCountdown = 0;
  inEditMode: boolean;

  runTypes = [
    { id: 1, description: 'All Jobs' },
    { id: 2, description: 'Create Manual Claim' }
  ];
  runTypeId = 1;
  currentJobId: number;
  selectedRecords: string[] = [];
  selectedDeposits: SelectedDeposit[] = [];
  invoices: IncomeInvoice[];
  invoiceDate: any = new Date();
  accountingSystemType: number;
  loadingData = true;

  constructor(
    private globalService: GlobalService,
    private compService: CompanyService,
    private claimsService: ClaimsService,
    private notiService: NotificationService,
    private divisionService: DivisionService,
    private modalService: NgbModal,
    public gridService: GridService,
    private jobService: JobService) {
    this.calculateClientName = this.calculateClientName.bind(this);
    this.calculateSiteAddress = this.calculateSiteAddress.bind(this);
    this.calculateConnectionStatus = this.calculateConnectionStatus.bind(this);
    this.calculateClientEmail = this.calculateClientEmail.bind(this);
  }

  ngOnInit(): void {

    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          this.setHeightWidths();
        }
      )
    );

    this.setHeightWidths();
    this.getCompanyConfigurations();
    this.loadData(true, true);
    this.setupDataRefresh();
  }

  setHeightWidths() {
    this.gridHeight = window.innerHeight - 107;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getCompanyConfigurations() {
    this.subscriptions.push(
      this.compService.getCompanyConfigurations().subscribe({
        next: () => {
          this.accountingSystemType = this.globalService.getCompanyConfigValue(ConfigurationEnum.AccountingSystem);
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }

  loadData(runDataSource: boolean, useCache: boolean) {
    this.loadingData = true;
    this.resetCountdown();
    this.jobService.jobs = null; // clear in case it's changed - we had a case where an entry was updated in the main app on the same day
    this.subscriptions.push(
      this.claimsService.getClaimsData(useCache)
        .subscribe({
          next: () => {
            this.loadingData = false;
            this.jobs = this.jobService.jobs;
            this.loading = false;
            if (runDataSource) {
              if (this.runTypeId === 1) {
                this.selectOptionChanged();
              } else {
                this.onJobNumberChanged();
              }
            }
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loadingData = false;
            this.loading = false;
          }
        })
    );
  }

  setUpDataSource() {
    this.dataSource = new CustomStore({
      key: 'selectedCode',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.claimsService.getDepositInvoices(this.runTypeId === 1 ? null : this.jobId).subscribe({
              next: (res) => {
                this.invoices = res;
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      }
    });
  }


  onToolbarPreparing(e, templateName: string, toolbarTemplate2: string) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.push({
      location: 'before',
      locateInMenu: 'auto',
      template: templateName
    });

    toolbarItems.unshift({
      location: 'after',
      locateInMenu: 'auto',
      template: toolbarTemplate2
    },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          type: 'default',
          text: 'Submit',
          onClick: this.processInvoices.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          type: 'outline',
          icon: 'refresh',
          onClick: this.refresh.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          type: 'default',
          stylingMode: 'outlined',
          text: 'Reset Layout',
          onClick: this.clearStatePersistance.bind(this)
        }
      });
  }

  selectOptionChanged() {
    this.dataSource = null;
    this.currentJobId = null;
    this.jobNumber = '';
    if (this.runTypeId === 1) {
      this.setUpDataSource();
    }
  }

  calculateClientName(data) {
    return this.jobs.find(i => i.id === data.jobId)?.contractName;
  }

  calculateClientEmail(data) {
    return this.jobs.find(i => i.id === data.jobId)?.clientEmail;
  }

  jobSearch() {
    const modalRef = this.modalService.open(JobSearchModalComponent, { windowClass: 'modal-1000' });
    modalRef.componentInstance.searchType = JobSearchTypeEnum.AllJobs;

    modalRef.result.then((jobNumber) => {
      if (jobNumber) {
        this.jobNumber = jobNumber;
        this.jobId = this.jobs.find(i => i.jobNumber === jobNumber).id;
        this.setUpDataSource();
      }
    });
  }

  onJobNumberChanged() {
    this.jobNumber = this.jobNumber.trim();
    this.jobId = this.jobs.find(i => i.jobNumber === this.jobNumber)?.id;
    if (this.jobId && this.currentJobId !== this.jobId) {
      this.currentJobId = this.jobId;
      this.setUpDataSource();
    }
  }

  calculateConnectionStatus(data) {
    const job = this.jobs.find(i => i.id === data.jobId);
    if (job) {
      const division = this.divisionService.divisions.find(i => i.id === job.divisionId);

      if (!division) {
        return 'Division not specified';
      }

      if (!division.accountingSystemTenantId || division.accountingSystemTenantId.trim() === '') {
        return 'Division not linked to accounts';
      }

      // Xero/MYOB requires an Income Account
      if (this.accountingSystemType == 1 || this.accountingSystemType == 3) {
        if (!division.incomeAccountId || division.incomeAccountId.trim() === '') {
          return 'Division not linked to an Income Account';
        }
      }

      // Xero also requires a Job Tracking Category
      if (this.accountingSystemType == 1) {
        if (!division.jobTrackingCategoryId || division.jobTrackingCategoryId.trim() === '') {
          return 'Division not linked to a Job Tracking Category';
        }
      }

      // QuickBooks and Business Central require a Service Code for Deposit Income
      if (this.accountingSystemType == 2 || this.accountingSystemType == 4) {
        if (!division.depositIncomeAccountId || division.depositIncomeAccountId.trim() === '') {
          return 'Division not linked to a Service Code for Deposit';
        }
      }
    } else {
      return 'Job Invalid';
    }

    const incomeError = this.claimsService.incomeInvoiceErrors
      .find(i => i.jobId === data.jobId
        && i.incomeInvoiceTypeId === IncomeInvoiceTypeEnum.Deposit
        && i.orderNumber === data.orderNumber);

    if (incomeError) {
      if (incomeError.errorString.substring(1, incomeError.errorString.length) === 'Invoice # must be unique.') {
        return 'Integration Error: ' + incomeError.errorString + ' - Process again to add .2 to the end';
      }
      else if (incomeError.errorString.toLowerCase().indexOf("pending processing in business central") != -1) {
        return incomeError.errorString;
      } 
      return 'Integration Error: ' + incomeError.errorString;
    }
    return 'OK to Submit';
  }

  processInvoices() {
    // authorise
    if (!this.loading) {
      this.selectedDeposits = [];
      this.selectedRecords.forEach(selectedRecord => {
        this.selectedDeposits.push({ selectedCode: selectedRecord, invoiceDate: formatDate(this.invoiceDate, 'yyyy-MM-dd') });
      });
      this.loading = true;
      this.subscriptions.push(
        this.claimsService.submitDepositInvoices(this.runTypeId === 1 ? null : this.jobId,
          { selectedDeposits: this.selectedDeposits }).subscribe({
            next: () => {
              this.loading = false;
              this.selectedRecords = [];
              this.setUpDataSource();
            }, error: (err) => {
              this.notiService.notify(err);
              this.loading = false;
            }
          })
      );
    }
  }

  setupDataRefresh(): void {
    // Refresh data every 10 minutes by counting down the minutes
    this.subscriptions.push(
      timer(0, 60000).subscribe(() => {
        if (!this.inEditMode) {
          if (this.minuteCountdown > 0 && !this.loading) {
            this.minuteCountdown--; // Decrement the countdown
          }

          if (this.minuteCountdown <= 0) {
            this.loadData(true, false);
          }
        }
      })
    );
  }

  refresh() {
    this.loadData(true, false);
  }

  clearStatePersistance() {
    this.loadingData = true;
    localStorage.removeItem('deposit-invoices');
    setTimeout(() => {
      this.loadingData = false;
    }, 300); // wait
  }

  calculateSiteAddress(data) {
    return this.jobs.find(i => i.id === data.jobId)?.jobAddressString;
  }
  resetCountdown() {
    this.minuteCountdown = 10; // Reset the countdown 
  }
}