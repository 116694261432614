import { SplitOrder } from './purchase-order';
export class Invoice {
  public id: number;
  public vendorId: number;
  public invoiceNumber: string;
  public invoiceDate: Date;
  public orderNumber: string;
  public description: string;
  public totalExGST: number;
  public totalGST: number;
  public totalIncGST: number;
  public invoiceStatusId: number;
  public hasBlob: boolean;
  public purchaseOrderId: number;
  public invoiceBatchId: number;
  public allocatedToUserId: number;
  public isWorkDone: boolean;
  public authorisedByUserId: number;
  public onHoldType: string;
  public externalInvoiceId: string;
  public externalInvoiceStatus: string;
  public paidDate: Date;
  public dueDate: Date;

  public createDate: Date;
  public createUserId: number;
  public modifiedDate: Date;
  public modifiedUserId: number;

  public jobId: number;
  public poNumber: string;
  public vendorGroupId: number;
  public costCentreId: number;

  public sendToEmail: string;
  public ccToSelf: boolean;
  public emailSubject: string;

  constructor(vendorId: number, invoiceNumber: string, invoiceDate: Date, orderNumber: string, description: string,
    totalExGST: number, totalGST: number, totalIncGST: number, purchaseOrderId: number, invoiceBatchId: number,
    isWorkDone: boolean, authorisedByUserId: number, dueDate: Date, invoiceStatusId: number,
    sendToEmail: string, ccToSelf: boolean, emailSubject: string) {
    this.vendorId = vendorId;
    this.invoiceNumber = invoiceNumber;
    this.invoiceDate = invoiceDate;
    this.orderNumber = orderNumber;
    this.description = description;
    this.totalExGST = totalExGST;
    this.totalGST = totalGST;
    this.totalIncGST = totalIncGST;
    this.purchaseOrderId = purchaseOrderId;
    this.invoiceBatchId = invoiceBatchId;
    this.isWorkDone = isWorkDone;
    this.authorisedByUserId = authorisedByUserId;
    this.dueDate = dueDate;
    this.invoiceStatusId = invoiceStatusId;
    this.sendToEmail = sendToEmail;
    this.ccToSelf = ccToSelf;
    this.emailSubject = emailSubject;
  }
}

export enum InvoiceStatusTypeEnum {
  Entered = 1,
  OnHold = 2,
  Approved = 7,
  Posted = 8,
  Paid = 9
}

export class InvoiceAddForm {
  public vendorId: number;
  public invoiceNumber: string;
  public invoiceDate: Date;
  public dueDate: Date;
  public orderNumber: string;
  public description: string;
  public totalExGST: number;
  public totalGST: number;
  public totalIncGST: number;
  public purchaseOrderId: number;
  public jobId: number;
  public poNumber: string;
  public costCentreId: number;
  public jobNumber: string;
  public jobAddressString: string;
  public costCentreDesc: string;
  public siteManager: string;
  public invoiceBatchId: number;
  public invoiceStatusId: number;
  public hasBlob: boolean;
  public isWorkDone: boolean;
  public varianceCodeId: number;
  public varianceReasonId: number;
  public varianceReasonDescription: string;
  public varianceTotal: number;
  public splitInvoiceMasterId: number;
  public splitOrders: SplitOrder[];
}


export class RejectInvoiceForm {
  public vendorName: string;
  public invoiceNumber: string;
  public invoiceDate: Date;
  public jobNumber: string;
  public jobAddressString: string;
  public sendRejectToEmail: string;
  public comment: string;
  public ccToSelf: boolean;
  public emailSubject: string;
}

export class BackChargeForm {
  public invoicedVendor: string;
  public vendorId: number;
  public invoiceNumber: string;
  public invoiceDate: Date;
  public jobNumber: string;
  public jobAddressString: string;
  public sendToEmail: string;
  public comment: string;
  public ccToSelf: boolean;
  public emailSubject: string;
  public totalExGST: number;
  public totalGST: number;
  public totalIncGST: number;
}

export const InvoiceStatuses = [
  { id: 1, description: 'Entered' },
  { id: 2, description: 'OnHold' },
  { id: 7, description: 'Approved' },
  { id: 8, description: 'Posted' },
  { id: 9, description: 'Paid' },
  { id: 20, description: 'Rejected' },
  { id: 30, description: 'Deleted' },
  { id: 32, description: 'Split' }
];
