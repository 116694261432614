<div class="page">

  <h4>
    Job Load - use with caution!
  </h4>

  <js-loading-spinner *ngIf="loadingFile"></js-loading-spinner>

  <div *ngIf="!loadingFile" id="fileuploader">
    <dx-file-uploader #fileUploader selectButtonText="Select file" labelText="or drop file here" [multiple]="false"
      [(value)]="fileValue" uploadMode="useForm">
    </dx-file-uploader>
  </div>


  <dx-check-box *ngIf="!loadingFile" text="Skip existing jobs" [(value)]='skipExistingJobs' class="ms-2">
  </dx-check-box>
  <br>
  <br>

  <dx-button *ngIf="fileValue.length && !loadingFile" text="Load" type="default" class="mt-2 ms-2"
    (onClick)="loadFileGo()">
  </dx-button>

  <dx-data-grid *ngIf="!loadingFile && fileLoaded" id="dataGrid" class="m-auto" [height]="gridHeight"
    [dataSource]="dataSource" [allowColumnReordering]="true" [allowColumnResizing]="true" [repaintChangesOnly]="true"
    [showBorders]="true" [rowAlternationEnabled]="true" [wordWrapEnabled]="true"
    (onExporting)="gridService.onExporting($event, 'export')">

    <dxo-scrolling mode="virtual"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-search-panel [visible]="true" location="before" [width]="250"></dxo-search-panel>
    <dxo-paging [enabled]="false"></dxo-paging>

    <dxi-column dataField="rowNumber" caption="Row" dataType="number" width="85" [format]="0"> </dxi-column>
    <dxi-column dataField="column" dataType="string" width="85"> </dxi-column>

    <dxi-column dataField="jobNumber" dataType="string" [width]="110"> </dxi-column>
    <dxi-column dataField="description" dataType="string"> </dxi-column>

    <dxi-column dataField="invoiceTotal" dataType="number" width="140" [format]="{ type: 'fixedPoint', precision: 2 }">
    </dxi-column>

    <dxi-column dataField="errorString" dataType="string" minWidth="500"> </dxi-column>

  </dx-data-grid>

  <div *ngIf="fileLoaded">Note: File will not load at all if there are any errors.</div>

</div>
