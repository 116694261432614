<dx-tab-panel #panel height="calc(100vh - 50px)" [dataSource]="sections" [selectedIndex]="selectedIndex" [loop]="false"
  [swipeEnabled]="false" (onTitleClick)="tabSelected($event)">

  <div *dxTemplate="let title of 'title'">
    <span>{{title}}</span>
  </div>

  <div *dxTemplate="let section of 'item'">
    <div style="height: calc(100vh - 100px);">
      <js-deposits *ngIf="section === 'Deposits'"></js-deposits>
      <js-progress-claims *ngIf="section === 'Claims'"></js-progress-claims>
      <js-variation-invoices *ngIf="section === 'Variations'"></js-variation-invoices>
      <js-all-income-invoices *ngIf="section === 'Posted Invoices'"></js-all-income-invoices>
      <js-variation-splits *ngIf="section === 'Variation Splits'"></js-variation-splits>
      <js-receipts *ngIf="section === 'Receipts'"></js-receipts>
    </div>
  </div>
</dx-tab-panel>
