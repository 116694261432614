import { IAddress } from './address';

export class Job {
    id: number;
    jobNumber: string;
    jobAddressId: number;
    contractName: string;
    contractAddressId: number;
    isActive: boolean;
    jobAddress: IAddress;
    contractAddress: IAddress;
    isLocked: boolean;
    lockedDate: Date;
    generalNotes: string;
    warningNote: string;
    contractPrice: number;
    clientEmail: string;
    phoneNumber1: string;
    phoneNotes1: string;
    phoneNumber2: string;
    phoneNotes2: string;
    salesDate: Date;
    contractSignedDate: Date;
    houseTypeId: number;
    depositAmount: number;
    basePrice: number;
    budgetAmount: number;
    volume: string;
    folio: string;
    council: string;
    jobTypeId: number;
    isCustomerNotificationEnabled: boolean;
    houseModificationDescription: string;
    jobMargin: number;
    variationMargin: number;
    isGSTFree: boolean;
    gstRate: number;
    quoteDate: Date;
    totalReceipts: number;
    companyActivityId: number;
    estate: string;
    landZoningId: number;
    landTitleTypeId: number;
    landTypeId: number;
    titleDueDate: Date;
    depositPaid: number;
    depositBalanceDue: Date;
    feesMargin: number;
    planNumber: string;
    createDate: Date;
    modifiedDate: Date;
    divisionId: number;
    salesQuoteSignedDate: Date;
    contractQuoteDate: Date;
    firstName1: string;
    lastName1: string;
    firstName2: string;
    lastName2: string;
    masterJobId: number;

    jobAddressString: string;
    isOnHold: boolean;
    currentActivityCode: string;
    currentActivityDesc: string;

    constructor(id: number, jobAddressString: string) {
      this.id = id;
      this.jobAddressString = jobAddressString;
    }
}
