import { JobService } from './../../services/felixApi/job.service';
import { MaintenanceService } from './../../services/felixApi/maintenance.service';
import { GlobalService } from './../../services/global.service';
import { EstimatingService } from './../../services/felixApi/estimating.service';
import { Component, Input, OnInit } from '@angular/core';
import { PriceFileItem } from '../../dtos/price-file-item';
import { PoService } from '../../services/felixApi/po.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Phase } from '../../dtos/phase';
import { ConstructionStageTypeEnum } from '../../dtos/construction-stage-type';

@Component({
  selector: 'js-cost-centre-select',
  templateUrl: './cost-centre-select.component.html',
  styleUrls: ['./cost-centre-select.component.scss']
})
export class CostCentreSelectComponent implements OnInit {
  @Input() currentCostCentreId: number;
  @Input() titleText: string;

  costCentres: PriceFileItem[] = [];
  selectedCostCentreIds: number[] = [];
  phases: Phase[] = [
    { id: -1, orderNo: -1, phaseCode: '', description: 'All' },
    { id: 0, orderNo: 0, phaseCode: '', description: 'Default' }
  ];
  selectedPhaseId = -1;
  constructionStageId: number;
  constructionStages: object[] = [
    { id: 1, description: 'Construction' },
    { id: 2, description: 'Maintenance' },
    { id: 3, description: 'Warranty' }
  ];
  isHandedOver: boolean;

  constructor(private estimatingService: EstimatingService,
    private _activeModal: NgbActiveModal,
    private globalService: GlobalService,
    private maintenanceService: MaintenanceService,
    private jobService: JobService,
    private poService: PoService) {
    this.cancel = this.cancel.bind(this);
  }

  ngOnInit() {
    this.costCentres = [];
    this.phases = this.phases.concat(this.maintenanceService.phases);

    this.isHandedOver = this.jobService.currentJobExtra?.handoverDate ? true : false;

    if (this.isHandedOver) {
      if (this.jobService.currentJobExtra?.maintenanceCompleteDate) {
        this.constructionStageId = ConstructionStageTypeEnum.Warranty;
        this.constructionStages = [
          { id: 3, description: 'Warranty' }
        ];
      } else {
        this.constructionStageId = null; //must be selected
      }
    } else {
      this.constructionStageId = ConstructionStageTypeEnum.Prehandover;
    }

    this.estimatingService.costCentres.forEach(costCentre => {
      const linesforCostCentre = this.poService.orderLines
        .filter(i => i.costCentreId === costCentre.id && !i.purchaseOrderId
          && i.supplierQuantity);

      if (linesforCostCentre && linesforCostCentre.length) {
        const phasesforCostCentre = linesforCostCentre.map(m => m.phaseId).filter(this.globalService.onlyUnique);

        let addCostCentre = false;

        // if we have any to be checked we exclude
        phasesforCostCentre.forEach(phase => {
          if (!linesforCostCentre.find(i => i.phaseId === phase && i.isToBeChecked)) {
            addCostCentre = true;
          }
        });

        if (addCostCentre) {
          this.costCentres.push(costCentre);
        }
      }
    });
  }

  cancel() {
    this._activeModal.dismiss();
  }

  submit() {
    this._activeModal.close(
      {
        selectedCostCentreIds: this.selectedCostCentreIds,
        phaseId: this.selectedPhaseId >= 0 ? this.selectedPhaseId : null,
        constructionStageId: this.constructionStageId
      }
    );
  }

  onMultiRowClick(e) {
    let keys = e.component.getSelectedRowKeys();
    const index = keys.indexOf(e.key);

    if (index > -1) {
      keys.splice(index, 1);
    } else {
      keys = keys.concat(e.key);
    }

    e.component.selectRows(keys);
  }
}
