<dx-popup class="popup" [width]="gridWidth" [height]="gridHeight" [showTitle]="true" title="Generate PO"
  [dragEnabled]="true" [hideOnOutsideClick]="false" [(visible)]="popupVisible" [shading]="true">
  <div *dxTemplate="let data of 'content'">

    <div class="card p-2">
      <js-loading-spinner *ngIf="loading" [usePopUp]="false"></js-loading-spinner>

      <div *ngIf="!loading">
        <div class="settings">
          <div class="column">
            <div class="field">
              <div class="label">Vendor:</div>
              <div class="value">
                {{ vendor.vendorName }}
              </div>
            </div>

            <div class="field">
              <div class="label">Cost Centre:</div>
              <div class="value">
                <dx-drop-down-box [dropDownOptions]="dropDownOptions" [dataSource]="costCentres" [value]="costCentreId"
                  displayExpr="description" valueExpr="id" contentTemplate="contentTemplate" [showClearButton]="true">
                  <div *dxTemplate="let e of 'contentTemplate'">
                    <dx-data-grid [dataSource]="costCentres" keyExpr="id" [remoteOperations]="false" height="358px"
                      [showBorders]="true" [wordWrapEnabled]="true" [selectedRowKeys]="[costCentreId]"
                      [hoverStateEnabled]="true" [selection]="{ mode: 'single' }"
                      [scrolling]="{ mode: 'standard', preloadEnabled: true }" [autoNavigateToFocusedRow]="true"
                      (onSelectionChanged)="onCCDropDownChanged(e.component, $event)">

                      <dxo-search-panel [visible]="true" location="before" [width]="150"></dxo-search-panel>
                      <dxo-paging [enabled]="false"></dxo-paging>
                      <dxi-column dataField="priceFileCode" caption="Code" [width]="100"></dxi-column>
                      <dxi-column dataField="description"></dxi-column>
                    </dx-data-grid>
                  </div>
                </dx-drop-down-box>
              </div>
            </div>

            <div *ngIf="!forBackCharge" class="field">
              <div class="label">Extra Reason:</div>
              <div class="value">
                <dx-text-area class="subjectClass" [(value)]="varianceReason"> </dx-text-area>
              </div>
            </div>

            <div *ngIf="!forBackCharge" class="field">
              <div class="label">Extra Code:</div>
              <div class="value">
                <dx-select-box [(ngModel)]="varianceCodeId" [dataSource]="varianceCodes" valueExpr="id"
                  [searchEnabled]="true" displayExpr="description" placeholder="Select code">
                </dx-select-box>
              </div>
            </div>

            <div class="field">
              <div class="label">Order line description:</div>
              <div class="value">
                <dx-text-area id="description" [height]="90" [(ngModel)]="description"> </dx-text-area>
              </div>
            </div>

            <div *ngIf="!forBackCharge" class="field">
              <div class="label">Amount:</div>
              <div class="value">
                <dx-number-box id="amount" [ngModel]="amount" [readOnly]="true" format="#,##0.00"> </dx-number-box>
              </div>
            </div>

            <div class="field">
              <div class="label">Vendor Email:</div>
              <div class="value">
                <dx-text-box id="emailAddress" [(ngModel)]="emailAddress"> </dx-text-box>
              </div>
            </div>

            <div class="field">
              <div class="label"></div>
              <div class="value">
                <dx-check-box id="sendEmail" [(ngModel)]="sendEmail" text="Email purchase order to vendor">
                </dx-check-box>
              </div>
            </div>

            <div class="field">
              <div class="label"></div>
              <div class="value">
                <dx-check-box id="ccToSelf" [(ngModel)]="ccToSelf" text="CC to yourself"> </dx-check-box>
              </div>
            </div>
          </div>
        </div>

        <dx-button text="Create" type="default" (onClick)="create()" [disabled]="!costCentreId || (!forBackCharge && !varianceCodeId)">
        </dx-button>
      </div>
    </div>
  </div>
</dx-popup>
