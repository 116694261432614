<div class="jobHeight">
  <div>
    <div *ngIf="!isRestrictedScreen" class="w100">Job Number</div>

    <dx-text-box class="inlineBlock" [(value)]="jobNumber" (onEnterKey)="onJobNumberChanged()" [width]="130"
      (onBlur)="onJobNumberChanged()">
    </dx-text-box>
  </div>

  <dx-button type="default" icon="search" (onClick)="jobSearch()"></dx-button>


  <div *ngIf="jobNumber" class="jobString" [ngStyle]="{'max-width': 'calc(' + jobStringMaxWidth + 'px' + ')'}">
    <div class="jobStringText text-nowrap">
      <span
        *ngIf='jobService.currentJobExtra && jobService.currentJobExtra.isOnHold && jobService.currentJobExtra.cancellationDate === null'
        class='me-1 red'>
        ON HOLD
      </span>
      <span *ngIf='jobService.currentJobExtra && jobService.currentJobExtra.cancellationDate !== null' class='me-1 red'>
        CANCELLED
      </span>
      {{jobString}}
    </div>
    <div class="jobStringText text-nowrap">
      {{jobAddress}}
      <span *ngIf="currentActivity && currentActivity !== ''">({{currentActivity}})</span>
    </div>
  </div>
</div>
