import { UserService } from './user.service';
import { AuthApiService } from './auth-api.service';
import { CompanyService } from './company.service';
import { GlobalService } from './../global.service';
import { Division } from './../../dtos/division';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { throwError as observableThrowError, Observable, of, forkJoin } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { DivisionAccount } from '../../dtos/division-account';
import { HttpService } from '../http.service';
import { CompanyConfiguration } from '../../dtos/company-configuration';
import { JobService } from './job.service';

@Injectable({
  providedIn: 'root'
})
export class DivisionService {

  baseURL = environment.apiUrl + '/divisions/';
  accountsbaseURL = environment.apiUrl + '/division-accounts/';
  divisions: Division[];
  divisionsCompany: string;
  divisionAccounts: DivisionAccount[];
  divisionAccountsCompany: string;

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private companyService: CompanyService,
    private globalService: GlobalService,
    private authApi: AuthApiService,
    private userService: UserService,
    private jobService: JobService
  ) { }

  getDivisions(useCache: boolean) {
    if (useCache && this.divisions && this.divisions.length && this.divisionsCompany === this.globalService.getCurrentCompanyId()) {
      return of(this.divisions);
    } else {
      return this.http.get<Division[]>(this.baseURL, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.divisions = res;
          this.divisionsCompany = this.globalService.getCurrentCompanyId();
        }),
        catchError(this.handleError));
    }
  }

  addDivision(division: Division) {
    return this.http.post<Division>(this.baseURL, JSON.stringify(division), this.httpService.getHttpOptions());
  }

  updateDivision(id: string, updatedRecord: any) {
    return this.http.patch<Division>(this.baseURL + id, JSON.stringify(updatedRecord), this.httpService.getHttpOptions());
  }

  deleteDivision(id: string) {
    return this.http.delete(this.baseURL + id, this.httpService.getHttpOptions());
  }

  moveDivision(id: number, orderNumber: number): Observable<Division> {
    const url = this.baseURL + 'move/' + id + '?orderNumber=' + orderNumber;

    return this.http.patch<Division>(url, JSON.stringify({}), this.httpService.getHttpOptions());
  }


  getDivisionAccounts(useCache: boolean) {
    if (useCache && this.divisionAccounts && this.divisionAccounts.length
      && this.divisionAccountsCompany === this.globalService.getCurrentCompanyId()) {
      return of(this.divisionAccounts);
    } else {
      return this.http.get<DivisionAccount[]>(this.accountsbaseURL, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.divisionAccounts = res;
          this.divisionAccountsCompany = this.globalService.getCurrentCompanyId();
        }),
        catchError(this.handleError));
    }
  }

  addDivisionAccount(divisionAccount: DivisionAccount) {
    return this.http.post<Division>(this.accountsbaseURL, JSON.stringify(divisionAccount), this.httpService.getHttpOptions());
  }

  updateDivisionAccount(id: string, updatedRecord: any) {
    return this.http.patch<Division>(this.accountsbaseURL + id, JSON.stringify(updatedRecord), this.httpService.getHttpOptions());
  }

  deleteDivisionAccount(id: string) {
    return this.http.delete(this.accountsbaseURL + id, this.httpService.getHttpOptions());
  }



  getDataForJobClientLink(): Observable<CompanyConfiguration[]> {
    return forkJoin(
      [
        this.companyService.getCompanyConfigs(),
        this.authApi.getAreaPermissions(),
        this.getDivisions(true),
        this.userService.getCurrCompUsers(true),
        this.jobService.getJobsByAddress(true)
      ]
    )
      .pipe(map(
        ([configs]) => {
          return configs;
        }, (err) => {
          return this.handleError(err);
        }
      ));
  }


  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
