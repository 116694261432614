export class VarianceReason {
  id: number;
  code: string;
  reason: string;
  isActive: boolean;

  constructor(id: number, reason: string) {
    this.id = id;
    this.reason = reason;
  }
}
