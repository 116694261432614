import { GridService } from './../../services/grid.service';
import { CompanyService } from './../../services/felixApi/company.service';
import { OrderLineExtra } from './../../dtos/order-line-extra';
import { UserService } from './../../services/felixApi/user.service';
import { EstimatingService } from './../../services/felixApi/estimating.service';
import { JobService } from './../../services/felixApi/job.service';
import { MaintenanceService } from './../../services/felixApi/maintenance.service';
import { PoService } from './../../services/felixApi/po.service';
import { NotificationService } from './../../services/notification.service';
import { GlobalService } from './../../services/global.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Job } from '../../dtos/job';
import { PriceFileItem } from '../../dtos/price-file-item';
import { User } from '../../dtos/user';
import { VarianceCode } from '../../dtos/variance-code';
import CustomStore from 'devextreme/data/custom_store';
import { RoleTypeEnum } from '../../dtos/role-type.enum';
import { JobTypeEnum } from '../../dtos/job-type.enum';

@Component({
  selector: 'js-extras',
  templateUrl: './extras.component.html',
  styleUrls: ['./extras.component.scss']
})
export class ExtrasComponent implements OnInit, OnDestroy {
  dataSource: CustomStore;
  subscriptions: Subscription[] = [];
  gridHeight: number;
  loadingData = true;
  jobs: Job[];
  costCentres: PriceFileItem[];
  users: User[];
  allVarianceCodes: VarianceCode[];
  showStandardJobsOnly = true;
  includeInactiveJobs = false;

  constructor(
    private poService: PoService,
    private maintenanceService: MaintenanceService,
    private jobService: JobService,
    private estimatingService: EstimatingService,
    private notiService: NotificationService,
    private userService: UserService,
    private companyService: CompanyService,
    public gridService: GridService,
    private globalService: GlobalService) {
    // this.setMarginCellValue = this.setMarginCellValue.bind(this);
    this.calculateSiteAddress = this.calculateSiteAddress.bind(this);
    this.calculateCostCentreSortValue = this.calculateCostCentreSortValue.bind(this);
    this.calculateSiteManager = this.calculateSiteManager.bind(this);
    this.calculateEstimator = this.calculateEstimator.bind(this);
    this.calculateEstimatorTitle = this.calculateEstimatorTitle.bind(this);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          this.setHeightWidths();
        }
      )
    );

    this.setHeightWidths();
    this.loadData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setHeightWidths() {
    this.gridHeight = window.innerHeight - 107;
  }

  loadData() {
    this.subscriptions.push(
      this.poService.getDataForExtrasReport()
        .subscribe(
          () => {
            this.jobs = this.jobService.jobs;
            this.costCentres = this.estimatingService.costCentres;
            this.users = this.userService.users;
            this.allVarianceCodes = this.maintenanceService.allVarianceCodes;
            this.loadingData = false;
            this.setUpDataSource();
          },
          err => {
            this.notiService.notify(err);
            this.loadingData = false;
          })
    );
  }

  setUpDataSource() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.poService.getAllOrderLineExtras(this.includeInactiveJobs).subscribe(res => {
              const orderLineExtras: OrderLineExtra[] = [];
              res.forEach(orderExtra => {
                let job = this.jobs.find(i => i.id === orderExtra.jobId);
                if (job) {
                  if (!this.showStandardJobsOnly || job.jobTypeId === JobTypeEnum.StandardJob) {
                    orderExtra.poNumber = job.jobNumber + (!orderExtra.poNumber || orderExtra.poNumber === '' ? ' no PO' : '.' + orderExtra.poNumber);
                    orderLineExtras.push(orderExtra);
                  }
                }
              });
              return resolve(orderLineExtras);
            }, err => {
              return reject(this.globalService.returnError(err));
            })
          ));
      }
    });
  }

  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxCheckBox',
        options: {
          text: 'Show standard jobs only',
          value: this.showStandardJobsOnly,
          rtlEnabled: true,
          onValueChanged: this.showStandardJobsOnlyChanged.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxCheckBox',
        options: {
          text: 'Include inactive jobs',
          value: this.includeInactiveJobs,
          rtlEnabled: true,
          onValueChanged: this.includeInactiveJobsChanged.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: this.setUpDataSource.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          type: 'default',
          stylingMode: 'outlined',
          text: 'Reset Layout',
          onClick: this.clearStatePersistance.bind(this)
        }
      });
  }

  showStandardJobsOnlyChanged(e) {
    this.showStandardJobsOnly = e.value;
    this.setUpDataSource();
  }

  includeInactiveJobsChanged(e) {
    this.includeInactiveJobs = e.value;
    this.setUpDataSource();
  }

  calculateSiteAddress(data) {
    return this.jobs.find(i => i.id === data.jobId)?.jobAddressString;
  }

  calculateSiteManager(data) {
    return this.jobService.jobRoles.find(i => i.jobId === data.jobId && i.roleId === RoleTypeEnum.SiteManager)?.user.fullName;
  }

  calculateCostCentreSortValue(data) {
    if (data.costCentreId) {
      return this.costCentres.find(i => i.id === data.costCentreId)?.orderNumber;
    } else {
      return null;
    }
  }

  clearStatePersistance() {
    this.loadingData = true;
    localStorage.removeItem('extras-report');
    setTimeout(() => {
      this.loadingData = false;
    }, 300); // wait
  }

  calculateEstimator(data) {
    return this.jobService.jobRoles.find(i => i.jobId === data.jobId && i.roleId === RoleTypeEnum.ConstructionEstimator)?.user.fullName;
  }

  calculateEstimatorTitle() {
    return this.companyService.companyRoles.find(i => i.roleId === RoleTypeEnum.ConstructionEstimator)?.companyRoleDescription;
  }
}
