import { Component, OnDestroy, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { GridService } from '../../services/grid.service';
import { MaintenanceService } from '../../services/felixApi/maintenance.service';
import CustomStore from 'devextreme/data/custom_store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'js-gl-account-type',
  templateUrl: './gl-account-type.component.html',
  styleUrls: ['./gl-account-type.component.scss']
})
export class GlAccountTypeComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  dataSource: CustomStore;

  constructor(
    private maintenanceService: MaintenanceService,
    private globalService: GlobalService,
    protected gridService: GridService
  ) { }

  ngOnInit(): void {
    this.setUpDataSource();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setUpDataSource() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.maintenanceService.getGlAccountTypes(false).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      },
      insert: async (values) => {
        if (values.isActive === undefined || values.isActive === null) {
          values.isActive = true;
        }
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.maintenanceService.addGlAccountType(values).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.maintenanceService.updateGlAccountType(encodeURIComponent(key), values).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.maintenanceService.deleteGlAccountType(encodeURIComponent(key)).subscribe({
              next: () => {
                return resolve();
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      }
    });
  }
}
