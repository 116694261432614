import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxImageCompressService } from 'ngx-image-compress';


/* MATERIAL */
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatGridListModule } from '@angular/material/grid-list';

/* DevExtreme */
import {
  DxTreeListModule,
  DxButtonModule,
  DxBoxModule,
  DxDataGridModule,
  DxPopupModule,
  DxTemplateModule,
  DxFileUploaderModule,
  DxSelectBoxModule,
  DxDropDownBoxModule,
  DxTextBoxModule,
  DxCheckBoxModule,
  DxTextAreaModule,
  DxDateBoxModule,
  DxNumberBoxModule,
  DxLoadPanelModule,
  DxListModule,
  DxToolbarModule,
  DxTabPanelModule,
  DxHtmlEditorModule,
  DxFormModule,
  DxResizableModule,
  DxScrollViewModule,
  DxDropDownButtonModule,
  DxTagBoxModule
} from 'devextreme-angular';

/* COMPONENTS */
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { BackgroundLogoComponent } from './background-logo.component';

import { ErrorPopupComponent } from './error-popup/error-popup.component';
import { DebounceClickDirective } from './debounce-click';
import { GenericFilterPipe } from './genericFilter.pipe';
import { FocusElementDirective } from './focus-element.directive';
import { JobNumberComponent } from './job-number/job-number.component';
import { JobSearchModalComponent } from './job-search-modal/job-search-modal.component';
import { AccountsCodePipe } from './accounts-code.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { JobRolesComponent } from './job-roles/job-roles.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { ShowPdfComponent } from './show-pdf/show-pdf.component';
import { HelpModalComponent } from './help.component';
import { ImageCropperModule } from 'ngx-image-cropper';


@NgModule({
    imports: [
        CommonModule,
        DxCheckBoxModule,
        DxDataGridModule,
        DxTextBoxModule,
        DxTreeListModule,
        DxButtonModule,
        DxBoxModule,
        DxPopupModule,
        DxTemplateModule,
        DxFileUploaderModule,
        DxSelectBoxModule,
        DxDropDownBoxModule,
        DxTextAreaModule,
        DxDateBoxModule,
        DxNumberBoxModule,
        DxLoadPanelModule,
        DxListModule,
        DxToolbarModule,
        DxTabPanelModule,
        DxFormModule,
        DxHtmlEditorModule,
        DxResizableModule,
        DxScrollViewModule,
        DxTagBoxModule,
        DxDropDownButtonModule,
        NgxExtendedPdfViewerModule,
        ImageCropperModule
    ],
    declarations: [
        HelpModalComponent,
        LoadingSpinnerComponent,
        ErrorPopupComponent,
        BackgroundLogoComponent,
        DebounceClickDirective,
        GenericFilterPipe,
        FocusElementDirective,
        JobNumberComponent,
        JobSearchModalComponent,
        AccountsCodePipe,
        JobRolesComponent,
        ShowPdfComponent
    ],
    exports: [
        HelpModalComponent,
        CommonModule,
        FormsModule,
        DebounceClickDirective,
        GenericFilterPipe,
        FocusElementDirective,
        // mat
        MatSelectModule,
        MatTableModule,
        MatTabsModule,
        MatGridListModule,
        MatTooltipModule,
        DxTabPanelModule,
        DxFormModule,
        DxHtmlEditorModule,
        // dx
        DxTreeListModule,
        DxButtonModule,
        DxBoxModule,
        DxDataGridModule,
        DxPopupModule,
        DxTemplateModule,
        DxFileUploaderModule,
        DxSelectBoxModule,
        DxDropDownBoxModule,
        DxTextBoxModule,
        DxCheckBoxModule,
        DxTextAreaModule,
        DxDateBoxModule,
        DxNumberBoxModule,
        DxLoadPanelModule,
        DxListModule,
        DxToolbarModule,
        DxResizableModule,
        DxScrollViewModule,
        DxTagBoxModule,
        DxDropDownButtonModule,
        // ng
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        LoadingSpinnerComponent,
        ErrorPopupComponent,
        BackgroundLogoComponent,
        JobNumberComponent,
        JobSearchModalComponent,
        AccountsCodePipe,
        JobRolesComponent,
        NgxExtendedPdfViewerModule,
        ImageCropperModule
    ],
    providers: [
      NgxImageCompressService
    ]
})
export class SharedModule { }
