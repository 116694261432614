import { GlobalService } from './../services/global.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'js-claims',
  templateUrl: './claims.component.html',
  styleUrls: ['./claims.component.scss']
})
export class ClaimsComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  sections: string[];
  counter = 0;
  selectedSection: string;
  selectedIndex = 0;

  constructor(private globalService: GlobalService) { }

  ngOnInit() {
    if (this.globalService.incomeInvoicePermission === undefined) {
      this.subscriptions.push(
        this.globalService.invoicePermissionsChanged.subscribe(
          () => {
            this.setVisibleTabs();
          }
        )
      );
    }
    else {
      this.setVisibleTabs();
    }
  }

  tabSelected(e) {
    this.counter++;
    this.selectedSection = e.itemData;
  }

  setVisibleTabs() {
    if (this.globalService.incomeInvoicePermission === 'Admin' || this.globalService.incomeInvoicePermission === 'Write') {
      this.sections = [
        'Deposits',
        'Claims',
        'Variations',
        'Posted Invoices',
        'Variation Splits'
      ];
    } else if (this.globalService.incomeInvoicePermission === 'Read') {
      this.sections = [
        'Posted Invoices'
      ];
    }
  }
  
  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

}
