import { GridService } from './../../services/grid.service';
import { PoService } from './../../services/felixApi/po.service';
import { EstimatingService } from './../../services/felixApi/estimating.service';
import { JobService } from './../../services/felixApi/job.service';
import { MaintenanceService } from './../../services/felixApi/maintenance.service';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import DataSource from 'devextreme/data/data_source';
import { Invoice } from '../../dtos/invoice';
import { Vendor } from '../../dtos/vendor';
import { Job } from '../../dtos/job';
import { PriceFileItem } from '../../dtos/price-file-item';
import { PurchaseOrder } from '../../dtos/purchase-order';

@Component({
  selector: 'js-duplicate-invoice',
  templateUrl: './duplicate-invoice.component.html',
  styleUrls: ['./duplicate-invoice.component.scss']
})
export class DuplicateInvoiceComponent implements OnInit {
  @Input() invoices: Invoice[];
  dataSource: DataSource;
  vendors: Vendor[];
  jobs: Job[];
  costCentres: PriceFileItem[];
  invoiceData: Invoice[];
  po: PurchaseOrder;
  invoicesTotalExGst = 0;
  totalOverBudget: number;

  constructor(
    private _activeModal: NgbActiveModal,
    private jobService: JobService,
    private estimatingService: EstimatingService,
    private poService: PoService,
    public gridService: GridService,
    private maintenanceService: MaintenanceService
  ) {
    this.calculateCustomSummary = this.calculateCustomSummary.bind(this);
    this.calculateVendorSortValue = this.calculateVendorSortValue.bind(this);
  }

  ngOnInit() {
    this.vendors = this.maintenanceService.allVendors;
    this.jobs = this.jobService.jobs;
    this.costCentres = this.estimatingService.costCentres;
    this.po = this.poService.allPurchaseOrdersForCompany.find(i => i.id === this.invoices[0].purchaseOrderId);

    this.invoices.forEach(invoice => {
      this.invoicesTotalExGst += invoice.totalExGST;
    });

    this.totalOverBudget = this.po.orderTotal - this.po.approvedInvoicesTotal - this.invoicesTotalExGst;
    // this.totalOverBudget = this.totalOverBudget > 0 ? 0 : -this.totalOverBudget;

    this.invoiceData = this.invoices;

    this.dataSource = new DataSource({
      key: 'id',
      load: () => this.invoiceData
    });
  }

  cancel() {
    this._activeModal.dismiss();
  }

  calculateCustomSummary(options) {
    if (options.name === 'OrderTotal') {
      if (options.summaryProcess === 'start') {
        options.totalValue = this.po.orderTotal;
      }
    }
    if (options.name === 'InvoicesTotal') {
      if (options.summaryProcess === 'start') {
        options.totalValue = this.invoicesTotalExGst;
      }
    }
    if (options.name === 'OverBudget') {
      if (options.summaryProcess === 'start') {
        options.totalValue = this.totalOverBudget;
      }
    }
  }

  calculateVendorSortValue(data) {
     return this.vendors.find(i => i.id === data.vendorId)?.vendorName;
  }
}
