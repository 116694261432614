<div class="page">

  <dx-data-grid *ngIf="!loadingData" #allInvoicesGrid id="allInvoicesGrid" [height]="gridHeight"
    [dataSource]="dataSource" [allowColumnReordering]="false" [allowColumnResizing]="true" [repaintChangesOnly]="true"
    [showBorders]="true" [rowAlternationEnabled]="true" [wordWrapEnabled]="true" [hoverStateEnabled]="true"
    [focusedRowEnabled]="true" (onToolbarPreparing)="onToolbarPreparing($event, 'toolbarTemplate')"
    (onExporting)="gridService.onExporting($event, 'all-invoices')">

    <dxo-search-panel [visible]="true" location="after" [width]="200"></dxo-search-panel>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-scrolling mode="virtual"></dxo-scrolling>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping #expand [autoExpandAll]="true" [contextMenuEnabled]="false"></dxo-grouping>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-column-chooser [enabled]="true" mode="dragAndDrop"> </dxo-column-chooser>
    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="all-invoices" [savingTimeout]="500">
    </dxo-state-storing>

    <dxo-editing mode="row" [allowUpdating]="isAdmin" [allowAdding]="false" [allowDeleting]="false" [useIcons]="true">
    </dxo-editing>

    <dxi-column dataField="vendorId" caption="Vendor" dataType="number" [setCellValue]="setVendorCellValue"
      [calculateSortValue]="calculateVendorSortValue">
      <dxo-lookup [dataSource]="vendors" displayExpr="vendorName" valueExpr="id"></dxo-lookup>
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="orderNumber" width="150" [setCellValue]="setOrderNumberCellValue">
    </dxi-column>

    <dxi-column dataField="invoiceNumber" width="120">
    </dxi-column>

    <dxi-column dataField="invoiceDate" dataType="date" width="95" format="d/M/yy" alignment="center">
    </dxi-column>

    <dxi-column dataField="dueDate" dataType="date" width="95" format="d/M/yy" alignment="center">
    </dxi-column>

    <dxi-column dataField="totalIncGST" dataType="number" width="106" [format]="{ type: 'fixedPoint', precision: 2 }"
      [setCellValue]="setTotalIncGSTCellValue">
    </dxi-column>

    <dxi-column dataField="totalGST" dataType="number" width="90" [format]="{ type: 'fixedPoint', precision: 2 }"
      [setCellValue]="setTotalGSTCellValue">
    </dxi-column>

    <dxi-column dataField="totalExGST" dataType="number" [visible]='false'
      [format]="{ type: 'fixedPoint', precision: 2 }" [editorOptions]="{disabled: true}">
    </dxi-column>

    <dxi-column dataField="jobNumber" caption="Job" dataType="string" width="100"
      [calculateCellValue]='calculateJobNumber' [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="division" caption="Division" dataType="string" width="100"
      [calculateCellValue]='calculateDivision' [visible]="false" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="jobId" caption="Job Address" dataType="number" [allowEditing]="false">
      <dxo-lookup [dataSource]="jobs" displayExpr="jobAddressString" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="costCentreId" caption="Cost Centre" dataType="string"
      [calculateSortValue]="calculateCostCentreSortValue" [allowEditing]="false">
      <dxo-lookup [dataSource]="costCentres" displayExpr="description" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="purchaseOrderId" dataType="number" width="120" [visible]="false" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="authorisedByUserId" caption="Approved by" dataType="number" width="120"
      [allowEditing]="false">
      <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="description" caption="Comment">
    </dxi-column>

    <dxi-column dataField="invoiceStatusId" caption="Status" dataType="number" width="120">
      <dxo-lookup [dataSource]="invoiceStatuses" displayExpr="description" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="glAccountType" caption="Vendor Type" dataType="string" width="120"
      [calculateCellValue]="calcGlAccountType" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="vendorGroup" caption="Vendor Group" dataType="string" width="120"
      [calculateCellValue]="calcVendorGroup" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="createUserId" caption="Entered by" dataType="number" width="120" [allowEditing]="false"
      [visible]="false">
      <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="createDate" caption="Entered" dataType="date" width="95" format="d/M/yy" alignment="center"
      [allowEditing]="false" [visible]="false">
    </dxi-column>

    <dxi-column dataField="modifiedUserId" caption="Modified by" dataType="number" width="120" [allowEditing]="false"
      [visible]="false">
      <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="modifiedDate" caption="Modified" dataType="date" width="95" format="d/M/yy"
      alignment="center" [allowEditing]="false" [visible]="false">
    </dxi-column>

    <dxi-column dataField="paidDate" caption="Paid" dataType="date" width="90" format="d/M/yy" alignment="center"
      [allowEditing]="false" [visible]="false">
    </dxi-column>

    <dxi-column dataField="vendorEmail" dataType="string" width="110" [allowEditing]="false" [visible]="false"
      [calculateCellValue]="calcVendorEmail">
    </dxi-column>

    <dxi-column dataField="vendorPhone" dataType="string" width="90" [allowEditing]="false" [visible]="false"
      [calculateCellValue]="calcVendorPhone">
    </dxi-column>

    <dxi-column dataField="vendorContact" dataType="string" width="90" [allowEditing]="false" [visible]="false"
      [calculateCellValue]="calcVendorContact">
    </dxi-column>

    <dxi-column type="buttons" [width]="buttonsWidth">
      <dxi-button *ngIf="isAdmin" name="edit"></dxi-button>
      <dxi-button icon="doc" [onClick]="downloadAttachment" hint='Download Invoice'></dxi-button>
      <dxi-button icon="download" [onClick]="openPO" hint='Download PO'></dxi-button>
      <dxi-button *ngIf="isAdmin" icon="trash" [onClick]="deleteRecord"></dxi-button>
      <dxi-button icon="import" [onClick]="openCostCentre" hint='Go to Construction Orders'></dxi-button>
      <dxi-button template="paidCommand">
        <div class="paidButtonClass" *dxTemplate="let data of 'paidCommand'">
          <i *ngIf="data.data.paidDate" class="material-icons green" matTooltip="Paid">check</i>
          <i *ngIf="!data.data.paidDate" class="material-icons" (click)="checkIfPaid(data)"
            matTooltip="Check if paid">question_mark</i>
        </div>
      </dxi-button>
    </dxi-column>

    <!-- summary totals -->
    <dxo-summary>
      <dxi-total-item column="vendorId" summaryType="count" displayFormat="{0} rows">
      </dxi-total-item>
      <dxi-total-item column="totalExGST" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="totalGST" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="totalIncGST" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}">
      </dxi-total-item>
    </dxo-summary>

    <!-- Templates -->
    <div *dxTemplate="let data of 'toolbarTemplate'">
      <div>
        <dx-button id="allButton" text="All Invoices"
          (onClick)="selectedVendorId = null; jobNumber = null; setUpDataSource(true)">
        </dx-button>

        <dx-select-box id="selectVendor" [dataSource]="vendors" displayExpr="vendorName" valueExpr="id"
          [showClearButton]="true" placeholder="Select vendor" [width]="286" [(value)]='selectedVendorId'
          [searchEnabled]="true" (onValueChanged)='setUpDataSource(true)'>
        </dx-select-box>

        <dx-text-box id="jobNumberBox" placeholder="Job number..." [(value)]="jobNumber"
          (onEnterKey)="onJobNumberChanged()" (onFocusOut)="onJobNumberChanged()">
        </dx-text-box>
        <dx-button id="selectJobButton" icon="search" (onClick)="jobSearch()"></dx-button>
        <dx-button id="selectJobClearButton" icon="clear" (onClick)="jobNumber = ''; setUpDataSource(true)"></dx-button>
      </div>
    </div>
  </dx-data-grid>

</div>

<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loadingFile" [showIndicator]="true"
  [showPane]="true" [shading]="false" [hideOnOutsideClick]="false">
</dx-load-panel>


<!-- delete popup -->
<dx-popup class="popup" [width]="400" [height]="225" [showTitle]="true" [title]="invoiceNumber" [dragEnabled]="true"
  [hideOnOutsideClick]="true" [(visible)]="deletePopupVisible" [shading]="true">
  <div *dxTemplate="let data of 'content'">
    <div>Delete this record?</div>
    <div>Beware that deleting will NOT delete the external accounting system record which must be deleted manually!
    </div>
    <br>
    <dx-button id="load-button" text="Delete" type="danger" (onClick)="deleteClicked()">
    </dx-button>
  </div>
</dx-popup>
