import { NotificationService } from './../../../services/notification.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { GridService } from '../../../services/grid.service';
import { Subscription } from 'rxjs';
import CustomStore from 'devextreme/data/custom_store';
import { OrderLoadError } from '../../../dtos/order-load-error';
import { IntegrationService } from '../../../services/felixApi/integration.service';

@Component({
  selector: 'js-order-line-history-load',
  templateUrl: './order-line-history-load.component.html',
  styleUrls: ['./order-line-history-load.component.scss']
})
export class OrderLineHistoryLoadComponent implements OnInit, OnDestroy {

  dataSource: CustomStore;
  subscriptions: Subscription[] = [];
  loadingFile = false;
  fileLoaded = false;
  fileValue: any[] = [];
  errors: OrderLoadError[];
  gridHeight: number;
  skipWhereOrdersExist = false;
  loadingUnorderedLines = false;
  skipMissingJobs = false;
  defaultVendorCode = 'STANDARD';
  skipInactiveJobs = true;
  includeCompletionsFrom: Date;

  constructor(
    private integrationService: IntegrationService,
    protected gridService: GridService,
    private notiService: NotificationService
  ) { }

  ngOnInit(): void {
    this.setUpDataSource();
    this.gridHeight = window.innerHeight - 540;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  loadFileGo() {
    this.loadingFile = true;
    const formData: FormData = new FormData();
    formData.append('image', this.fileValue[0], this.fileValue[0].name);

    this.subscriptions.push(
      this.integrationService.loadHistoryOrderLines(formData, this.skipWhereOrdersExist,
        this.loadingUnorderedLines, this.defaultVendorCode, this.skipMissingJobs, this.skipInactiveJobs, this.includeCompletionsFrom)
        .subscribe({
          next: (errors) => {
            this.errors = errors;
            this.loadingFile = false;
            this.fileLoaded = true;
            this.setUpDataSource();
          }, error: (err) => {
            this.notiService.notify(err);
            this.fileValue = [];
            this.loadingFile = false;
          }
        })
    );
  }

  setUpDataSource() {
    this.dataSource = new CustomStore({
      key: 'rowNumber',
      load: async () => this.errors
    });
  }
}
