import { CompanyService } from './company.service';
import { ClientJob } from './../../dtos/client-job';
import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable, of, forkJoin } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Job } from '../../dtos/job';
import { GlobalService } from '../global.service';
import { TruthEngineSession, SessionName } from '../../dtos/session';
import { JobExtra } from '../../dtos/job-extra';
import { HouseType } from '../../dtos/house-type';
import { JobDocument } from '../../dtos/job-document';
import { JobDocumentRecordTypeEnum } from '../../dtos/job-document-record-type.enum';
import { JobRole } from '../../dtos/job-role';
import { HttpService } from '../http.service';
import { AuthService } from '../auth.service';
import { TaskControl } from '../../dtos/task-control';

@Injectable({
  providedIn: 'root'
})
export class JobService {

  private currentJobNo: string;
  jobs: Job[];
  currentJob: Job;
  jobsCompany: number;
  jobExtras: JobExtra[];
  currentJobExtra: JobExtra;
  jobExtrasCompany: number;
  houseTypes: HouseType[];
  houseTypesCompany: number;
  jobDocuments: JobDocument[];
  jobDocumentsJobId: number;
  jobRoles: JobRole[];
  jobRolesCompany: number;

  constructor(
    private _http: HttpClient,
    private authService: AuthService,
    private httpService: HttpService,
    private companyService: CompanyService,
    private globalService: GlobalService) { }


  getSessionObject(): TruthEngineSession {
    if (sessionStorage.getItem(SessionName)) {
      return JSON.parse(sessionStorage.getItem(SessionName));
    } else {
      const session = new TruthEngineSession();
      sessionStorage.setItem(SessionName, JSON.stringify(session));
      return JSON.parse(sessionStorage.getItem(SessionName));
    }
  }

  setSessionAtt(att: string, obj: any) {
    const session = this.getSessionObject();
    session[att] = obj;
    const sessionString = JSON.stringify(session);
    sessionStorage.setItem(SessionName, sessionString);
  }

  setCurrentJob(job: string) {
    this.currentJobNo = job;
    // store the latest job number to be the default in long term storage as well as session
    localStorage.setItem('jobANNX-LastJobNumber', job);
    this.setSessionAtt('currentJobNo', job);
  }

  // get one job
  getJob(jobNo: string): Observable<Job> {
    return this._http.get<Job>(this.globalService.getApiUrl() + '/jobs/' + jobNo,
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  getCurrentJob(): string {
    if (this.currentJobNo !== undefined && this.currentJobNo !== null) {
      return this.currentJobNo;
    }
    // return the last used job number on this client as default
    const session = this.getSessionObject();
    if (session && session.currentJobNo) {
      this.currentJobNo = session.currentJobNo;
      return this.currentJobNo;
    }
    if (localStorage.getItem('jobANNX-LastJobNumber')) {
      this.currentJobNo = localStorage.getItem('jobANNX-LastJobNumber');
      if (this.currentJobNo !== undefined && this.currentJobNo !== null) {
        return this.currentJobNo;
      }
    }
    return '';
  }

  // search for jobs by a matching contract name string
  getJobsByAddress(useCache: boolean): Observable<Job[]> {
    if (useCache && this.jobsCompany === this.globalService.getCurrentCompany().id
      && this.jobs && this.jobs.length) {
      return of(this.jobs);
    } else {
      const searchString = '';
      return this._http.get<Job[]>(this.globalService.getApiUrl() + '/jobs?jobSearchType=JobAddress&searchString=' + searchString,
        this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.jobs = res; this.jobsCompany = this.globalService.getCurrentCompany().id;

            this.jobs.forEach(job => {
              job.jobAddressString = this.globalService.getJobString(job);
            });
          }),
          catchError(this.handleError));
    }
  }

  getJobsByAssignee(userId: number) {
    return this._http.get<Job[]>(this.globalService.getApiUrl() + '/jobs?jobSearchType=ContractName&userId='
      + userId, this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  // search for jobs by a matching contract name string
  getJobRoles(useCache: boolean): Observable<JobRole[]> {
    if (useCache && this.jobRolesCompany === this.globalService.getCurrentCompany().id
      && this.jobRoles && this.jobRoles.length) {
      return of(this.jobRoles);
    } else {
      return this._http.get<JobRole[]>(this.globalService.getApiUrl() + '/jobs/roles',
        this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.jobRoles = res; this.jobRolesCompany = this.globalService.getCurrentCompany().id;
          }),
          catchError(this.handleError));
    }
  }

  getJobRolesForJob(jobNumber: string): Observable<JobRole[]> {
    return this._http.get<JobRole[]>(this.globalService.getApiUrl() + '/jobs/' + jobNumber + '/roles',
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  // search for jobs by a matching contract name string
  getTemplateJobs(): Observable<Job[]> {
    return this._http.get<Job[]>(this.globalService.getApiUrl() + '/jobs?jobSearchType=Templates',
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  getMyJobs() {
    return this._http.get<Job[]>(this.globalService.getApiUrl() + '/jobs?jobSearchType=ContractName&userId='
      + this.authService.getCurrentUserId(), this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  getJobsByAddressWithExtras(useCache: boolean): Observable<Job[]> {
    return forkJoin(
      [this.getJobsByAddress(useCache),
      this.getJobRoles(useCache),
      this.getAllJobExtras(useCache),
      this.getHouseTypes(),
      this.companyService.getCompanyActivities(false, true),
      this.companyService.getCompanyRoles(useCache)
      ])
      .pipe(map(
        ([jobs]) => {
          return jobs;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getMyJobsWithExtras(): Observable<Job[]> {
    return forkJoin(
      [this.getMyJobs(),
      this.companyService.getCompanyActivities(false, true),
      this.getAllJobExtras(false)
      ])
      .pipe(map(
        ([jobs]) => {
          return jobs;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }


  // extra details for hold flag etc
  getAllJobExtras(useCache: boolean): Observable<JobExtra[]> {
    if (useCache && this.jobExtrasCompany === this.globalService.getCurrentCompany().id
      && this.jobExtras && this.jobExtras.length) {
      return of(this.jobExtras);
    } else {
      return this._http.get<JobExtra[]>(this.globalService.getApiUrl() + '/job-extras',
        this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.jobExtras = res; this.jobExtrasCompany = this.globalService.getCurrentCompany().id;
          }),
          catchError(this.handleError));
    }
  }

  getJobExtras(jobId: number): Observable<JobExtra> {
    return this._http.get<JobExtra>(this.globalService.getApiUrl() + '/job-extras/' + jobId,
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  addJobExtra(itm: any): Observable<JobExtra> {
    const url = this.globalService.getApiUrl() + '/job-extras/';
    return this._http.post<JobExtra>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  updateJobExtra(id: number, itm: any): Observable<JobExtra> {
    const url = this.globalService.getApiUrl() + '/job-extras/' + id;
    return this._http.patch<JobExtra>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }


  getHouseTypes(): Observable<HouseType[]> {
    if (this.houseTypesCompany === this.globalService.getCurrentCompany().id
      && this.houseTypes && this.houseTypes.length) {
      return of(this.houseTypes);
    } else {
      return this._http.get<HouseType[]>(this.globalService.getApiUrl() + '/house-types?activeOnly=false',
        this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.houseTypes = res;
            this.houseTypesCompany = this.globalService.getCurrentCompany().id;
          }),
          catchError(this.handleError));
    }
  }


  getJobDocuments(useCache: boolean, jobId: number): Observable<JobDocument[]> {
    if (useCache && this.jobDocuments && this.jobDocuments.length && this.jobDocumentsJobId === jobId) {
      return of(this.jobDocuments);
    } else {
      let url = this.globalService.getApiUrl() + '/job/' + jobId
        + '/documents?onlyAttachments=true';
      return this._http.get<JobDocument[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.jobDocuments = res;
          this.jobDocumentsJobId = jobId;
        }),
        catchError(this.handleError));
    }
  }


  getClientJobs(divisionId: number): Observable<ClientJob[]> {
    return this._http.get<ClientJob[]>(this.globalService.getApiUrl() + '/client-job-connections?divisionId=' + divisionId,
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  getClientJobsByTenant(tenantId: string): Observable<ClientJob[]> {
    return this._http.get<ClientJob[]>(this.globalService.getApiUrl() + '/client-job-connections?tenantId=' + tenantId,
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  updateClientJob(id: string, itm: any): Observable<ClientJob> {
    const url = this.globalService.getApiUrl() + '/client-jobs/' + id;
    return this._http.patch<ClientJob>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }


  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
