<div class="page">

  <dx-data-grid id="dataGrid" class="max-w-800 m-auto grid-height" [dataSource]="dataSource"
    [allowColumnReordering]="true" [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true"
    [rowAlternationEnabled]="true" [wordWrapEnabled]="true"
    (onExporting)="gridService.onExporting($event, 'vendor-groups')">

    <dxo-scrolling mode="virtual"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <!-- <dxo-row-dragging [allowReordering]="true" [onReorder]="onReorder"></dxo-row-dragging> -->
    <dxo-search-panel [visible]="true" location="before" [width]="250"></dxo-search-panel>
    <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"
      refreshMode="full">
    </dxo-editing>
    <dxo-paging [enabled]="false"></dxo-paging>

    <dxi-column dataField="description" dataType="string">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="supplierPayTermsTypeId" caption="Payment Terms Type" dataType="number" [width]="150">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxo-lookup [dataSource]="supplierPayTermsTypes" valueExpr="id" displayExpr="description">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="followingMonths" caption="#Following Months (blank for this month)" dataType="number"
      width="150">
      <dxi-validation-rule type="async" message="Only used for End Of Month"
        [validationCallback]="followingMonthValidation">
      </dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="supplierPayTermsDays" caption="Days" dataType="number" width="60">
      <dxi-validation-rule type="async" message="Enter 7 (weekly) or 14 (fortnightly) for Subcontractor"
        [validationCallback]="asyncValidation">
      </dxi-validation-rule>
    </dxi-column>

  </dx-data-grid>

  <div class="max-w-800 m-auto">Example: For 'End of Month' and when the number of following months is 1, if the invoice
    date is 13th Jan, the due date will be the end of Feb + any Days (i.e. End of January + months + days)</div>

</div>
