import { GlobalService } from './../services/global.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'js-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  sections: string[];
  counter = 0;
  selectedSection: string;
  selectedIndex = 0;

  constructor(
    private globalService: GlobalService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    if (this.globalService.invoicePermission === undefined) {
      this.subscriptions.push(
        this.globalService.invoicePermissionsChanged.subscribe(
          () => {
            this.setVisibleTabs();
          }
        )
      );
    }
    else {
      this.setVisibleTabs();
    }
  }

  tabSelected(e) {
    this.counter++;
    this.selectedSection = e.itemData;
  }

  setVisibleTabs() {
    this.sections = [];
    if (this.globalService.invoicePermission === 'Admin' || this.globalService.invoicePermission === 'Write') {
      this.sections.push('Invoice Entry');
      this.sections.push('On Hold');
    }
    if (this.globalService.invoiceSubmitPermission === 'Admin' || this.globalService.invoiceSubmitPermission === 'Write') {
      this.sections.push('Approved');
    }
    if (this.globalService.invoicePermission === 'Admin' || this.globalService.invoicePermission === 'Write' || this.globalService.invoicePermission === 'Read') {
      this.sections.push('All Invoices');
      this.sections.push('All Orders');
    }

    // TODO: change to user permissions when ready
    if (this.authService.isSuperUser()) {
      this.sections.push('Back Charges');
    }

    // we can check if we are passing the variation number from the login url
    const session = this.globalService.getSessionObject();
    if (session.externalInvoiceId && session.externalInvoiceId !== '') {
      this.globalService.externalInvoiceId = session.externalInvoiceId;
      this.globalService.setSessionAtt('externalInvoiceId', ''); // clear
      this.selectedSection = 'All Invoices';
      this.selectedIndex = 3;
    } else {
      this.selectedSection = 'Invoice Entry';
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

}
