export class IncomeInvoice {
  public id: number;
  public jobId: number;
  public incomeInvoiceTypeId: number;
  public claimJobLineId: number;
  public jobVariationId: number;
  public variationSplitId: number;
  public orderNumber: number;
  public invoiceDate: Date;
  public dueDate: Date;
  public totalExGST: number;
  public totalGST: number;
  public totalIncGST: number;
  public incomeInvoiceStatusId: number;
  public totalPaid: number;
  public fullyPaidOnDate: Date;
  public modifiedDate: Date;
  public modifiedUserId: number;
  public createDate: Date;
  public selectedCode: string;

  public claimDescription: string;
  public extraDescription: string;
  public approvedDate: Date;
  public connectionStatus: string;
}

export enum IncomeInvoiceTypeEnum {
  Deposit = 1,
  Claim = 2,
  Variation = 3
}
