import { GridService } from './../../services/grid.service';
import { CompanyService } from './../../services/felixApi/company.service';
import { User } from './../../dtos/user';
import { UserService } from './../../services/felixApi/user.service';
import { WIPReport } from './../../dtos/wip-report';
import { JobService } from './../../services/felixApi/job.service';
import { DivisionService } from './../../services/felixApi/division.service';
import { NotificationService } from './../../services/notification.service';
import { InvoiceService } from './../../services/felixApi/invoice.service';
import { GlobalService } from './../../services/global.service';
import { ClaimsService } from './../../services/felixApi/claims.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import CustomStore from 'devextreme/data/custom_store';
import { HouseType } from '../../dtos/house-type';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HelpModalComponent } from '../../shared/help.component';
import { UtilsService } from '../../services/utils.service';
import { RoleTypeEnum } from '../../dtos/role-type.enum';

@Component({
  selector: 'js-margins-report',
  templateUrl: './margins-report.component.html',
  styleUrls: ['./margins-report.component.scss']
})
export class MarginsReportComponent implements OnInit, OnDestroy {
  dataSource: CustomStore;
  subscriptions: Subscription[] = [];
  gridHeight: number;
  loadingData = true;
  showProfitAsMargin = false;
  summariseToMasterJob = false;
  ignoreOrdersLock = false;
  houseTypes: HouseType[];
  invoiceCutOffDate: Date;
  includeInactiveJobs = false;
  users: User[];
  salesRepWording = '';

  constructor(private claimsService: ClaimsService,
    private companyService: CompanyService,
    private invoiceService: InvoiceService,
    private jobService: JobService,
    private divisionService: DivisionService,
    private notiService: NotificationService,
    private modalService: NgbModal,
    private utilsService: UtilsService,
    private userService: UserService,
    public gridService: GridService,
    private globalService: GlobalService) {
    this.calculateDivision = this.calculateDivision.bind(this);
    this.calculateMasterJob = this.calculateMasterJob.bind(this);
    this.saveState = this.saveState.bind(this);
    this.loadState = this.loadState.bind(this);
    this.calculateSiteAddress = this.calculateSiteAddress.bind(this);
    this.calculateContractSignedDate = this.calculateContractSignedDate.bind(this);
    this.calculateSalesRep = this.calculateSalesRep.bind(this);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          this.setHeightWidths();
        }
      )
    );

    this.setHeightWidths();
    this.loadState(); // to set check boxes
    this.invoiceCutOffDate = new Date();
    this.loadData(true);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setHeightWidths() {
    this.gridHeight = window.innerHeight - 107;
  }

  loadData(useCache: boolean) {
    this.subscriptions.push(
      this.invoiceService.getWIPData(useCache)
        .subscribe(
          () => {
            this.houseTypes = this.jobService.houseTypes;
            this.users = this.userService.users;

            this.salesRepWording = this.companyService.companyRoles?.find(i => i.roleId === RoleTypeEnum.SalesRep)?.companyRoleDescription;

            this.loadingData = false;

            if (!useCache) {
              this.setUpDataSource();
            }
          },
          err => {
            this.notiService.notify(err);
            this.loadingData = false;
          })
    );
  }

  setUpDataSource() {
    this.dataSource = new CustomStore({
      key: 'jobNumber',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.claimsService.getMarginsData(this.invoiceCutOffDate, this.includeInactiveJobs).subscribe(res => {
              if (this.summariseToMasterJob) {
                const summarisedJobs: WIPReport[] = [];
                res.forEach(resJob => {
                  const job = this.jobService.jobs.find(i => i.jobNumber === resJob.jobNumber);
                  if (!job.masterJobId) {
                    // check for any child jobs
                    const childJobs = this.jobService.jobs.filter(i => i.masterJobId === job.id);

                    childJobs.forEach(childJob => {
                      // get the WIP record
                      const childWIPRecord = res.find(i => i.jobNumber === childJob.jobNumber);

                      if (childWIPRecord) {
                        resJob.basePrice += childWIPRecord.basePrice ?? 0;
                        resJob.contractPrice += childWIPRecord.contractPrice ?? 0;
                        resJob.postContractVariations += childWIPRecord.postContractVariations ?? 0;
                        resJob.contractPriceIncGST += childWIPRecord.contractPriceIncGST ?? 0;
                        resJob.jobCostExcGST += childWIPRecord.jobCostExcGST ?? 0;
                        resJob.costInvoices += childWIPRecord.costInvoices ?? 0;
                        resJob.claimInvoices += childWIPRecord.claimInvoices ?? 0;
                        resJob.variationInvoices += childWIPRecord.variationInvoices ?? 0;
                        resJob.incomeInvoices += childWIPRecord.incomeInvoices ?? 0;
                        resJob.orderExtraTotal += childWIPRecord.orderExtraTotal ?? 0;
                        resJob.variationsInScheduledAmount += childWIPRecord.variationsInScheduledAmount ?? 0;
                        resJob.extraIncomeIncGST += childWIPRecord.extraIncomeIncGST ?? 0;
                        resJob.allOrdersTotal += childWIPRecord.allOrdersTotal ?? 0;
                      }
                    });

                    if (this.ignoreOrdersLock) {
                      resJob.jobCostExcGST = resJob.allOrdersTotal;
                    }
                    summarisedJobs.push(resJob);
                  }
                });

                return resolve(summarisedJobs);
              } else {
                if (this.ignoreOrdersLock) {
                  res.forEach(resJob => {
                    resJob.jobCostExcGST = resJob.allOrdersTotal;
                  });
                }
                return resolve(res);
              }
            }, err => {
              return reject(this.globalService.returnError(err));
            })
          ));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) => {
          this.subscriptions.push(
            this.claimsService.updateJobCashFlow(encodeURIComponent(key), values).subscribe(res => {
              return resolve(res);
            }, err => {
              return reject(this.globalService.returnError(err));
            }
            ));
        });
      }
    });
  }

  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.unshift(
      {
        location: 'before',
        locateInMenu: 'auto',
        widget: 'dxTextBox',
        options: {
          width: 90,
          value: 'Cut-Off Date:',
          readOnly: true,
          stylingMode: 'underlined'
        }
      },
      {
        location: 'before',
        locateInMenu: 'auto',
        widget: 'dxDateBox',
        options: {
          value: new Date(),
          onValueChanged: this.invoiceCutOffDateChanged.bind(this),
          calendarOptions: { showTodayButton: true }
        }
      },
      {
        location: 'before',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          text: 'Go',
          type: 'default',
          onClick: this.setUpDataSource.bind(this)
        }
      },
      {
        location: 'before',
        locateInMenu: 'auto',
        widget: 'dxCheckBox',
        options: {
          text: 'Show Profit % by Margin',
          value: this.showProfitAsMargin,
          rtlEnabled: true,
          onValueChanged: this.showProfitByMarginChanged.bind(this)
        }
      },
      {
        location: 'before',
        locateInMenu: 'auto',
        widget: 'dxCheckBox',
        options: {
          text: 'Summarise to Master Job',
          value: this.summariseToMasterJob,
          rtlEnabled: true,
          onValueChanged: this.summariseToMasterJobChanged.bind(this)
        }
      },
      {
        location: 'before',
        locateInMenu: 'auto',
        widget: 'dxCheckBox',
        options: {
          text: 'Always use Orders Total for Job Cost',
          value: this.ignoreOrdersLock,
          rtlEnabled: true,
          onValueChanged: this.ignoreOrdersLockChanged.bind(this)
        }
      },
      {
        location: 'before',
        locateInMenu: 'auto',
        widget: 'dxCheckBox',
        options: {
          text: 'Include Inactive Jobs',
          value: this.includeInactiveJobs,
          rtlEnabled: true,
          onValueChanged: this.includeInactiveJobsChanged.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: this.refresh.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          type: 'default',
          stylingMode: 'outlined',
          text: 'Reset Layout',
          onClick: this.clearStatePersistance.bind(this)
        }
      });
  }

  calcContractIncGst(rowData): number {
    return ((!rowData.contractPriceIncGST ? 0 : rowData.contractPriceIncGST)
      + (!rowData.postContractVariations ? 0 : rowData.postContractVariations)
      + (!rowData.extraIncomeIncGST ? 0 : rowData.extraIncomeIncGST));
  }

  calcScheduledContractIncGst(rowData): number {
    return ((!rowData.contractPriceIncGST ? 0 : rowData.contractPriceIncGST)
      + (!rowData.variationsInScheduledAmount ? 0 : rowData.variationsInScheduledAmount));
  }

  calcContractExGst(rowData): number {
    const job = this.jobService.jobs.find(i => i.jobNumber === rowData.jobNumber);
    if (job?.isGSTFree) {
      return this.calcContractIncGst(rowData);
    }
    return Math.round(this.calcContractIncGst(rowData)
      / (1 + (job?.gstRate ? job.gstRate : this.invoiceService.globalGSTRate) / 100) * 100) / 100;
  }

  calcScheduledContractExGst(rowData): number {
    const job = this.jobService.jobs.find(i => i.jobNumber === rowData.jobNumber);
    if (job.isGSTFree) {
      return this.calcScheduledContractIncGst(rowData);
    }
    return Math.round(this.calcScheduledContractIncGst(rowData)
      / (1 + (job?.gstRate ? job.gstRate : this.invoiceService.globalGSTRate) / 100) * 100) / 100;
  }

  calcProfitAmount(rowData): number {
    return this.calcContractExGst(rowData) - (!rowData.jobCostExcGST ? 0 : rowData.jobCostExcGST);
  }

  calcScheduledProfitAmount(rowData): number {
    return this.calcScheduledContractExGst(rowData) - this.calculateOriginalCost(rowData);
  }

  calcTotalContract = (rowData) => {
    return this.calcContractIncGst(rowData);
  }

  calcProfit = (rowData) => {
    return this.calcProfitAmount(rowData);
  }

  calcMarkUpPercent = (rowData) => {
    if (rowData && rowData.jobCostExcGST) {
      return this.returnInfinityAsNull(Math.round(this.calcProfitAmount(rowData) / rowData.jobCostExcGST * 10000) / 100);
    } else {
      return null;
    }
  }

  calcScheduledMarkUpPercent = (rowData) => {
    if (rowData && rowData.allOrdersTotal && this.calculateOriginalCost(rowData)) {
      return this.returnInfinityAsNull(Math.round(this.calcScheduledProfitAmount(rowData)
        / (this.calculateOriginalCost(rowData)) * 10000) / 100);
    } else {
      return null;
    }
  }

  calcMarginPercent = (rowData) => {
    if (rowData && rowData.jobCostExcGST && this.calcContractExGst(rowData)) {
      return this.returnInfinityAsNull(Math.round(this.calcProfitAmount(rowData) / this.calcContractExGst(rowData) * 10000) / 100);
    } else {
      return null;
    }
  }

  calcScheduledMarginPercent = (rowData) => {
    if (rowData && rowData.allOrdersTotal && this.calcScheduledContractExGst(rowData)) {
      return Math.round(this.calcScheduledProfitAmount(rowData) / this.calcScheduledContractExGst(rowData) * 10000) / 100;
    } else {
      return null;
    }
  }

  calcMarkUpPercentDrift = (rowData) => {
    if (this.calcMarkUpPercent(rowData) && this.calcScheduledMarkUpPercent(rowData)) {
      return this.returnInfinityAsNull(this.calcMarkUpPercent(rowData) - this.calcScheduledMarkUpPercent(rowData));
    }
    return null;
  }

  calcMarginPercentDrift = (rowData) => {
    if (this.calcMarginPercent(rowData) && this.calcScheduledMarginPercent(rowData)) {
      return this.returnInfinityAsNull(this.calcMarginPercent(rowData) - this.calcScheduledMarginPercent(rowData));
    }
    return null;
  }

  calculateOriginalCost = (rowData) => {
    if (rowData && rowData.allOrdersTotal) {
      return Math.round(rowData.allOrdersTotal - (rowData.orderExtraTotal ? rowData.orderExtraTotal : 0));
    } else {
      return 0;
    }
  }

  returnInfinityAsNull(value: number): number {
    return value === Infinity || value === -Infinity ? null : value;
  }

  clearStatePersistance() {
    this.loadingData = true;
    this.showProfitAsMargin = false;
    this.summariseToMasterJob = false;
    this.ignoreOrdersLock = false;
    localStorage.removeItem('margin-report');
    setTimeout(() => {
      this.loadingData = false;
    }, 300); // wait
  }

  calculateDivision(rowData): string {
    const job = this.jobService.jobs.find(i => i.jobNumber === rowData.jobNumber);
    return this.divisionService.divisions.find(i => i.id === job?.divisionId)?.description;
  }

  calculateMasterJob(rowData): string {
    const masterJobId = this.jobService.jobs.find(i => i.jobNumber === rowData.jobNumber)?.masterJobId;

    if (masterJobId) {
      return this.jobService.jobs.find(i => i.id === masterJobId)?.jobNumber;
    } else {
      return '';
    }
  }

  showProfitByMarginChanged(e) {
    this.showProfitAsMargin = e.value;
  }

  summariseToMasterJobChanged(e) {
    this.summariseToMasterJob = e.value;
    this.setUpDataSource();
  }

  ignoreOrdersLockChanged(e) {
    this.ignoreOrdersLock = e.value;
    this.setUpDataSource();
  }

  invoiceCutOffDateChanged(e) {
    this.invoiceCutOffDate = e.value;
    this.setUpDataSource();
  }

  includeInactiveJobsChanged(e) {
    this.includeInactiveJobs = e.value;
    this.setUpDataSource();
  }

  saveState(currentState) {
    if (currentState) {
      currentState.showProfitAsMargin = this.showProfitAsMargin ? true : false;

      const stateString = JSON.stringify(currentState);
      localStorage.setItem('margin-report', stateString);
    }
  }

  loadState(): any {
    const currentState = JSON.parse(localStorage.getItem('margin-report'));

    if (currentState) {
      this.showProfitAsMargin = currentState.showProfitAsMargin ? true : false;
    }
    return currentState;
  }

  help(dataField: string) {
    const modalRef = this.modalService.open(HelpModalComponent,
      { windowClass: 'modal-lg', scrollable: true });
    modalRef.componentInstance.dataField = dataField;

    modalRef.result.then(() => { });
  }

  calculateSiteAddress(data) {
    return this.jobService.jobs.find(i => i.jobNumber === data.jobNumber)?.jobAddressString;
  }

  calculateContractSignedDate(data): Date {
    return this.utilsService.convertDateStringToDate(this.jobService.jobs.find(i => i.jobNumber === data.jobNumber)?.contractSignedDate);
  }

  calculateSalesRep(rowData): string {
    const job = this.jobService.jobs.find(i => i.jobNumber === rowData.jobNumber);
    return this.jobService.jobRoles.find(i => i.jobId === job.id && i.roleId === RoleTypeEnum.SalesRep)?.user.fullName;
  }

  refresh() {
    this.loadData(false);
  }
}
