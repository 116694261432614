<div class="modal-header">
  <h3 class="modal-title">{{costCentreDescription}}</h3>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  Comment:
  <br>
  <dx-text-area class="subjectClass" [height]="90" [(value)]="comment"></dx-text-area>
  <br>

  <span class="me-2">
    <dx-button id="blueButton" [width]="110" type="default" text="Blue" (onClick)="markColour(1)"></dx-button>
  </span>
  <span class="me-2">
    <dx-button id="orangeButton" [width]="110" type="default" text="Orange" (onClick)="markColour(2)"></dx-button>
  </span>
  <span class="me-2">
    <dx-button id="redButton" [width]="110" type="danger" text="Red" (onClick)="markColour(3)"></dx-button>
  </span>
  <span>
    <dx-button id="magentaButton" [width]="110" type="default" text="Magenta" (onClick)="markColour(4)"></dx-button>
  </span>
</div>

<div class="modal-footer">
  <dx-button *ngIf="!hasOrderLines" type="danger" stylingMode="outlined" text="Mark Not Applicable"
    (onClick)="markColour(-1)">
  </dx-button>
  <dx-button type="default" stylingMode="outlined" text="Clear Flag & Save" (onClick)="markColour(0)"></dx-button>
  <dx-button type="default" stylingMode="outlined" text="Save" (onClick)="markColour(colourId)"></dx-button>
</div>
