<div class="page">

  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <dx-data-grid *ngIf="!loading" id="divisionsGrid" class="max-w-1500 m-auto grid-height" [height]="gridHeight"
    [dataSource]="dataSource" [allowColumnReordering]="false" [allowColumnResizing]="true" [repaintChangesOnly]="true"
    [showBorders]="true" [rowAlternationEnabled]="true" [wordWrapEnabled]="true"
    (onToolbarPreparing)="onToolbarPreparing($event, 'toolbarTemplate')"
    (onExporting)="gridService.onExporting($event, 'job-customers')">

    <dxo-search-panel [visible]="true" location="before" [width]="250"></dxo-search-panel>
    <dxo-scrolling mode="virtual"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-editing mode="row" [allowAdding]="false" [allowUpdating]="true" [allowDeleting]="false" [useIcons]="true"
      refreshMode="repaint">
    </dxo-editing>

    <div *dxTemplate="let data of 'refreshTemplate'">
      <div class="m-l-10">
        <dx-button icon="refresh" (onClick)="refresh()"></dx-button>
      </div>
    </div>

    <dxi-column dataField="jobNumber" [allowEditing]="false" width="120" sortOrder="asc"> </dxi-column>

    <dxi-column dataField="contractName" [allowEditing]="false"> </dxi-column>

    <dxi-column dataField="siteAddress" [allowEditing]="false" [calculateCellValue]="calculateSiteAddress">
    </dxi-column>

    <dxi-column dataField="clientId" [visible]="false"> </dxi-column>

    <dxi-column dataField="divisionId" [visible]="false"> </dxi-column>

    <dxi-column dataField="accountsId" caption="Connected to Account" dataType="string" width="300"
      editCellTemplate="editContactConnection">
      <dxo-lookup [dataSource]="accountingSystemContacts" [displayExpr]="calculateContactName" valueExpr="contactID">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="modifiedUserId" caption="Modified By" dataType="number" [allowEditing]="false" width="200">
      <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="modifiedDate" dataType="date" width="120" format="d/M/yy" alignment="center"
      [allowEditing]="false">
    </dxi-column>

    <!-- templates -->
    <div *dxTemplate="let data of 'toolbarTemplate'">
      <div>
        <dx-select-box id="selectDivision" [dataSource]="divisions" displayExpr="description" valueExpr="id"
          placeholder="Select Division" [width]="286" [(value)]='divisionId'
          (onValueChanged)='onSelectionChanged($event)'>
        </dx-select-box>
      </div>
    </div>

    <div *dxTemplate="let cellInfo of 'editContactConnection'">
      <dx-drop-down-box [dropDownOptions]="dropDownOptions" [dataSource]="accountingSystemContacts"
        [showClearButton]="true" [(value)]="cellInfo.value" [displayExpr]="calculateContactName" valueExpr="contactID"
        contentTemplate="contentTemplate" (onValueChanged)="onDropDownChanged(cellInfo, $event)">
        <div *dxTemplate="let e of 'contentTemplate'">
          <dx-data-grid [dataSource]="accountingSystemContacts" keyExpr="contactID" [remoteOperations]="false"
            height="500px" [showBorders]="true" [selection]="{ mode: 'single' }" [focusedRowEnabled]="true"
            [focusedRowKey]="cellInfo.value" [wordWrapEnabled]="true" [selectedRowKeys]="[cellInfo.value]"
            [hoverStateEnabled]="true" [scrolling]="{ mode: 'virtual', preloadEnabled: true }"
            (onSelectionChanged)="onAccountSelectionChanged(cellInfo, e, $event)">

            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-search-panel [visible]="true" [width]="256"></dxo-search-panel>
            <dxo-group-panel [visible]="false"></dxo-group-panel>
            <dxo-grouping #expand [autoExpandAll]="true" [contextMenuEnabled]="true" expandMode="rowClick">
            </dxo-grouping>

            <dxi-column dataField="contactID" caption="Code" [visible]="false"></dxi-column>
            <dxi-column caption="Name" [calculateCellValue]="calculateContactName"></dxi-column>
            <dxi-column dataField="name" [visible]="false"></dxi-column>
            <dxi-column dataField="siteAddress" [visible]="false"></dxi-column>
          </dx-data-grid>
        </div>
      </dx-drop-down-box>
    </div>
  </dx-data-grid>

</div>

<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loadingAccounts" [showIndicator]="true"
  [showPane]="true" [shading]="true" [hideOnOutsideClick]="false">
</dx-load-panel>

<!-- auto - connect -->
<dx-popup class="popup" [width]="300" [height]="130" [showTitle]="true" title="Auto Connect Contacts"
  [dragEnabled]="false" [hideOnOutsideClick]="true" [(visible)]="autoConnectPopupVisible">
  <div *dxTemplate="let data of 'content'">
    <dx-button id="autoConnectButton" text="Go" type="success" (onClick)="autoConnectGo()"> </dx-button>
  </div>
</dx-popup>
