import { GridService } from './../../services/grid.service';
import { CompanyService } from './../../services/felixApi/company.service';
import { JobService } from './../../services/felixApi/job.service';
import { PoService } from './../../services/felixApi/po.service';
import { NotificationService } from './../../services/notification.service';
import { MaintenanceService } from './../../services/felixApi/maintenance.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Vendor } from '../../dtos/vendor';
import { Subscription } from 'rxjs';
import { PurchaseOrder } from '../../dtos/purchase-order';
import { Job } from '../../dtos/job';
import { CompanyActivityService } from '../../services/felixApi/company-activity.service';
import { RoleTypeEnum } from '../../dtos/role-type.enum';

@Component({
  selector: 'js-global-change-order-vendor',
  templateUrl: './global-change-order-vendor.component.html',
  styleUrls: ['./global-change-order-vendor.component.scss']
})
export class GlobalChangeOrderVendorComponent implements OnInit, OnDestroy {

  isUpdating = false;
  oldVendorId: number;
  newVendorId: number;
  vendors: Vendor[];
  subscriptions: Subscription[] = [];
  varianceCodeId: number;
  varianceReason: string;
  costDate: Date;
  purchaseOrderCount: number;
  purchaseOrders: PurchaseOrder[] = [];
  gridHeight: number;
  jobs: Job[];
  selectedRows: number[] = [];
  effectiveDate: string;
  cancelAndRecreateCallUp = true;

  constructor(
    private _activeModal: NgbActiveModal,
    private companyService: CompanyService,
    private maintenanceService: MaintenanceService,
    private jobService: JobService,
    private notiService: NotificationService,
    private poService: PoService,
    public gridService: GridService,
    private companyActivityService: CompanyActivityService
  ) {
    this.calculateActivityDesc = this.calculateActivityDesc.bind(this);
    this.calculateSiteAddress = this.calculateSiteAddress.bind(this);
    this.calculateSiteManager = this.calculateSiteManager.bind(this);
    this.calculateSiteManagerTitle = this.calculateSiteManagerTitle.bind(this);
  }

  ngOnInit() {
    this.vendors = this.maintenanceService.allVendors.filter(i => !i.hideFromOrders);
    this.gridHeight = window.innerHeight - 540;


    this.subscriptions.push(
      this.jobService.getJobsByAddressWithExtras(true)
        .subscribe(
          jobs => {
            this.jobs = jobs;
          },
          err => {
            this.notiService.notify(err);
          })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  close() {
    this._activeModal.close();
  }

  getPurchaseOrders() {
    if (this.oldVendorId) {
      // first get all the orders for this vendor that we can change
      this.isUpdating = true;
      this.subscriptions.push(
        this.poService.getOrdersForVendorForChange(this.oldVendorId)
          .subscribe(
            purchaseOrders => {
              this.purchaseOrders = purchaseOrders;
              if (!purchaseOrders.length) {
                this.notiService.showInfo('No orders to change');
              }
              this.isUpdating = false;
            },
            err => {
              this.notiService.notify(err);
              this.isUpdating = false;
            })
      );
    }
  }

  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.unshift(
      {
        location: 'before',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          text: 'Find Orders',
          type: 'default',
          onClick: this.getPurchaseOrders.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          text: 'Run Change',
          type: 'default',
          onClick: this.changeVendorForOrderGo.bind(this)
        }
      });
  }

  calculateActivityDesc(data) {
    const jobExtra = this.jobService.jobExtras?.find(i => i.jobId === data.jobId);
    if (jobExtra && jobExtra.maintenanceCompleteDate) {
      return 'Maintenance Complete';
    } else if (jobExtra && jobExtra.currentActivityId) {
      const activity = this.companyActivityService.activities.find(i => i.id === jobExtra.currentActivityId);
      if (activity) {
        return activity.activityCode + ' - ' + activity.description;
      }
    }
    return '';
  }

  changeVendorForOrderGo() {
    if (this.selectedRows.length && this.oldVendorId && this.costDate && this.newVendorId && this.varianceCodeId
      && this.varianceReason && this.varianceReason !== '') {
      this.isUpdating = true;
      this.purchaseOrderCount = this.selectedRows.length;
      this.effectiveDate = this.costDate.getFullYear()
        + ('0' + (this.costDate.getMonth() + 1)).toString().slice(-2)
        + ('0' + this.costDate.getDate()).slice(-2);

      this.runUpdate();
    } else {
      this.notiService.showInfo('Select all required fields');
    }
  }

  runUpdate() {
    let count = 0;
    this.selectedRows.forEach(rowKey => {
      // add delay to help with server load
      count++;
      setTimeout(() => {
        const po = this.purchaseOrders.find(i => i.id === rowKey);

        this.subscriptions.push(
          this.poService.changeVendorForPurchaseOrder(po.id, this.varianceCodeId,
            this.varianceReason, this.cancelAndRecreateCallUp, this.effectiveDate, this.newVendorId, false).subscribe(
              () => {
                this.purchaseOrderCount--;
                if (this.purchaseOrderCount === 0) {
                  this.notiService.showSuccess('Completed');
                  this.close();
                }
              },
              () => {
                this.purchaseOrderCount--;
                if (this.purchaseOrderCount === 0) {
                  this.notiService.showSuccess('Completed. Some orders failed to update.');
                  this.close();
                }
              })
        );
      }, 2000 * count);
    });
  }

  calculateSiteAddress(data) {
    return this.jobs.find(i => i.id === data.jobId)?.jobAddressString;
  }

  calculateSiteManager(data) {
    return this.jobService.jobRoles.find(i => i.jobId === data.jobId && i.roleId === RoleTypeEnum.SiteManager)?.user.fullName;
  }

  calculateSiteManagerTitle() {
    return this.companyService.companyRoles.find(i => i.roleId === RoleTypeEnum.SiteManager)?.companyRoleDescription;
  }
}
