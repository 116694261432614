<div class="modal-header">
  <h3 class="modal-title">Bulk add balance as extras</h3>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <b>Updates active jobs and where order does not have accrual stopped.</b>
  <br>
  <br>

  <js-extra-variance-select [varianceCodeId]=varianceCodeId [varianceReason]="varianceReason"
    (varianceCodeIdSelected)="varianceCodeId = $event" (varianceReasonSelected)="varianceReason = $event">
  </js-extra-variance-select>
  <br>
  <div class="dx-field">
    <div class="dx-field-label">Line Description:</div>
    <div class="dx-field-value">
      <dx-text-box [(value)]="orderLineDescription"> </dx-text-box>
    </div>
  </div>

  <br>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="run()">Update</button>
</div>
