import { PoService } from './../../services/felixApi/po.service';
import { GlobalService } from './../../services/global.service';
import { NotificationService } from './../../services/notification.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JobSearchTypeEnum } from '../../dtos/job-search-type.enum';
import { JobSearchModalComponent } from '../../shared/job-search-modal/job-search-modal.component';
import { Job } from '../../dtos/job';
import { JobService } from '../../services/felixApi/job.service';
import { Subscription } from 'rxjs';
import { OrderLine } from '../../dtos/order-line';
import { EstimatingService } from '../../services/felixApi/estimating.service';

@Component({
  selector: 'js-copy-job',
  templateUrl: './copy-job.component.html',
  styleUrls: ['./copy-job.component.scss']
})
export class CopyJobComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  jobs: Job[];
  jobNumber: string;
  loading = false;
  jobString = '';
  jobAddress: string;
  zeroQuantities = false;
  selectedIds: number[] = [];
  orderLines: OrderLine[] = [];
  selectedRowKeys: number[] = [];
  treeHeight: number;
  ignoreExistingCostCentres = false;

  constructor(
    private _activeModal: NgbActiveModal,
    private globalService: GlobalService,
    private jobService: JobService,
    private poService: PoService,
    private estimatingService: EstimatingService,
    private modalService: NgbModal,
    private notiService: NotificationService
  ) {
    this.runCopy = this.runCopy.bind(this);
  }

  ngOnInit(): void {
    if (!this.jobService.jobs || !this.jobService.jobs.length) {
      this.getJobs();
    } else {
      this.jobs = this.jobService.jobs;
    }

    this.treeHeight = window.innerHeight - 380;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getJobs() {
    this.loading = true;
    this.subscriptions.push(
      this.jobService.getJobsByAddress(false)
        .subscribe({
          next: (jobs) => {
            this.jobs = jobs;
            this.loading = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }})
    );
  }

  cancel() {
    this._activeModal.dismiss();
  }

  onJobNumberChanged() {
    this.jobNumber = this.jobNumber?.trim();
    const copyJobFrom = this.jobs.find(i => i.jobNumber === this.jobNumber);
    this.jobString = copyJobFrom?.contractName;
    this.jobAddress = this.globalService.getJobString(copyJobFrom);

    if (!copyJobFrom) {
      this.notiService.showInfo('Invalid Job');
    } else {
      this.selectedIds = [];
      this.selectedRowKeys = [];
      this.loading = true;

      this.subscriptions.push(
        this.poService.getOrderLinesForCopy(copyJobFrom.id)
          .subscribe({
            next: (res) => {
              // ordered lines with no qty are deleted
              const orderLines = res.filter(i => !i.purchaseOrderId || i.measuredQuantity !== 0);

              // we get the tree set up here
              // we add a row for each cost centre
              this.orderLines = [];

              orderLines.forEach(orderLine => {
                const existingCostCentre = this.orderLines.find(i => i.id === (-orderLine.costCentreId));
                const costCentre = this.estimatingService.priceFileItemGroups.find(i => i.id === orderLine.costCentreId);

                if (!existingCostCentre) {
                  // use negatives to avoid conflicts with order lines
                  this.orderLines.push(new OrderLine(-costCentre.id, null, costCentre.priceFileCode,
                    costCentre.orderNumber, 0, orderLine.costCentreCodeAndDesc,
                    orderLine.subGroupItemDesc, costCentre.description, costCentre.priceFileCode));
                }

                orderLine.costCentreOrderNumber = costCentre.orderNumber;
                orderLine.costCentreId = -orderLine.costCentreId;
              });

              // now we add the order lines
              this.orderLines = this.orderLines.concat(orderLines);
              this.loading = false;
            },
            error: (err) => {
              this.notiService.notify(err);
              this.loading = false;
            }})
      );
    }
  }

  jobSearch() {
    const modalRef = this.modalService.open(JobSearchModalComponent, { windowClass: 'modal-1000' });
    modalRef.componentInstance.searchType = JobSearchTypeEnum.AllJobs;

    modalRef.result.then((jobNumber) => {
      if (jobNumber) {
        this.jobNumber = jobNumber;
        this.onJobNumberChanged();
      }
    });
  }

  onSelectionChanged(e: any) {
    const selectedItems = e.component.getSelectedRowsData('leavesOnly')

    if (selectedItems && selectedItems.length) {
      this.selectedIds = selectedItems.map(i => i.id);
    } else {
      this.selectedIds = [];
    }
  }

  runCopy() {
    if (this.selectedIds && this.selectedIds.length) {
      const copyJobFormId = this.jobs.find(i => i.jobNumber === this.jobNumber)?.id;

      if (!copyJobFormId) {
        this.notiService.showInfo('Invalid Job');
      } else {
        this._activeModal.close({
          fromJobId: copyJobFormId,
          zeroQuantities: this.zeroQuantities,
          selectedIds: this.selectedIds,
          ignoreExistingCostCentres: this.ignoreExistingCostCentres
        });
      }
    }
  }
}
