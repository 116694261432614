import { CompanyService } from '../../services/felixApi/company.service';
import { NotificationService } from '../../services/notification.service';
import { UserService } from '../../services/felixApi/user.service';
import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { JobService } from '../../services/felixApi/job.service';
import { Job } from '../../dtos/job';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { JobSearchTypeEnum } from '../../dtos/job-search-type.enum';
import { GlobalService } from '../../services/global.service';
import { UserTypeEnum } from '../../dtos/user-type.enum';
import { Company } from '../../dtos/company';
import { AuthService } from '../../services/auth.service';
import { Subscription } from 'rxjs';
import CustomStore from 'devextreme/data/custom_store';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { User } from '../../dtos/user';
import { JobTypeEnum } from '../../dtos/job-type.enum';

@Component({
  selector: 'js-job-search-modal',
  templateUrl: './job-search-modal.component.html',
  styleUrls: ['./job-search-modal.component.scss']
})
export class JobSearchModalComponent implements OnInit, OnDestroy {
  @Input() searchType: number;

  @ViewChild('dataGrid', { static: false }) dataGrid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  errorMessage: any;
  formErrorMessage = '';
  jobs: Job[] = [];
  jobSearchTypeEnum = JobSearchTypeEnum;
  myJobsOnly = false;
  templatesOnly = false;
  loading = true;
  currentCompany: Company;
  userType = UserTypeEnum;
  innerWidth: number; // width of the screen.
  isRestrictedForm = false; // if Client or Trade or the screen width is too small then we restrict the form
  activeJobsOnly = true; // do we want just active jobs only - default yes
  includeInactive = false; // mat check needs another flag
  filteredJobs: Job[];
  searchString: string;
  canOnlySeeMyJobs: boolean;
  jobData: CustomStore;
  selectedJobNumber: any;
  gridHeight = 200;
  users: User[] = [];
  assigneeId: number;
  showAssignedUser: boolean;
  showSiteManager: boolean;
  showSalesRep: boolean;
  showJobsHandedOver = false;
  isTrackingActive: boolean;

  constructor(
    private _jobService: JobService,
    private _activeModal: NgbActiveModal,
    private _authService: AuthService,
    private _userService: UserService,
    private companyService: CompanyService,
    private notiService: NotificationService,
    private _globalService: GlobalService) {
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
  }


  ngOnInit(): void {
    this.currentCompany = this._globalService.getCurrentCompany();
    this.isTrackingActive = true;

    if (!this.isTrackingActive ||
      (localStorage.getItem('truthengine-showJobsHandedOver') && localStorage.getItem('truthengine-showJobsHandedOver') === 'Yes')) {
      this.showJobsHandedOver = true;
    }

    this.innerWidth = window.innerWidth;
    this.calcIsRestrictedForm();
    this.subscribeToWidthChanges();
    this.getUsers();

    this.canOnlySeeMyJobs = this._authService.getCurrentUser().canOnlySeeAllocatedJobs;
    this.selectedJobNumber = this._jobService.currentJob?.jobNumber;

    if (this.searchType === this.jobSearchTypeEnum.Templates) {
      this.getTemplates();
    } else if (this.searchType === this.jobSearchTypeEnum.MyJobs) {
      this.getMyJobs();
    } else if (this.searchType === this.jobSearchTypeEnum.AllJobs) {
      // if we have previously set it for my jobs only
      if (this.canOnlySeeMyJobs) {
        this.getMyJobs();
      } else if (localStorage.getItem('truthengine-MyJobsOnly') && localStorage.getItem('truthengine-MyJobsOnly') === 'Yes') {
        this.getMyJobs();
      } else {
        this.loadJobs();
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  subscribeToWidthChanges() {
    this.subscriptions.push(
      this._globalService.innerHeightWidthChanged.subscribe(width => {
        this.innerWidth = window.innerWidth;
        this.calcIsRestrictedForm();
      })
    );
  }

  calcIsRestrictedForm() {
    this.showAssignedUser = false;
    this.showSalesRep = false;
    this.showSiteManager = false;
    if (this._authService.isClient() || this._authService.isAssociate() || this.innerWidth < 700) {
      this.isRestrictedForm = true;
    } else {
      this.isRestrictedForm = false;
      if (this.innerWidth > 1000) {
        this.showAssignedUser = true;
        if (this.innerWidth > 1150) {
          this.showSiteManager = true;
          if (this.innerWidth > 1400) {
            this.showSalesRep = true;
          }
        }
      }
    }

    // console.log(window.innerHeight);
    this.gridHeight = window.innerHeight - 210;
  }

  getUsers() {
    if (!this._authService.isClient() && !this._authService.isAssociate()) {
      this.subscriptions.push(
        this._userService.getCurrCompUsers(true)
          .subscribe(
            () => {
              this.users = this._userService.officeUsers;
            },
            err => {
              this.notiService.notify(err);
            })
      );
    }
  }

  loadJobs() {
    // because we entered an address search clear the other search fields
    this.jobs = [];
    this.formErrorMessage = '';
    this.myJobsOnly = false;
    this.templatesOnly = false;
    this.loading = true;
    this.assigneeId = null;

    this.subscriptions.push(
      this._jobService.getJobsByAddressWithExtras(false)
        .subscribe(
          jobs => {
            this.jobs = jobs;
            this.createJobDataSource();
          },
          error => {
            this.errorMessage = <any>error;
            this.loading = false;
          })
    );
  }

  getTemplates() {
    if (this.templatesOnly) { // we need to reset to all jobs
      if (this.canOnlySeeMyJobs) {
        this.myJobsOnly = false;
        this.getMyJobs();
      } else {
        this.loadJobs();
      }
    } else {
      // this.searchForm.patchValue({ searchString: '' });
      this.jobs = [];
      this.formErrorMessage = '';
      this.myJobsOnly = false;
      this.templatesOnly = true;
      this.loading = true;
      this.assigneeId = null;

      this.subscriptions.push(
        this._jobService.getTemplateJobs()
          .subscribe(
            jobs => {
              this.jobs = jobs;
              this.createJobDataSource();
            },
            error => {
              this.errorMessage = <any>error;
              this.loading = false;
            })
      );
    }
  }

  getMyJobs() {
    if (this.myJobsOnly) { // we need to reset to all jobs
      this.loadJobs();
      localStorage.setItem('truthengine-MyJobsOnly', 'No');
      this.myJobsOnly = false;
    } else {
      this.jobs = [];
      this.formErrorMessage = '';
      this.loading = true;
      localStorage.setItem('truthengine-MyJobsOnly', 'Yes');
      this.myJobsOnly = true;
      this.templatesOnly = false;
      this.assigneeId = null;

      this.subscriptions.push(
        this._jobService.getMyJobsWithExtras()
          .subscribe(
            jobs => {
              this.jobs = jobs;
              this.createJobDataSource();
            },
            error => {
              this.errorMessage = <any>error;
              this.loading = false;
            })
      );
    }
  }

  cancel() {
    this._activeModal.close(null);
  }

  onSelectionChanged(event) {
    if (event?.selectedRowKeys?.length > 0) {
      if (event.selectedRowsData[0].jobNumber !== this.selectedJobNumber) {
        this._activeModal.close(event.selectedRowsData[0].jobNumber);
      }
    }
  }

  activeJobsClicked() {
    if (this.activeJobsOnly) {
      this.activeJobsOnly = false;
      this.createJobDataSource();
    } else {
      this.activeJobsOnly = true;
      this.createJobDataSource();
    }
  }

  createJobDataSource() {
    this.clearSearch();
    let filteredJobs = this.jobs;
    if (this.activeJobsOnly) {
      filteredJobs = this.jobs.filter(i => i.isActive);
    }

    const shownJobs: Job[] = [];

    filteredJobs.forEach(job => {
      job.jobAddressString = this._globalService.getJobString(job);
      job.currentActivityDesc = '';
      job.currentActivityCode = null; // default at the end

      const jobExtra = this._jobService.jobExtras?.find(i => i.jobId === job.id);
      if (jobExtra) {
        if (jobExtra && jobExtra.maintenanceCompleteDate) {
          job.currentActivityDesc = 'Maintenance Complete';
          shownJobs.push(job);
        } else if (jobExtra.cancellationDate) {
          job.currentActivityDesc = 'Cancelled';
          shownJobs.push(job);
        } else if (jobExtra.isOnHold) {
          job.currentActivityDesc = 'On Hold';
          shownJobs.push(job);
        } else if (!jobExtra.handoverDate || this.showJobsHandedOver) {
          const activity = this.companyService.activities?.find(i => i.id === jobExtra.currentActivityId);
          if (activity) {
            job.currentActivityDesc = activity.description;
            job.currentActivityCode = activity.activityCode;
          } else {
            if (!job.isActive) {
              job.currentActivityDesc = 'Inactive Job';
            } else if (!job.salesDate && job.jobTypeId === JobTypeEnum.StandardJob) {
              job.currentActivityDesc = 'Pending Sale';
              job.currentActivityCode = '';
            }
          }
          shownJobs.push(job);
        }
      } else {
        if (!job.isActive) {
          job.currentActivityDesc = 'Inactive Job';
        } else if (!job.salesDate && job.jobTypeId === JobTypeEnum.StandardJob) {
          job.currentActivityDesc = 'Pending Sale';
          job.currentActivityCode = '';
        }
        shownJobs.push(job);
      }
    });

    this.jobData = new CustomStore({
      key: 'jobNumber',
      loadMode: 'raw',
      load: () => shownJobs
    });
    this.loading = false;
  }

  runFilter(data) {
    if (!data.value || data.value === '') {
      this.dataGrid?.instance.clearFilter('search');
    } else {
      this.dataGrid?.instance.searchByText(data.value);
    }
  }

  clearSearch() {
    this.searchString = '';
    if (this.dataGrid && this.dataGrid.instance) {
      this.dataGrid.instance.clearFilter('search');
    }
  }

  filterByAssigned(e: any) {
    // filter by assigned user
    // if (e.selectedItem.id) {
    if (!this.loading) {
      this.assigneeId = e.selectedItem?.id;
      this.loading = true;

      this.subscriptions.push(
        this._jobService.getJobsByAssignee(this.assigneeId)
          .subscribe(
            jobs => {
              localStorage.setItem('truthengine-MyJobsOnly', 'No');
              this.myJobsOnly = false;
              this.templatesOnly = false;
              this.jobs = jobs;
              this.createJobDataSource();
            },
            error => {
              this.errorMessage = <any>error;
              this.loading = false;
            })
      );
    }
  }

  showJobsHandedOverClick(e) {
    localStorage.setItem('truthengine-showJobsHandedOver', e.value ? 'Yes' : 'No');
    this.createJobDataSource();
  }

  calculateActivitySort(data) {
    return data.currentActivityCode;
  }

  onRowClicked() {
    this._activeModal.close(this.selectedJobNumber);
  }
}
