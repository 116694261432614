import { GridService } from './../../services/grid.service';
import { NotificationService } from './../../services/notification.service';
import { EstimatingService } from './../../services/felixApi/estimating.service';
import { PoService } from './../../services/felixApi/po.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { OrderLine } from '../../dtos/order-line';
import { Phase } from '../../dtos/phase';
import { UnitOfMeasure } from '../../dtos/unitOfMeasure';
import { Vendor } from '../../dtos/vendor';
import { MaintenanceService } from '../../services/felixApi/maintenance.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PriceFileItem } from '../../dtos/price-file-item';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { JobService } from '../../services/felixApi/job.service';
import * as saveAs from 'file-saver';
import { DxDataGridComponent } from 'devextreme-angular';
import { CostCentreSummaryComponent } from '../cost-centre-summary/cost-centre-summary.component';
import { SaveToRecipeComponent } from '../save-to-recipe/save-to-recipe.component';
const ExcelJS = require('exceljs');

@Component({
  selector: 'js-order-export',
  templateUrl: './order-export.component.html',
  styleUrls: ['./order-export.component.scss']
})
export class OrderExportComponent implements OnInit {

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  dataSource: any = {};
  phases: Phase[] = [{ id: 0, orderNo: 0, phaseCode: 'Default', description: 'Default' }];
  unitsOfMeasure: UnitOfMeasure[];
  vendors: Vendor[];
  costCentres: PriceFileItem[];
  resetLayout: boolean;
  showEmptyCostCentres = false;
  orderExport: OrderLine[];
  gridHeight: number;
  jobNumber: string;

  constructor(
    private _activeModal: NgbActiveModal,
    private jobService: JobService,
    private poService: PoService,
    private estimatingService: EstimatingService,
    private maintenanceService: MaintenanceService,
    private notiService: NotificationService,
    public gridService: GridService,
    private modalService: NgbModal
  ) {
    this.calculateVendorSortValue = this.calculateVendorSortValue.bind(this);
    this.calculateVendorCodeSortValue = this.calculateVendorCodeSortValue.bind(this);
    this.calculateVendorName = this.calculateVendorName.bind(this);
  }

  ngOnInit(): void {
    this.jobNumber = this.jobService.currentJob.jobNumber;
    this.gridHeight = window.innerHeight - 170;
    this.phases = this.phases.concat(this.maintenanceService.phases);
    this.unitsOfMeasure = this.maintenanceService.unitOfMeasures;
    this.vendors = this.maintenanceService.allVendors;
    this.costCentres = this.estimatingService.priceFileItemGroups.filter(i => !i.priceFileItemParentId);

    this.setUpDataSource();
  }

  getOrderLinesDataSet(): OrderLine[] {
    const dataSet = this.poService.orderLines;

    dataSet.forEach(element => {
      element.purchaseOrderNumber = this.poService.getPurchaseOrderNumber(element);

      const costCentre = this.estimatingService.priceFileItemGroups.find(i => i.id === element.costCentreId);
      element.costCentreCode = costCentre?.priceFileCode?? '';
      element.costCentreOrderNumber = costCentre?.orderNumber;
      element.costCentreCodeAndDesc = costCentre?.priceFileCode + ' - ' + costCentre.description;

      element.subGroupItemDesc = 'Ad-Hoc Items';
      element.priceFileItemParentId = null;
      element.priceFileItemParentOrderNumber = 0;

      if (element.priceFileItemId) {
        const priceFileItem = this.estimatingService.allPriceFileItems.find(i => i.id === element.priceFileItemId);

        if (priceFileItem?.priceFileItemParentId) {
          const priceFileItemParent = this.estimatingService.priceFileItemGroups
            .find(i => i.id === priceFileItem.priceFileItemParentId);
          if (priceFileItemParent) {
            element.subGroupItemDesc = priceFileItemParent.description;
            element.priceFileItemParentId = priceFileItemParent.id;
            element.priceFileItemParentOrderNumber = priceFileItemParent?.orderNumber;
          }
        }
      }
    });

    // add an empty row when there are no itmes for a cost centre
    if (this.showEmptyCostCentres) {
      this.estimatingService.priceFileItemGroups.filter(i => !i.priceFileItemParentId).forEach(cc => {
        const foundItem = this.poService.orderLines.find(i => i.costCentreId === cc.id);
        if (!foundItem) {
          dataSet.push(new OrderLine(0, cc.id, cc.priceFileCode, cc.orderNumber, 0, cc.priceFileCode + ' - ' + cc.description, 'No Items', 'Cost Centre Empty', ''));
        }
      });
    }

    this.orderExport = dataSet;
    return dataSet;
  }

  setUpDataSource() {
    this.dataSource = new DataSource({
      key: 'id',
      load: () => this.getOrderLinesDataSet(),
    });
  }

  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxCheckBox',
        options: {
          text: 'Show Empty Cost Centres',
          value: this.showEmptyCostCentres,
          rtlEnabled: true,
          onValueChanged: this.showEmptyCostCentresChanged.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          text: 'Reset Layout',
          onClick: this.clearStatePersistance.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          text: 'Summary',
          onClick: this.showCostCentreSummary.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          text: 'Save to Recipe',
          onClick: this.saveToRecipe.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          width: 40,
          icon: 'collapse',
          onClick: this.collapseAll.bind(this),
          matTooltip: 'Collapse All Rows'
        }
      }
    );
  }

  cancel() {
    if (this.resetLayout) {
      localStorage.removeItem('orders-export-grid');
    }
    this._activeModal.dismiss();
  }

  calculateVendorSortValue(data) {
    return this.vendors.find(i => i.id === data.vendorId)?.vendorName;
  }

  calculateVendorCodeSortValue(data) {
    return this.vendors.find(i => i.id === data.vendorId)?.vendorCode;
  }

  calculateVendorName(data) {
    return this.vendors.find(i => i.id === data.vendorId)?.vendorName;
  }

  clearStatePersistance() {
    this.resetLayout = true;
    this.notiService.showInfo('Please now close & re-open export grid to reset layout');
  }

  showEmptyCostCentresChanged() {
    this.showEmptyCostCentres = !this.showEmptyCostCentres;
    this.setUpDataSource();
  }

  onExporting(e) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Tasks',
      { pageSetup: { paperSize: 8, orientation: 'landscape', showGridLines: true } });

    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      keepColumnWidths: false,
      customizeCell: function (options) {
        const { gridCell, excelCell } = options;
        const column = gridCell.column;
      },
      topLeftCell: { row: 5, column: 1 },
    }).then((cellRange) => {
      // header
      worksheet.getCell('A1').value = 'Job:';
      worksheet.getCell('A1').font = { name: 'Calibri', size: 12, bold: true };
      worksheet.getCell('B1').value = this.jobService.currentJob.jobNumber;
      worksheet.getCell('B1').font = { name: 'Calibri', size: 12, bold: true };

      worksheet.getCell('A2').value = 'Client:';
      worksheet.getCell('A2').font = { name: 'Calibri', size: 12, bold: true };
      worksheet.getCell('B2').value = this.jobService.currentJob.contractName;
      worksheet.getCell('B2').font = { name: 'Calibri', size: 12, bold: true };

      worksheet.getCell('A3').value = 'Site:';
      worksheet.getCell('A3').font = { name: 'Calibri', size: 12, bold: true };
      worksheet.getCell('B3').value = this.jobService.currentJob.jobAddressString;
      worksheet.getCell('B3').font = { name: 'Calibri', size: 12, bold: true };

      // defalt widths - may not work if user hides columns
      worksheet.getColumn(1).alignment = { vertical: 'top', wrapText: false };
      worksheet.getColumn(1).width = 15;
      worksheet.getColumn(2).width = 50;
      worksheet.getColumn(4).width = 10;
      worksheet.getColumn(6).width = 15;
      worksheet.getColumn(7).width = 25;
      worksheet.getColumn(10).width = 15;
      worksheet.getColumn(11).width = 15;
      worksheet.getColumn(12).width = 15;
      worksheet.getColumn(15).width = 20;
    }).then(() => {

      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }),
          this.jobService.currentJob.jobNumber + ' - ' + this.jobService.currentJob.jobAddressString + '.xlsx');
      });
    });
    e.cancel = true;
  }

  getGroupTitle(cellInfo) {
    return cellInfo.data.key.split(';')[1];
  }

  calculateGroupSortValue(orderLine: OrderLine) {
    return ('00000' + orderLine.costCentreOrderNumber.toString()).slice(-6) + ';' + orderLine.costCentreCodeAndDesc;
  }

  calculateSubGroupSortValue(orderLine: OrderLine) {
    return ('00000' + orderLine.priceFileItemParentOrderNumber.toString()).slice(-6) + ';' + orderLine.subGroupItemDesc;
  }

  collapseAll() {
    this.grid.instance.collapseAll();
  }

  showCostCentreSummary() {
    const modalRef = this.modalService.open(CostCentreSummaryComponent, { windowClass: 'modal-1000' });
    modalRef.componentInstance.orderExport = this.orderExport;
    modalRef.result.then(() => { }, () => { });
  }

  saveToRecipe() {
    const modalRef = this.modalService.open(SaveToRecipeComponent, { windowClass: 'modal-1000' });
    modalRef.result.then(() => { }, () => { });
  }
}
