import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'js-bulk-add-extras',
  templateUrl: './bulk-add-extras.component.html',
  styleUrls: ['./bulk-add-extras.component.scss']
})
export class BulkAddExtrasComponent implements OnInit {

  loading: boolean;
  orderLineDescription = 'Balance adjustment';
  varianceCodeId: number;
  varianceReason: string;

  constructor(
    private _activeModal: NgbActiveModal
  ) {  }

  ngOnInit() {
  }

  cancel() {
    this._activeModal.dismiss();
  }

  run() {
    this._activeModal.close({varianceCodeId: this.varianceCodeId, varianceReason: this.varianceReason, orderLineDescription: this.orderLineDescription});
  }
}
