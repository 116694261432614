import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'js-colour-note',
  templateUrl: './colour-note.component.html',
  styleUrls: ['./colour-note.component.scss']
})
export class ColourNoteComponent implements OnInit {
  @Input() comment: string;
  @Input() colourId: string;
  @Input() costCentreDescription: string;
  @Input() hasOrderLines: boolean;


  constructor(
    private _activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
  }

  cancel() {
    this._activeModal.dismiss();
  }

  markColour(colourId) {
    this._activeModal.close({colourId: colourId, comment: this.comment});
  }

}
