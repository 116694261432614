import { VariationService } from './services/felixApi/variation.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { BrowserCacheLocation,
  InteractionType, IPublicClientApplication,
  LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { MsalModule, MsalInterceptor,
  MsalInterceptorConfiguration, MsalGuardConfiguration,
  MSAL_INSTANCE, MSAL_GUARD_CONFIG,
  MSAL_INTERCEPTOR_CONFIG, MsalService,
  MsalBroadcastService, MsalRedirectComponent, MsalGuard } from '@azure/msal-angular';

import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { LoginModule } from './login/login.module';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { UserService } from './services/felixApi/user.service';
import { CompanyService } from './services/felixApi/company.service';
import { GlobalService } from './services/global.service';
import { AuthService } from './services/auth.service';
import { NotificationService } from './services/notification.service';
import { RefreshGuard } from './services/refresh-guard.service';
import { MenuComponent } from './shared/menu/menu.component';
import { OrdersComponent } from './orders/orders.component';
import { OrderHeaderModalComponent } from './orders/order-header-modal/order-header-modal.component';
import { OrderLinesComponent } from './orders/order-lines/order-lines.component';
import { MaintenanceService } from './services/felixApi/maintenance.service';
import { EstimatingService } from './services/felixApi/estimating.service';
import { JobService } from './services/felixApi/job.service';
import { MaintenanceMenuComponent } from './Maintenance/maintenance-menu/maintenance-menu.component';
import { VarianceCodeComponent } from './Maintenance/variance-code/variance-code.component';
import { VarianceReasonComponent } from './Maintenance/variance-reason/variance-reason.component';
import { OrderExportComponent } from './orders/order-export/order-export.component';
import { OrderSendComponent } from './order-send/order-send.component';
import { OrderControlComponent } from './Maintenance/order-control/order-control.component';
import { InvoiceEntryComponent } from './invoice/invoice-entry/invoice-entry.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { InvoicesOnHoldComponent } from './invoice/invoices-on-hold/invoices-on-hold.component';
import { InvoicesApprovedComponent } from './invoice/invoices-approved/invoices-approved.component';
import { CostCentreSelectComponent } from './orders/cost-centre-select/cost-centre-select.component';
import { VendorsComponent } from './Maintenance/vendors/vendors.component';
import { ExtraSelectComponent } from './orders/extra-select/extra-select.component';
import { CopyJobComponent } from './orders/copy-job/copy-job.component';
import { AccountsCodeComponent } from './Maintenance/order-control/accounts-code/accounts-code.component';
import { CategoriesCategoryComponent } from './Maintenance/order-control/accounts-category/accounts-category.component';
import { DivisionsComponent } from './Maintenance/divisions/divisions.component';
import { GlAccountTypeComponent } from './Maintenance/gl-account-type/gl-account-type.component';
import { DivisionAccountComponent } from './Maintenance/division-account/division-account.component';
import { DivisionProductComponent } from './Maintenance/division-product/division-product.component';
import { VendorGroupComponent } from './Maintenance/vendor-group/vendor-group.component';
import { AllInvoicesComponent } from './invoice/all-invoices/all-invoices.component';
import { ClaimsComponent } from './claims/claims.component';
import { DepositsComponent } from './claims/deposits/deposits.component';
import { ProgressClaimsComponent } from './claims/progress-claims/progress-claims.component';
import { JobAccountingCustomerComponent } from './Maintenance/job-accounting-customer/job-accounting-customer.component';
import { PurchaseOrderQueryComponent } from './migration-tools/purchase-order-query/purchase-order-query.component';
import { InvoiceQueryComponent } from './migration-tools/invoice-query/invoice-query.component';
import { OrderImportComponent } from './migration-tools/order-import/order-import.component';
import { IncomeInvoiceQueryComponent } from './migration-tools/income-invoice-query/income-invoice-query.component';
import { VariationInvoicesComponent } from './claims/variation-invoices/variation-invoices.component';
import { ReportMenuComponent } from './Reports/report-menu/report-menu.component';
import { WipReportComponent } from './Reports/wip-report/wip-report.component';
import { AccrualsComponent } from './Reports/accruals/accruals.component';
import { MarginsReportComponent } from './Reports/margins-report/margins-report.component';
import { OrderLookupComponent } from './invoice/order-lookup/order-lookup.component';
import { ColourNoteComponent } from './orders/colour-note/colour-note.component';
import { DuplicateInvoiceComponent } from './invoice/duplicate-invoice/duplicate-invoice.component';
import { AddInvoiceComponent } from './invoice/invoice-entry/add-invoice/add-invoice.component';
import { AllIncomeInvoicesComponent } from './claims/all-income-invoices/all-income-invoices.component';
import { AllOrdersComponent } from './invoice/all-orders/all-orders.component';
import { OrderInvoicesComponent } from './invoice/order-invoices/order-invoices.component';
import { ExtrasComponent } from './Reports/extras/extras.component';
import { VariationSplitsComponent } from './claims/variation-splits/variation-splits.component';
import { CreateZeroPoComponent } from './orders/create-zero-po/create-zero-po.component';
import { ExtraVarianceSelectComponent } from './orders/extra-variance-select/extra-variance-select.component';
import { CompanyActivityService } from './services/felixApi/company-activity.service';
import { CompanyActivitiesComponent } from './Maintenance/company-activities/company-activities.component';
import { IntegrationService } from './services/felixApi/integration.service';
import { ClaimHistoryLoadComponent } from './Maintenance/history-load/claim-history-load/claim-history-load.component';
import { InvoiceHistoryLoadComponent } from './Maintenance/history-load/invoice-history-load/invoice-history-load.component';
import { OrderHistoryLoadComponent } from './Maintenance/history-load/order-history-load/order-history-load.component';
import { OrderLineHistoryLoadComponent } from './Maintenance/history-load/order-line-history-load/order-line-history-load.component';
import { VariationHistoryLoadComponent } from './Maintenance/history-load/variation-history-load/variation-history-load.component';
import { BudgetNoAccualsComponent } from './Maintenance/history-load/budget-no-accuals/budget-no-accuals.component';
import { OrderToolsMenuComponent } from './orders/order-tools-menu/order-tools-menu.component';
import { GlobalChangeOrderVendorComponent } from './orders/global-change-order-vendor/global-change-order-vendor.component';
import { GlobalRevalueOrdersComponent } from './orders/global-revalue-orders/global-revalue-orders.component';
import { DatabuildPriceFileComponent } from './Maintenance/history-load/databuild-price-file/databuild-price-file.component';
import { AddendaWriterLoadComponent } from './Maintenance/history-load/addenda-writer-load/addenda-writer-load.component';
import { CopyPoItemsComponent } from './orders/copy-po-items/copy-po-items.component';
import { TasksLoadComponent } from './Maintenance/history-load/tasks-load/tasks-load.component';
import { JobLoadComponent } from './Maintenance/history-load/job-load/job-load.component';
import { ReceiptsComponent } from './claims/receipts/receipts.component';
import { PreviousInvoicesComponent } from './invoice/previous-invoices/previous-invoices.component';
import { TrackingFieldDataComponent } from './Maintenance/history-load/tracking-field-data/tracking-field-data.component';
import { JobKeyDatesComponent } from './Maintenance/history-load/job-key-dates/job-key-dates.component';
import { CostCentreSummaryComponent } from './orders/cost-centre-summary/cost-centre-summary.component';
import { SubbieInvoiceDateCheckComponent } from './invoice/subbie-invoice-date-check/subbie-invoice-date-check.component';
import { GlobalFindItemComponent } from './orders/global-find-item/global-find-item.component';
import { GlobalAddItemComponent } from './orders/global-add-item/global-add-item.component';
import { SaveToRecipeComponent } from './orders/save-to-recipe/save-to-recipe.component';
import { MoveItemsToNewPoComponent } from './orders/move-items-to-new-po/move-items-to-new-po.component';
import { RejectInvoiceComponent } from './invoice/reject-invoice/reject-invoice.component';
import { BulkAddExtrasComponent } from './invoice/bulk-add-extras/bulk-add-extras.component';
import { OrderLogoComponent } from './Maintenance/order-control/order-logo/order-logo.component';
import { UsersLoadComponent } from './Maintenance/history-load/users-load/users-load.component';
import { BackChargeComponent } from './invoice/back-charge/back-charge.component';
import { BackChargesComponent } from './invoice/back-charges/back-charges.component';
import { MigrationToolsMenuComponent } from './migration-tools/migration-tools-menu/migration-tools-menu.component';

import config from "devextreme/core/config";
import { licenseKey } from "../devextreme-license";
import { ManualOrderComponent } from './orders/manual-order/manual-order.component';
import { InvoiceLogsComponent } from './invoice/invoices-logs/invoice-logs.component';
import { ContactsLoadComponent } from './Maintenance/history-load/contacts-load/contacts-load.component';
config({ licenseKey });

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  if (logLevel === LogLevel.Error) {
    console.error(message);
    // how to log to a service?
  } else {
    // console.log(message);
  }
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientID,
      authority: environment.authority,
      redirectUri: environment.applicationUrl + 'auth',
      postLogoutRedirectUri: '/',
      knownAuthorities: [environment.authoryDomain]
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.apiUrl, environment.consentScopes);
  // protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...environment.consentScopes],
    },
  };
}

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    MenuComponent,
    OrdersComponent,
    OrderHeaderModalComponent,
    OrderLinesComponent,
    MaintenanceMenuComponent,
    VarianceCodeComponent,
    VarianceReasonComponent,
    OrderExportComponent,
    OrderSendComponent,
    OrderControlComponent,
    InvoiceEntryComponent,
    InvoiceComponent,
    InvoicesOnHoldComponent,
    InvoicesApprovedComponent,
    CostCentreSelectComponent,
    VendorsComponent,
    ExtraSelectComponent,
    CopyJobComponent,
    AccountsCodeComponent,
    CategoriesCategoryComponent,
    DivisionsComponent,
    GlAccountTypeComponent,
    DivisionAccountComponent,
    DivisionProductComponent,
    VendorGroupComponent,
    AllInvoicesComponent,
    ClaimsComponent,
    DepositsComponent,
    ProgressClaimsComponent,
    JobAccountingCustomerComponent,
    PurchaseOrderQueryComponent,
    InvoiceQueryComponent,
    OrderImportComponent,
    IncomeInvoiceQueryComponent,
    VariationInvoicesComponent,
    ReportMenuComponent,
    WipReportComponent,
    AccrualsComponent,
    MarginsReportComponent,
    OrderLookupComponent,
    ColourNoteComponent,
    DuplicateInvoiceComponent,
    AddInvoiceComponent,
    AllIncomeInvoicesComponent,
    AllOrdersComponent,
    OrderInvoicesComponent,
    ExtrasComponent,
    VariationSplitsComponent,
    InvoiceHistoryLoadComponent,
    CreateZeroPoComponent,
    OrderHistoryLoadComponent,
    ExtraVarianceSelectComponent,
    OrderLineHistoryLoadComponent,
    ClaimHistoryLoadComponent,
    CompanyActivitiesComponent,
    VariationHistoryLoadComponent,
    BudgetNoAccualsComponent,
    OrderToolsMenuComponent,
    GlobalChangeOrderVendorComponent,
    GlobalRevalueOrdersComponent,
    DatabuildPriceFileComponent,
    AddendaWriterLoadComponent,
    CopyPoItemsComponent,
    TasksLoadComponent,
    JobLoadComponent,
    ReceiptsComponent,
    PreviousInvoicesComponent,
    TrackingFieldDataComponent,
    JobKeyDatesComponent,
    CostCentreSummaryComponent,
    SubbieInvoiceDateCheckComponent,
    GlobalFindItemComponent,
    GlobalAddItemComponent,
    SaveToRecipeComponent,
    MoveItemsToNewPoComponent,
    RejectInvoiceComponent,
    BulkAddExtrasComponent,
    OrderLogoComponent,
    UsersLoadComponent,
    BackChargeComponent,
    BackChargesComponent,
    ManualOrderComponent,
    InvoiceLogsComponent,
    ContactsLoadComponent,
    MigrationToolsMenuComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgbCollapseModule,
    NgbModule,
    ToastrModule.forRoot(),
    AppRoutingModule,
    SharedModule,
    LoginModule,
    MsalModule
  ],
  providers: [
    UserService,
    CompanyService,
    GlobalService,
    AuthService,
    NotificationService,
    MaintenanceService,
    EstimatingService,
    JobService,
    CompanyActivityService,
    IntegrationService,
    VariationService,
    RefreshGuard,
    HttpClientModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
