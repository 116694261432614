export class BackCharge {
  public id: number;
  public backChargeNumber: number;
  public jobId: number;
  public vendorToBackChargeId: number;
  public vendorToRectifyId: number;
  public reason: string;
  public amount: number;
  public vendorAgreementTypeId: number;
  public purchaseOrderId: number;
  public creditNoteId: number;
  public backChargeStatusId: number;
  public comment: string;
  public emailAddress: string;
  public sentDate: Date;
  public modifiedDate: Date;
  public modifiedUserId: number;
  public createDate: Date;
  public createUserId: number;

  public poNumber: string;
  public creditNoteNumber: string;
  public sendNoticeToVendor: boolean;
  public ccToSelf: boolean;
}

export const VendorAgreementTypes = [
  { id: 1, description: 'Pending' },
  { id: 2, description: 'Accepted' },
  { id: 5, description: 'Not Accepted' }
];

export const BackChargeStatuses = [
  { id: 1, description: 'Pending' },
  { id: 2, description: 'Sent' },
  { id: 6, description: 'Completed' },
  { id: 7, description: 'Cancelled' }
];

export enum BackChargeStatusEnum {
  Pending = 1,
  Sent = 2,
  Completed = 6,
  Cancelled = 7
}
