<div class="modal-header">
  <h3 class="modal-title">Reject Invoice</h3>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>


<div class="modal-body">
  <div class="card p-2">

    <js-loading-spinner *ngIf="loading" [usePopUp]="false"></js-loading-spinner>

    <form (submit)="reject()">
      <dx-form *ngIf="!loading" id="form" [(formData)]="rejectInvoiceForm" [readOnly]="false"
        [showColonAfterLabel]="true" labelLocation="left" width="100%" [showValidationSummary]="false"
        validationGroup="customerData">

        <dxi-item itemType="group" [colCount]="1">
          <dxi-item dataField="vendorName" [colSpan]="1" [editorOptions]="{ disabled: true }"></dxi-item>
        </dxi-item>

        <dxi-item itemType="group" [colCount]="2">
          <dxi-item dataField="jobNumber" [colSpan]="1" [editorOptions]="{ disabled: true }"></dxi-item>
        </dxi-item>

        <dxi-item itemType="group" [colCount]="1">
          <dxi-item dataField="jobAddressString" [colSpan]="1" [editorOptions]="{ disabled: true }"></dxi-item>
        </dxi-item>

        <dxi-item itemType="group" [colCount]="2">
          <dxi-item dataField="invoiceNumber" [colSpan]="1" editorType="dxTextBox" [editorOptions]="{ disabled: true }">
          </dxi-item>
          <dxi-item dataField="invoiceDate" [colSpan]="1" editorType="dxDateBox" [editorOptions]="invoiceDateOptions">
          </dxi-item>
        </dxi-item>

        <dxi-item itemType="group" [colCount]="1">
          <dxi-item dataField="sendRejectToEmail" [colSpan]="1">
            <dxo-label location="left" text="Email"></dxo-label>
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dxi-item>
        </dxi-item>

        <dxi-item itemType="group" [colCount]="1">
          <dxi-item dataField="emailSubject" [colSpan]="1">
            <dxo-label location="left" text="Subject"></dxo-label>
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dxi-item>
        </dxi-item>

        <dxi-item itemType="group" [colCount]="1">
          <dxi-item dataField="comment" editorType="dxTextArea" [colSpan]="1" [editorOptions]="{ height: 70 }">
            <dxo-label location="left" text="Reason"></dxo-label>
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dxi-item>
        </dxi-item>

        <dxi-item itemType="group" [colCount]="1">
          <dxi-item itemType="button" [colSpan]="1" horizontalAlignment="right" [buttonOptions]="updateButtonOptions">
          </dxi-item>
        </dxi-item>
      </dx-form>
    </form>
  </div>
</div>
