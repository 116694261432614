import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { GlobalService } from './global.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(
    private globalService: GlobalService,
    private auth: AuthService
  ) { }

  getHttpOptions(userId: number = null, companyId: number = null): { headers: HttpHeaders; } {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'client-id': environment.clientName,
        'client-session-id': require('uuid').v4(),
        'client-version': environment.fullVersion,
        'company-id': companyId ? companyId.toString() : this.globalService.getCurrentCompanyId(),
        'user-id': userId ? userId.toString() : this.auth.getCurrentUserId()?.toString() ?? '0'
      })
    };
    return httpOptions;
  }

  getHttpFileOptions(): { headers: HttpHeaders; } {
    const httpOptions = {
      headers: new HttpHeaders({
        'client-id': environment.clientName,
        'client-session-id': require('uuid').v4(),
        'client-version': environment.fullVersion,
        'company-id': this.globalService.getCurrentCompanyId(),
        'user-id': this.auth.getCurrentUserId()?.toString() ?? '0'
      })
    };
    return httpOptions;
  }

  getHttpFilePDFOptions(): { headers: HttpHeaders; } {
    const httpOptions = {
      headers: new HttpHeaders({
        responseType: 'arraybuffer',
        'client-id': environment.clientName,
        'client-session-id': require('uuid').v4(),
        'client-version': environment.fullVersion,
        'company-id': this.globalService.getCurrentCompanyId(),
        'user-id': this.auth.getCurrentUserId()?.toString() ?? '0'
      })
    };
    return httpOptions;
  }
}
