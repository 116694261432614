<div class="modal-header">
  <h3 class="modal-title">Job Export for {{jobNumber}}</h3>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="card p-2">
    <dx-data-grid id="dataGrid" [dataSource]="dataSource" [allowColumnReordering]="true" [allowColumnResizing]="true"
      [height]="gridHeight" [repaintChangesOnly]="true" [showBorders]="true" [rowAlternationEnabled]="true"
      [wordWrapEnabled]="true" (onToolbarPreparing)="onToolbarPreparing($event)" (onExporting)="onExporting($event)"
      (onExporting)="gridService.onExporting($event, 'order-export')">

      <dxo-scrolling mode="virtual"></dxo-scrolling>
      <dxo-sorting mode='multiple'></dxo-sorting>
      <dxo-export [enabled]="true"></dxo-export>
      <dxo-search-panel [visible]="true" location="before" [width]="250"></dxo-search-panel>
      <dxo-paging [enabled]="false"></dxo-paging>
      <dxo-group-panel [visible]="true"></dxo-group-panel>
      <dxo-grouping #expand [autoExpandAll]="true" [contextMenuEnabled]="true" expandMode="rowClick"></dxo-grouping>
      <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
      <dxo-state-storing [enabled]="true" type="localStorage" storageKey="orders-export-grid"></dxo-state-storing>


      <dxi-column dataField="costCentreOrderNumber" [visible]="false" dataType="number" sortOrder="asc">
      </dxi-column>
      <dxi-column dataField="priceFileItemParentOrderNumber" [visible]="false" dataType="number" sortOrder="asc">
      </dxi-column>

      <dxi-column dataField="costCentreCode" caption="Cost Code" [width]="80" dataType="string" [visible]="false">
      </dxi-column>

      <dxi-column dataField="costCentreCodeAndDesc" caption="Cost Centre" dataType="string" [groupIndex]="0"
        groupCellTemplate="groupCellTemplate" [calculateGroupValue]="calculateGroupSortValue">
      </dxi-column>

      <dxi-column dataField="subGroupItemDesc" dataType="string" caption="Sub-Group" [groupIndex]="1" [width]="220"
        groupCellTemplate="groupCellTemplate" [calculateGroupValue]="calculateSubGroupSortValue">
      </dxi-column>

      <dxi-column dataField="priceFileCode" caption="Item Code" [width]="110" sortOrder="asc">
      </dxi-column>

      <dxi-column dataField="description" dataType="string">
      </dxi-column>

      <dxi-column dataField="unitOfMeasure" dataType="number" caption="Units" [width]="100">
        <dxo-lookup [dataSource]="unitsOfMeasure" displayExpr="description" valueExpr="description"></dxo-lookup>
      </dxi-column>

      <dxi-column dataField="quantityString" caption="Measured Qty" dataType="string" [width]="100">
      </dxi-column>

      <dxi-column dataField="measuredQuantity" caption="Actual Qty" dataType="number" [width]="70" allowEditing="false">
      </dxi-column>

      <dxi-column dataField="vendorId" caption="Vendor Code" dataType="number" [width]="100"
        [calculateSortValue]="calculateVendorCodeSortValue">
        <dxo-lookup [dataSource]="vendors" displayExpr="vendorCode" valueExpr="id">
        </dxo-lookup>
      </dxi-column>

      <dxi-column dataField="vendorName" caption="Vendor Name" dataType="string" [width]="170"
        [calculateCellValue]="calculateVendorName">
      </dxi-column>

      <dxi-column dataField="supplierQuantity" caption="Vendor Qty" dataType="number" [width]="70">
      </dxi-column>

      <dxi-column dataField="productCode" dataType="string" [width]="85" [hidingPriority]="1"> </dxi-column>

      <dxi-column dataField="rate" dataType="number" [width]="100" [format]="{ type: 'fixedPoint', precision: 2 }">
      </dxi-column>

      <dxi-column dataField="lineTotal" dataType="number" [width]="100" [format]="{ type: 'fixedPoint', precision: 2 }">
      </dxi-column>

      <dxi-column dataField="phaseId" caption="Phase" dataType="number" [width]="70" [hidingPriority]="4">
        <dxo-lookup [dataSource]="phases" displayExpr="phaseCode" valueExpr="id">
        </dxo-lookup>
      </dxi-column>

      <dxi-column dataField="purchaseOrderNumber" caption="PO Number" dataType="string" [width]="100">
      </dxi-column>

      <dxi-column dataField="isToBeChecked" caption="To Be Checked" dataType="boolean" [width]="75"
        [hidingPriority]="3">
      </dxi-column>

      <dxi-column dataField="comment" dataType="string" [width]="200" [hidingPriority]="1"> </dxi-column>

      <dxo-summary>
        <dxi-group-item column="lineTotal" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}"
          [showInGroupFooter]="true">
        </dxi-group-item>
        <dxi-total-item column="lineTotal" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}">
        </dxi-total-item>
      </dxo-summary>

      <div *dxTemplate="let data of 'groupCellTemplate'">
        {{ getGroupTitle(data) }}
      </div>
    </dx-data-grid>
  </div>
</div>
