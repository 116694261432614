import { CompanyActivityService } from './company-activity.service';
import { DivisionService } from './division.service';
import { UserService } from './user.service';
import { Injectable } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { JobService } from './job.service';
import { Job } from '../../dtos/job';
import { IncomeInvoice } from '../../dtos/income-invoice';
import { HttpService } from '../http.service';
import { IncomeInvoiceError } from '../../dtos/income-invoice-error';
import { WIPReport } from '../../dtos/wip-report';
import { Variation } from '../../dtos/variation';
import { ClaimJobLine } from '../../dtos/claim-job-line';
import { VariationSplit } from '../../dtos/variation-split';
import { VariationSplitHistory } from '../../dtos/variation-split-history';
import { formatDate } from 'devextreme/localization';
import { Receipt } from '../../dtos/receipt';

@Injectable({
  providedIn: 'root'
})
export class ClaimsService {

  globalGSTRate: number;
  incomeInvoiceErrors: IncomeInvoiceError[];
  jobVariationsInvoiced: Variation[];
  allClaimJobLines: ClaimJobLine[];
  allVariationSplits: VariationSplit[];
  variationSplitsHistory: VariationSplitHistory[];
  receipts: Receipt[];
  cachCompanyReceipts: string;

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private divisionService: DivisionService,
    private jobService: JobService,
    private userService: UserService,
    private companyActivityService: CompanyActivityService,
    private globalService: GlobalService) { }


  getClaimsData(useCache): Observable<Job[]> {
    return forkJoin(
      [
        this.jobService.getJobsByAddress(useCache),
        this.userService.getCurrCompUsers(useCache),
        this.divisionService.getDivisions(useCache),
        this.companyActivityService.getCompanyActivities()
      ]
    )
      .pipe(map(
        ([result]) => {
          return result;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getDepositInvoiceRecords(jobId: number): Observable<IncomeInvoice[]> {
    let url = this.globalService.getApiUrl() + '/income-invoices/get-deposits';
    if (jobId) {
      url += '?jobId=' + jobId;
    }
    return this._http.get<IncomeInvoice[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.globalService.handleError));
  }

  submitDepositInvoiceRecords(jobId: number, selectedIds: any): Observable<IncomeInvoice[]> {
    let url = this.globalService.getApiUrl() + '/income-invoices/submit-deposits';
    if (jobId) {
      url += '?jobId=' + jobId;
    }
    return this._http.post<IncomeInvoice[]>(url, JSON.stringify(selectedIds), this.httpService.getHttpOptions()).pipe(
      catchError(this.globalService.handleError));
  }

  getClaimsDueRecords(): Observable<IncomeInvoice[]> {
    const url = this.globalService.getApiUrl() + '/income-invoices/get-claims';

    return this._http.get<IncomeInvoice[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.globalService.handleError));
  }

  submitClaimsDueRecords(submitFutureDatedInvoices: boolean, selectedIds: any): Observable<IncomeInvoice[]> {
    const url = this.globalService.getApiUrl() + '/income-invoices/submit-claims?'
      + 'submitFutureDatedInvoices=' + submitFutureDatedInvoices;

    return this._http.post<IncomeInvoice[]>(url, JSON.stringify(selectedIds), this.httpService.getHttpOptions()).pipe(
      catchError(this.globalService.handleError));
  }

  getVariationClaimsDueRecords(dateOldInvoicesToday: boolean): Observable<IncomeInvoice[]> {
    const url = this.globalService.getApiUrl() + '/income-invoices/get-variations?'
      + 'dateOldInvoicesToday=' + dateOldInvoicesToday;

    return this._http.get<IncomeInvoice[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.globalService.handleError));
  }

  submitVariationClaimsDueRecords(submitFutureDatedInvoices: boolean, dateOldInvoicesToday: boolean, 
    selectedCodes: any): Observable<IncomeInvoice[]> {
    const url = this.globalService.getApiUrl() + '/income-invoices/submit-variations?'
      + '&submitFutureDatedInvoices=' + submitFutureDatedInvoices + '&dateOldInvoicesToday=' + dateOldInvoicesToday;

    return this._http.post<IncomeInvoice[]>(url, JSON.stringify(selectedCodes), this.httpService.getHttpOptions()).pipe(
      catchError(this.globalService.handleError));
  }

  getIncomeInvoiceErrors(): Observable<IncomeInvoiceError[]> {
    const url = this.globalService.getApiUrl() + '/income-invoice-errors';
    return this._http.get<IncomeInvoiceError[]>(url, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.incomeInvoiceErrors = res;
      }),
      catchError(this.globalService.handleError));
  }

  getIncomeInvoices(): Observable<IncomeInvoice[]> {
    const url = this.globalService.getApiUrl() + '/income-invoices';

    return this._http.get<IncomeInvoice[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.globalService.handleError));
  }

  getJobVariationsInvoiced(): Observable<Variation[]> {
    const url = this.globalService.getApiUrl() + '/job-variations/all-post-contract-invoiced';

    return this._http.get<Variation[]>(url, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.jobVariationsInvoiced = res;
      }),
      catchError(this.globalService.handleError));
  }

  getAllClaimLines(): Observable<ClaimJobLine[]> {
    const url = this.globalService.getApiUrl() + '/claim-job-lines';

    return this._http.get<ClaimJobLine[]>(url, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.allClaimJobLines = res;
      }),
      catchError(this.globalService.handleError));
  }

  getAllVariationSplits(): Observable<VariationSplit[]> {
    const url = this.globalService.getApiUrl() + '/variation-splits/all';

    return this._http.get<VariationSplit[]>(url, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.allVariationSplits = res;
      }),
      catchError(this.globalService.handleError));
  }

  getVariationSplitsHistory(): Observable<VariationSplitHistory[]> {
    const url = this.globalService.getApiUrl() + '/variation-splits/history';

    return this._http.get<VariationSplitHistory[]>(url, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.variationSplitsHistory = res;
      }),
      catchError(this.globalService.handleError));
  }


  getDepositInvoices(jobId: number): Observable<IncomeInvoice[]> {
    return forkJoin(
      [
        this.getDepositInvoiceRecords(jobId),
        this.getIncomeInvoiceErrors()
      ]
    )
      .pipe(map(
        ([result]) => {
          return result;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  submitDepositInvoices(jobId: number, selectedCodes: any): Observable<IncomeInvoice[]> {
    return forkJoin(
      [
        this.submitDepositInvoiceRecords(jobId, selectedCodes),
        this.getIncomeInvoiceErrors()
      ]
    )
      .pipe(map(
        ([result]) => {
          return result;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getClaimsDue(): Observable<IncomeInvoice[]> {
    return forkJoin(
      [
        this.getClaimsDueRecords(),
        this.getIncomeInvoiceErrors()
      ]
    )
      .pipe(map(
        ([result]) => {
          return result;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  submitClaimsDue(submitFutureDatedInvoices: boolean, selectedIds: any): Observable<IncomeInvoice[]> {
    return forkJoin(
      [
        this.submitClaimsDueRecords(submitFutureDatedInvoices, selectedIds),
        this.getIncomeInvoiceErrors()
      ]
    )
      .pipe(map(
        ([result]) => {
          return result;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  checkClaimActivityInJob(jobId: number, claimJobLineId: number): Observable<boolean> {
    const url = this.globalService.getApiUrl() + '/claim-job-lines/check-task?claimJobLineId=' + claimJobLineId 
      + '&jobId=' + jobId;

    return this._http.get<boolean>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.globalService.handleError));
  }

  getVariationClaimsDue(submitFutureDatedInvoices: boolean): Observable<IncomeInvoice[]> {
    return forkJoin(
      [
        this.getVariationClaimsDueRecords(submitFutureDatedInvoices),
        this.getIncomeInvoiceErrors()
      ]
    )
      .pipe(map(
        ([result]) => {
          return result;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  submitVariationClaimsDue(submitFutureDatedInvoices: boolean, dateOldInvoicesToday: boolean, 
    selectedIds: any): Observable<IncomeInvoice[]> {
    return forkJoin(
      [
        this.submitVariationClaimsDueRecords(submitFutureDatedInvoices, dateOldInvoicesToday, selectedIds),
        this.getIncomeInvoiceErrors()
      ]
    )
      .pipe(map(
        ([result]) => {
          return result;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getPostedIncomeInvoices(): Observable<IncomeInvoice[]> {
    return forkJoin(
      [
        this.getIncomeInvoices(),
        this.getIncomeInvoiceErrors(),
        this.getJobVariationsInvoiced(),
        this.getAllVariationSplits(),
        this.getAllClaimLines()
      ]
    )
      .pipe(map(
        ([result]) => {
          return result;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  deleteIncomeInvoice(id: string) {
    const url = this.globalService.getApiUrl() + '/income-invoices/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  updateIncomeInvoices(key: string, data: any) {
    const url = this.globalService.getApiUrl() + '/income-invoices/' + key;
    return this._http.patch(url, JSON.stringify(data), this.httpService.getHttpOptions());
  }

  checkIfIncomeInvoicePaid(id: string): Observable<IncomeInvoice> {
    const url = this.globalService.getApiUrl() + '/income-invoices/' + id + '/payment-status';
    return this._http.post<IncomeInvoice>(url, '', this.httpService.getHttpOptions());
  }

  updateVariation(key: string, data: any) {
    const firstIndex = key.indexOf(':');
    const idString = key.substring(firstIndex + 1);
    const secondIndex = idString.indexOf(':');
    const url = this.globalService.getApiUrl() + '/job-variations/' + idString.substring(0, secondIndex);
    return this._http.patch(url, JSON.stringify(data), this.httpService.getHttpOptions());
  }

  getReceipts(useCache: boolean): Observable<Receipt[]> {
    if (useCache && this.receipts && this.receipts.length
      && this.cachCompanyReceipts === this.globalService.getCurrentCompanyId()) {
      return of(this.receipts);
    } else {
      return this._http.get<Receipt[]>(this.globalService.getApiUrl() +
        '/receipts', this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.receipts = res; this.cachCompanyReceipts = this.globalService.getCurrentCompanyId();
          }),
          catchError(this.globalService.handleError));
    }
  }

  addReceipt(dataRecord: any): Observable<Receipt> {
    const url = this.globalService.getApiUrl() + '/receipts';
    return this._http.post<Receipt>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateReceipt(id: string, itm: any): Observable<Receipt> {
    const url = this.globalService.getApiUrl() + '/receipts/' + id;
    return this._http.patch<Receipt>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteReceipt(id: string): Observable<Receipt> {
    const url = this.globalService.getApiUrl() + '/receipts/' + id;
    return this._http.delete<Receipt>(url, this.httpService.getHttpOptions());
  }


  getMarginsData(invoiceCutOffDate: Date, includeInactiveJobs: boolean): Observable<WIPReport[]> {
    const url = this.globalService.getApiUrl() + '/reports/margin-report?invoiceCutOffDate=' + formatDate(invoiceCutOffDate, 'd-MMMM-yyyy')
      + '&includeInactiveJobs=' + includeInactiveJobs;
    return this._http.get<WIPReport[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.globalService.handleError));
  }


  getWIP(invoiceCutOffDate: Date, includeInactiveJobs: boolean): Observable<WIPReport[]> {
    const url = this.globalService.getApiUrl() + '/reports/wip?invoiceCutOffDate=' + formatDate(invoiceCutOffDate, 'd-MMMM-yyyy')
      + '&includeInactiveJobs=' + includeInactiveJobs;
    return this._http.get<WIPReport[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.globalService.handleError));
  }

  updateJobCashFlow(id: string, itm: any) {
    const url = this.globalService.getApiUrl() + '/job-cash-flows/job-number/' + id;
    return this._http.patch(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }
}
