<dx-tab-panel #panel height="calc(100vh - 50px)" [dataSource]="sections" [selectedIndex]="selectedIndex" [loop]="false"
  [swipeEnabled]="false" (onTitleClick)="tabSelected($event)">

  <div *dxTemplate="let title of 'title'">
    <span>{{title}}</span>
  </div>

  <div *dxTemplate="let section of 'item'">
    <div style="height: calc(100vh - 100px);">
      <js-invoice-entry *ngIf="section === 'Invoice Entry'"></js-invoice-entry>
      <js-invoices-on-hold *ngIf="section === 'On Hold'"></js-invoices-on-hold>
      <js-invoices-approved *ngIf="section === 'Approved'"></js-invoices-approved>
      <js-all-invoices *ngIf="section === 'All Invoices'"></js-all-invoices>
      <js-all-orders *ngIf="section === 'All Orders'"></js-all-orders>
      <js-back-charges *ngIf="section === 'Back Charges'"></js-back-charges>
    </div>
  </div>
</dx-tab-panel>
