import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'js-error-popup',
  templateUrl: './error-popup.component.html',
  styleUrls: ['./error-popup.component.scss']
})
export class ErrorPopupComponent implements OnInit {
  @Input() errorMessage: string;

  constructor(private _activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  close() {
    this._activeModal.close();
  }
}
