import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'js-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent implements OnInit {
  @Input() usePopUp: boolean;

  showSpinner = false;

  constructor() { }

  ngOnInit() {
    this.showSpinner = false;
    setTimeout(() => {
      this.showSpinner = true;
    }, 600);
  }
}
