import { GridService } from './../../services/grid.service';
import { VariationSplitHistory } from '../../dtos/variation-split-history';
import { ClaimsService } from '../../services/felixApi/claims.service';
import { NotificationService } from '../../services/notification.service';
import { GlobalService } from '../../services/global.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'js-variation-splits',
  templateUrl: './variation-splits.component.html',
  styleUrls: ['./variation-splits.component.scss']
})
export class VariationSplitsComponent implements OnInit, OnDestroy {
  dataSource: CustomStore;
  subscriptions: Subscription[] = [];
  gridHeight: number;
  loadingData = true;
  variationSplitsHistory: VariationSplitHistory[];

  constructor(
    private claimsService: ClaimsService,
    private notiService: NotificationService,
    public gridService: GridService,
    private globalService: GlobalService) {
  }

  ngOnInit() {
    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          this.setHeightWidths();
        }
      )
    );

    this.setHeightWidths();
    this.loadData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setHeightWidths() {
    this.gridHeight = window.innerHeight - 107;
  }

  loadData() {
    this.subscriptions.push(
      this.claimsService.getVariationSplitsHistory()
        .subscribe({
          next: (res) => {
            this.variationSplitsHistory = res;
            this.loadingData = false;
            this.setUpDataSource();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loadingData = false;
  }})
    );
  }

  setUpDataSource() {
    this.dataSource = new CustomStore({
      key: 'jobNumber',
      loadMode: 'raw',
      load: () => this.variationSplitsHistory
    });
  }

  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: this.setUpDataSource.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          type: 'default',
          stylingMode: 'outlined',
          text: 'Reset Layout',
          onClick: this.clearStatePersistance.bind(this)
        }
      });
  }

  clearStatePersistance() {
    this.loadingData = true;
    localStorage.removeItem('splits-report');
    setTimeout(() => {
      this.loadingData = false;
    }, 300); // wait
  }
}
