import { GridService } from './../../services/grid.service';
import { AuthService } from './../../services/auth.service';
import { InvoiceStatusTypeEnum } from './../../dtos/invoice';
import { UserService } from './../../services/felixApi/user.service';
import { DivisionService } from './../../services/felixApi/division.service';
import { PurchaseOrder } from '../../dtos/purchase-order';
import { PoService } from '../../services/felixApi/po.service';
import { JobService } from '../../services/felixApi/job.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Vendor } from '../../dtos/vendor';
import { MaintenanceService } from '../../services/felixApi/maintenance.service';
import { GlobalService } from '../../services/global.service';
import { CompanyService } from './../../services/felixApi/company.service';
import { ConfigurationEnum } from '../../dtos/configuration-enum';
import { InvoiceService } from '../../services/felixApi/invoice.service';
import { AccountingSystemService } from '../../services/felixApi/accounting-system.service';
import { NotificationService } from '../../services/notification.service';
import { Job } from '../../dtos/job';
import { PriceFileItem } from '../../dtos/price-file-item';
import { EstimatingService } from '../../services/felixApi/estimating.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { VendorGroup } from '../../dtos/vendor-group';
import { User } from '../../dtos/user';
import { VendorAccount } from '../../dtos/vendor-account';
import { formatDate } from 'devextreme/localization';
import CustomStore from 'devextreme/data/custom_store';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'js-invoices-approved',
  templateUrl: './invoices-approved.component.html',
  styleUrls: ['./invoices-approved.component.scss']
})
export class InvoicesApprovedComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  dataSource: CustomStore;
  vendors: Vendor[];
  accountingSystemType: number;
  loadingData = true;
  loading = false;
  jobs: Job[];
  gridHeight: number;
  lastVendorId: number;
  attachmentsPopupVisible: boolean;
  currentInvoiceId: any;
  files: any[] = [];
  loadingFile: boolean;
  attachmentExists: any;
  attachmentsPopupHeight: number;
  costCentres: PriceFileItem[];

  @ViewChild('approvedGrid') grid: DxDataGridComponent;
  vendorGroups: VendorGroup[];
  selectedVendorGroupId: number;
  users: User[];
  vendorAccounts: VendorAccount[];
  currentDateString: string;
  includeExpiredInsurances = false;
  selectedRecords: number[] = [];
  invoiceAdmin = false;

  constructor(
    private globalService: GlobalService,
    private compService: CompanyService,
    private invoiceService: InvoiceService,
    private accountingSystemService: AccountingSystemService,
    private notiService: NotificationService,
    private authService: AuthService,
    private jobService: JobService,
    private poService: PoService,
    private userService: UserService,
    private estimatingService: EstimatingService,
    private maintenanceService: MaintenanceService,
    public gridService: GridService,
    private divisionService: DivisionService) {
    this.setVendorCellValue = this.setVendorCellValue.bind(this);
    this.setOrderNumberCellValue = this.setOrderNumberCellValue.bind(this);
    this.downloadAttachment = this.downloadAttachment.bind(this);
    this.calculateJobNumber = this.calculateJobNumber.bind(this);
    this.calculateConnectionStatus = this.calculateConnectionStatus.bind(this);
    this.calculateVendorSortValue = this.calculateVendorSortValue.bind(this);
    this.markOnHold = this.markOnHold.bind(this);
    this.openCostCentre = this.openCostCentre.bind(this);
    this.openPO = this.openPO.bind(this);
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          this.setHeightWidths();
        }
      )
    );

    if (this.authService.isAdminOrSuper()
      || this.authService.areaPermissions.find(i => i.applicationArea === 'Invoices')?.permissionType === 'Admin') {
      this.invoiceAdmin = true;
    }

    const costingDate = new Date();
    this.currentDateString = costingDate.getFullYear() + '-'
      + ('0' + (costingDate.getMonth() + 1)).toString().slice(-2) + '-'
      + ('0' + costingDate.getDate()).slice(-2);

    this.setHeightWidths();
    this.getCompanyConfigurations();
    this.loadData(true);
  }

  setHeightWidths() {
    this.gridHeight = window.innerHeight - 107;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getCompanyConfigurations() {
    this.subscriptions.push(
      this.compService.getCompanyConfigurations().subscribe({
        next: () => {
          this.accountingSystemType = this.globalService.getCompanyConfigValue(ConfigurationEnum.AccountingSystem);
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }

  loadData(useCache: boolean) {
    this.loadingData = true;
    this.subscriptions.push(
      this.invoiceService.getInvoicesDataForOnHold(useCache)
        .subscribe({
          next: () => {
            this.vendors = this.maintenanceService.allVendors;
            this.jobs = this.jobService.jobs;
            this.costCentres = this.estimatingService.costCentres;
            this.users = this.userService.users;
            this.vendorGroups = [new VendorGroup(null, 'All Groups')];
            this.vendorGroups = this.vendorGroups.concat(this.maintenanceService.vendorGroups);
            this.vendorAccounts = this.maintenanceService.vendorAccountCache;
            this.loadingData = false;
            this.setUpDataSource(useCache);
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loadingData = false;
          }
        })
    );
  }

  setUpDataSource(useCache: boolean) {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.invoiceService.getInvoicesApproved(useCache).subscribe({
              next: (res) => {
                res.forEach(invoice => {
                  invoice.vendorGroupId = this.vendors.find(i => i.id === invoice.vendorId)?.vendorGroupId;
                });
                const filteredInvoices = res.filter(i => !this.selectedVendorGroupId || i.vendorGroupId === this.selectedVendorGroupId);
                return resolve(filteredInvoices);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.invoiceService.updateInvoice(encodeURIComponent(key), values).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.invoiceService.deleteInvoice(encodeURIComponent(key)).subscribe({
              next: () => {
                return resolve();
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      }
    });
  }

  onToolbarPreparing(e, templateName: string, templateName2: string) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.push({
      location: 'before',
      locateInMenu: 'auto',
      template: templateName
    });

    toolbarItems.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        template: templateName2
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          width: 170,
          type: 'default',
          text: 'Submit to Accounts',
          onClick: this.processInvoices.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: this.refresh.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          type: 'default',
          stylingMode: 'outlined',
          text: 'Reset Layout',
          onClick: this.clearStatePersistance.bind(this)
        }
      });
  }

  setVendorCellValue(rowData, value) {
    rowData.vendorId = value;
    this.lastVendorId = value;
  }

  async setOrderNumberCellValue(rowData, value, originalData) {
    rowData.orderNumber = value;

    const orderNumberArray = value.split('.');
    // let validOrder = false;
    rowData.purchaseOrderId = null;
    rowData.poNumber = null;
    rowData.jobId = null;

    if (orderNumberArray.length > 1) {
      const jobNumber = orderNumberArray[0];
      const job = this.jobs.find(i => i.jobNumber === jobNumber);
      if (job) {
        let orderNumber = orderNumberArray[1] + (orderNumberArray.length <= 2 ? '' : '.' + orderNumberArray[2]);

        if (orderNumberArray.length > 3) {
          orderNumber += '.' + orderNumberArray[3];
        }
        // look this up to see if we can find the matching PO
        await this.getPurchaseOrder(job.id, orderNumber)
          .then(
            res => {
              if (res) {
                rowData.purchaseOrderId = res.id;
                rowData.jobId = job.id;
                rowData.poNumber = orderNumber;
              }
            });
      }
    }
  }

  getPurchaseOrder(jobId: number, orderNumber: string): Promise<PurchaseOrder> {
    return new Promise((resolve, reject) =>
      this.subscriptions.push(
        this.poService.getPurchaseOrder(jobId, orderNumber).subscribe({
          next: (res) => {
            return resolve(res);
          }, error: (err) => {
            return reject(this.globalService.returnError(err));
          }
        })
      ));
  }

  calculateJobNumber(data) {
    return this.jobs.find(i => i.id === data.jobId)?.jobNumber;
  }

  calculateConnectionStatus(data) {
    const invoiceError = this.invoiceService.invoiceErrors.find(i => i.invoiceId === data.id);
    if (invoiceError) {
      if (invoiceError.errorString.toLowerCase().indexOf("pending processing in business central") != -1) {
        return invoiceError.errorString;
      } 
      return 'Integration Error: ' + invoiceError.errorString;
    }

    const job = this.jobs.find(i => i.id === data.jobId);
    if (job) {
      const division = this.divisionService.divisions.find(i => i.id === job.divisionId);

      if (!division) {
        return 'Division not specified';
      }

      if (!division.accountingSystemTenantId || division.accountingSystemTenantId.trim() === '') {
        return 'Division not linked to accounts';
      }

      // Xero also requires a Job Tracking Category
      if (this.accountingSystemType == 1) {
        if (!division.jobTrackingCategoryId || division.jobTrackingCategoryId.trim() === '') {
          return 'Division not linked to a Job Tracking Category';
        }
      }

      const vendorForLine = this.vendors.find(i => i.id === data.vendorId);

      if (!vendorForLine?.glAccountTypeId) {
        return 'Vendor not set up with a Vendor Type';
      }

      if (!vendorForLine?.vendorGroupId) {
        return 'Vendor not set up with a Vendor Group';
      }

      const divisionAccount = this.divisionService.divisionAccounts
        .find(i => i.glAccountTypeId === vendorForLine.glAccountTypeId && i.divisionId === job.divisionId);

      if (!divisionAccount?.accountId || divisionAccount.accountId.trim() === '') {
        const glAccountType = this.maintenanceService.glAccountTypes.find(i => i.id === vendorForLine.glAccountTypeId);
        return 'Division Account not set up for Vendor Type: ' + glAccountType?.description;
      }

      const vendorAccount = this.vendorAccounts.find(i => i.vendorId === data.vendorId && i.tenantId === division.accountingSystemTenantId);
      if (!vendorAccount?.accountId?.trim()) {
        return 'Vendor Not Connected';
      }
    } else {
      return 'Job Invalid';
    }

    const dateNowString = formatDate(new Date(), 'yyyy-MM-dd');
    var d = new Date();
    d.setDate(d.getDate() + 14);
    const date1MonthString = formatDate(d, 'yyyy-MM-dd');
    const vendor = this.vendors.find(i => i.id === data.vendorId);

    if (vendor?.incomeProtectionExpiry && vendor.incomeProtectionExpiry <= dateNowString) {
      return 'Income Protection Expired';
    }
    if (vendor?.publicLiabilityExpiry && vendor.publicLiabilityExpiry <= dateNowString) {
      return 'Public Liability Expired';
    }
    if (vendor?.workCoverExpiry && vendor.workCoverExpiry <= dateNowString) {
      return 'WorkCover Expired';
    }

    // check for upcoming expiries
    let upcomingExpiry = (vendor?.incomeProtectionExpiry && vendor.incomeProtectionExpiry <= date1MonthString)
      || (vendor?.publicLiabilityExpiry && vendor.publicLiabilityExpiry <= date1MonthString)
      || (vendor?.workCoverExpiry && vendor.workCoverExpiry <= date1MonthString);

    return 'OK to Submit' + (upcomingExpiry ? ' - NOTE: An insurance is expiring in the next 2 weeks!' : '');
  }

  downloadAttachment(e) {
    // download attachment
    this.loadingFile = true;
    this.subscriptions.push(
      this.invoiceService.getInvoiceAttachment(e.row.data.id).subscribe({
        next: (res) => {
          if (res) {
            this.poService.convertBlobAndOpen(res.attachment, res.attachmentTypeId, res.attachmentName, true, e.row.data.id);
          } else {
            this.notiService.showInfo('No invoice attached');
          }
          this.loadingFile = false;
        }, error: (err) => {
          this.notiService.notify(err);
          this.loadingFile = false;
        }
      })
    );
  }

  markOnHold(e) {
    this.loadingFile = true;
    this.subscriptions.push(
      this.invoiceService.updateInvoice(e.row.data.id, { invoiceStatusId: InvoiceStatusTypeEnum.OnHold }).subscribe({
        next: () => {
          this.loadingFile = false;
          this.loadData(true);
        }, error: (err) => {
          this.notiService.notify(err);
          this.loadingFile = false;
        }
      })
    );
  }

  processInvoices() {
    // authorise - ensure we can only click once
    if (!this.loadingFile) {
      this.loadingFile = true;
      this.subscriptions.push(
        this.accountingSystemService.submitInvoices(this.selectedVendorGroupId, this.includeExpiredInsurances,
          { ids: this.selectedRecords }).subscribe({
            next: () => {
              this.loadingFile = false;
              this.selectedRecords = [];
              this.loadData(true);
            }, error: (err) => {
              this.notiService.notify(err);
              this.loadingFile = false;
              this.grid.instance.refresh();
            }
          })
      );
    }
  }

  clearStatePersistance() {
    this.loading = true;
    localStorage.removeItem('invoices-approved');
    setTimeout(() => {
      this.loading = false;
    }, 300); // wait
  }

  calculateVendorSortValue(data) {
    return this.vendors.find(i => i.id === data.vendorId)?.vendorName;
  }

  refresh() {
    this.selectedRecords = [];
    this.loadData(false);
  }

  openPO(e) {
    if (e.row.data.jobId && e.row.data.purchaseOrderId) {
      const jobNumber = this.jobs.find(i => i.id === e.row.data.jobId)?.jobNumber;
      const dataRecord = {
        purchaseOrderIds: [e.row.data.purchaseOrderId],
        emailAddresses: [],
        ccToSelf: false,
        download: true,
        printPrices: true
      };

      this.notiService.showInfo('downloading order');

      this.subscriptions.push(
        this.poService.sendPurchaseOrders(e.row.data.jobId, dataRecord)
          .subscribe({
            next: (orderResponse) => {
              this.poService.convertAndSave(orderResponse.pdfOrders, jobNumber, e.row.data.poNumber, false);
            },
            error: (err) => {
              this.notiService.notify(err);
            }
          })
      );
    }
  }

  openCostCentre(e) {
    if (e.row.data.jobId && e.row.data.costCentreId) {
      this.jobService.setCurrentJob(this.jobs.find(i => i.id === e.row.data.jobId)?.jobNumber);
      localStorage.setItem('jobANNX-costcentreId', e.row.data.costCentreId);
      window.open(environment.applicationUrl + 'orders?coy=' + this.globalService.getCurrentCompanyId());
    }
  }
}
