<div class="modal-header">
  <h2 class="modal-title">Roles for job {{jobNumber}}</h2>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <dx-data-grid #dataGrid *ngIf="!loading" [dataSource]="jobRoles" [remoteOperations]="false" [showBorders]="true"
    [focusedRowEnabled]="true" [showRowLines]="true" [wordWrapEnabled]="true" [rowAlternationEnabled]="true"
    [scrolling]="{ mode: 'standard' }">

    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-selection mode="single"></dxo-selection>

    <dxi-column dataField="companyRoleDescription"></dxi-column>
    <dxi-column dataField="userId" caption='Assignee' [calculateCellValue]="calculateUser"></dxi-column>

    <dxi-column [width]="30" cellTemplate="emailCellTemplate" [calculateCellValue]="calculateEmailAddress">
    </dxi-column>

    <div *dxTemplate="let cellInfo of 'emailCellTemplate'">
      <a *ngIf="cellInfo.value && cellInfo.value !== ''" [href]="getEmailString(cellInfo.value)"
        class="material-icons">email</a>
    </div>
  </dx-data-grid>
</div>

<div class="modal-footer">
  <button *ngIf="clientEmailString" class="btn btn-sm btn-light">
    <a [href]="clientEmailString">Email Client</a>
  </button>
</div>
