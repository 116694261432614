import { InvoiceService } from './../../services/felixApi/invoice.service';
import { GridService } from './../../services/grid.service';
import { BackChargeForm, Invoice, InvoiceStatusTypeEnum } from './../../dtos/invoice';
import { AuthService } from './../../services/auth.service';
import { UserService } from './../../services/felixApi/user.service';
import { PurchaseOrder } from '../../dtos/purchase-order';
import { PoService } from '../../services/felixApi/po.service';
import { JobService } from '../../services/felixApi/job.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Vendor } from '../../dtos/vendor';
import { MaintenanceService } from '../../services/felixApi/maintenance.service';
import { GlobalService } from '../../services/global.service';
import { NotificationService } from '../../services/notification.service';
import { Job } from '../../dtos/job';
import { EstimatingService } from '../../services/felixApi/estimating.service';
import { User } from '../../dtos/user';
import CustomStore from 'devextreme/data/custom_store';
import { BackChargesService } from '../../services/felixApi/back-charges.service';
import { BackCharge, BackChargeStatusEnum, BackChargeStatuses, VendorAgreementTypes } from '../../dtos/back-charge';
import { DxDataGridComponent } from 'devextreme-angular';
import { PriceFileItem } from '../../dtos/price-file-item';

@Component({
  selector: 'js-back-charges',
  templateUrl: './back-charges.component.html',
  styleUrls: ['./back-charges.component.scss']
})
export class BackChargesComponent implements OnInit, OnDestroy {

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  dataSource: CustomStore;
  vendors: Vendor[];
  loadingData: boolean;
  gridHeight: number;
  jobs: Job[] = [];
  users: User[];
  includeCompleted = false;
  isAdmin = false;
  vendorAgreementTypes = VendorAgreementTypes;
  backChargeStatuses = BackChargeStatuses;
  purchaseOrders: PurchaseOrder[] = [];
  editPopupWidth: number;
  editPopupHeight: number;
  dropDownOptions: object;
  canEdit: boolean;
  costCentres: PriceFileItem[] = [];
  creditNotesForJobAndVendor: Invoice[] = [];
  ccToSelf = true;
  saveAndSendPopupVisible = false;
  currentRowIndex: number;
  isNewRow: boolean;
  sendNoticeToVendor = false;
  showGeneratePOModal: boolean;
  vendorToRectify: Vendor;
  reason: string;
  jobId: number;
  forceChange: boolean;
  loading: boolean;
  showCreditNoteForm: boolean;
  // sendCreditEmail: boolean;
  currentRowData: BackCharge;
  backChargeForm: BackChargeForm = new BackChargeForm();
  invoiceDateOptions: object;
  updateButtonOptions = {
    text: 'Create & Send Back Charge Credit',
    type: 'default',
    stylingMode: 'contained',
    useSubmitBehavior: true
  };
  purchaseOrder: PurchaseOrder;
  job: Job;
  invoiceVendorOptions: object;
  vendorOptions: object;
  invoiceTotalIncOptions: object;
  creditFormHeight: number;
  creditFormWidth: number;

  constructor(
    private globalService: GlobalService,
    private backChargesService: BackChargesService,
    private invoiceService: InvoiceService,
    private notiService: NotificationService,
    private jobService: JobService,
    private poService: PoService,
    private userService: UserService,
    private estimatingService: EstimatingService,
    private maintenanceService: MaintenanceService,
    public gridService: GridService,
    private authService: AuthService) {
    this.dropDownOptions = { width: 700 };
    this.calculateSiteAddress = this.calculateSiteAddress.bind(this);
    this.calculateVendorSortValue = this.calculateVendorSortValue.bind(this);
    this.setVendorToBackCharge = this.setVendorToBackCharge.bind(this);
    this.onEditingStart = this.onEditingStart.bind(this);
    this.sendNotice = this.sendNotice.bind(this);
    this.cancelClickHandler = this.cancelClickHandler.bind(this);
    this.saveClickHandler = this.saveClickHandler.bind(this);
    this.calculateJobSortValue = this.calculateJobSortValue.bind(this);
    this.generatePO = this.generatePO.bind(this);
    this.generateCredit = this.generateCredit.bind(this);
    this.calculateCostCentreSortValue = this.calculateCostCentreSortValue.bind(this);
    this.onJobSelectionChanged = this.onJobSelectionChanged.bind(this);
    this.setPurchaseOrderIdCellValue = this.setPurchaseOrderIdCellValue.bind(this);
    this.setJobIdCellValue = this.setJobIdCellValue.bind(this);
    this.onInitNewRow = this.onInitNewRow.bind(this);
  }

  ngOnInit(): void {
    if (this.authService.isAdminOrSuper()
      || this.authService.areaPermissions.find(i => i.applicationArea === 'BackCharges')?.permissionType === 'Admin') {
      this.isAdmin = true;
    }

    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          this.setHeightWidths();
        }
      )
    );

    this.setHeightWidths();
    this.loadData(false);
  }

  setHeightWidths() {
    this.gridHeight = window.innerHeight - 107;
    this.editPopupWidth = window.innerWidth < 910 ? window.innerWidth - 10 : 900;
    this.editPopupHeight = window.innerHeight < 910 ? window.innerHeight - 10 : 900;

    this.creditFormWidth = window.innerWidth < 610 ? window.innerWidth - 10 : 600;
    this.creditFormHeight = window.innerHeight < 630 ? window.innerHeight - 10 : 620;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  loadData(useCache: boolean) {
    this.subscriptions.push(
      this.invoiceService.getInvoicesDataForBackCharges(useCache)
        .subscribe({
          next: () => {
            this.vendors = this.maintenanceService.allVendors;
            this.jobs = this.jobService.jobs;

            this.users = this.userService.users;
            this.costCentres = this.estimatingService.costCentres;

            this.loadingData = false;
            this.setUpDataSource();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loadingData = false;
          }
        })
    );
  }

  setUpDataSource() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.backChargesService.getBackCharges(this.includeCompleted)
              .subscribe({
                next: (res) => {
                  return resolve(res);
                }, error: (err) => {
                  return reject(this.globalService.returnError(err));
                }
              })
          ));
      },
      insert: async (values) => {
        if (this.sendNoticeToVendor) {
          values.sendNoticeToVendor = true;
          values.ccToSelf = this.ccToSelf;
        }
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.backChargesService.addBackCharge(values).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) => {
          this.subscriptions.push(
            this.backChargesService.updateBackCharge(encodeURIComponent(key), values).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            }))
        });
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.backChargesService.deleteBackCharge(encodeURIComponent(key)).subscribe({
              next: () => {
                return resolve();
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      }
    });

  }

  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxCheckBox',
        options: {
          text: 'Include Completed & Cancelled',
          value: this.includeCompleted,
          rtlEnabled: true,
          onValueChanged: this.includeCompletedChanged.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: this.refresh.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          type: 'default',
          stylingMode: 'outlined',
          text: 'Reset Layout',
          onClick: this.clearStatePersistance.bind(this)
        }
      }
    );
  }

  includeCompletedChanged(e) {
    this.includeCompleted = e.value;
    this.setUpDataSource();
  }

  calculateVendorSortValue(data) {
    return this.vendors.find(i => i.id === data.vendorId)?.vendorName;
  }

  calculateSiteAddress(data) {
    return this.jobs.find(i => i.id === data.jobId)?.jobAddressString;
  }

  calculateBackChargeNumber(data) {
    if (data.backChargeNumber) {
      return 'BC' + data.backChargeNumber.toString().padStart(4, '0');
    }
    return '';
  }

  onInitNewRow(e) {
    e.data.vendorAgreementTypeId = this.vendorAgreementTypes.find(i => i.description === 'Pending').id;
    e.data.backChargeStatusId = BackChargeStatusEnum.Pending;
    this.isNewRow = true;
  }

  refresh() {
    this.loadData(false);
  }

  clearStatePersistance() {
    this.loadingData = true;
    localStorage.removeItem('back-charges');
    setTimeout(() => {
      this.loadingData = false;
    }, 300); // wait
  }

  onJobSelectionChanged(cellInfo, e, event) {
    if (event.selectedRowKeys.length > 0) {
      this.grid.instance.cellValue(cellInfo.rowIndex, 'jobId', event.selectedRowKeys[0]);
      e.component.close();
    }
  }

  onPOSelectionChanged(cellInfo, e, event) {
    if (event.selectedRowKeys.length > 0) {
      this.grid.instance.cellValue(cellInfo.rowIndex, 'purchaseOrderId', event.selectedRowKeys[0]);
      e.component.close();
    }
  }

  setJobIdCellValue(rowData, value, originalValue) {
    if (originalValue.jobId !== value) {
      rowData.jobId = value;
      rowData.purchaseOrderId = null;
      rowData.creditNoteId = null;
      this.getPurchaseOrdersAndInvoicesForJob(value, rowData.vendorToBackChargeId ?? originalValue.vendorToBackChargeId);
    }
  }

  setPurchaseOrderIdCellValue(rowData, value) {
    rowData.purchaseOrderId = value;
    rowData.poNumber = this.purchaseOrders.find(i => i.id === value)?.poNumber ?? '';
  }

  setVendorToBackCharge(rowData, value) {
    if (value) {
      rowData.vendorToBackChargeId = value;
      const vendor = this.vendors.find(i => i.id === value);
      rowData.emailAddress = vendor?.email;
    } else {
      rowData.vendorToBackChargeId = null;
    }
  }

  calculateJobSortValue(data) {
    const job = this.jobs.find(i => i.id === data.jobId);
    if (job) {
      return job.jobNumber;
    }
    return '';
  }

  onEditingStart(e) {
    this.isNewRow = false;
    this.sendNoticeToVendor = false;
    this.canEdit = (e.data.sentDate || e.data.purchaseOrderId) ? false : true;
    this.currentRowIndex = this.grid.instance.getRowIndexByKey(e.key);
    this.purchaseOrders = [];
    this.currentRowData = e.data;
    this.getPurchaseOrdersAndInvoicesForJob(e.data.jobId, e.data.vendorToBackChargeId);
  }

  getPurchaseOrdersAndInvoicesForJob(jobId: number, vendorId: number) {
    this.purchaseOrders = [];
    this.creditNotesForJobAndVendor = [];

    if (jobId) {
      this.subscriptions.push(
        this.poService.getOrdersAndInvoicesForJob(jobId)
          .subscribe({
            next: (res) => {
              this.purchaseOrders = res;
              this.creditNotesForJobAndVendor = this.poService.invoicesForThisJob.filter(i => i.vendorId === vendorId && i.totalExGST < 0);
            },
            error: (err) => {
              this.notiService.notify(err);
            }
          })
      );
    }
  }

  calculateCostCentreSortValue(data) {
    return this.costCentres.find(i => i.id === data?.costCentreId)?.orderNumber;
  }

  sendNotice() {
    // send notice
    this.saveAndSendPopupVisible = true;
  }

  saveAndSend() {
    this.saveAndSendPopupVisible = false;
    if (!this.isNewRow) {
      this.grid.instance.cellValue(this.currentRowIndex, 'sendNoticeToVendor', true);
      this.grid.instance.cellValue(this.currentRowIndex, 'ccToSelf', this.ccToSelf);
    } else {
      this.sendNoticeToVendor = true;
    }
    this.grid.instance.saveEditData();
  }

  generatePO() {
    const purchaseOrderId = this.grid.instance.cellValue(this.currentRowIndex, 'purchaseOrderId');
    if (purchaseOrderId) {
      this.notiService.showInfo('Purchase Order already assigned.');
      return;
    }

    const vendorToRectifyId = this.grid.instance.cellValue(this.currentRowIndex, 'vendorToRectifyId');
    if (!vendorToRectifyId) {
      this.notiService.showInfo('Please select a vendor to rectify.');
      return;
    }

    this.reason = this.grid.instance.cellValue(this.currentRowIndex, 'reason');
    if (!this.reason || this.reason.trim() === '') {
      this.notiService.showInfo('Please enter a reason.');
      return;
    }

    this.jobId = this.grid.instance.cellValue(this.currentRowIndex, 'jobId');
    if (!this.jobId) {
      this.notiService.showInfo('Please a job.');
      return;
    }

    this.vendorToRectify = this.vendors.find(i => i.id === vendorToRectifyId);
    this.forceChange = !this.forceChange;
    this.showGeneratePOModal = true;
  }

  setPurchaseOrderId(purchaseOrderId: number) {
    this.showGeneratePOModal = false;
    this.grid.instance.cellValue(this.currentRowIndex, 'purchaseOrderId', purchaseOrderId);
    this.grid.instance.cellValue(this.currentRowIndex, 'poNumber', this.purchaseOrders.find(i => i.id === purchaseOrderId)?.poNumber);
  }

  generateCredit() {
    const creditNoteId = this.grid.instance.cellValue(this.currentRowIndex, 'creditNoteId');
    if (creditNoteId) {
      this.notiService.showInfo('Credit Note already assigned.');
      return;
    }

    const purchaseOrderId = this.grid.instance.cellValue(this.currentRowIndex, 'purchaseOrderId');
    if (!purchaseOrderId) {
      this.notiService.showInfo('Purchase Order must be generated or assigned.');
      return;
    }

    const vendorToBackChargeId = this.grid.instance.cellValue(this.currentRowIndex, 'vendorToBackChargeId');
    if (!vendorToBackChargeId) {
      this.notiService.showInfo('Please select a vendor to back charge.');
      return;
    }

    const vendor = this.vendors.find(i => i.id === vendorToBackChargeId);
    if (!vendor) {
      this.notiService.showInfo('Vendor not found.');
      return;
    }

    this.job = this.jobService.jobs.find(i => i.id === this.currentRowData.jobId);
    this.purchaseOrder = this.purchaseOrders.find(i => i.id === this.currentRowData.purchaseOrderId);

    this.backChargeForm.vendorId = vendorToBackChargeId;
    this.backChargeForm.invoicedVendor = vendor.vendorName;
    this.backChargeForm.jobNumber = this.job?.jobNumber;
    this.backChargeForm.jobAddressString = this.job?.jobAddressString;
    this.backChargeForm.invoiceNumber = this.calculateBackChargeNumber(this.currentRowData);
    this.backChargeForm.invoiceDate = new Date;
    this.backChargeForm.emailSubject = `Notice of Back-Charge for job ${this.job?.jobAddressString}`;
    this.backChargeForm.comment = this.currentRowData.reason;
    this.backChargeForm.ccToSelf = true;
    this.backChargeForm.totalExGST = this.currentRowData.amount;
    this.backChargeForm.totalGST = (this.currentRowData.amount * 0.1);
    this.backChargeForm.totalIncGST = this.currentRowData.amount + (this.currentRowData.amount * 0.1);
    this.backChargeForm.sendToEmail = vendor.email;

    this.invoiceVendorOptions = {
      items: this.vendors,
      required: true,
      displayExpr: 'vendorName',
      valueExpr: 'id'
    };

    this.vendorOptions = {
      items: this.vendors,
      required: true,
      displayExpr: 'vendorName',
      valueExpr: 'id',
      disabled: true
    };

    this.invoiceDateOptions = {
      type: 'date',
      useMaskBehavior: true,
      disabled: true
    };

    this.invoiceTotalIncOptions = {
      type: 'number',
      format: '#,##0.00',
      disabled: true
    };

    this.showCreditNoteForm = true;
  }

  generateCreditGo() {
    this.showCreditNoteForm = false;
    this.loading = true;

    // we can just pick the first batch
    const firstBatch = this.invoiceService.invoiceBatches[0];

    const invoiceData = new Invoice(this.backChargeForm.vendorId,
      this.backChargeForm.invoiceNumber, this.backChargeForm.invoiceDate,
      this.job.jobNumber + '.' + this.purchaseOrder.poNumber, this.backChargeForm.comment,
      -this.backChargeForm.totalExGST, -this.backChargeForm.totalGST, -this.backChargeForm.totalIncGST,
      this.purchaseOrder.id, firstBatch?.id, true, this.authService.getCurrentUserId(),
      new Date(), InvoiceStatusTypeEnum.Approved,
      this.backChargeForm.sendToEmail, this.backChargeForm.ccToSelf, this.backChargeForm.emailSubject);

    this.subscriptions.push(
      this.invoiceService.createBackChargeCredit(invoiceData)
        .subscribe({
          next: (res) => {
            this.loading = false;
            this.notiService.showSuccess('Back-Charged Credit Created');
            this.grid.instance.cellValue(this.currentRowIndex, 'creditNoteId', res.id);
            this.grid.instance.cellValue(this.currentRowIndex, 'backChargeStatusId', BackChargeStatusEnum.Completed);
            this.grid.instance.saveEditData();
          },
          error: (err) => {
            this.loading = false;
            this.notiService.notify(err);
          }
        })
    );
  }

  cancelClickHandler() {
    this.grid.instance.cancelEditData();
  }

  saveClickHandler(e) {
    this.grid.instance.saveEditData();
  }

  onPODropDownChanged(cellInfo, e) {
    if (!e.value) {
      this.grid.instance.cellValue(this.currentRowIndex, 'purchaseOrderId', null);
    }
  }
}
