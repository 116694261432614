import { IntegrationService } from './../../../services/felixApi/integration.service';
import { NotificationService } from './../../../services/notification.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { GridService } from '../../../services/grid.service';
import { Subscription } from 'rxjs';
import CustomStore from 'devextreme/data/custom_store';
import { ClaimLoadError } from '../../../dtos/claim-load-error';

@Component({
  selector: 'js-job-key-dates',
  templateUrl: './job-key-dates.component.html',
  styleUrls: ['./job-key-dates.component.scss']
})
export class JobKeyDatesComponent implements OnInit, OnDestroy {

  dataSource: CustomStore;
  subscriptions: Subscription[] = [];
  loadingFile = false;
  fileLoaded = false;
  fileValue: any[] = [];
  gridHeight: number;
  skipMissingJobs = false;
  errors: ClaimLoadError[];

  constructor(
    protected gridService: GridService,
    private notiService: NotificationService,
    private integrationService: IntegrationService
  ) { }

  ngOnInit(): void {
    this.setUpDataSource();
    this.gridHeight = window.innerHeight - 490;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  loadFileGo() {
    this.loadingFile = true;
    const formData: FormData = new FormData();
    formData.append('image', this.fileValue[0], this.fileValue[0].name);

    this.subscriptions.push(
      this.integrationService
        .loadHistoryJobKeyDates(formData, this.skipMissingJobs)
        .subscribe({
          next: (errors) => {
            this.errors = errors;
            this.loadingFile = false;
            this.fileLoaded = true;
            this.setUpDataSource();
          }, error: (err) => {
            this.notiService.notify(err);
            this.fileValue = [];
            this.loadingFile = false;
          }
        })
    );
  }

  setUpDataSource() {
    this.dataSource = new CustomStore({
      key: 'rowNumber',
      load: async () => this.errors
    });
  }
}
